import moment from "moment";

export function createAtToDate(dateTime) {
  const date = dateTime.split("T")[0];
  return moment(date).format("DD/MM/YYYY");
}
export function createAtToTime(timestamp) {
  return timestamp.split(/[T.]/)[1];
}

export function convertPriceToIndFormat(price) {
  return parseInt(price).toLocaleString("en-IN");
}

/**
 *
 * @param {File|undefined} image
 */
export function generateLocalImageUrl(image) {
  if (image) {
    return URL.createObjectURL(image);
  } else {
    return "";
  }
}

/**
 *
 * @param {File | undefined} file
 * @returns File size in kb
 */
export function getFileSize(file) {
  if (file !== undefined) {
    return file.size / 1000;
  } else {
    return 1;
  }
}
