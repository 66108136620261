import { message } from "antd";
import { HttpResponse } from "../model/HttpResponse";
import { HTTPStatus } from "./helperObj";

/**
 *
 * @param {object} errorObj
 */
function getErrorMessage(errorObj) {
  let errorMessage = "";
  const keys = Object.keys(errorObj);
  if (keys.length > 0) {
    errorMessage = errorObj[keys[0]];
  }
  return errorMessage;
}

export function handleException(err) {
  switch (err.response.status) {
    case HTTPStatus.BAD_REQUEST:
      return new HttpResponse(
        HTTPStatus.BAD_REQUEST,
        null,
        getErrorMessage(err.response.data.errors?err.response.data.errors:err.response.data)
      );
    case HTTPStatus.FORBIDDEN:
      return new HttpResponse(
        HTTPStatus.FORBIDDEN,
        null,
        err.response.statusText
      );
    default:
      return new HttpResponse(
        err.response.status,
        null,
        err.response.statusText
      );
  }
}

/**
 * @param {HttpResponse} exception
 * @param {any} history
 */
export function handleControllerResultException(exception, history) {
  if (exception.statusCode === 401) {
    localStorage.removeItem("userDetails");
    history.push("/login");
  } else {
    message.error(exception.error);
  }
}
