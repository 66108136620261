import React from "react";
import RichEditor from "../../components/global/RichEditor/RichEditor";
import InputField from "../../components/global/InputField";
import { generateLocalImageUrl } from "../../../util/util";
/**
 * @typedef {object} Props
 * @property {string} heading
 * @property {import("@tiptap/react").Editor | null} editor
 * @property {(image:File|undefined, imageUrl:string)=>void} setImage
 * @property {string} imageUrl
 * @property {React.CSSProperties} styles
 * @property {boolean} showInputError
 */

/**
 * @param {Props} props
 */
export default function BlogElement({
  editor,
  setImage,
  heading,
  styles,
  imageUrl = "",
  showInputError = false,
}) {
  return (
    <div style={{ ...styles }}>
      <p
        style={{
          color: "white",
          backgroundColor: "#212121",
          fontWeight: "600",
          fontSize: "1.5rem",
          padding: "10px",
        }}
      >
        {heading}
      </p>
      <InputField
        label="Content image"
        inputFieldAttributes={{
          type: "file",
          onChange: (e) =>
            setImage(
              e.target.files[0],
              generateLocalImageUrl(e.target.files[0])
            ),
        }}
        stylesProps={{ marginBottom: "2rem" }}
        showInputError={showInputError}
        showErrorCondition={imageUrl === ""}
      />
      {imageUrl !== "" && (
        <img
          src={imageUrl}
          alt={heading}
          width="100%"
          height="400px"
          style={{ objectFit: "fill", marginBottom: "2rem" }}
        />
      )}
      <RichEditor editor={editor} />
    </div>
  );
}
