import React, { useCallback, useEffect, useState } from "react";
import { Tab } from "react-bootstrap";
import { cancelRequest } from "../../../../controller/collectionController";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { handleControllerResultException } from "../../../../util/handleException";
import Table from "../../../components/global/Table/Table";
import { userTicketChanceCancelStatus } from "../../../../controller/ticketController";
import { HTTPStatus } from "../../../../util/helperObj";
import { message } from "antd";
import HorizontalTabs from "../../../components/global/HorizontalTabs";
import CancelRequestListItem from "./CancelRequestListItem";

/**
 * @type {import("../../../../model/UserTicketModal").UserAssignedTicket[]}
 */
const cancelTicketRequestListType = [];

export default function CancelRequest() {
  const [cancelTicketRequestList, setCancelTicketRequestList] = useState(
    cancelTicketRequestListType
  );
  const [currentTab, setCurrentTab] = useState("request");

  const history = useHistory();

  const handleCancelRequestList = useCallback(
    async (listType) => {
      const res = await cancelRequest(listType);
      if (res[0] !== null) {
        setCancelTicketRequestList(res[0]);
      } else {
        handleControllerResultException(res[1], history);
      }
    },
    [history]
  );

  useEffect(() => {
    handleCancelRequestList("request");
  }, [handleCancelRequestList]);

  async function handleTicketStatus(id) {
    const res = await userTicketChanceCancelStatus(id);
    if (res.statusCode === HTTPStatus.SUCCESS) {
      message.success(res.success);
      setCancelTicketRequestList((prevState) =>
        prevState.filter((data) => data.id !== id)
      );
    } else {
      handleControllerResultException(res, history);
    }
  }
  return (
    <Tab.Pane eventKey="CancelRequest">
      <HorizontalTabs
        tabList={["request", "canceled"]}
        currentActiveTab={currentTab}
        onClick={(tabName) => {
          setCurrentTab(tabName);
          handleCancelRequestList(tabName);
        }}
      />
      <div style={{ overflowX: "auto", marginTop: "2rem" }}>
        <Table>
          <thead>
            <tr>
              <th>Pnr no</th>
              <th>Customer name</th>
              <th>Customer email</th>
              <th>Customer phone no</th>
              <th>Manager</th>
              <th>Package Category</th>
              <th>Package type</th>
              {currentTab === "request" && <th>Cancel Request</th>}
              {currentTab === "canceled" && <th>Payment</th>}
            </tr>
          </thead>
          <tbody>
            {cancelTicketRequestList.length > 0 &&
              cancelTicketRequestList.map((ticket) => (
                <CancelRequestListItem
                  ticket={ticket}
                  currentTab={currentTab}
                  handleTicketStatus={handleTicketStatus}
                  key={ticket.id}
                />
              ))}
          </tbody>
        </Table>
      </div>
    </Tab.Pane>
  );
}
