import {
  QUICK_TICKET_CUSTOMER_EMAIL,
  QUICK_TICKET_CUSTOMER_NAME,
  QUICK_TICKET_CUSTOMER_PHONE_NUMBER,
  QUICK_TICKET_DAY_SCHEDULE,
  QUICK_TICKET_MANAGER_EMAIL,
  QUICK_TICKET_MANAGER_EXPERIENCE,
  QUICK_TICKET_MANAGER_NAME,
  QUICK_TICKET_MANAGER_PHONE_NUMBER,
  QUICK_TICKET_TOUR_DURATION,
  QUCIK_TICKET_ECONOMY_COST,
  QUICK_TICKET_ECONOMY_CHILD_ADE_1_5_COST,
  QUICK_TICKET_ECONOMY_CHILD_ADE_5_12_COST,
  QUCIK_TICKET_DELIGHT_COST,
  QUICK_TICKET_DELIGHT_CHILD_ADE_1_5_COST,
  QUICK_TICKET_DELIGHT_CHILD_ADE_5_12_COST,
  QUCIK_TICKET_CLASSIC_COST,
  QUICK_TICKET_CLASSIC_CHILD_ADE_1_5_COST,
  QUICK_TICKET_CLASSIC_CHILD_ADE_5_12_COST,
  QUCIK_TICKET_LUXURY_COST,
  QUICK_TICKET_LUXURY_CHILD_ADE_5_12_COST,
  QUICK_TICKET_LUXURY_CHILD_ADE_1_5_COST,
  QUCIK_TICKET_PREMIUM_COST,
  QUICK_TICKET_PREMIUM_CHILD_ADE_1_5_COST,
  QUICK_TICKET_PREMIUM_CHILD_ADE_5_12_COST,
  QUCIK_TICKET_ELITE_COST,
  QUICK_TICKET_ELITE_CHILD_ADE_1_5_COST,
  QUICK_TICKET_ELITE_CHILD_ADE_5_12_COST,
  QUICK_TICKET_HOTEL_DETAILS,
  QUICK_TICKET_GUEST_COUNT,
} from "../../actions/QuickTicketAction";
import "./QuickTicketStateType";
/**
 * @type {QuickTicket}
 */
const initialState = {
  customerName: "",
  customerEmail: "",
  customerPhoneNumber: "",
  managerName: "",
  managerEmail: "",
  managerExperience: "",
  managerPhoneNumber: "",
  tourDuration: "",
  daySchedule: [],
  economyCost: "",
  economyChildOneToFiveCost: "",
  economyChildFiveToTwelveCost: "",
  delightCost: "",
  delightChildOneToFiveCost: "",
  delightChildFiveToTwelveCost: "",
  luxuryCost: "",
  luxuryChildOneToFiveCost: "",
  luxuryChildFiveToTwelveCost: "",
  classicCost: "",
  classicChildOneToFiveCost: "",
  classicChildFiveToTwelveCost: "",
  premiumCost: "",
  premiumChildOneToFiveCost: "",
  premiumChildFiveToTwelveCost: "",
  eliteCost: "",
  eliteChildOneToFiveCost: "",
  eliteChildFiveToTwelveCost: "",
  hotelDetails: {},
  guestCount: "",
};

/**
 *
 * @param {QuickTicket} state
 * @param {{type:string, payload:string|number}} action
 * @returns {QuickTicket}
 */
export function quickTicketReducer(state = initialState, action) {
  switch (action.type) {
    case QUICK_TICKET_CUSTOMER_NAME:
      return {
        ...state,
        customerName: action.payload,
      };
    case QUICK_TICKET_CUSTOMER_EMAIL:
      return {
        ...state,
        customerEmail: action.payload,
      };
    case QUICK_TICKET_CUSTOMER_PHONE_NUMBER:
      return {
        ...state,
        customerPhoneNumber: action.payload,
      };
    case QUICK_TICKET_MANAGER_NAME:
      return {
        ...state,
        managerName: action.payload,
      };
    case QUICK_TICKET_MANAGER_PHONE_NUMBER:
      return {
        ...state,
        managerPhoneNumber: action.payload,
      };
    case QUICK_TICKET_MANAGER_EMAIL:
      return {
        ...state,
        managerEmail: action.payload,
      };
    case QUICK_TICKET_MANAGER_EXPERIENCE:
      return {
        ...state,
        managerExperience: action.payload,
      };
    case QUICK_TICKET_TOUR_DURATION:
      return {
        ...state,
        tourDuration: action.payload,
      };
    case QUICK_TICKET_DAY_SCHEDULE:
      return {
        ...state,
        daySchedule: action.payload,
      };
    case QUCIK_TICKET_ECONOMY_COST:
      return {
        ...state,
        economyCost: action.payload,
      };
    case QUICK_TICKET_ECONOMY_CHILD_ADE_1_5_COST:
      return {
        ...state,
        economyChildOneToFiveCost: action.payload,
      };
    case QUICK_TICKET_ECONOMY_CHILD_ADE_5_12_COST:
      return {
        ...state,
        economyChildFiveToTwelveCost: action.payload,
      };
    case QUCIK_TICKET_DELIGHT_COST:
      return {
        ...state,
        delightCost: action.payload,
      };
    case QUICK_TICKET_DELIGHT_CHILD_ADE_1_5_COST:
      return {
        ...state,
        delightChildOneToFiveCost: action.payload,
      };
    case QUICK_TICKET_DELIGHT_CHILD_ADE_5_12_COST:
      return {
        ...state,
        delightChildFiveToTwelveCost: action.payload,
      };
    case QUCIK_TICKET_CLASSIC_COST:
      return {
        ...state,
        classicCost: action.payload,
      };
    case QUICK_TICKET_CLASSIC_CHILD_ADE_1_5_COST:
      return {
        ...state,
        classicChildOneToFiveCost: action.payload,
      };
    case QUICK_TICKET_CLASSIC_CHILD_ADE_5_12_COST:
      return {
        ...state,
        classicChildFiveToTwelveCost: action.payload,
      };
    case QUCIK_TICKET_LUXURY_COST:
      return {
        ...state,
        luxuryCost: action.payload,
      };
    case QUICK_TICKET_LUXURY_CHILD_ADE_1_5_COST:
      return {
        ...state,
        luxuryChildOneToFiveCost: action.payload,
      };
    case QUICK_TICKET_LUXURY_CHILD_ADE_5_12_COST:
      return {
        ...state,
        luxuryChildFiveToTwelveCost: action.payload,
      };
    case QUCIK_TICKET_PREMIUM_COST:
      return {
        ...state,
        premiumCost: action.payload,
      };
    case QUICK_TICKET_PREMIUM_CHILD_ADE_1_5_COST:
      return {
        ...state,
        premiumChildOneToFiveCost: action.payload,
      };
    case QUICK_TICKET_PREMIUM_CHILD_ADE_5_12_COST:
      return {
        ...state,
        premiumChildFiveToTwelveCost: action.payload,
      };
    case QUCIK_TICKET_ELITE_COST:
      return {
        ...state,
        eliteCost: action.payload,
      };
    case QUICK_TICKET_ELITE_CHILD_ADE_1_5_COST:
      return {
        ...state,
        eliteChildOneToFiveCost: action.payload,
      };
    case QUICK_TICKET_ELITE_CHILD_ADE_5_12_COST:
      return {
        ...state,
        eliteChildFiveToTwelveCost: action.payload,
      };
    case QUICK_TICKET_HOTEL_DETAILS:
      return {
        ...state,
        hotelDetails: action.payload,
      };
    case QUICK_TICKET_GUEST_COUNT:
      return {
        ...state,
        guestCount: action.payload,
      };
    default:
      return state;
  }
}
