import axios from "axios";
import { HttpResponse } from "../model/HttpResponse";
import { handleException } from "../util/handleException";
import {
  createVoucherObj,
  createVoucherServerObj,
} from "../util/handleVoucherDataFormat";

const saveVoucherAPI = "wft_admin/voucher-create";
const voucherListAPI = "wft_admin/voucher-list";
const voucherDetailsAPI = "wft_admin/voucher-detail";
const voucherUpdateAPI = "wft_admin/voucher-update";

export async function saveVoucher(data) {
  const userDetails = localStorage.getItem("userDetails");
  if (userDetails) {
    const parsedUser = JSON.parse(userDetails);
    const token = parsedUser["user"]["token"];
    try {
      const res = await axios.post(saveVoucherAPI, data, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      return new HttpResponse(res.status, "Voucher created successsfuly", null);
    } catch (err) {
      return handleException(err);
    }
  } else {
    return new HttpResponse(401, null, "Unauthorized");
  }
}

/**
 *
 * @returns {Promise<[{ result:import("../util/handleVoucherDataFormat").VoucherServerObj[], count:number }|null,HttpResponse|null]>}
 */
export async function getVoucherList(pageNumber = 1) {
  const result = [null, null];
  const userDetails = localStorage.getItem("userDetails");
  if (userDetails) {
    const parsedUser = JSON.parse(userDetails);
    const token = parsedUser["user"]["token"];
    try {
      const res = await axios.get(voucherListAPI, {
        headers: {
          Authorization: `Token ${token}`,
        },
        params: {
          page: pageNumber,
        },
      });
      /**
       * @type {import("../util/handleVoucherDataFormat").VoucherServerObj[]}
       */
      const voucherObjList = [];
      for (let x = 0; x < res.data.results.length; x++) {
        const voucherObj = createVoucherServerObj(res.data.results[x]);
        voucherObjList.push(voucherObj);
      }
      result[0] = {
        result: voucherObjList,
        count: res.data.count,
      };
      return result;
    } catch (err) {
      result[1] = handleException(err);
      return result;
    }
  } else {
    result[1] = new HttpResponse(401, null, "Unauthorized");
    return result;
  }
}

/**
 *
 * @returns {Promise<[VoucherState|null,HttpResponse|null]>}
 */
export async function getVoucerDetails(voucherId) {
  const result = [null, null];
  const userDetails = localStorage.getItem("userDetails");
  if (userDetails) {
    const parsedUser = JSON.parse(userDetails);
    const token = parsedUser["user"]["token"];
    try {
      const res = await axios.get(`${voucherDetailsAPI}/${voucherId}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      result[0] = createVoucherObj(res.data);
      return result;
    } catch (err) {
      result[1] = handleException(err);
      return result;
    }
  } else {
    result[1] = new HttpResponse(401, null, "Unauthorized");
    return result;
  }
}

export async function voucherUpdate(voucherId, data) {
  const userDetails = localStorage.getItem("userDetails");
  if (userDetails) {
    try {
      const parsedUser = JSON.parse(userDetails);
      const token = parsedUser["user"]["token"];
      const res = await axios.patch(`${voucherUpdateAPI}/${voucherId}`, data, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      return new HttpResponse(res.status, "Update successful", null);
    } catch (err) {
      return handleException(err);
    }
  } else {
    return new HttpResponse(401, null, "Unauthorized");
  }
}
