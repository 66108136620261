import React, { useEffect } from "react";
import { useState } from "react";
import { Alert } from "react-bootstrap";
import styles from "./team.module.css";
import {
  saveTeamMember,
  updateTeamMemberInfo,
} from "../../../controller/teamController";
import "../../../model/typeDefs";
import { useDispatch } from "react-redux";
import { logout } from "../../../store/actions/AuthActions";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import ModalLayout from "../../components/global/ModalLayout";
import InputField from "../../components/global/InputField";
import SelectField from "../../components/global/SelectField";
import componentStyle from "../../../css/components.module.css";


/**
 * @typedef {Object} TeamMembersFormProps
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setFormVisible
 * @property {TeamMemberModel} teamMember
 */

/**
 * @param {TeamMembersFormProps} props
 */

function TeamMemberForm({ setFormVisible, teamMember, history }) {
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [designation, setDesignation] = useState("");
  const [accessType, setAccessType] = useState("");
  const [profileImage, setProfileImage] = useState(null);
  const [joinDate, setJoinDate] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [inputError, setInputError] = useState(false);
  const dispatch = useDispatch();

  /**
   *
   * @param {React.FormEvent<HTMLFormElement>} e
   */
  async function save(e) {
    e.preventDefault();
    setInputError(false);
    if (
      userName !== "" &&
      email !== "" &&
      password !== "" &&
      designation !== "" &&
      accessType !== "" &&
      joinDate !== ""
    ) {
      setError("");
      setSuccess("");
      setLoading(true);
      const res = await saveTeamMember({
        name: userName,
        email,
        password,
        designation,
        accessType,
        profileImage,
        joinDate,
      });
      setLoading(false);
      if (res.statusCode === 201) {
        setSuccess(res.success);
      } else if (res.statusCode === 401) {
        dispatch(logout(history));
      } else {
        setError(res.error);
      }
    } else {
      setInputError(true);
    }
  }

  useEffect(() => {
    if (teamMember.id > 0) {
      setUserName(teamMember.name);
      setDesignation(teamMember.designation);
      setAccessType(teamMember.accessType);
      setJoinDate(teamMember.joinDate);
    }
  }, [teamMember]);

  /**
   *
   * @param {React.FormEvent<HTMLFormElement>} e
   */
  async function update(e) {
    e.preventDefault();
    setInputError(false);
    if (userName !== "" && designation !== "" && joinDate !== "" && accessType !== "") {
      setError("");
      setSuccess("");
      const res = await updateTeamMemberInfo({
        id: teamMember.id,
        name: userName,
        designation,
        accessType,
        profileImage,
        joinDate,
      });
      if (res.statusCode === 200) {
        setSuccess(res.success);
      } else if (res.statusCode === 401) {
        dispatch(logout(history));
      } else {
        setError(res.error);
      }
    } else {
      setInputError(true);
    }
  }

  return (
    <ModalLayout
      modalCloseAction={() => setFormVisible(false)}
      modalStyles={{
        width: "35rem",
        height: "40rem",
        overflow: "hidden",
        overflowY: "auto",
      }}
    >
      <InputField
        label="Name"
        inputFieldAttributes={{
          value: userName,
          onChange: (e) => setUserName(e.target.value),
        }}
        showInputError={inputError}
      />
      {teamMember.id === 0 && (
        <InputField
          label="Email address"
          inputFieldAttributes={{
            value: email,
            onChange: (e) => setEmail(e.target.value),
            type: "email",
          }}
          stylesProps={{ marginTop: "20px" }}
          showInputError={inputError}
        />
      )}

      <InputField
        label="Designation"
        inputFieldAttributes={{
          value: designation,
          onChange: (e) => setDesignation(e.target.value),
        }}
        stylesProps={{ marginTop: "20px" }}
        showInputError={inputError}
      />
      <InputField
        label="Join date"
        inputFieldAttributes={{
          value: joinDate,
          onChange: (e) => setJoinDate(e.target.value),
          type: "date",
        }}
        stylesProps={{ marginTop: "20px" }}
        showInputError={inputError}
      />

      <InputField
        label="Profile image"
        inputFieldAttributes={{
          onChange: (e) => setProfileImage(e.target.files[0]),
          type: "file",
        }}
        stylesProps={{ marginTop: "20px" }}
      />
      <SelectField
        label="Access type"
        selectFieldAttributes={{
          value: accessType,
          onChange: (e) => setAccessType(e.target.value),
        }}
        options={["Basic", "Advance"]}
        stylesProps={{ marginTop: "20px" }}
        showInputError={inputError}
      />
      {teamMember.id === 0 && (
        <InputField
          label="Password"
          inputFieldAttributes={{
            value: password,
            onChange: (e) => setPassword(e.target.value),
            type: "password",
          }}
          stylesProps={{ marginTop: "20px" }}
          showInputError={inputError}
        />
      )}

      {success !== "" && (
        <div className={`${styles.mt24}`}>
          <Alert
            variant="success"
            style={{
              background: "white",
              padding: "0",
              margin: "0",
              border: "none",
              textAlign: "center",
              fontWeight: "500",
            }}
          >
            {success}
          </Alert>
        </div>
      )}
      {error !== "" && (
        <div className={`${styles.mt24}`}>
          <Alert
            variant="danger"
            style={{
              background: "white",
              padding: "0",
              margin: "0",
              border: "none",
              textAlign: "center",
              fontWeight: "500",
            }}
          >
            {error}
          </Alert>
        </div>
      )}

      <div
        style={{
          marginTop: "25px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <button
          style={{ border: "none" }}
          onClick={() => {
            setFormVisible(false);
          }}
        >
          Close
        </button>
        <button
          className={componentStyle.btnPrimary}
          onClick={teamMember.id === 0 ? save : update}
          disabled={loading}
        >
          &#x2713; {teamMember.id === 0 ? "Save" : "Update"}
        </button>
      </div>
    </ModalLayout>
  );
}

export default withRouter(TeamMemberForm);
