import React, { useEffect, useRef, useState } from "react";
import ModalLayout from "../../global/ModalLayout";
import InputField from "../../global/InputField";
import componentStyles from "../../../../css/components.module.css";
import SelectField from "../../global/SelectField";
import TextArea from "../../global/TextArea";
import "../../../../store/reducers/packagereducer/reduerStateType";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  addAcAvailability,
  addCarName,
  addCarType,
  addDescription,
  addDestination,
  addNoOfSeaters,
  addSource,
  addHeading,
  resetCarForm,
  addDayNumber,
  addIndex,
} from "../../../../store/actions/CarTravelFormAction";
import {
  addWidgetInfo,
  updateCarTravelInfo,
} from "../../../../store/actions/PackageAction";

/**
 * @typedef {object} Props
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setShowCardTravelFormModal
 * @property {number} day
 */
/**
 * @param {Props} props
 */

export default function CarTravelFormModal({
  setShowCardTravelFormModal,
  day = "",
}) {
  /**
   * @type {import("../../../../store/reducers/cartravel/carTraveltypes").CarTravelState}
   */
  const {
    id,
    isUpdate,
    heading,
    description,
    source,
    dest,
    no_of_searters,
    car_name,
    car_type,
    ac_non,
    day_no,
    index,
    activity_type,
  } = useSelector((state) => state.carTravel);

  /**
   * @type {PackageState}
   */
  const { complete_tour_data } = useSelector((state) => state.package);

  const [inputError, setInputError] = useState(false);
  const dispatch = useDispatch();
  const widgetIndex = useRef(`${complete_tour_data[day - 1].length + 1}`);

  useEffect(() => {
    if (index === "" && day_no === "") {
      dispatch(addDayNumber(`${day}`));
      dispatch(addIndex(widgetIndex.current));
    }
  }, [dispatch, day, day_no, index]);

  function handleSave() {
    setInputError(false);
    if (
      source !== "" &&
      dest !== "" &&
      ac_non !== "" &&
      no_of_searters !== "" &&
      car_name !== "" &&
      car_type !== ""
    ) {
      const dataObj = {
        id,
        heading,
        description,
        source,
        dest,
        ac_non,
        no_of_searters,
        car_name,
        car_type,
        day_no,
        index,
        activity_type,
      };

      if (!isUpdate) {
        dispatch(addWidgetInfo(dataObj));
      } else {
        dispatch(updateCarTravelInfo(dataObj));
      }
      dispatch(resetCarForm());
      setShowCardTravelFormModal(false);
    } else {
      setInputError(true);
    }
  }

  return (
    <ModalLayout
      modalCloseAction={() => {
        dispatch(resetCarForm());
        setShowCardTravelFormModal(false);
      }}
      modalStyles={{
        width: "35rem",
        height: "45rem",
        overflow: "hidden",
        overflowY: "auto",
      }}
    >
      <InputField
        label="Car type"
        inputFieldAttributes={{
          value: car_type,
          onChange: (e) => dispatch(addCarType(e.target.value)),
          type: "text",
        }}
        showInputError={inputError}
      />
      <InputField
        label="Car name"
        inputFieldAttributes={{
          value: car_name,
          onChange: (e) => dispatch(addCarName(e.target.value)),
          type: "text",
        }}
        showInputError={inputError}
        stylesProps={{ marginTop: "20px" }}
      />
      <InputField
        label="No of seats"
        inputFieldAttributes={{
          value: no_of_searters,
          onChange: (e) => dispatch(addNoOfSeaters(e.target.value)),
          type: "number",
        }}
        showInputError={inputError}
        stylesProps={{ marginTop: "20px" }}
      />
      <SelectField
        label="AC availability"
        options={["AC", "Non AC"]}
        selectFieldAttributes={{
          value: ac_non,
          onChange: (e) => dispatch(addAcAvailability(e.target.value)),
        }}
        showInputError={inputError}
        stylesProps={{ marginTop: "20px" }}
      />
      <InputField
        label="Source"
        inputFieldAttributes={{
          value: source,
          onChange: (e) => dispatch(addSource(e.target.value)),
          type: "text",
        }}
        showInputError={inputError}
        stylesProps={{ marginTop: "20px" }}
      />
      <InputField
        label="Destination"
        inputFieldAttributes={{
          value: dest,
          onChange: (e) => dispatch(addDestination(e.target.value)),
          type: "text",
        }}
        showInputError={inputError}
        stylesProps={{ marginTop: "20px" }}
      />
      <InputField
        label="Heading (optional)"
        stylesProps={{ marginTop: "20px" }}
        inputFieldAttributes={{
          value: heading,
          type: "text",
          onChange: (e) => dispatch(addHeading(e.target.value)),
        }}
      />
      <TextArea
        label="Inclusion (optional)"
        stylesProps={{ marginTop: "20px" }}
        textAreaAttributes={{
          value: description,
          onChange: (e) => dispatch(addDescription(e.target.value)),
          cols: 12,
          rows: 8,
        }}
      />
      <div
        style={{
          marginTop: "25px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <button
          style={{ border: "none" }}
          onClick={() => {
            dispatch(resetCarForm());
            setShowCardTravelFormModal(false);
          }}
        >
          Close
        </button>
        <button className={componentStyles.btnPrimary} onClick={handleSave}>
          &#x2713; {!isUpdate ? "Save" : "Update"}
        </button>
      </div>
    </ModalLayout>
  );
}
