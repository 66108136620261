import React from "react";
/**
 * @typedef {object} Props
 * @property {string[]|number[]} tabList
 * @property {string|number} currentActiveTab
 * @property {(tab:string)=>void} onClick
 */

/**
 * @param {Props} props
 */
export default function HorizontalTabs({
  currentActiveTab,
  tabList,
  onClick,
}) {
  
  return (
    <div
      style={{
        width: "70%",
        margin: "3rem auto",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        fontSize: "1.5rem",
      }}
    >
      {tabList.length > 0 &&
        tabList.map((data, index) => (
          <span
            key={index}
            role="button"
            onClick={()=>onClick(data)}
            style={{
              backgroundColor: `${currentActiveTab === data ? "#55b3e5" : "#fcfcfc"}`,
              padding: "2px 30px",
              borderRadius: "8px",
              color: `${currentActiveTab === data ? "#212121" : "#55b3e5"}`,
            }}
          >
            {data}
          </span>
        ))}
    </div>
  );
}
