import axios from "axios";
import { handleException } from "../util/handleException";
import { getToken } from "../util/tokenHandler";
import { createDaySchedulesArr } from "../util/handlePackageDataFormat";
import "../store/reducers/QuickTicketReducer/QuickTicketStateType";
const tourListAPI = "wft_admin/tour-detail-list";

/**
 *
 * @returns {Promise<[{daySchedules:DayScheduleWidget[][], hotelDetails:QuickTicketHotelDetails} | null, import ("../model/HttpResponse").HttpResponse | null]>}
 */
export async function getTourDetail(tourDuration) {
  const result = [null, null];
  try {
    const res = await axios.get(tourListAPI, {
      headers: {
        Authorization: `Token ${getToken()}`,
      },
      params: {
        duration: tourDuration,
      },
    });
    const daySchedules = createDaySchedulesArr(res.data);
    let hotelDetails = [];
    for (let x = 0; x < res.data.length; x++) {
      const data = res.data[x]["hotel"];
      if (data) {
        hotelDetails = data;
      }
    }
    result[0] = {
      daySchedules,
      hotelDetails,
    };
    return result;
  } catch (err) {
    result[1] = handleException(err);
    return result;
  }
}
