import React, { useState } from "react";
import ModalLayout from "../../../components/global/ModalLayout";
import InputField from "../../../components/global/InputField";
import Button from "../../../components/global/Button";
import SelectField from "../../../components/global/SelectField";
import { cancelRequestPaymentUpdate } from "../../../../controller/collectionController";
import { HTTPStatus } from "../../../../util/helperObj";
import { message } from "antd";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { handleControllerResultException } from "../../../../util/handleException";
/**
 * @typedef {object} Props
 * @property {()=>void} setShowModal
 * @property {import("../../../../model/UserTicketModal").UserAssignedTicket} ticket
 */

/**
 * @param {Props} props
 */
export default function CancelRequestPaymentModal({ setShowModal, ticket }) {
  const [cancelPayment, setCancelPayment] = useState(
    ticket.cancelPayment ? "Payment returned" : "Payment not returned"
  );
  const [cancelPaymentRemarks, setCancelPaymentRemarks] = useState(
    ticket.cancelPaymentRemark
  );
  const [cancelPaymentAmount, setCancelPaymentAmount] = useState(
    ticket.cancelPaymentAmount
  );
  const [cancelPaymentDate, setCancelPaymentDate] = useState(
    ticket.cancelPaymentDate
  );
  const [cancelPaymentTransaction, setCancelPaymentTransaction] = useState(
    ticket.cancelPaymentTransaction
  );
  const [inputError, setInputError] = useState(false);
  const [updating, setUpdating] = useState(false);
  const history = useHistory();
  async function handleUpdate() {
    if (
      cancelPaymentDate !== "" &&
      cancelPaymentTransaction !== "" &&
      cancelPaymentAmount !== ""
    ) {
      setUpdating(true);
      const res = await cancelRequestPaymentUpdate(
        ticket.id,
        cancelPayment === "Payment returned" ? true : false,
        cancelPaymentAmount,
        cancelPaymentDate,
        cancelPaymentTransaction, 
        cancelPaymentRemarks
      );
      setUpdating(false);

      if (res.statusCode === HTTPStatus.SUCCESS) {
        message.success(res.success);
      } else {
        handleControllerResultException(res, history);
      }
    } else {
      setInputError(true);
    }
  }

  return (
    <ModalLayout
      modalCloseAction={() => setShowModal(false)}
      modalStyles={{
        width: "50%",
        height: "50%",
        textAlign: "left",
        overflowY: "auto",
      }}
    >
      <InputField
        label="Cancel payment date"
        inputFieldAttributes={{
          value: cancelPaymentDate,
          type: "date",
          onChange: (e) => setCancelPaymentDate(e.target.value),
        }}
        showInputError={inputError}
        stylesProps={{ marginBottom: "1rem" }}
      />
      <InputField
        label="Transaction Id"
        inputFieldAttributes={{
          value: cancelPaymentTransaction,
          onChange: (e) => setCancelPaymentTransaction(e.target.value),
        }}
        showInputError={inputError}
        stylesProps={{ marginBottom: "1rem" }}
      />
      <InputField
        label="Amount"
        inputFieldAttributes={{
          value: cancelPaymentAmount,
          onChange: (e) => setCancelPaymentAmount(e.target.value),
          type: "number",
        }}
        showInputError={inputError}
        stylesProps={{ marginBottom: "1rem" }}
      />
      <InputField
        label="Cancel payment remarks (optional)"
        inputFieldAttributes={{
          value: cancelPaymentRemarks,
          onChange: (e) => setCancelPaymentRemarks(e.target.value),
        }}
        stylesProps={{ marginBottom: "1rem" }}
      />
      <SelectField
        label="Return payment"
        selectFieldAttributes={{
          value: cancelPayment,
          onChange: (e) => setCancelPayment(e.target.value),
        }}
        options={["Payment returned", "Payment not returned"]}
        stylesProps={{ marginBottom: "1rem" }}
      />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button disabled={updating} loading={updating} onClick={handleUpdate}>
          Update
        </Button>
      </div>
    </ModalLayout>
  );
}
