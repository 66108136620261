import React, { useCallback, useEffect, useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { getApplicantList } from "../../../controller/CareerController";
import { handleControllerResultException } from "../../../util/handleException";
import Table from "../../components/global/Table/Table";
import Button from "../../components/global/Button";
import ApplicantListItem from "./ApplicantListItem";
/**
 * @type {import("../../../model/ApplicantModel").ApplicantModel[]}
 */
const applicantListType = [];

export default function Applicants() {
  const [applicantList, setApplicantList] = useState(applicantListType);
  const { jobId } = useParams();
  const history = useHistory();

  const handleApplicantList = useCallback(
    async (id) => {
      const res = await getApplicantList(id);
      if (res[0] !== null) {
        setApplicantList(res[0]);
      } else {
        handleControllerResultException(res[1], history);
      }
    },
    [history]
  );

  useEffect(() => {
    if (jobId) {
      handleApplicantList(jobId);
    }
  }, [jobId, handleApplicantList]);

  return (
    <div>
      <Button onClick={() => history.goBack()}>Return</Button>
      {applicantList.length > 0 ? (
        <Table styles={{ marginTop: "2rem" }}>
          <thead>
            <tr>
              <th>Applicant name</th>
              <th>Applicant contact no</th>
              <th>Applicant email</th>
              <th>Date</th>
              <th>Details</th>
              <th>Resume</th>
            </tr>
          </thead>
          <tbody>
            {applicantList.map((data) => (
              <ApplicantListItem key={data.id} applicant={data} />
            ))}
          </tbody>
        </Table>
      ) : (
        <p style={{ textAlign: "center" }}>No applicants found ☹️</p>
      )}
    </div>
  );
}
