import React, { useCallback, useEffect, useState } from "react";
import componentStyles from "../../../css/components.module.css";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import {
  changeCustomPackageStatus,
  fetchCustomPackageList,
} from "../../../controller/PackageController";
import { message } from "antd";
import { useDispatch } from "react-redux";
import { resetPackageState } from "../../../store/actions/PackageAction";
import { HTTPStatus } from "../../../util/helperObj";
import { adminConfirmation } from "../../../services/util";

export default function CustomPackages() {
  /**
   *  @type {import ("../../../model/CustomPackageListItem").default[]}
   */
  const customPackageListType = [];
  const [customPackageList, setCustomPackageList] = useState(
    customPackageListType
  );
  const [isAdmin, setIsAdmin] = useState(false);

  /**
   * @type {"active"|"inactive"}
   */
  const packageStatusType = "active";
  const [packageStatus, setPackageStatus] = useState(packageStatusType);
  const dispatch = useDispatch();

  const fetchCustomPackageHandler = useCallback(async () => {
    const res = await fetchCustomPackageList(packageStatus);
    if (Array.isArray(res)) {
      setCustomPackageList(res);
    } else {
      message.error(res.error);
    }
  }, [packageStatus]);

  useEffect(() => {
    fetchCustomPackageHandler();
    dispatch(resetPackageState());
  }, [dispatch, fetchCustomPackageHandler]);

  useEffect(() => {
    const res = adminConfirmation();
    if (res !== null) {
      setIsAdmin(res);
    }
  }, []);

  async function handlePackageStatusUpdate(packageId, packageStatus) {
    const res = await changeCustomPackageStatus(packageId, packageStatus);
    if (res.statusCode === HTTPStatus.SUCCESS) {
      message.success(
        packageStatus === true
          ? "Package is now inactiave"
          : "Package is now active"
      );
      setCustomPackageList((prevState) =>
        prevState.filter((data) => data.id !== packageId)
      );
    } else {
      message.error(res.error);
    }
  }

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Link to="/custom-package/create">
          <button className={componentStyles.btnPrimary}>
            &#43; Add Package
          </button>
        </Link>
      </div>
      {isAdmin && (
        <div
          style={{
            width: "70%",
            margin: "3rem auto",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "1.5rem",
          }}
        >
          <span
            role="button"
            onClick={() => setPackageStatus("active")}
            style={{
              backgroundColor: `${
                packageStatus === "active" ? "#55b3e5" : "#ffffff"
              }`,
              padding: "2px 30px",
              borderRadius: "8px",
              color: `${packageStatus === "active" ? "#212121" : "#55b3e5"}`,
            }}
          >
            Active packages
          </span>
          <span
            onClick={() => setPackageStatus("inactive")}
            role="button"
            style={{
              backgroundColor: `${
                packageStatus === "inactive" ? "#55b3e5" : "#ffffff"
              }`,
              padding: "2px 30px",
              borderRadius: "8px",
              color: `${packageStatus === "inactive" ? "#212121" : "#55b3e5"}`,
            }}
          >
            Inactive packages
          </span>
        </div>
      )}
      <div style={{ marginTop: "3rem" }} className={componentStyles.grid3}>
        {customPackageList.length > 0 &&
          customPackageList.map((data) => (
            <div
              key={data.id}
              style={{
                margin: "10px",
                border: "1px solid #212121",
                padding: "20px",
                borderRadius: "6px",
                width: "auto",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p style={{ color: "#55b3e5" }}>{data.uniqueId}</p>
                {isAdmin && (
                  <button
                    onClick={() =>
                      handlePackageStatusUpdate(data.id, data.isActive)
                    }
                    style={{
                      border: `2px solid ${data.isActive ? "green" : "red"}`,
                      borderRadius: "5px",
                      fontSize: "15px",
                      padding: "5px 10px",
                      color: `${data.isActive ? "green" : "red"}`,
                      backgroundColor: `${
                        data.isActive ? "#dcfce7" : "#fee2e2"
                      }`,
                    }}
                  >
                    {data.isActive ? "Active" : "Inactive"}
                  </button>
                )}
              </div>

              <h1 style={{ fontSize: "1.4rem" }}>{data.heading}</h1>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <div>
                  managed by:{" "}
                  <span style={{ color: "#212121", fontWeight: "bold" }}>
                    {data.managedBy}
                  </span>
                </div>
                <div>
                  Pricing:{" "}
                  <span style={{ color: "#212121", fontWeight: "bold" }}>
                    &#8377; {data.price}
                  </span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <div>
                  Duration:{" "}
                  <span style={{ color: "#212121", fontWeight: "bold" }}>
                    {data.duration}
                  </span>
                </div>
                <div>
                  Rating:{" "}
                  <span style={{ color: "#212121", fontWeight: "bold" }}>
                    {data.rating}
                  </span>
                </div>
              </div>
              <div
                style={{ display: "flex", marginTop: "10px", flexWrap: "wrap" }}
              >
                <span style={{ color: "#55b3e5" }}>{data.itineraryType}</span>
                <span style={{ marginLeft: "10px", color: "#55b3e5" }}>
                  {data.itinerarySubType}
                </span>
              </div>
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Link to={`/custom-package/create/${data.id}`}>
                  <button className={componentStyles.btnPrimary}>Edit</button>
                </Link>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
