import StarterKit from "@tiptap/starter-kit";
import { EditorContent, useEditor } from "@tiptap/react";
import MenuBar from "../../../components/global/RichEditor/components/MenuBar";

export default function RichText({ print , value }) {
  const editor = useEditor({
    content: value ? value :"<h5>Please write something</h5>",
    editorProps: {
      attributes: { 
        class: "editor-base  mt-4",
      },
    },
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: true,
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: true,
        },
      }),
    ],
  });

  return (
    <div onBlur={()=>print(editor?.getHTML())} className="p-4 mt-4">
      <MenuBar editor={editor} />
      <div style={{ border: "1px solid black", borderRadius: 10, padding: 10, marginTop:"1rem"
       }}>
      <EditorContent editor={editor} />
      </div>
    </div>
  );
}
