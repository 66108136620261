import React from "react";

const Home = () => {
  return (
    <>
      <div style={{ textAlign: "center", fontSize: "1.1rem" }}>
        Coming soon...🙂
      </div>
    </>
  );
};
export default Home;
