/**
 * @typedef {object} VoucherListStateType
 * @property {import("../../../util/handleVoucherDataFormat").VoucherServerObj[]} voucherList
 */

import { ADD_VOUCHER_LIST } from "../../actions/VoucherListAction";

/**
 * @type {VoucherListStateType}
 */
const initialState = {
  voucherList: [],
};

/**
 *
 * @returns {VoucherListStateType}
 */
export function voucherListReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_VOUCHER_LIST:
      return {
        ...state,
        voucherList: action.payload,
      };
    default:
      return state;
  }
}
