export const FLIGHT_TRAVEL_DAY_NO = "flight travel day no";
export const FLIGHT_TRAVEL_INDEX = "flight travel index";
export const FLIGHT_TRAVEL_LANDING_TIME = "flight travel landing time";
export const FLIGHT_TRAVEL_TAKEOFF_TIME = "flight travel takeoff time";
export const FLIGHT_TRAVEL_FLIGHT_DATE = "flight travel flight date";
export const FLIGHT_TRAVEL_FLIGHT_DEST = "flight travel flight destination";
export const FLIGHT_TRAVEL_FLIGHT_SOURCE = "flght travel flight source";
export const FLIGHT_TRAVEL_FLIGHT_DURATION = "flight travel flight duration";
export const FLIGHT_TRAVEL_FLIGHT_NAME = "flight travel flight name";
export const FLIGHT_TRAVEL_FLIGHT_NUMBER = "flight travel flight number";
export const RESET_FLIGHT_TRAVEL_FORM = "reset flight travel form";
export const FLIGHT_TRAVEL_INFO_UPDATE = "flight travel info update";

export function addFlightTravelDayNo(payload) {
  return {
    type: FLIGHT_TRAVEL_DAY_NO,
    payload,
  };
}

export function addFlightTravelIndex(payload) {
  return {
    type: FLIGHT_TRAVEL_INDEX,
    payload,
  };
}

export function addFlightTravelLandingTime(payload) {
  return {
    type: FLIGHT_TRAVEL_LANDING_TIME,
    payload,
  };
}

export function addFlightTravelTakeoffTime(payload) {
  return {
    type: FLIGHT_TRAVEL_TAKEOFF_TIME,
    payload,
  };
}

export function addFlightTravelFightDate(payload) {
  return {
    type: FLIGHT_TRAVEL_FLIGHT_DATE,
    payload,
  };
}

export function addFlightTravelFlightSource(payload) {
  return {
    type: FLIGHT_TRAVEL_FLIGHT_SOURCE,
    payload,
  };
}

export function addFlightTravelFlightDestination(payload) {
  return {
    type: FLIGHT_TRAVEL_FLIGHT_DEST,
    payload,
  };
}

export function addFlightTravelFlightName(payload) {
  return {
    type: FLIGHT_TRAVEL_FLIGHT_NAME,
    payload,
  };
}

export function addFlightTravelFlightNumber(payload) {
  return {
    type: FLIGHT_TRAVEL_FLIGHT_NUMBER,
    payload,
  };
}

export function addFlightTravelFlightDuration(payload) {
  return {
    type: FLIGHT_TRAVEL_FLIGHT_DURATION,
    payload,
  };
}

export function resetFlightTravelForm() {
  return {
    type: RESET_FLIGHT_TRAVEL_FORM,
  };
}

export function addIsFlightTravelInfoUpdate(payload) {
  return {
    type: FLIGHT_TRAVEL_INFO_UPDATE,
    payload,
  };
}
