import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ModalLayout from "../../global/ModalLayout";
import InputField from "../../global/InputField";
import {
  addFerryDayNo,
  addFerryDescription,
  addFerryDestination,
  addFerryHeading,
  addFerryIndex,
  addFerrySource,
  addFerryType,
  resetFerryForm,
} from "../../../../store/actions/FerryTravelFormAction";
import TextArea from "../../global/TextArea";
import componentStyles from "../../../../css/components.module.css";
import {
  addWidgetInfo,
  updateFerryTravelInfo,
} from "../../../../store/actions/PackageAction";
/**
 * @typedef {object} Props
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setShowFerryTravelFormModal
 * @property {number} day
 */

/**
 * @param {Props} props
 */
export default function FerryTravelFormModal({
  setShowFerryTravelFormModal,
  day = "",
}) {
  /**
   * @type {import("../../../../store/reducers/Ferrytravel/ferryFormStatetypes").FerryTravelFormState}
   */

  const {
    id,
    isUpdate,
    day_no,
    index,
    heading,
    description,
    type,
    source,
    dest,
    activity_type,
  } = useSelector((state) => state.ferryTravel);
  const dispatch = useDispatch();
  const [inputError, setInputError] = useState(false);

  /**
   * @type {PackageState}
   */
  const { complete_tour_data } = useSelector((state) => state.package);
  const widgetIndex = useRef(`${complete_tour_data[day - 1].length + 1}`);

  useEffect(() => {
    if (index === "" && day_no === "") {
      dispatch(addFerryDayNo(`${day}`));
      dispatch(addFerryIndex(widgetIndex.current));
    }
  }, [dispatch, day, day_no, index]);

  function handleSave() {
    setInputError(false);
    if (type !== "" && source !== "" && dest !== "") {
      const dataObj = {
        id,
        day_no,
        index,
        activity_type,
        heading,
        description,
        source,
        dest,
        type,
      };
      if (!isUpdate) {
        dispatch(addWidgetInfo(dataObj));
      } else {
        dispatch(updateFerryTravelInfo(dataObj));
      }
      dispatch(resetFerryForm());
      setShowFerryTravelFormModal(false);
    } else {
      setInputError(true);
    }
  }

  return (
    <ModalLayout
      modalCloseAction={() => {
        dispatch(resetFerryForm());
        setShowFerryTravelFormModal(false);
      }}
      modalStyles={{
        width: "35rem",
        height: "45rem",
        overflow: "hidden",
        overflowY: "auto",
      }}
    >
      <TextArea
        label="Heading (optional)"
        textAreaAttributes={{
          value: heading,
          onChange: (e) => dispatch(addFerryHeading(e.target.value)),
          cols: 12,
          rows: 8,
        }}
      />
      <TextArea
        label="Inclusion (optional)"
        stylesProps={{ marginTop: "20px" }}
        textAreaAttributes={{
          value: description,
          onChange: (e) => dispatch(addFerryDescription(e.target.value)),
          cols: 12,
          rows: 8,
        }}
      />
      <InputField
        label="Source"
        stylesProps={{ marginTop: "20px" }}
        inputFieldAttributes={{
          value: source,
          onChange: (e) => dispatch(addFerrySource(e.target.value)),
        }}
        showInputError={inputError}
      />
      <InputField
        label="Destination"
        stylesProps={{ marginTop: "20px" }}
        inputFieldAttributes={{
          value: dest,
          onChange: (e) => dispatch(addFerryDestination(e.target.value)),
        }}
        showInputError={inputError}
      />
      <InputField
        label="Ferry type"
        stylesProps={{ marginTop: "20px" }}
        inputFieldAttributes={{
          value: type,
          onChange: (e) => dispatch(addFerryType(e.target.value)),
        }}
      />
      <div
        style={{
          marginTop: "25px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <button
          style={{ border: "none" }}
          onClick={() => {
            dispatch(resetFerryForm());
            setShowFerryTravelFormModal(false);
          }}
        >
          Close
        </button>
        <button className={componentStyles.btnPrimary} onClick={handleSave}>
          &#x2713; {!isUpdate ? "Save" : "Update"}
        </button>
      </div>
    </ModalLayout>
  );
}
