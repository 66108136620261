import React from "react";
import campImg from "../../newPages/NewTicket/Asset31.png";
import coupleImg from "../../newPages/NewTicket/couple.png";
import diveImg from "../../newPages/NewTicket/img23.png";
import carImg from "../../newPages/NewTicket/car.png";
import pinImg from "../../newPages/NewTicket/pin.png";
import hotelImg from "../../newPages/NewTicket/h3.png";
import "../../../store/reducers/packagereducer/reduerStateType";
const randomImagesList = [campImg, coupleImg, diveImg];

/**
 * @typedef {object} Props
 * @property {DayScheduleWidget[]} daySchedules
 */

/**
 * @param {Props} props
 */

export default function ShortDayScheduleCard({ daySchedules }) {
  return (
    <div
      style={{
        border: "1px solid #212121",
        marginBottom: "1.5rem",
        borderRadius: "8px",
        padding: "20px",
        display:"flex", 
        justifyContent:"space-between",
        fontFamily:"Poppins"
      }}
    >
      <div>
        {daySchedules.length > 0 &&
          daySchedules.map((data, index) => (
            <div key={index}>{generateListItem(data)}</div>
          ))}
      </div>
      <img
        src={randomImagesList[Math.floor(Math.random() * 3)]}
        alt="img"
        height="150"
        width="200"
      />
    </div>
  );
}

/**
 *
 * @param {DayScheduleWidget} data
 */
function generateListItem(data) {
  if (data.activity_type === "travel_car") {
    return (
      <div style={{ marginBottom: "1rem" }}>
        <img src={carImg} alt="car" height="20" width="20" />{" "}
        <span>
          {data.source} to {data.dest}
        </span>
      </div>
    );
  } else if (data.activity_type === "sightseeing") {
    return (
      <div style={{ marginBottom: "1rem" }}>
        <img src={pinImg} alt="car" height="20" width="20" />{" "}
        <span>{data.name}</span>
      </div>
    );
  } else if (data.activity_type === "travel_ferry") {
    return (
      <div style={{ marginBottom: "1rem" }}>
        <img src={hotelImg} alt="car" height="20" width="20" />{" "}
        <span>
          {data.source} to {data.dest}
        </span>
      </div>
    );
  }
}
