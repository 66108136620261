/**
 * Class representing a guest
 * @class
 */

import { createAtToDate, createAtToTime } from "../util/util";

export class GuestModel {
  /**
   * @type {string}
   */
  type = "guest";
  /**
   * @type {string}
   */
  guestId;
  /**
   * @type {string}
   */
  guestDetail;
  /**
   * @type {string}
   */
  fullName;
  /**
   * @type {string}
   */
  emailId;
  /**
   * @type {string}
   */
  phoneNo;
  /**
   * @type {string}
   */
  guestCount;
  /**
   * @type {string}
   */
  duration;
  /**
   * @type {string}
   */
  budget;
  /**
   * @type {string}
   */
  quickNotes;
  /**
   * @type {string}
   */
  followUpStatus;

  /**
   *
   * @type {number}
   */
  assingTo;

  /**
   * @type {string}
   */
  date;
  /**
   * @type {string}
   */
  time;
  /**
   * @type {string}
   */
  source;

  /**
   * @type {boolean}
   */
  isActive;
  /**
   *
   * @param {any} data
   * @returns instance of GuestModel
   */

  static createGuestObj(data) {
    const obj = new GuestModel();
    obj.guestId = data.guest_id;
    obj.guestDetail = data.guest_detail;
    obj.fullName = data.full_name;
    obj.emailId = data.email_id;
    obj.phoneNo = data.phone_no;
    obj.guestCount = data.guest_count;
    obj.duration = data.duration;
    obj.budget = data.budget;
    obj.quickNotes = data.quick_notes;
    obj.followUpStatus = data.followup_status;
    obj.assingTo = data.assign_to;
    obj.source = data.source;
    obj.date = createAtToDate(data.created_at);
    obj.time = createAtToTime(data.created_at);
    obj.isActive = data.is_active;
    return obj;
  }

  /**
   * @returns instance of GuestModel. All the values will be empty
   */
  static initialGuestObj() {
    const obj = new GuestModel();
    obj.guestId = "";
    obj.guestDetail = "";
    obj.fullName = "";
    obj.emailId = "";
    obj.phoneNo = "";
    obj.guestCount = "";
    obj.duration = "";
    obj.budget = "";
    obj.quickNotes = "";
    obj.followUpStatus = "";
    obj.assingTo = 0;
    obj.isActive = false;
    return obj;
  }
}
