import React from "react";
import componentStyles from "../../../css/components.module.css";
import layoutStyles from "../../../css/layout.module.css";

/**
 * @typedef {object} Props
 * @property {string} label
 * @property {React.CSSProperties} stylesProps
 * @property {boolean} showInputError
 * @property {React.InputHTMLAttributes<HTMLInputElement>} inputFieldAttributes
 * @property {boolean} showErrorCondition
 * @property {string} placeHolder
 * @property {string}   errorMessage
 */

/**
 * @param {Props} props
 */
export default function InputField({
  label,
  stylesProps,
  showInputError = false,
  inputFieldAttributes,
  showErrorCondition = inputFieldAttributes.value === "",
  placeHolder = "",
  errorMessage = `Please provide ${label}`,
}) {
  return (
    <div className={layoutStyles.flexColStart} style={stylesProps}>
      <label className={componentStyles.label}>{label}</label>
      <input
        className={componentStyles.inputField}
        {...inputFieldAttributes}
        placeholder={placeHolder}
      />
      {showInputError && showErrorCondition ? (
        <p style={{ color: "red" }}>{errorMessage}</p>
      ) : (
        <></>
      )}
    </div>
  );
}
