import React, { useState } from "react";
import Button from "../../components/global/Button";
import ApplicantDetailsModal from "./ApplicantDetailsModal";

/**
 * @typedef {object} Props
 * @property {import('../../../model/ApplicantModel').ApplicantModel} applicant
 */

/**
 * @param {Props} props
 */
export default function ApplicantListItem({ applicant }) {
  const [showApplicantDetailsModal, setShowApplicantDetailsModal] =
    useState(false);
  return (
    <tr>
      <td>{applicant.applicantName}</td>
      <td>{applicant.applicantContactNumber}</td>
      <td>{applicant.applicantEmail}</td>
      <td>{applicant.date}</td>
      <td>
        <Button onClick={() => setShowApplicantDetailsModal(true)}>
          Details
        </Button>
        {showApplicantDetailsModal && (
          <ApplicantDetailsModal
            applicant={applicant}
            setShowModal={setShowApplicantDetailsModal}
          />
        )}
      </td>
      <td>
        <a href={applicant.resume} target="_black">
          <Button>Resume</Button>
        </a>
      </td>
    </tr>
  );
}
