import React, { useState } from "react";
import Button from "../../components/global/Button";
import { changeJobStatus } from "../../../controller/CareerController";
import { HTTPStatus } from "../../../util/helperObj";
import { message } from "antd";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { handleControllerResultException } from "../../../util/handleException";

/**
 * @typedef {object} Props
 * @property {import('../../../model/JobModal').JobModel} job
 * @property {()=>void} onStatusChangeAction - this function will call after status is changed successfuly
 */

/**
 * @param {Props} props
 */
export default function JobStatus({ job, onStatusChangeAction }) {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  async function handleJobStatus() {
    setLoading(true);
    const res = await changeJobStatus(job.id);
    setLoading(false);
    if (res.statusCode === HTTPStatus.SUCCESS) {
      message.success(res.success);
      onStatusChangeAction && onStatusChangeAction();
    } else {
      handleControllerResultException(res, history);
    }
  }

  return (
    <Button
      variant={job.isActive ? "outlined-success" : "outlined-danger"}
      onClick={handleJobStatus}
      disabled={loading}
      loading={loading}
    >
      {job.isActive ? "Active" : "Inactive"}
    </Button>
  );
}
