import React, { useEffect, useState } from "react";
import componentStyle from "../../../css/components.module.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getVoucherList } from "../../../controller/voucherController";
import { message } from "antd";
import { useCallback } from "react";
import PaginationPageSelectors from "../../components/global/PaginationPageSelectors";
import { useDispatch } from "react-redux";
import { resetVoucherState } from "../../../store/actions/VoucherAction";
import moment from "moment";

export default function Voucher() {
  const [voucherList, setVoucherList] = useState([]);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();
  const dispatch = useDispatch();
  const handleVouncerList = useCallback(async () => {
    const res = await getVoucherList(currentPage);

    if (res[0] !== null) {
      setVoucherList(res[0].result);
      setNumberOfPages(Math.ceil(res[0].count / 20));
    } else {
      message.error(res[1].error);
    }
  }, [currentPage]);

  useEffect(() => {
    dispatch(resetVoucherState());
    handleVouncerList();
  }, [handleVouncerList, dispatch]);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <button
          className={componentStyle.btnPrimary}
          onClick={() => history.push("/voucher/form")}
        >
          <span style={{ marginRight: "5px" }}>&#43;</span> Create voucher
        </button>
      </div>
      <div style={{ overflowX: "auto" }}>
        <table
          style={{
            marginTop: "3rem",
            width:"100%",
            color: "#212121",
            fontSize: "15px",
            textAlign: "center",
          }}
        >
          <thead>
            <tr style={{ backgroundColor: "#55b3e5" }}>
              <th style={{ border: "1px solid #212121", padding: "10px 20px" }}>
                Guest name
              </th>
              <th style={{ border: "1px solid #212121", padding: "10px 20px" }}>
                Contact details
              </th>
              <th style={{ border: "1px solid #212121", padding: "10px 20px" }}>
                Number of guest
              </th>
              <th style={{ border: "1px solid #212121", padding: "10px 20px" }}>
                Hotel detail
              </th>
              <th style={{ border: "1px solid #212121", padding: "10px 20px" }}>
                Check-in
              </th>
              <th style={{ border: "1px solid #212121", padding: "10px 20px" }}>
                Preview
              </th>
              <th style={{ border: "1px solid #212121", padding: "10px 20px" }}>
                Edit
              </th>
            </tr>
          </thead>
          <tbody>
            {voucherList.length > 0 &&
              voucherList.map((data) => (
                <tr key={data.id}>
                  <td
                    style={{
                      border: "1px solid #212121",
                      padding: "10px 20px",
                    }}
                  >
                    <p> {data.person_name}</p>
                  </td>
                  <td
                    style={{
                      border: "1px solid #212121",
                      padding: "10px 20px",
                    }}
                  >
                    <p style={{width:"14rem"}}>
                      {" "}
                      Ph - {data.person_contact} <br />
                      Email - {data.person_email}
                    </p>
                  </td>
                  <td
                    style={{
                      border: "1px solid #212121",
                      padding: "10px 20px",
                    }}
                  >
                    <p style={{width:"10rem"}}>
                    {data.no_of_guest}
                    </p>
                  </td>
                  <td
                    style={{
                      border: "1px solid #212121",
                      padding: "10px 20px",
                    }}
                  >
                    <p style={{width:"14rem"}}>
                    {data.hotel_name}, {data.hotel_location},{" "}
                    {data.hotel_island_name}
                    </p>
                  </td>
                  <td
                    style={{
                      border: "1px solid #212121",
                      padding: "10px 20px",
                    }}
                  >
                    <p style={{width:"8rem"}}>
                    {moment(data.check_in).format("DD/MM/YYYY")}
                    </p>
                  </td>
                  <td
                    style={{
                      border: "1px solid #212121",
                      padding: "10px 20px",
                    }}
                  >
                    <button
                      className={componentStyle.btnPrimary}
                      onClick={() =>
                        history.push(`/voucher/preview/${data.id}`)
                      }
                    >
                      Preview
                    </button>
                  </td>
                  <td
                    style={{
                      border: "1px solid #212121",
                      padding: "10px 20px",
                    }}
                  >
                    <button
                      className={componentStyle.btnPrimary}
                      onClick={() => history.push(`/voucher/form/${data.id}`)}
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "3rem" }}
      >
        {numberOfPages > 1 && (
          <PaginationPageSelectors
            totalPages={numberOfPages}
            currentPage={currentPage}
            pageClickFunc={(page) => setCurrentPage(page)}
          />
        )}
      </div>
    </div>
  );
}
