import React from "react";
import "../../../../store/reducers/packagereducer/reduerStateType";
import ActivityWidgetLayout from "../ActivityWidgetLayout";
import { useDispatch } from "react-redux";
import { setIsFerryTravelInfoUpdate } from "../../../../store/actions/FerryTravelFormAction";
import ferryImg from "../../../../images/ferry.png";
import ferryIcon from "../../../../jsx/newPages/NewTicket/ferry icon.svg";

/**
 * @typedef {object}Props
 * @property {TravelFerry} widgetData
 * @property {React.CSSProperties} widgetCardContainerStyle
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setShowFerryTravelFormModal
 */

/**
 *  @param {Props} props
 */

export default function FerryTravelWidget({
  widgetData,
  widgetCardContainerStyle,
  setShowFerryTravelFormModal,
}) {
  const dispatch = useDispatch();
  return (
    <ActivityWidgetLayout
      widgetContainerStyle={widgetCardContainerStyle}
      editWidgetAction={() => {
        dispatch(setIsFerryTravelInfoUpdate(widgetData));
        setShowFerryTravelFormModal(true);
      }}
      wingetDayIndexValue={{
        day_no: parseInt(widgetData.day_no),
        index: parseInt(widgetData.index),
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <img src={ferryIcon} alt="ferry" height="40" width="40" />
        <h1
          style={{  fontSize: "1.6rem", color: "#212121", marginLeft: "15px" }}
        >
          {widgetData.source} - {widgetData.dest}
        </h1>
      </div>
      <p
        style={{
          marginTop: "10px",
          color: "grey",
          fontSize: "15px",
          wordBreak: "break-all",
        }}
      >
        {widgetData.heading}
      </p>
      <div style={{ display: "flex", alignItems: "center" }}>
        <img src={ferryImg} alt="ferry" height="150" width="200" />
        <div style={{ marginLeft: "20px", textAlign: "center" }}>
          <label style={{ fontSize: "15px" }}>Ferry type</label>
          <p style={{ fontSize: "17px", fontWeight: "500", color: "#212121" }}>
            {widgetData.type}
          </p>
        </div>
      </div>
      <div style={{ marginTop: "20px" }}>
        <h3 style={{ fontSize: "20px" }}>Inclusion</h3>
        <p
          style={{
            fontSize: "14px",
            color: "#212121",
            fontWeight: "500",
            wordBreak: "break-all",
          }}
        >
          {widgetData.description}
        </p>
      </div>
    </ActivityWidgetLayout>
  );
}
