import React, { useCallback, useEffect, useState } from "react";
import { Tab } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Button from "../../../components/global/Button";
import { getSightseenList } from "../../../../controller/sightseencontroller";
import { handleControllerResultException } from "../../../../util/handleException";
import Table from "../../../components/global/Table/Table";
import PaginationPageSelectors from "../../../components/global/PaginationPageSelectors";
import InputField from "../../../components/global/InputField";
/**
 * @type {import('../../../../model/sightseenModel').SightseenModel[]}
 */
const sightseenListType = [];

export default function SightSeens() {
  const [sightseenList, setSightseenList] = useState(sightseenListType);
  const history = useHistory();
  const [totalPages, setTotalPages] = useState(1);
  const [currentPaga, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [isSearched, setIsSearched] = useState("");

  const handleSightseenList = useCallback(
    async (page = 1, searchText = "") => {
      const res = await getSightseenList(page, searchText);
      if (res[0] !== null) {
        setSightseenList(res[0].results);
        setTotalPages(Math.ceil(res[0].count / 10));
      } else {
        handleControllerResultException(res[1], history);
      }
    },
    [history]
  );

  useEffect(() => {
    handleSightseenList();
  }, [handleSightseenList]);

  
  function handleSearch() {
    setCurrentPage(1);
    handleSightseenList(1, searchText);
    searchText === "" ? setIsSearched(false) : setIsSearched(true);
  }

  return (
    <Tab.Pane eventKey="SightSeens">
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Link to="/sightseen-form">
          <Button variant="Secondary">&#43; Add Sightseen</Button>
        </Link>
      </div>
      <div style={{ display: "flex", alignItems: "center", marginTop: "2rem" }}>
        <InputField
          placeHolder="Search based on name and location"
          inputFieldAttributes={{
            value: searchText,
            onChange: (e) => setSearchText(e.target.value),
          }}
          stylesProps={{ marginRight: "1rem", width: "60%" }}
        />
        <Button onClick={handleSearch}>Search</Button>
        {isSearched && (
          <button
            onClick={() => {
              setSearchText("");
              setCurrentPage(1);
              handleSightseenList();
              setIsSearched(false);
            }}
            style={{
              border: "none",
              color: "red",
              background: "white",
              transform: "scale(1.5)",
              marginLeft: "1rem",
            }}
          >
            &times;
          </button>
        )}
      </div>
      <Table styles={{ marginTop: "3rem" }}>
        <thead>
          <tr>
            <th>Image</th>
            <th>Name</th>
            <th>Location</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          {sightseenList.length > 0 &&
            sightseenList.map((data) => (
              <tr key={data.id}>
                <td>
                  <img
                    src={data.sightseenLowResImageLink}
                    alt={data.sightseenName}
                    height="80"
                    width="80"
                    style={{ objectFit: "fill", borderRadius: "100%" }}
                  />
                </td>
                <td>{data.sightseenName}</td>

                <td>{data.sightseenLocation}</td>
                <td>
                  <Link to={`/sightseen-form/${data.id}`}>
                    <Button> Edit</Button>
                  </Link>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      <div
        style={{ marginTop: "2rem", justifyContent: "center", display: "flex" }}
      >
        <PaginationPageSelectors
          totalPages={totalPages}
          currentPage={currentPaga}
          pageClickFunc={(page) => {
            handleSightseenList(page, searchText);
            setCurrentPage(page);
          }}
        />
      </div>
    </Tab.Pane>
  );
}
