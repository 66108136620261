import React, { useCallback, useEffect, useState } from "react";
import componentStyle from "../../../css/components.module.css";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import "../../../store/reducers/voucher/VoucherStateType";
import InputField from "../../components/global/InputField";
import {
  addVouhcerData,
  insertVoucerInclusion,
  insertVoucherInstruction,
  removeVoucherInclusion,
  removeVoucherInstruction,
  removeVoucherPnr,
  setVoucherCheckInDate,
  setVoucherCheckOutDate,
  setVoucherExtraMatress,
  setVoucherHotelIslandName,
  setVoucherHotelLocation,
  setVoucherHotelName,
  setVoucherIssuerEmail,
  setVoucherIssuerName,
  setVoucherIssuerPhoneNumber,
  setVoucherMealPlan,
  setVoucherNoOfRooms,
  setVoucherPersonContactNumber,
  setVoucherPersonEmail,
  setVoucherPersonName,
  setVoucherPnrId,
  setVoucherReCheckInDate,
  setVoucherReCheckOutDate,
  setVoucherRoomType,
  setVoucherTotalNumOfAdults,
  setVoucherTotalNumOfChildren,
  updateVoucherInclusion,
  updateVoucherInstruction,
} from "../../../store/actions/VoucherAction";
import SevableInputField from "../../components/global/SevableInputField";
import { message } from "antd";
import {
  getVoucerDetails,
  voucherUpdate,
} from "../../../controller/voucherController";
import { voucherObjToServerVoucherObj } from "../../../util/handleVoucherDataFormat";
import PnrSelectorModal from "../../components/voucher/PnrSelectorModal";
import { HTTPStatus } from "../../../util/helperObj";
export default function VoucherForm() {
  const history = useHistory();
  const { voucherId } = useParams();
  /**
   * @type {VoucherState}
   */
  const voucherData = useSelector((state) => state.voucher);
  const [inputError, setInputError] = useState(false);
  const [showPnrSelectorModal, setShowPnrSelectorModal] = useState(false);
  const [updatingVoucher, setUpdatingVoucher] = useState(false);
  const [fetchingVoucherDetails, setFetchingVoucherDetails] = useState(false);
  const dispatch = useDispatch();

  function checkIfInputProvided() {
    return (
      voucherData.personName !== "" &&
      voucherData.personContactNumber !== "" &&
      voucherData.personEmail !== "" &&
      voucherData.issuerName !== "" &&
      voucherData.issuerEmail !== "" &&
      voucherData.issuerPhoneNumber !== "" &&
      voucherData.hotelName !== "" &&
      voucherData.roomType !== "" &&
      voucherData.extraMatress !== "" &&
      voucherData.mealPlan !== "" &&
      voucherData.hotelLocation !== "" &&
      voucherData.hotelIslandName !== "" &&
      voucherData.numOfRooms !== "" &&
      voucherData.totalNumOfAdults !== "" &&
      voucherData.totalNumOfChildren !== "" &&
      voucherData.checkInDate !== "" &&
      voucherData.checkOutDate !== "" &&
      voucherData.inclusion.length > 0 &&
      voucherData.instruction.length > 0
    );
  }

  function handleSave() {
    if (checkIfInputProvided()) {
      history.push("/voucher/preview");
    } else {
      setInputError(true);
      message.error("Plesae provide all information");
    }
  }

  const handleVoucherDetails = useCallback(async () => {
    if (voucherId && voucherData.id === 0) {
      setFetchingVoucherDetails(true);
      const res = await getVoucerDetails(voucherId);
      setFetchingVoucherDetails(false);
      if (res[0] !== null) {
        res[0]["isUpdate"] = true;
        dispatch(addVouhcerData(res[0]));
      } else {
        message.error(res[1].error);
      }
    }
  }, [voucherId, dispatch, voucherData.id]);

  useEffect(() => {
    handleVoucherDetails();
  }, [handleVoucherDetails]);

  async function handleVoucherUpdate() {
    if (checkIfInputProvided()) {
      setUpdatingVoucher(true);
      const obj = voucherObjToServerVoucherObj(voucherData);
      setUpdatingVoucher(false);
      const res = await voucherUpdate(voucherData.id, obj);
      if (res.statusCode === HTTPStatus.SUCCESS) {
        message.success(res.success);
      } else {
        message.error(res.error);
      }
    } else {
      setInputError(true);
      message.error("Plesae provide all information");
    }
  }

  if (fetchingVoucherDetails) {
    return (
      <p style={{ textAlign: "center", fontSize: "20px", color: "black" }}>
        Loading...🚀
      </p>
    );
  }
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "3rem",
        }}
      >
        <button
          className={componentStyle.btnPrimary}
          onClick={() => history.goBack()}
        >
          <span style={{ marginRight: "4px" }}>&#x2190;</span> Previous page
        </button>
        {voucherData.isUpdate && (
          <button
            className={componentStyle.btnPrimary}
            disabled={updatingVoucher}
            onClick={handleVoucherUpdate}
          >
            {updatingVoucher ? "...Updating" : "Update"}
          </button>
        )}
      </div>
      <button
        className={componentStyle.btnPrimary}
        onClick={() => setShowPnrSelectorModal(true)}
      >
        Select Pnr code
      </button>
      {voucherData.pnr !== "" && (
        <div style={{ marginTop: "20px", color: "#212121" }}>
          Pnr code - <strong>{voucherData.pnr}</strong>{" "}
          <button
            style={{
              border: "none",
              background: "white",
              color: "red",
              transform: "scale(1.2)",
              marginLeft: "5px",
            }}
            onClick={() => dispatch(removeVoucherPnr())}
          >
            &#215;
          </button>
        </div>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <InputField
          label="Guest name"
          inputFieldAttributes={{
            value: voucherData.personName,
            onChange: (e) => dispatch(setVoucherPersonName(e.target.value)),
            disabled: voucherData.pnr !== "" && true,
          }}
          showInputError={inputError}
          stylesProps={{ width: "100%" }}
        />
        <InputField
          label="Guest contact number"
          inputFieldAttributes={{
            type: "number",
            value: voucherData.personContactNumber,
            disabled: voucherData.pnr !== "" && true,
            onChange: (e) =>
              dispatch(setVoucherPersonContactNumber(e.target.value)),
          }}
          showInputError={inputError}
          stylesProps={{ width: "100%", marginLeft: "10px" }}
        />
        <InputField
          label="Guest Email"
          inputFieldAttributes={{
            type: "email",
            value: voucherData.personEmail,
            disabled: voucherData.pnr !== "" && true,
            onChange: (e) => dispatch(setVoucherPersonEmail(e.target.value)),
          }}
          showInputError={inputError}
          stylesProps={{ width: "100%", marginLeft: "10px" }}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <InputField
          label="Issuer name"
          inputFieldAttributes={{
            value: voucherData.issuerName,
            onChange: (e) => dispatch(setVoucherIssuerName(e.target.value)),
          }}
          showInputError={inputError}
          stylesProps={{ width: "100%" }}
        />
        <InputField
          label="Issuer contact number"
          inputFieldAttributes={{
            type: "number",
            value: voucherData.issuerPhoneNumber,
            onChange: (e) =>
              dispatch(setVoucherIssuerPhoneNumber(e.target.value)),
          }}
          showInputError={inputError}
          stylesProps={{ width: "100%", marginLeft: "10px" }}
        />
        <InputField
          label="Issuer email"
          inputFieldAttributes={{
            type: "email",
            value: voucherData.issuerEmail,
            onChange: (e) => dispatch(setVoucherIssuerEmail(e.target.value)),
          }}
          showInputError={inputError}
          stylesProps={{ width: "100%", marginLeft: "10px" }}
        />
      </div>

      <div
        style={{
          marginTop: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <InputField
          label="Hotel name"
          inputFieldAttributes={{
            value: voucherData.hotelName,
            onChange: (e) => dispatch(setVoucherHotelName(e.target.value)),
          }}
          showInputError={inputError}
          stylesProps={{ width: "100%" }}
        />
        <InputField
          label="Hotel location"
          inputFieldAttributes={{
            value: voucherData.hotelLocation,
            onChange: (e) => dispatch(setVoucherHotelLocation(e.target.value)),
          }}
          showInputError={inputError}
          stylesProps={{ width: "100%", marginLeft: "10px" }}
        />
        <InputField
          label="Hotel island name"
          inputFieldAttributes={{
            value: voucherData.hotelIslandName,
            onChange: (e) =>
              dispatch(setVoucherHotelIslandName(e.target.value)),
          }}
          showInputError={inputError}
          stylesProps={{ width: "100%", marginLeft: "10px" }}
        />
      </div>
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <InputField
          label="Number of hotel rooms"
          inputFieldAttributes={{
            type: "number",
            value: voucherData.numOfRooms,
            onChange: (e) => dispatch(setVoucherNoOfRooms(e.target.value)),
          }}
          showInputError={inputError}
          stylesProps={{ width: "100%" }}
        />
        <InputField
          label="Number of adults"
          inputFieldAttributes={{
            type: "number",
            value: voucherData.totalNumOfAdults,
            onChange: (e) =>
              dispatch(setVoucherTotalNumOfAdults(e.target.value)),
          }}
          showInputError={inputError}
          stylesProps={{ width: "100%", marginLeft: "10px" }}
        />
        <InputField
          label="Number of children"
          inputFieldAttributes={{
            type: "number",
            value: voucherData.totalNumOfChildren,
            onChange: (e) =>
              dispatch(setVoucherTotalNumOfChildren(e.target.value)),
          }}
          showInputError={inputError}
          stylesProps={{ width: "100%", marginLeft: "10px" }}
        />
        <InputField
          label="Extra matress"
          inputFieldAttributes={{
            type: "number",
            value: voucherData.extraMatress,
            onChange: (e) => dispatch(setVoucherExtraMatress(e.target.value)),
          }}
          showInputError={inputError}
          stylesProps={{ width: "100%", marginLeft: "10px" }}
        />
      </div>
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <InputField
          label="Room type"
          inputFieldAttributes={{
            value: voucherData.roomType,
            onChange: (e) => dispatch(setVoucherRoomType(e.target.value)),
          }}
          showInputError={inputError}
          stylesProps={{ width: "100%" }}
        />
        <InputField
          label="Meal plan"
          inputFieldAttributes={{
            value: voucherData.mealPlan,
            onChange: (e) => dispatch(setVoucherMealPlan(e.target.value)),
          }}
          showInputError={inputError}
          stylesProps={{ width: "100%", marginLeft: "10px" }}
        />
      </div>
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <InputField
          label="Check-in date"
          inputFieldAttributes={{
            type: "date",
            value: voucherData.checkInDate,
            onChange: (e) => dispatch(setVoucherCheckInDate(e.target.value)),
          }}
          showInputError={inputError}
          stylesProps={{ width: "100%" }}
        />
        <InputField
          label="Check-out date"
          inputFieldAttributes={{
            type: "date",
            value: voucherData.checkOutDate,
            onChange: (e) => dispatch(setVoucherCheckOutDate(e.target.value)),
          }}
          showInputError={inputError}
          stylesProps={{ width: "100%", marginLeft: "10px" }}
        />
      </div>
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <InputField
          label="ReCheck-in date (optional)"
          inputFieldAttributes={{
            type: "date",
            value: voucherData.reCheckInDate,
            onChange: (e) => dispatch(setVoucherReCheckInDate(e.target.value)),
          }}
          stylesProps={{ width: "100%" }}
        />
        <InputField
          label="ReCheck-out date (optional)"
          inputFieldAttributes={{
            type: "date",
            value: voucherData.reCheckOutDate,
            onChange: (e) => dispatch(setVoucherReCheckOutDate(e.target.value)),
          }}
          stylesProps={{ width: "100%", marginLeft: "10px" }}
        />
      </div>
      <SevableInputField
        styleProps={{ marginTop: "20px" }}
        label="Inclusion"
        saveInputFunc={(data) => dispatch(insertVoucerInclusion(data))}
        savedInputList={voucherData.inclusion}
        deleteInputFunc={(index) => dispatch(removeVoucherInclusion(index))}
        editInputFunc={(data, index) =>
          dispatch(
            updateVoucherInclusion({
              index,
              value: data,
            })
          )
        }
        showInputError={inputError}
      />
      <SevableInputField
        styleProps={{ marginTop: "20px" }}
        label="Instruction"
        savedInputList={voucherData.instruction}
        saveInputFunc={(data) => dispatch(insertVoucherInstruction(data))}
        deleteInputFunc={(index) => dispatch(removeVoucherInstruction(index))}
        editInputFunc={(data, index) =>
          dispatch(
            updateVoucherInstruction({
              index,
              value: data,
            })
          )
        }
        showInputError={inputError}
      />
      <div
        style={{ marginTop: "2rem", display: "flex", justifyContent: "center" }}
      >
        <button
          className={componentStyle.btnPrimary}
          onClick={handleSave}
          style={{ marginTop: "2rem" }}
        >
          Preview voucher <span style={{ marginLeft: "10px" }}>&#8594;</span>
        </button>
      </div>
      {showPnrSelectorModal && (
        <PnrSelectorModal
          setShowPnrSelectorModal={setShowPnrSelectorModal}
          selectFunc={(pnrData) => {
            dispatch(setVoucherPnrId(pnrData.pnrNumber));
            dispatch(setVoucherPersonName(pnrData.customerName));
            dispatch(
              setVoucherPersonContactNumber(pnrData.customerPhoneNumber)
            );
            dispatch(setVoucherPersonEmail(pnrData.customerEmail));
            setShowPnrSelectorModal(false);
          }}
        />
      )}
    </div>
  );
}
