import React from 'react'
import Rectangle from "./Rectangle283.png"


export function H4({ className , children }) {
    return (
        <h4 className={`font-bold my-4 ${className}`}>
            {children}
        </h4>
    )
}


export function ImgCard({ title, img }) {
    return (
        <div className='col'>
            <h4 className='font-bold my-4'>{title}</h4>
             <img className='w-[140px] h-[110px]' src={img?img:Rectangle} />
            <h4 className='font-bold my-4'>Name:</h4>
            <div className="form-group mb-3">
                <input
                    type="text"
                    className="form-control w-full input-default "
                    placeholder="Enter name"
                />
            </div>
            <h4 className='font-bold my-4'>Designation</h4>
            <div className="form-group mb-3">
                <input
                    type="text"
                    className="form-control w-full input-default "
                    placeholder="Enter Designation"
                />
            </div>
        </div>
    )
}