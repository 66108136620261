import React from "react";

/**
 * @typedef {object} Props
 * @property {()=>void} moveUpAction
 * @property {()=>void} moveDownAction
 * @property {React.CSSProperties} styles
 */

/**
 * @param {Props} props
 */
export default function MoveUpDownButton({
  moveUpAction,
  moveDownAction,
  styles,
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        ...styles,
      }}
    >
      <button
        style={{
          border: "none",
          background: "#d1d5db",
          padding: "5px 10px",
          borderRadius: "4px",
        }}
        onClick={() => moveUpAction()}
      >
        &uarr;
      </button>
      <button
        style={{
          border: "none",
          background: "#d1d5db",
          marginTop: "5px",
          padding: "5px 10px",
          borderRadius: "4px",
        }}
        onClick={() => moveDownAction()}
      >
        &darr;
      </button>
    </div>
  );
}
