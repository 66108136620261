export const VOUCHER_PNR = "voucher pnr";
export const VOUCHER_PERSON_NAME = "voucher person name";
export const REMOVE_VOUCHER_PNR = "remove voucher pnr";
export const VOUCHER_PERSON_CONTACT_NUMBER = "vouhcer person contact number";
export const VOUCHER_ISSUER_NAME = "voucher issuer name";
export const VOUCHER_ISSUER_PHONE_NUMBER = "voucher issuer phone number";
export const VOUCHER_ISSUER_EMAIL = "voucher issuer email";
export const VOUCHER_PERSON_EMAIL = "voucher person email";
export const VOUCHER_HOTEL_NAME = "voucher hotel name";
export const VOUCHER_HOTEL_LOCATION = "voucher hotel location";
export const VOUCHER_HOTEL_ISLAND_NAME = "voucher hotel island name";
export const VOUCHER_NO_OF_ROOMS = "voucher no of rooms";
export const VOUCHER_ROOM_TYPE = "voucher room type";
export const VOUCHER_EXTRA_MATRESS = "voucher extra matress";
export const VOUCHER_TOTAL_NO_ADULTS = "voucher total number of adults";
export const VOUCHER_TOTAL_NO_CHIDREN = "voucher total number of children";
export const VOUCHER_MEAL_PLAN = "voucher meal plan";
export const VOUCHER_CHECK_IN_DATE = "voucher check in date";
export const VOUCHER_CHECK_OUT_DATE = "voucher check out date";
export const VOUCHER_RE_CHECK_IN_DATE = "voucher recheck in date";
export const VOUCHER_RE_CHECK_OUT_DATE = "voucher recheck out date";
export const VOUCHER_INSERT_INCLUSION = "voucher insert inclusion";
export const VOUCHER_REMOVE_INCLUSION = "voucher remove inclusion";
export const VOUCHER_UPDATE_INCLUSION = "voucher update inclusion";
export const VOUCHER_INSERT_INSTRICTION = "voucher insert instruction";
export const VOUCHER_UPDATE_INSTRUCTION = "voucher update instruction";
export const VOUCHER_REMOVE_INSTRUCTION = "voucher remove instruction";
export const RESET_VOUCHER_STATE = "reset voucher state";
export const ADD_VOUCHER_DATA = "add voucher data";
/**
 * @param {string} payload
 */
export function setVoucherPnrId(payload) {
  return {
    type: VOUCHER_PNR,
    payload,
  };
}

/**
 * @param {string} payload
 */

export function setVoucherPersonName(payload) {
  return {
    type: VOUCHER_PERSON_NAME,
    payload,
  };
}

/**
 * @param {string} payload
 */

export function setVoucherPersonContactNumber(payload) {
  return {
    type: VOUCHER_PERSON_CONTACT_NUMBER,
    payload,
  };
}

/**
 * @param {string} payload
 */

export function setVoucherPersonEmail(payload) {
  return {
    type: VOUCHER_PERSON_EMAIL,
    payload,
  };
}

export function setVoucherIssuerName(payload) {
  return {
    type: VOUCHER_ISSUER_NAME,
    payload,
  };
}

export function setVoucherIssuerEmail(payload) {
  return {
    type: VOUCHER_ISSUER_EMAIL,
    payload,
  };
}

export function setVoucherIssuerPhoneNumber(payload) {
  return {
    type: VOUCHER_ISSUER_PHONE_NUMBER,
    payload,
  };
}

export function removeVoucherPnr() {
  return {
    type: REMOVE_VOUCHER_PNR,
  };
}
/**
 * @param {string} payload
 */
export function setVoucherHotelName(payload) {
  return {
    type: VOUCHER_HOTEL_NAME,
    payload,
  };
}

/**
 * @param {string} payload
 */

export function setVoucherHotelLocation(payload) {
  return {
    type: VOUCHER_HOTEL_LOCATION,
    payload,
  };
}

/**
 * @param {string} payload
 */

export function setVoucherHotelIslandName(payload) {
  return {
    type: VOUCHER_HOTEL_ISLAND_NAME,
    payload,
  };
}

/**
 * @param {string} payload
 */
export function setVoucherNoOfRooms(payload) {
  return {
    type: VOUCHER_NO_OF_ROOMS,
    payload,
  };
}

export function setVoucherRoomType(payload) {
  return {
    type: VOUCHER_ROOM_TYPE,
    payload,
  };
}

export function setVoucherExtraMatress(payload) {
  return {
    type: VOUCHER_EXTRA_MATRESS,
    payload,
  };
}

export function setVoucherMealPlan(payload) {
  return {
    type: VOUCHER_MEAL_PLAN,
    payload,
  };
}

/**
 * @param {string} payload
 */
export function setVoucherTotalNumOfAdults(payload) {
  return {
    type: VOUCHER_TOTAL_NO_ADULTS,
    payload,
  };
}

/**
 * @param {string} payload
 */
export function setVoucherTotalNumOfChildren(payload) {
  return {
    type: VOUCHER_TOTAL_NO_CHIDREN,
    payload,
  };
}

/**
 * @param {string} payload
 */

export function setVoucherCheckInDate(payload) {
  return {
    type: VOUCHER_CHECK_IN_DATE,
    payload,
  };
}

/**
 * @param {string} payload
 */

export function setVoucherCheckOutDate(payload) {
  return {
    type: VOUCHER_CHECK_OUT_DATE,
    payload,
  };
}

/**
 * @param {string} payload
 */

export function setVoucherReCheckInDate(payload) {
  return {
    type: VOUCHER_RE_CHECK_IN_DATE,
    payload,
  };
}

/**
 * @param {string} payload
 */

export function setVoucherReCheckOutDate(payload) {
  return {
    type: VOUCHER_RE_CHECK_OUT_DATE,
    payload,
  };
}

/**
 * @param {string} payload
 */

export function insertVoucerInclusion(payload) {
  return {
    type: VOUCHER_INSERT_INCLUSION,
    payload,
  };
}

/**
 * @param {number} index
 */

export function removeVoucherInclusion(index) {
  return {
    type: VOUCHER_REMOVE_INCLUSION,
    payload: index,
  };
}

/**
 * @typedef {object} UpdateParam
 * @property {number} index
 * @property {string} value
 */

/**
 * @param {UpdateParam} payload
 */

export function updateVoucherInclusion(payload) {
  return {
    type: VOUCHER_UPDATE_INCLUSION,
    payload,
  };
}

/**
 * @param {string} payload
 */
export function insertVoucherInstruction(payload) {
  return {
    type: VOUCHER_INSERT_INSTRICTION,
    payload,
  };
}
/**
 * @param {UpdateParam} payload
 */

export function updateVoucherInstruction(payload) {
  return {
    type: VOUCHER_UPDATE_INSTRUCTION,
    payload,
  };
}

/**
 * @param {number} index
 */

export function removeVoucherInstruction(index) {
  return {
    type: VOUCHER_REMOVE_INSTRUCTION,
    payload: index,
  };
}

export function resetVoucherState() {
  return {
    type: RESET_VOUCHER_STATE,
  };
}

/**
 *
 * @param {VoucherState} voucher
 */
export function addVouhcerData(voucher) {
  return {
    type: ADD_VOUCHER_DATA,
    payload: voucher,
  };
}
