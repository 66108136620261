import {
  FLIGHT_TRAVEL_DAY_NO,
  FLIGHT_TRAVEL_FLIGHT_DATE,
  FLIGHT_TRAVEL_FLIGHT_DEST,
  FLIGHT_TRAVEL_FLIGHT_DURATION,
  FLIGHT_TRAVEL_FLIGHT_NAME,
  FLIGHT_TRAVEL_FLIGHT_NUMBER,
  FLIGHT_TRAVEL_FLIGHT_SOURCE,
  FLIGHT_TRAVEL_INDEX,
  FLIGHT_TRAVEL_INFO_UPDATE,
  FLIGHT_TRAVEL_LANDING_TIME,
  FLIGHT_TRAVEL_TAKEOFF_TIME,
  RESET_FLIGHT_TRAVEL_FORM,
} from "../../actions/FlightTravelFormAction";

/**
 * @type {import("./flightTravelTypes").FlightTravelState}
 */
const initialState = {
  day_no: "",
  index: "",
  activity_type: "travel_flight",
  flight_dest: "",
  flight_duration: "",
  flight_name: "",
  flight_no: "",
  isUpdate: false,
  landing: "",
  flight_source: "",
  take_off: "",
  flight_date: "",
  id: null,
};

/**
 * @returns {import("./flightTravelTypes").FlightTravelState}
 */

export function flightTravelReducer(state = initialState, action) {
  switch (action.type) {
    case FLIGHT_TRAVEL_DAY_NO:
      return {
        ...state,
        day_no: action.payload,
      };
    case FLIGHT_TRAVEL_INDEX:
      return {
        ...state,
        index: action.payload,
      };
    case FLIGHT_TRAVEL_LANDING_TIME:
      return {
        ...state,
        landing: action.payload,
      };
    case FLIGHT_TRAVEL_TAKEOFF_TIME:
      return {
        ...state,
        take_off: action.payload,
      };
    case FLIGHT_TRAVEL_FLIGHT_SOURCE:
      return {
        ...state,
        flight_source: action.payload,
      };
    case FLIGHT_TRAVEL_FLIGHT_DEST:
      return {
        ...state,
        flight_dest: action.payload,
      };
    case FLIGHT_TRAVEL_FLIGHT_NAME:
      return {
        ...state,
        flight_name: action.payload,
      };
    case FLIGHT_TRAVEL_FLIGHT_DURATION:
      return {
        ...state,
        flight_duration: action.payload,
      };
    case FLIGHT_TRAVEL_FLIGHT_NUMBER:
      return {
        ...state,
        flight_no: action.payload,
      };
    case FLIGHT_TRAVEL_FLIGHT_DATE:
      return {
        ...state,
        flight_date: action.payload,
      };
    case FLIGHT_TRAVEL_INFO_UPDATE:
      return {
        ...action.payload,
        isUpdate: true,
      };
    case RESET_FLIGHT_TRAVEL_FORM:
      return initialState;
    default:
      return state;
  }
}
