import React, { useState } from 'react'
import { H4 } from '../Componets'

function Careers() {

const [ benefit , setBenefit ] = useState({
    title: "Lorem ipsum dolor sit amet, consectetur.",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.",
    benefits : [
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.",
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.",
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.",
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do."
    ]

})


let intToStr = ["One", "Two"]



  return (
    <>
    <H4>Careers:</H4>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>

    <h4 className='text-xl font-semibold'>BENEFITS</h4>  
    
    


    </>
  )
}

export default Careers
