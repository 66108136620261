export const FERRY_DAY_NO = "ferry day no";
export const FERRY_INDEX = "ferry index";
export const FERRY_SOURCE = "ferry source";
export const FERRY_DESTINATION = "ferry destination";
export const FERRY_TYPE = "ferry type";
export const FERRY_HEADING = "ferry heading";
export const FERRY_DESCRIPTION = "ferry description";
export const RESET_FERRY_FORM = "reset ferry form";
export const IS_FERRY_TRAVEL_INFO_UPDATE = "is ferry travel info update";

export function addFerryDayNo(payload) {
  return {
    type: FERRY_DAY_NO,
    payload,
  };
}

export function addFerryIndex(payload) {
  return {
    type: FERRY_INDEX,
    payload,
  };
}

export function addFerrySource(payload) {
  return {
    type: FERRY_SOURCE,
    payload,
  };
}

export function addFerryDestination(payload) {
  return {
    type: FERRY_DESTINATION,
    payload,
  };
}
export function addFerryType(payload) {
  return {
    type: FERRY_TYPE,
    payload,
  };
}

export function addFerryHeading(payload) {
  return {
    type: FERRY_HEADING,
    payload,
  };
}

export function addFerryDescription(payload) {
  return {
    type: FERRY_DESCRIPTION,
    payload,
  };
}

export function resetFerryForm() {
  return {
    type: RESET_FERRY_FORM,
  };
}

/**
 * @param {TravelFerry} payload
 */
export function setIsFerryTravelInfoUpdate(payload) {
  return {
    type: IS_FERRY_TRAVEL_INFO_UPDATE,
    payload,
  };
}
