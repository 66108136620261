import React, { useState } from "react";
import editSvg from "../../../images/edit.svg";
import deleteSvg from "../../../images/delete.svg";
import AlertModal from "../global/AlertModal";
import MoveUpDownButton from "../../components/package/MoveUpDownButton";
import { useDispatch } from "react-redux";
import {
  moveDownActivity,
  moveUpActivity,
  removeWidgetInfo,
} from "../../../store/actions/PackageAction";
/**
 * @typedef {object} Props
 * @property {React.CSSProperties} widgetContainerStyle
 * @property {()=>void} editWidgetAction
 * @property {{day_no:number,index:number}} wingetDayIndexValue
 * @property {JSX.Element} children
 */

/**
 * @param {Props} props
 */
export default function ActivityWidgetLayout({
  widgetContainerStyle,
  editWidgetAction,
  wingetDayIndexValue,
  children,
}) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const dispatch = useDispatch();
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "start",
          ...widgetContainerStyle,
        }}
      >
        <MoveUpDownButton
          moveUpAction={() => dispatch(moveUpActivity(wingetDayIndexValue))}
          moveDownAction={() => dispatch(moveDownActivity(wingetDayIndexValue))}
        />
        <div
          style={{
            border: "1px solid #9ca3af",
            padding: "20px",
            borderRadius: "5px",
            marginLeft: "10px",
            flexGrow: "1",
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div>
              <button style={{ border: "none" }} onClick={editWidgetAction}>
                <img src={editSvg} alt="edit" width="20" height="20" />
              </button>
              <button
                style={{ border: "none", marginLeft: "15px" }}
                onClick={() => setShowDeleteModal(true)}
              >
                <img src={deleteSvg} alt="delete" width="20" height="20" />
              </button>
            </div>
          </div>
          {children}
        </div>
      </div>
      {showDeleteModal && (
        <AlertModal
          alertMessage="Do you want to remove this widget?"
          confirmButtonAction={() =>
            dispatch(removeWidgetInfo(wingetDayIndexValue))
          }
          setModalVisibility={setShowDeleteModal}
        />
      )}
    </>
  );
}
