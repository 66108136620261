import React, { useCallback, useEffect, useState } from "react";
import Button from "../../../components/global/Button";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import InputField from "../../../components/global/InputField";
import SelectField from "../../../components/global/SelectField";
import { generateLocalImageUrl, getFileSize } from "../../../../util/util";
import TextArea from "../../../components/global/TextArea";
import {
  createSightseen,
  getSightseenDetails,
  updateSightseen,
} from "../../../../controller/sightseencontroller";
import { HTTPStatus } from "../../../../util/helperObj";
import { handleControllerResultException } from "../../../../util/handleException";
import { message } from "antd";

export default function SightSeenForm() {
  const history = useHistory();
  const { sightseenId } = useParams();
  const [sightseenName, setSightseenName] = useState("");
  const [sightseenLocation, setSightseenLocation] = useState("");
  const [sightseenDescription, setSightseenDescription] = useState("");
  const [sightseenImage, setSightseenImage] = useState();
  const [sightseenImageLink, setSightseenImageLink] = useState("");
  const [sightseenLowResImage, setSightseenLowResImage] = useState();
  const [sightseenLowResImageLink, setSightseenLowResImageLink] = useState("");
  const [sightseenDescriptionPdf, setSightseenDescriptionPdf] = useState("");
  const [inputError, setInputError] = useState(false);
  const [saving, setSaving] = useState(false);

  const handleSightseenDetails = useCallback(
    async (sightseenId) => {
      const res = await getSightseenDetails(sightseenId);
      if (res[0] !== null) {
        setSightseenName(res[0].sightseenName);
        setSightseenLocation(res[0].sightseenLocation);
        setSightseenDescription(res[0].sightseenDescription);
        setSightseenImageLink(res[0].sightseenImageLink);
        setSightseenLowResImageLink(res[0].sightseenLowResImageLink);
        setSightseenDescriptionPdf(res[0].sightseenDescriptionPdf);
      } else {
        handleControllerResultException(res[1], history);
      }
    },
    [history]
  );

  useEffect(() => {
    if (sightseenId) {
      handleSightseenDetails(sightseenId);
    }
  }, [handleSightseenDetails, sightseenId]);

  function handleImageChange(image, setImage, setImageLink) {
    if (image !== undefined) {
      setImage(image);
      setImageLink(generateLocalImageUrl(image));
    } else {
      setImage(undefined);
      setImageLink("");
    }
  }

  function checkInput() {
    return (
      sightseenName !== "" &&
      sightseenLocation !== "" &&
      sightseenDescription !== "" &&
      sightseenImageLink !== "" &&
      sightseenLowResImageLink !== ""
    );
  }

  async function handleSave() {
    setInputError(false);
    if (checkInput() && getFileSize(sightseenLowResImage) <= 200) {
      setSaving(true);
      const res = await createSightseen(
        sightseenName,
        sightseenLocation,
        sightseenDescription,
        sightseenDescriptionPdf,
        sightseenImage,
        sightseenLowResImage
      );
      setSaving(false);

      if (res.statusCode === HTTPStatus.CREATED) {
        message.success(res.success);
        history.goBack();
      } else {
        handleControllerResultException(res, history);
      }
    } else {
      setInputError(true);
    }
  }

  async function handleUpdate() {
    setInputError(false);
    if (checkInput()) {
      if (
        sightseenLowResImage !== undefined &&
        getFileSize(sightseenLowResImage) > 200
      ) {
        setInputError(true);
        return false;
      }
      setSaving(true);
      const res = await updateSightseen({
        sightseenId,
        sightseenName,
        sightseenLocation,
        sightseenDescription,
        sightseenImage,
        sightseenLowResImage,
        sightseenDescriptionPdf,
      });
      setSaving(false);
      if (res.statusCode === HTTPStatus.SUCCESS) {
        message.success(res.success);
      } else {
        handleControllerResultException(res, history);
      }
    } else {
      setInputError(true);
    }
  }

  return (
    <div>
      <Button
        onClick={() => history.goBack()}
        styles={{ marginBottom: "2rem" }}
      >
        Return
      </Button>
      <div style={{ display: "flex", alignItems: "center" }}>
        <InputField
          label="Signtseen name"
          inputFieldAttributes={{
            value: sightseenName,
            onChange: (e) => setSightseenName(e.target.value),
          }}
          showInputError={inputError}
          stylesProps={{ width: "100%" }}
        />
        <SelectField
          label="Sightseen location"
          options={["Port Blair", "Havelock", "Neil Island"]}
          selectFieldAttributes={{
            value: sightseenLocation,
            onChange: (e) => setSightseenLocation(e.target.value),
          }}
          showInputError={inputError}
          stylesProps={{ marginLeft: "1rem", width: "100%" }}
        />
      </div>
      <div style={{ display: "flex", alignItems: "center", marginTop: "2rem" }}>
        <InputField
          label="Image (This image will show in website)"
          inputFieldAttributes={{
            type: "file",
            onChange: (e) =>
              handleImageChange(
                e.target.files[0],
                setSightseenImage,
                setSightseenImageLink
              ),
          }}
          showInputError={inputError}
          showErrorCondition={sightseenImageLink === ""}
          errorMessage="Please provide a sightseen image"
          stylesProps={{ width: "100%" }}
        />
        <InputField
          label="Image (max 200 kb) (This image will be used for gererating ticket)"
          inputFieldAttributes={{
            type: "file",
            onChange: (e) =>
              handleImageChange(
                e.target.files[0],
                setSightseenLowResImage,
                setSightseenLowResImageLink
              ),
          }}
          showInputError={inputError}
          showErrorCondition={
            sightseenLowResImageLink === "" ||
            getFileSize(sightseenLowResImage) > 200
          }
          stylesProps={{ width: "100%", marginLeft: "1rem" }}
          errorMessage={
            sightseenLowResImageLink === ""
              ? "Plesae upload a image"
              : getFileSize(sightseenLowResImage) > 200
              ? "Image size should not cross 200 kb"
              : ""
          }
        />
      </div>
      <div style={{ display: "flex", alignItems: "center", marginTop: "2rem" }}>
        {sightseenImageLink !== "" && (
          <img
            src={sightseenImageLink}
            alt="hotelimage"
            height="200"
            width="200"
            style={{ objectFit: "fill", margin: "0px 20px" }}
          />
        )}
        {sightseenLowResImageLink !== "" && (
          <img
            src={sightseenLowResImageLink}
            alt="hotelLowResolutionimage"
            height="200"
            width="200"
            style={{ objectFit: "fill", margin: "0px 20px" }}
          />
        )}
      </div>
      <TextArea
        label="Sightseen description"
        textAreaAttributes={{
          value: sightseenDescription,
          onChange: (e) => setSightseenDescription(e.target.value),
          rows: "10",
        }}
        showInputError={inputError}
        stylesProps={{ marginTop: "2rem" }}
      />
      <TextArea
        label="Sightseen description (this description will be used in ticket)"
        textAreaAttributes={{
          value: sightseenDescriptionPdf,
          onChange: (e) => setSightseenDescriptionPdf(e.target.value),
          rows: "10",
        }}
        showInputError={inputError}
        stylesProps={{ marginTop: "2rem" }}
      />
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}
      >
        <Button
          disabled={saving}
          loading={saving}
          onClick={sightseenId ? handleUpdate : handleSave}
        >
          {sightseenId ? "Update" : "Save"}
        </Button>
      </div>
    </div>
  );
}
