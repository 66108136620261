import React from "react";

/**
 * @typedef {object} Props
 * @property {string} currentStatus
 * @property {(status:string)=>void} onChange
 */

/**
 * @param {Props} params
 */
export default function BlogStatus({ currentStatus, onChange }) {
  return (
    <select style={{borderRadius:"15px", padding:"2px 10px"}} defaultValue={currentStatus} onChange={(e) => onChange(e.target.value)}>
      <option value="Live">Live</option>
      <option value="Draft">Draft</option>
      <option value="Archive">Archive</option>
    </select>
  );
}
