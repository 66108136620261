import "../reduerStateType";

/**
 * @param {PackageState} state
 * @param {TravelFerry} payload
 */

export function updateFerryTravelInfo(state, payload) {
  const newArr = [...state.complete_tour_data];
  const ferryTravelData = newArr[payload.day_no - 1][payload.index - 1];
  if (ferryTravelData.activity_type === "travel_ferry") {
    ferryTravelData.heading = payload.heading;
    ferryTravelData.description = payload.description;
    ferryTravelData.source = payload.source;
    ferryTravelData.dest = payload.dest;
    ferryTravelData.type = payload.type;
  }
  return newArr;
}
