import { createAtToDate } from "../util/util";

/**
 * @typedef {object}  ApplicantModel
 * @property {number} id
 * @property {string} applicantName
 * @property {string} applicantContactNumber
 * @property {string} applicantEmail
 * @property {string} resume
 * @property {string} reasonToJoin
 * @property {string} date
 */

export function createApplicantObj(data) {
  /**
   * @type {ApplicantModel}
   */
  const result = {
    id: data.id,
    applicantName: data.applicant_name,
    applicantContactNumber: data.applicant_contact,
    applicantEmail: data.applicant_email,
    resume: data.resume,
    reasonToJoin: data.why_join !== null ? data.why_join : "",
    date: createAtToDate(data.created_at),
  };
  return result;
}
