/**
 *  @typedef {object} Props
 * @property {JSX.Element} children
 * @property {string} toolTipText
 * @property {()=>void} buttonActionFunc
 */

import { useState } from "react";

/**
 * @param {Props} props
 * @returns {JSX.Element} render tiptap editor toolbar button
 */

export default function MenuBtnContainer({
  children,
  toolTipText,
  buttonActionFunc,
}) {
  const [hover, setHover] = useState();

  return (
    <div style={{ position: "relative" }}>
      <p
        style={{
          display: hover ? "block" : "none",
          position: "absolute",
          top: "-40px",
          background: "white",
          color: "white",
          backgroundColor: "#121212",
          padding: "2px 5px",
          borderRadius: "10px",
          zIndex: "10",
          whiteSpace: "nowrap",
        }}
      >
        {" "}
        {toolTipText}
      </p>

      <button
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() => {
          buttonActionFunc();
        }}
        style={{
          border: "none",
          backgroundColor: "white",
          margin: "0px 0.6rem",
        }}
      >
        {children}
      </button>
    </div>
  );
}
