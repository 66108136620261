import React from "react";
import { Nav, Tab } from "react-bootstrap";
import LeadList from "./lead/LeadList";
import leadSvg from "../../../images/lead/leads.svg";
import quiresSvg from "../../../images/lead/quires.svg";
import usersSvg from "../../../images/lead/users.svg";
import reviewSvg from "../../../images/lead/reviews.svg";
import contactSvg from "../../../images/lead/contact.svg";
import reportSvg from "../../../images/lead/report.svg";
import ReviewList from "./review/ReviewList";
import ContactList from "./contact/ContactList";
import Users from "./users/Users";
import CancelRequest from "./CancelRequest/CancelRequest";
export default function Collections() {
  return (
    <div>
      <Tab.Container defaultActiveKey="Leads">
        <div className="card-tabs mt-3 mt-sm-0 mb-xxl-0 mb-4">
          <Nav as="ul" className="nav nav-tabs">
            <Nav.Item as="li" className="nav-item">
              <Nav.Link
                className="nav-link d-flex flex-row align-items-center ml-1"
                eventKey="Leads"
              >
                <img src={leadSvg} alt="lead" width="25" height="25" />
                Leads
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" className="nav-item">
              <Nav.Link className="nav-link" eventKey="Quires">
                <img src={quiresSvg} alt="lead" width="25" height="25" />
                New Quires
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" className="nav-item">
              <Nav.Link className="nav-link" eventKey="Users">
                <img src={usersSvg} alt="lead" width="25" height="25" />
                Users
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" className="nav-item">
              <Nav.Link className="nav-link" eventKey="Reviews">
                <img src={reviewSvg} alt="lead" width="25" height="25" />
                Reviews
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" className="nav-item">
              <Nav.Link className="nav-link" eventKey="Contact">
                <img src={contactSvg} alt="lead" width="25" height="25" />
                Contact Form
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" className="nav-item">
              <Nav.Link className="nav-link" eventKey="CancelRequest">
                Cancel Request
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <Tab.Content>
          <LeadList />
          <ReviewList />
          <ContactList />
          <Users />
          <CancelRequest />
        </Tab.Content>
      </Tab.Container>
    </div>
  );
}
