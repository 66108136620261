/**
 * Class representing a review
 * @class
 */

import { createAtToDate } from "../util/util";

export class ReviewModel {
  /**
   * @type {number}
   */
  id;
  /**
   * @type {string}
   */
  name;
  /**
   * @type {string}
   */
  reviewTopic;
  /**
   * @type {string}
   */
  overallRating;
  /**
   * @type {string}
   */
  headline;
  /**
   * @type {string}
   */
  tripExpRating;
  /**
   * @type {string}
   */
  accommodationRating;
  /**
   * @type {string}
   */
  foodQualityRating;
  /**
   * @type {string}
   */
  customerServiceRating;
  /**
   * @type {string}
   */
  recommendationRating;
  /**
   * @type {string}
   */
  nextTripProbability;
  /**
   * @type {string}
   */
  reviewDescription;

  /**
   * @type {string}
   */
  date;
  /**
   *
   * @type {string}
   */
  reviewStatus;
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.reviewTopic = data.review_topic;
    this.overallRating = data.overall_rating;
    this.headline = data.headline;
    this.tripExpRating = data.tripExp_rating;
    this.accommodationRating = data.accomodation_rating;
    this.foodQualityRating = data.foodqualtyRating;
    this.customerServiceRating = data.customer_service_rating;
    this.recommendationRating = data.recommendation_rating;
    this.nextTripProbability = data.nexttrip_probability;
    this.reviewDescription = data.review_description;
    this.reviewStatus = data.review_status;
    this.date = createAtToDate(data.created_at);
  }
}
