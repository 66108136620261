import React from "react";
import componentStyle from "../../../../css/components.module.css";
/**
 *  @typedef {object} Props
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setShowDayScheduleSelector
 *  @property {React.Dispatch<React.SetStateAction<boolean>>} setShowCarTravelFormModal
 * @property {React.Dispatch<React.SetStateAction<boolean>>}  setShowFlightTravelFormModal
 */
/**
 * @param {Props} props
 */
export default function TransportWidgetSelector({
  setShowCarTravelFormModal,
  setShowDayScheduleSelector,
  setShowFerryTravelFormModel,
  setShowFlightTravelFormModal,
}) {
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "30px",
        }}
      >
        <button
          className={componentStyle.btnOutlined}
          style={{
            textAlign: "left",
            padding: "1.3rem 1.4rem",
            fontSize: "1.2rem",
            marginBottom: "20px",
          }}
          onClick={() => {
            setShowCarTravelFormModal(true);
            setShowDayScheduleSelector(false);
          }}
        >
          Car Travel
        </button>
        <button
          className={componentStyle.btnOutlined}
          style={{
            textAlign: "left",
            padding: "1.3rem 1.4rem",
            fontSize: "1.2rem",
            marginBottom: "20px",
          }}
          onClick={() => {
            setShowFerryTravelFormModel(true);
            setShowDayScheduleSelector(false);
          }}
        >
          Ferry Travel
        </button>
        <button
          className={componentStyle.btnOutlined}
          style={{
            textAlign: "left",
            padding: "1.3rem 1.4rem",
            fontSize: "1.2rem",
            marginBottom: "20px",
          }}
          onClick={() => {
            setShowFlightTravelFormModal(true);
            setShowDayScheduleSelector(false);
          }}
        >
          Flight Trvel
        </button>
      </div>
    </div>
  );
}
