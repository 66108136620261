import React, { useCallback, useEffect, useState } from "react";
import InputField from "../../../components/global/InputField";
import SelectField from "../../../components/global/SelectField";
import { generateLocalImageUrl, getFileSize } from "../../../../util/util";
import TextArea from "../../../components/global/TextArea";
import Button from "../../../components/global/Button";
import {
  getHotelDetails,
  saveHotelData,
  updateHotelDetails,
} from "../../../../controller/hotelController";
import { HTTPStatus } from "../../../../util/helperObj";
import { message } from "antd";
import { handleControllerResultException } from "../../../../util/handleException";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";

export default function HotelForm() {
  const history = useHistory();
  const { hotelId } = useParams();
  const [hotelName, setHotelName] = useState("");
  const [location, setLocation] = useState("");
  const [hotelImage, setHotelImage] = useState();
  const [hotelImageLink, setHotelImageLink] = useState("");
  const [hotelLowResImage, setHotelLowResImage] = useState();
  const [hotelLowResImageLink, setHotelLowResImageLink] = useState("");
  const [hotelDescription, setHotelDescription] = useState("");
  const [inputError, setInputError] = useState("");
  const [saving, setSaving] = useState(false);

  function handleImageChange(image, setImage, setImageLink) {
    if (image !== undefined) {
      setImage(image);
      setImageLink(generateLocalImageUrl(image));
    } else {
      setImage();
      setImageLink("");
    }
  }

  function checkInputError() {
    return (
      hotelName !== "" &&
      location !== "" &&
      hotelImageLink !== "" &&
      hotelLowResImageLink !== "" &&
      hotelDescription !== ""
    );
  }

  async function handleSave() {
    setInputError(false);
    if (checkInputError() && getFileSize(hotelLowResImage) <= 200) {
      setSaving(true);
      const res = await saveHotelData(
        hotelName,
        location,
        hotelImage,
        hotelLowResImage,
        hotelDescription
      );
      setSaving(false);
      if (res.statusCode === HTTPStatus.CREATED) {
        message.success(res.success);
        history.goBack();
      } else {
        handleControllerResultException(res, history);
      }
    } else {
      setInputError(true);
    }
  }

  const handleHotelDetails = useCallback(
    async (hotelId) => {
      const res = await getHotelDetails(hotelId);
      if (res[0] !== null) {
        setHotelName(res[0].hotelName);
        setLocation(res[0].hotelLocation);
        setHotelDescription(res[0].hotelDescription);
        setHotelImageLink(res[0].hotelImageLink);
        setHotelLowResImageLink(res[0].hotelLowResImageLink);
      } else {
        handleControllerResultException(res[1], history);
      }
    },
    [history]
  );

  useEffect(() => {
    if (hotelId) {
      handleHotelDetails(hotelId);
    }
  }, [handleHotelDetails, hotelId]);

  async function handleUpdate() {
    setInputError(false);
    if (checkInputError()) {
      if (
        hotelLowResImage !== undefined &&
        getFileSize(hotelLowResImage > 200)
      ) {
        setInputError(true);
        return false;
      }

      setSaving(true);
      const res = await updateHotelDetails({
        hotelName,
        hotelLocation: location,
        hotelDescription,
        hotelImage,
        hotelLowResImage,
        hotelId,
      });
      setSaving(false);
      if (res.statusCode === HTTPStatus.SUCCESS) {
        message.success(res.success);
      } else {
        handleControllerResultException(res, history);
      }
    } else {
      setInputError(true);
    }
  }

  return (
    <div>
      <Button
        onClick={() => history.goBack()}
        styles={{ marginBottom: "2rem" }}
      >
        Return
      </Button>
      <div style={{ display: "flex", alignItems: "center" }}>
        <InputField
          label="Hotel name"
          inputFieldAttributes={{
            value: hotelName,
            onChange: (e) => setHotelName(e.target.value),
          }}
          showInputError={inputError}
          stylesProps={{ width: "100%" }}
        />
        <SelectField
          label="Location"
          options={["Port Blair", "Havelock", "Neil Island"]}
          selectFieldAttributes={{
            value: location,
            onChange: (e) => setLocation(e.target.value),
          }}
          showInputError={inputError}
          stylesProps={{ marginLeft: "1rem", width: "100%" }}
        />
      </div>
      <div style={{ display: "flex", alignItems: "center", marginTop: "2rem" }}>
        <InputField
          label="Hotel image (This image will be shown in website)"
          inputFieldAttributes={{
            type: "file",
            onChange: (e) =>
              handleImageChange(
                e.target.files[0],
                setHotelImage,
                setHotelImageLink
              ),
          }}
          showInputError={inputError}
          showErrorCondition={hotelImageLink === ""}
          stylesProps={{ width: "100%" }}
          errorMessage="Please provide hotel image"
        />
        <InputField
          label="Hotel image (max 200kb) (This image will be used for creating ticket) "
          inputFieldAttributes={{
            type: "file",
            onChange: (e) =>
              handleImageChange(
                e.target.files[0],
                setHotelLowResImage,
                setHotelLowResImageLink
              ),
          }}
          showInputError={inputError}
          showErrorCondition={
            hotelLowResImageLink === "" || getFileSize(hotelLowResImage) > 200
          }
          stylesProps={{ width: "100%", marginLeft: "1rem" }}
          errorMessage={
            hotelLowResImageLink === ""
              ? "Please upload hotel image"
              : getFileSize(hotelLowResImage) > 200
              ? "Image size should not cross 200kb size limit"
              : ""
          }
        />
      </div>
      <div style={{ display: "flex", alignItems: "center", marginTop: "2rem" }}>
        {hotelImageLink !== "" && (
          <img
            src={hotelImageLink}
            alt="hotelimage"
            height="200"
            width="200"
            style={{ objectFit: "fill", margin: "0px 20px" }}
          />
        )}
        {hotelLowResImageLink !== "" && (
          <img
            src={hotelLowResImageLink}
            alt="hotelLowResolutionimage"
            height="200"
            width="200"
            style={{ objectFit: "fill", margin: "0px 20px" }}
          />
        )}
      </div>
      <TextArea
        label="Hotel description"
        textAreaAttributes={{
          value: hotelDescription,
          onChange: (e) => setHotelDescription(e.target.value),
          rows: "10",
        }}
        showInputError={inputError}
        stylesProps={{ marginTop: "2rem" }}
      />
      <div
        style={{ marginTop: "2rem", display: "flex", justifyContent: "center" }}
      >
        <Button
          disabled={saving}
          loading={saving}
          onClick={hotelId ? handleUpdate : handleSave}
        >
          {hotelId ? "Update" : "Save"}
        </Button>
      </div>
    </div>
  );
}
