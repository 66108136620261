import axios from "axios";
import { handleException } from "../util/handleException";
import { getToken } from "../util/tokenHandler";
import { HttpResponse } from "../model/HttpResponse";
import { createSightseenObj } from "../model/sightseenModel";

const createSightseenAPI = "/wft_admin/sightseeing-create";
const sightseenListAPI = "/wft_admin/sightseeing-pagination-list";
const sightseenDetailsAPI = "/wft_admin/sightseeing-detail";
const sightseenUpdateAPI = "/wft_admin/sightseeing-update";

export async function createSightseen(
  sightseenName,
  sightseenLocation,
  sightseenDescription,
  sightseenDescriptionPdf,
  sightseenImage,
  sightseenLowResImage
) {
  try {
    const formData = new FormData();
    formData.append("name", sightseenName);
    formData.append("location", sightseenLocation);
    formData.append("image", sightseenImage);
    formData.append("pdf_image", sightseenLowResImage);
    formData.append("sightseeing_description", sightseenDescription);
    formData.append("pdf_description", sightseenDescriptionPdf);
    const res = await axios.post(createSightseenAPI, formData, {
      headers: {
        Authorization: `Token ${getToken()}`,
      },
    });
    return new HttpResponse(res.status, "Sighseen created successfuly");
  } catch (err) {
    return handleException(err);
  }
}

/**
 * @returns {Promise<[{results:import("../model/sightseenModel").SightseenModel[], count:number}|null, HttpResponse|null]>}
 */
export async function getSightseenList(page = 1, searchText = "") {
  const result = [null, null];
  try {
    const res = await axios.get(sightseenListAPI, {
      headers: {
        Authorization: `Token ${getToken()}`,
      },
      params: {
        page,
        keyword: searchText,
      },
    });
    const sightseenList = [];
    for (let data of res.data.results) {
      sightseenList.push(createSightseenObj(data));
    }
    result[0] = { results: sightseenList, count: res.data.count };
    return result;
  } catch (err) {
    result[1] = handleException(err);
    return result;
  }
}

/**
 *
 * @param {number} sightseenId
 * @returns {Promise<[import("../model/sightseenModel").SightseenModel|null, HttpResponse|null]>}
 */
export async function getSightseenDetails(sightseenId) {
  const result = [null, null];
  try {
    const res = await axios.get(`${sightseenDetailsAPI}/${sightseenId}`, {
      headers: {
        Authorization: `Token ${getToken()}`,
      },
    });
    result[0] = createSightseenObj(res.data);
    return result;
  } catch (err) {
    result[1] = handleException(err);
    return result;
  }
}

/**
 * @typedef {object} UpdateProps
 * @property {number} sightseenId
 * @property {string} sightseenName
 * @property {string} sightseenLocation
 * @property {string} sightseenDescription
 * @property {string} sightseenDescriptionPdf
 * @property {File|undefined} sightseenImage
 * @property {File|undefined} sightseenLowResImage
 */

/**
 * @param {UpdateProps} props
 */
export async function updateSightseen({
  sightseenId,
  sightseenName,
  sightseenLocation,
  sightseenDescription,
  sightseenImage,
  sightseenLowResImage,
  sightseenDescriptionPdf,
}) {
  try {
    const formData = new FormData();
    formData.append("name", sightseenName);
    formData.append("location", sightseenLocation);
    sightseenImage !== undefined && formData.append("image", sightseenImage);
    sightseenLowResImage !== undefined &&
      formData.append("pdf_image", sightseenLowResImage);
    formData.append("sightseeing_description", sightseenDescription);
    formData.append("pdf_description", sightseenDescriptionPdf);
    const res = await axios.put(
      `${sightseenUpdateAPI}/${sightseenId}`,
      formData,
      {
        headers: {
          Authorization: `Token ${getToken()}`,
        },
      }
    );
    return new HttpResponse(res.status, "Update successful");
  } catch (err) {
    return handleException(err);
  }
}
