import React, { useState } from "react";
import componentStyle from "../../../css/components.module.css";
import deleteImg from "../../../images/delete.svg";
import AlertModal from "../global/AlertModal";
import { useDispatch } from "react-redux";
import {
  moveDownActivityDay,
  moveUpActivityDay,
  removeActivityDay,
} from "../../../store/actions/PackageAction";
import MoveUpDownButton from "./MoveUpDownButton";
import DayScheduleSelector from "./DayScheduleSelector/DayScheduleSelector";
import CarTravelFormModal from "./cartravel/CarTravelFormModal";
import ScheduleWidgetList from "./ScheduleWidgetList";
import FerryTravelFormModal from "./ferryTravel/FerryTravelFormModal";
import SightSeeningFormModal from "./sightseen/SighSeeingFormModal";
import FlightTravelFormModal from "./flighTravel/FlightTravelFormModal";
/**
 * @typedef {object} Props
 * @property {number} day
 */

/**
 * @param {Props} props
 */

export default function DayScheduleCard({ day }) {
  const [alertModalVisibility, setAlertModalvisibility] = useState(false);
  const [showDayScheduleSelector, setShowDayScheduleSelector] = useState(false);
  const [showCardTravelFormModal, setShowCarTravelModal] = useState(false);
  const [showFerryTravelFormModal, setShowFerryTravelFormModal] =
    useState(false);
  const [showSightSeeingFormModal, setShowSightSeedingFormModal] =
    useState(false);
  const [showFlightTravelFormModal, setShowFlightTravelFormModal] =
    useState(false);

  const dispatch = useDispatch();
  return (
    <div style={{ display: "flex" }}>
      <MoveUpDownButton
        moveUpAction={() => dispatch(moveUpActivityDay(day - 1))}
        moveDownAction={() => dispatch(moveDownActivityDay(day - 1))}
        styles={{ marginRight: "10px" }}
      />
      <div
        style={{
          border: "2px solid #212121",
          padding: "1.4rem 1rem",
          borderRadius: "10px",
          marginBottom: "20px",
          flexGrow: "1",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span
            style={{ fontSize: "1.5rem", color: "#212121", fontWeight: "500" }}
          >
            {" "}
            Day {day}
          </span>

          <button
            style={{ border: "none" }}
            onClick={() => setAlertModalvisibility(true)}
          >
            <img src={deleteImg} alt="delete" height="20" width="20" />
          </button>
        </div>
        <div style={{ marginTop: "20px" }}>
          <ScheduleWidgetList
            dayNo={day}
            setShowCarTravelFormModal={setShowCarTravelModal}
            setShowFerryTravelFormModal={setShowFerryTravelFormModal}
            setShowSightSeenFormModal={setShowSightSeedingFormModal}
            setShowFlightTravelFormModal={setShowFlightTravelFormModal}
          />
        </div>
        <button
          className={componentStyle.btnGray}
          style={{ width: "100%", marginTop: "20px" }}
          onClick={() => setShowDayScheduleSelector(true)}
        >
          + New Widget
        </button>
        {alertModalVisibility && (
          <AlertModal
            alertMessage="Do you want to remove this card?"
            setModalVisibility={setAlertModalvisibility}
            confirmButtonAction={() => dispatch(removeActivityDay(day - 1))}
          />
        )}
        {showDayScheduleSelector && (
          <DayScheduleSelector
            setShowDayScheduleSelector={setShowDayScheduleSelector}
            setShowCarTravelFormModal={setShowCarTravelModal}
            setShowFerryTravelFormModel={setShowFerryTravelFormModal}
            setShowSightSeedingFormModal={setShowSightSeedingFormModal}
            setShowFlightTravelFormModal={setShowFlightTravelFormModal}
          />
        )}
        {showCardTravelFormModal && (
          <CarTravelFormModal
            day={day}
            setShowCardTravelFormModal={setShowCarTravelModal}
          />
        )}
        {showFerryTravelFormModal && (
          <FerryTravelFormModal
            day={day}
            setShowFerryTravelFormModal={setShowFerryTravelFormModal}
          />
        )}
        {showSightSeeingFormModal && (
          <SightSeeningFormModal
            setShowSightSeedingFormModal={setShowSightSeedingFormModal}
            day={day}
          />
        )}
        {showFlightTravelFormModal && (
          <FlightTravelFormModal
            setShowFlightTravelFormModal={setShowFlightTravelFormModal}
            day={day}
          />
        )}
      </div>
    </div>
  );
}
