import React from "react";
import componentStyle from "../../../../css/components.module.css";
import "./selectorTypes";

/**
 * @typedef {object} Props
 * @property {React.Dispatch<React.SetStateAction<WidgetType>>} setWidgetType
 *  @property {React.Dispatch<React.SetStateAction<boolean>>} setShowDayScheduleSelector
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setShowSightSeedingFormModal
 */
/**
 * @param {Props} props
 */
export default function PrimaryWidgetSelector({
  setWidgetType,
  setShowSightSeedingFormModal,
  setShowDayScheduleSelector,
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginTop: "30px",
      }}
    >
      <button
        className={componentStyle.btnOutlined}
        style={{
          textAlign: "left",
          padding: "1.3rem 1.4rem",
          fontSize: "1.2rem",
          marginBottom: "20px",
        }}
        onClick={() => {
          setShowSightSeedingFormModal(true);
          setShowDayScheduleSelector(false);
        }}
      >
        Sightseeing
      </button>
      <button
        className={componentStyle.btnOutlined}
        style={{
          textAlign: "left",
          padding: "1.3rem 1.4rem",
          fontSize: "1.2rem",
          marginBottom: "20px",
        }}
        onClick={() => setWidgetType("transport")}
      >
        Transport
      </button>
    </div>
  );
}
