import React, { useCallback, useEffect, useRef, useState } from "react";
import wayFindTripLogo from "../../../images/companyName.png";
import keyChainImg from "../../../images/keychain.png";
import tripsPvtImg from "../../../images/TRIPS PRIVATE LIMITED.png";
import handWaveImg from "../../../images/handwave.png";
import componentStyle from "../../../css/components.module.css";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import "../../../store/reducers/voucher/VoucherStateType";
import { voucherObjToServerVoucherObj } from "../../../util/handleVoucherDataFormat";
import {
  getVoucerDetails,
  saveVoucher,
} from "../../../controller/voucherController";
import { HTTPStatus } from "../../../util/helperObj";
import { message } from "antd";
import {
  addVouhcerData,
  resetVoucherState,
} from "../../../store/actions/VoucherAction";
import pingImg from "../../newPages/NewTicket/pin.png";
import moment from "moment";

export default function VoucherPreview() {
  const [savingVoucher, setSavingVoucher] = useState(false);
  const [fetchingVoucherDetails, setFetchingVoucherDetails] = useState(false);
  const componentMounted = useRef(true);
  const { voucherId } = useParams();
  const history = useHistory();
  function printVoucer() {
    const doc = document.querySelector("#voucher");
    const winPrint = window.open();
    winPrint.document.write(doc.innerHTML);
    winPrint.document.title = "TravelOps";
    winPrint.document.head.innerHTML += `<link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap" rel="stylesheet">`;

    winPrint.document.head.innerHTML += `<link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap" rel="stylesheet">`;

    winPrint.document.close();
    winPrint.print();
  }

  /**
   * @type {VoucherState}
   */
  const voucher = useSelector((state) => state.voucher);
  const dispatch = useDispatch();

  async function handleSave() {
    const serverObj = voucherObjToServerVoucherObj(voucher);
    setSavingVoucher(true);
    const res = await saveVoucher(serverObj);
    setSavingVoucher(false);
    if (res.statusCode === HTTPStatus.SUCCESS) {
      message.success(res.success);
      dispatch(resetVoucherState());
      history.push("/voucher");
    } else {
      message.error(res.error);
    }
  }

  const handleVoucherDetails = useCallback(async () => {
    if (voucherId) {
      setFetchingVoucherDetails(true);
      const res = await getVoucerDetails(voucherId);
      setFetchingVoucherDetails(false);
      if (res[0] !== null) {
        res[0]["isUpdate"] = true;
        dispatch(addVouhcerData(res[0]));
      } else {
        message.error(res[1].error);
      }
    }
  }, [voucherId, dispatch]);

  useEffect(() => {
    if (voucherId && voucher.id === 0 && componentMounted.current) {
      handleVoucherDetails();
    }
    return () => {
      componentMounted.current = false;
    };
  }, [handleVoucherDetails, voucher.id, voucherId]);

  if (fetchingVoucherDetails) {
    return (
      <p style={{ textAlign: "center", fontSize: "20px", color: "black" }}>
        Loading...🚀
      </p>
    );
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <button
          onClick={() => {
            history.goBack();
          }}
          className={componentStyle.btnPrimary}
          style={{ marginBottom: "3rem" }}
        >
          <span style={{ marginRight: "10px" }}>&#x2190;</span> Previous page
        </button>
        {!voucher.isUpdate ? (
          <button
            disabled={savingVoucher}
            className={componentStyle.btnPrimary}
            onClick={handleSave}
          >
            {savingVoucher ? "...Saving" : "Save"}
          </button>
        ) : (
          <button
            className={componentStyle.btnPrimary}
            onClick={() => history.push(`/voucher/form/${voucher.id}`)}
          >
            Edit
          </button>
        )}
      </div>
      <div id="voucher" style={{ marginTop: "5rem" }}>
        <div
          style={{ fontFamily: "Poppins", color: "black", padding: "3rem 0px" }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              position: "relative",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <img
                src={wayFindTripLogo}
                alt="wayfindlogo"
                height="80"
                width="200"
                style={{ objectFit: "contain" }}
              />
              {/* <img
                src={tripsPvtImg}
                alt="trips private limited"
                height="10"
                width="200"
                style={{ objectFit: "fill" }}
              /> */}
            </div>
            <img
              src={keyChainImg}
              alt="wayfindlogo"
              height="300"
              width="300"
              style={{
                objectFit: "fill",
                position: "absolute",
                right: "0",
                zIndex: "10",
              }}
            />
          </div>
          <div
            style={{ display: "flex", alignItems: "center", marginTop: "2rem" }}
          >
            <div>
              <img src={handWaveImg} alt="hand wave" height="40" width="40" />
            </div>
            <h1
              style={{
                fontWeight: "400",
                marginLeft: "10px",
                textTransform: "capitalize",
                margin: "0",
              }}
            >
              Hi{" "}
              <span style={{ fontWeight: "bold" }}>{voucher.personName}</span>
            </h1>
          </div>
          <p style={{}}>Thank you for booking with us</p>
          <p>
            Your hotel{" "}
            <span style={{ fontWeight: "bold" }}>{voucher.hotelName}</span> is
            reserved at{" "}
            <span style={{ fontWeight: "bold" }}>
              {voucher.hotelIslandName}
            </span>
          </p>

          <div
            style={{
              border: "2px solid #e6e6e6",
              padding: "20px",
              borderRadius: "10px",
            }}
          >
            <span
              style={{
                color: "#55b3e5",
                fontSize: "14px",
                printColorAdjust: "exact",
                fontWeight: "500",
              }}
            >
              Customer Details
            </span>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <span>
                Guest name: <span>{voucher.personName}</span>
              </span>
              <span>
                Issuer name:{" "}
                <span style={{ marginLeft: "5px" }}>{voucher.issuerName}</span>
              </span>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "5px",
              }}
            >
              <span>
                Guest phone number: <span>{voucher.personContactNumber}</span>
              </span>
              <span>
                Issuer phone number:{" "}
                <span style={{ marginLeft: "5px" }}>
                  {voucher.issuerPhoneNumber}
                </span>
              </span>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "5px",
              }}
            >
              <span>
                Guest email: <span>{voucher.personEmail}</span>
              </span>
              <span>
                Issuer email:{" "}
                <span style={{ marginLeft: "5px" }}>{voucher.issuerEmail}</span>
              </span>
            </div>
          </div>

          <div
            style={{
              border: "2px solid #e6e6e6",
              padding: "20px",
              borderRadius: "10px",
              marginTop: "2.5rem",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span
                style={{
                  color: "#04ad79",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                Booking Confirmed
              </span>
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "25px",
                  margin: "5px 0px",
                }}
              >
                {voucher.hotelName}
              </span>
              <div>
                <span>
                  <img
                    src={pingImg}
                    width="15"
                    height="20"
                    alt="pin"
                    style={{ objectFit: "fill" }}
                  />
                </span>{" "}
                <span>
                  Location:{" "}
                  <span>
                    {voucher.hotelLocation}, {voucher.hotelIslandName}
                  </span>
                </span>
              </div>
            </div>

            <div
              style={{
                width: "95%",
                background: "#e6e6e6",
                height: "2px",
                margin: "10px auto",
              }}
            ></div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "150px 180px",
                    gap: "10px",
                  }}
                >
                  <span>No of Rooms:</span>{" "}
                  <span>{voucher.numOfRooms} Rooms</span>
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "150px 180px",
                    marginTop: "10px",
                    gap: "10px",
                  }}
                >
                  <span>Room type:</span> <span>{voucher.roomType}</span>
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "150px 180px",
                    marginTop: "10px",
                    gap: "10px",
                  }}
                >
                  <span>Check-In Date:</span>{" "}
                  <span>
                    {moment(voucher.checkInDate).format("DD/MM/YYYY")}
                  </span>
                </div>
                {voucher.reCheckInDate !== "" && (
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "150px 180px",
                      marginTop: "10px",
                      gap: "10px",
                    }}
                  >
                    <span>Re-check-In Date:</span>{" "}
                    <span>
                      {moment(voucher.reCheckInDate).format("DD/MM/YYYY")}
                    </span>
                  </div>
                )}
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "150px 180px",
                    marginTop: "10px",
                    gap: "10px",
                  }}
                >
                  <span>Meal:</span> <span>{voucher.mealPlan}</span>
                </div>
              </div>
              <div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "170px 180px",
                    gap: "10px",
                  }}
                >
                  <span>Total No.of Guest:</span>{" "}
                  <span>
                    {voucher.totalNumOfAdults} Adults +{" "}
                    {voucher.totalNumOfChildren} Kids
                  </span>
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "170px 180px",
                    marginTop: "10px",
                    gap: "10px",
                  }}
                >
                  <span>Extra matress:</span>{" "}
                  <span>{voucher.extraMatress}</span>
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "170px 180px",
                    marginTop: "10px",
                    gap: "10px",
                  }}
                >
                  <span>Check-Out Date:</span>{" "}
                  <span>
                    {moment(voucher.checkOutDate).format("DD/MM/YYYY")}
                  </span>
                </div>
                {voucher.reCheckOutDate !== "" && (
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "170px 180px",
                      marginTop: "10px",
                      gap: "10px",
                    }}
                  >
                    <span> Re-check-Out Date:</span>{" "}
                    <span>
                      {moment(voucher.reCheckOutDate).format("DD/MM/YYYY")}
                    </span>
                  </div>
                )}
              </div>
            </div>

            {voucher.inclusion.length > 0 && (
              <div style={{ marginTop: "20px", display: "flex" }}>
                <span>Inclusion: </span>
                <ul
                  style={{
                    marginLeft: "10px",
                    width: "500px",
                    textAlign: "justify",
                  }}
                >
                  {voucher.inclusion.map((data, index) => (
                    <li key={index}>{data}</li>
                  ))}
                </ul>
              </div>
            )}
            <div style={{ marginTop: "20px", display: "flex" }}>
              <span>Instruction: </span>
              <ul
                style={{
                  marginLeft: "10px",
                  width: "500px",
                  textAlign: "justify",
                }}
              >
                {voucher.instruction.length > 0 &&
                  voucher.instruction.map((data, index) => (
                    <li key={index}>{data}</li>
                  ))}
              </ul>
            </div>
          </div>
          <p style={{ marginTop: "30px", textAlign: "center" }}>
            For more information about places of interest during your stay,
            visit our website or feel free to call us at:
            <br />
            <span>
              <strong>Contact :</strong> www.wayfindtrips.com{" "}
              <strong>Email ID :</strong> hello@wayfindtrips.com
              <strong> Mobile :</strong> +91-7400013770
            </span>
          </p>
        </div>
      </div>
      <div
        style={{ marginTop: "20px", display: "flex", justifyContent: "center" }}
      >
        <button onClick={printVoucer} className={componentStyle.btnPrimary}>
          Print
        </button>
      </div>
    </div>
  );
}
