import React, { useState } from "react";
import componentStyle from "../../../../css/components.module.css";
import PrimaryWidgetSelector from "./PrimaryWidgetSelector";
import TransportWidgetSelector from "./TransportWidgetSelector";
import "./selectorTypes";
import ModalLayout from "../../global/ModalLayout";
/**
 * @type {WidgetType}
 */
const WidgetType = "primary";

/**
 * @typedef {object} Props
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setShowDayScheduleSelector
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setShowCarTravelFormModal
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setShowFerryTravelFormModel
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setShowSightSeedingFormModal
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setShowFlightTravelFormModal
 */

/**
 * @param {Props} props
 */
export default function DayScheduleSelector({
  setShowDayScheduleSelector,
  setShowCarTravelFormModal,
  setShowFerryTravelFormModel,
  setShowSightSeedingFormModal,
  setShowFlightTravelFormModal,
}) {
  const [widgetType, setWidgetType] = useState(WidgetType);

  return (
    <ModalLayout modalCloseAction={() => setShowDayScheduleSelector(false)}>
      <div
        style={{
          display: widgetType !== "primary" ? "flex" : "block",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {widgetType !== "primary" && (
          <button
            style={{ border: "none" }}
            onClick={() => setWidgetType("primary")}
          >
            Back
          </button>
        )}
        <button
          style={{ border: "none" }}
          onClick={() => setShowDayScheduleSelector(false)}
        >
          Close
        </button>
      </div>
      <h3
        className={componentStyle.label}
        style={{
          fontSize: "1.3rem",
          textTransform: "capitalize",
          marginTop: "20px",
        }}
      >
        Select type of widget for day wise schedule
      </h3>
      {widgetType === "primary" && (
        <PrimaryWidgetSelector
          setWidgetType={setWidgetType}
          setShowSightSeedingFormModal={setShowSightSeedingFormModal}
          setShowDayScheduleSelector={setShowDayScheduleSelector}
        />
      )}
      {widgetType === "transport" && (
        <TransportWidgetSelector
          setShowCarTravelFormModal={setShowCarTravelFormModal}
          setShowDayScheduleSelector={setShowDayScheduleSelector}
          setShowFerryTravelFormModel={setShowFerryTravelFormModel}
          setShowFlightTravelFormModal={setShowFlightTravelFormModal}
        />
      )}
    </ModalLayout>
  );
}
