import React, { useCallback, useEffect, useState } from "react";
import ModalLayout from "../../components/global/ModalLayout";
import { getTicketPaymentDetails } from "../../../controller/ticketController";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { handleControllerResultException } from "../../../util/handleException";
import TicketPaymentCard from "./TicketPaymentCard";

/**
 *  @type {import("../../../model/TicketPaymentModel").TicketPaymentModel[]}
 */
const paymentListType = [];

/**
 * @typedef {object} Props
 * @property {()=>void} modalCloseAction
 * @property {import("../../../model/PnrListItemModel").PnrListItem} ticket
 *
 */

/**
 * @param {Props} props
 */
export default function TicketPaymentModal({ modalCloseAction, ticket }) {
  const history = useHistory();
  const [paymentList, setPaymentList] = useState(paymentListType);

  const handlePaymentList = useCallback(
    async (pnrNumber) => {
      const res = await getTicketPaymentDetails(pnrNumber);
      if (res[0] !== null) {
        setPaymentList(res[0]);
      } else {
        handleControllerResultException(res[1], history);
      }
    },
    [history]
  );

  useEffect(() => {
    if (ticket) {
      handlePaymentList(ticket.pnrNumber);
    }
  }, [ticket, handlePaymentList]);
  return (
    <ModalLayout
      modalCloseAction={modalCloseAction}
      modalStyles={{ width: "50%", height: "70%", overflowY: "auto" }}
    >
      {paymentList.length > 0 ? (
        paymentList.map((data) => (
          <TicketPaymentCard payment={data} key={data.id} />
        ))
      ) : (
        <p>No payment information found</p>
      )}
    </ModalLayout>
  );
}
