import React, { useCallback, useEffect, useState } from "react";
import Button from "../../components/global/Button";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getJobList } from "../../../controller/CareerController";
import { handleControllerResultException } from "../../../util/handleException";
import HorizontalTabs from "../../components/global/HorizontalTabs";
import Table from "../../components/global/Table/Table";
import PaginationPageSelector from "../../components/global/PaginationPageSelectors";
import JobStatus from "./JobStatus";

/**
 *@type  {import("../../../model/JobModal").JobModel[]}
 */

const jobListType = [];

export default function Jobs() {
  const [jobList, setJobList] = useState(jobListType);
  const [jobStatus, setJobStatus] = useState("Active");
  const [totalPage, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const history = useHistory();
  const handleJobList = useCallback(
    async (page = 1, status = "Active") => {
      const res = await getJobList(page, status);
      if (res[0] !== null) {
        setJobList(res[0].results);
        setTotalPages(Math.ceil(res[0].count / 10));
      } else {
        handleControllerResultException(res[1], history);
      }
    },
    [history]
  );

  useEffect(() => {
    handleJobList();
  }, [handleJobList]);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Link to="/job-form">
          <Button variant="Secondary">&#43; Add job</Button>
        </Link>
      </div>
      <HorizontalTabs
        tabList={["Active", "Inactive"]}
        currentActiveTab={jobStatus}
        onClick={(tab) => {
          handleJobList(1, tab);
          setJobStatus(tab);
          setCurrentPage(1);
        }}
      />
      <div style={{ overflowX: "auto" }}>
        <Table>
          <thead>
            <tr>
              <th>Title</th>
              <th>Location</th>
              <th>Job type</th>
              <th>Post type</th>
              <th>Status</th>
              <th>Applicants</th>
              <th>Update</th>
            </tr>
          </thead>
          <tbody>
            {jobList.length > 0 &&
              jobList.map((data) => (
                <tr key={data.id}>
                  <td>{data.title}</td>
                  <td>{data.location}</td>
                  <td>{data.jobType}</td>
                  <td>{data.postType}</td>
                  <td>
                    <JobStatus
                      job={data}
                      onStatusChangeAction={() => {
                        setJobList((prevState) =>
                          prevState.filter((job) => job.id !== data.id)
                        );
                      }}
                    />
                  </td>
                  <td>
                    <Link to={`/applicants/${data.id}`}>
                      <Button>Applicants</Button>
                    </Link>
                  </td>
                  <td>
                    <Link to={`/job-form/${data.id}`}>
                      <Button>Update</Button>
                    </Link>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
      <div
        style={{ marginTop: "2rem", display: "flex", justifyContent: "center" }}
      >
        <PaginationPageSelector
          totalPages={totalPage}
          currentPage={currentPage}
          pageClickFunc={(page) => {
            setCurrentPage(page);
            handleJobList(page, jobStatus);
          }}
        />
      </div>
    </div>
  );
}
