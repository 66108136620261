import axios from "axios";
import { handleException } from "../util/handleException";
import { getToken } from "../util/tokenHandler";
import { HttpResponse } from "../model/HttpResponse";
import { createHotelObj } from "../model/HotelModel";

const createHotel = "wft_admin/accommodation-create";
const hotelListAPI = "wft_admin/accommodation-pagination-list";
const hotelDetailsAPI = "wft_admin/accommodation-detail";
const updateHotelDetailsAPI = "wft_admin/accommodation-update";
/**
 *
 * @param {string} hotelName
 * @param {string} hotelLocation
 * @param {File} hotelImage
 * @param {File} hotelLowResImage
 * @param {string} hotelDescription
 */

export async function saveHotelData(
  hotelName,
  hotelLocation,
  hotelImage,
  hotelLowResImage,
  hotelDescription
) {
  try {
    const formData = new FormData();
    formData.append("name", hotelName);
    formData.append("location", hotelLocation);
    formData.append("image", hotelImage);
    formData.append("pdf_image", hotelLowResImage);
    formData.append("accommodation_description", hotelDescription);
    const res = await axios.post(createHotel, formData, {
      headers: {
        Authorization: `Token ${getToken()}`,
      },
    });
    return new HttpResponse(res.status, "Hotel information saved successfully");
  } catch (err) {
    return handleException(err);
  }
}

/**
 * @returns {Promise<[{results:import("../model/HotelModel").HotelModel[], count:number}|null, HttpResponse|null]>}
 */
export async function getHotelList(page = 1, searchText = "") {
  const result = [null, null];

  try {
    const res = await axios.get(hotelListAPI, {
      headers: {
        Authorization: `Token ${getToken()}`,
      },
      params: {
        page,
        keyword:searchText
      },
    });
    const hotelArr = [];
    for (let data of res.data.results) {
      hotelArr.push(createHotelObj(data));
    }
    result[0] = { results: hotelArr, count: res.data.count };
    return result;
  } catch (err) {
    result[1] = handleException(err);
    return result;
  }
}

/**
 *
 * @param {number} hotelId
 * @returns {Promise<[import("../model/HotelModel").HotelModel|null, HttpResponse|null]>}
 */
export async function getHotelDetails(hotelId) {
  const result = [null, null];
  try {
    const res = await axios.get(`${hotelDetailsAPI}/${hotelId}`, {
      headers: {
        Authorization: `Token ${getToken()}`,
      },
    });
    result[0] = createHotelObj(res.data);
    return result;
  } catch (err) {
    result[1] = handleException(err);
    return result;
  }
}

/**
 * @typedef {object} UpdateProps
 * @property {number} hotelId
 * @property {string} hotelName
 * @property {string} hotelDescription
 * @property {string} hotelLocation
 * @property {File | undefined} hotelImage
 * @property {File| undefined} hotelLowResImage
 */

/**
 * @param {UpdateProps} props
 */

export async function updateHotelDetails({
  hotelId,
  hotelName,
  hotelDescription,
  hotelLocation,
  hotelImage,
  hotelLowResImage,
}) {
  try {
    const formData = new FormData();
    formData.append("name", hotelName);
    formData.append("location", hotelLocation);
    hotelImage !== undefined && formData.append("image", hotelImage);
    hotelLowResImage !== undefined &&
      formData.append("pdf_image", hotelLowResImage);
    formData.append("accommodation_description", hotelDescription);
    const res = await axios.put(
      `${updateHotelDetailsAPI}/${hotelId}`,
      formData,
      {
        headers: {
          Authorization: `Token ${getToken()}`,
        },
      }
    );
    return new HttpResponse(res.status, "Update successful");
  } catch (err) {
    return handleException(err);
  }
}
