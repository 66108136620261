import React from 'react'
import NewTicket from '../NewTicket'
import { useState } from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { headers } from '../../api/ApiUtils'
import axios from 'axios'
import { message } from 'antd'

function EditTicket() {

    const [ data , setData ] = useState(null)

    const { id } = useParams()

    useEffect(() => {
        axios.get('/wft_admin/pnr-detail/'+ id,headers())
          .then(res => {
            let da = res.data
            da.method = 'put'
            da.edit = id
            setData(da)
          })
          .catch(err => {
            console.log('Error', err);
            message.info(err.response?.data.message || err.message)
          })
      }, [])
    
  return (
    <div>
        <NewTicket 
        load={data}
        />
      
    </div>
  )
}

export default EditTicket
