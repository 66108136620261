export const ADD_SIGHT_SEEN_DAY_NO = "add sight seen day no";
export const ADD_SIGHT_SEEN_INDEX = "add sight seen index";
export const ADD_SIGHT_SEEN_HEADING = "Add sightseen heading";
export const ADD_SIGHT_SEEN_DURATION = "add sightseen duration";
export const ADD_SIGHT_SEEN_DESCRIPTION = "add sight seen description";
export const ADD_SIGHT_SEEN_IMAGE = "add sight seen image";
export const ADD_SIGHT_SEEN_ID = "Add sight seen id";
export const ADD_SIGHT_SEEN_NAME = "add sight seen name";
export const RESET_SIGHT_SEEN_STATE = "reset sightseen state";
export const IS_UPDATE_SIGHT_SEEN_INFO = "is update sight seen info";
export function addSeightSeenDay(day) {
  return {
    type: ADD_SIGHT_SEEN_DAY_NO,
    payload: day,
  };
}

export function addSightSeenIndex(index) {
  return {
    type: ADD_SIGHT_SEEN_INDEX,
    payload: index,
  };
}

export function addSightSeenHeading(heading) {
  return {
    type: ADD_SIGHT_SEEN_HEADING,
    payload: heading,
  };
}

export function addSighSeenName(name) {
  return {
    type: ADD_SIGHT_SEEN_IMAGE,
    payload: name,
  };
}

export function addSightSeenDescription(des) {
  return {
    type: ADD_SIGHT_SEEN_DESCRIPTION,
    payload: des,
  };
}

export function addSightSeenDuration(dur) {
  return {
    type: ADD_SIGHT_SEEN_DURATION,
    payload: dur,
  };
}

export function addSightSeenId(id) {
  return {
    type: ADD_SIGHT_SEEN_ID,
    payload: id,
  };
}

export function addSightSeendImage(img) {
  return {
    type: ADD_SIGHT_SEEN_IMAGE,
    payload: img,
  };
}

export function resetSightSeenState() {
  return {
    type: RESET_SIGHT_SEEN_STATE,
  };
}
export function addSightSeenName(name) {
  return {
    type: ADD_SIGHT_SEEN_NAME,
    payload: name,
  };
}

/**
 * 
 * @param {SightSeeing} payload  
 */
export function isUpdateSightSeenInfo(payload) {
  return {
    type: IS_UPDATE_SIGHT_SEEN_INFO,
    payload,
  };
}
