import React, { useState } from "react";
import InputField from "../../components/global/InputField";
import SelectField from "../../components/global/SelectField";
import { useDispatch, useSelector } from "react-redux";
import "../../../store/reducers/QuickTicketReducer/QuickTicketStateType";
import {
  setQuickTickerManagerName,
  setQuickTicketCustomerEmail,
  setQuickTicketCustomerName,
  setQuickTicketCustomerPhoneNumber,
  setQuickTicketDaySchedule,
  setQuickTicketGuestCount,
  setQuickTicketHotelDetails,
  setQuickTicketManagerEmail,
  setQuickTicketManagerPhoneNumber,
  setQuickTicketTourDuration,
} from "../../../store/actions/QuickTicketAction";
import { getTourDetail } from "../../../controller/TourController";
import { handleControllerResultException } from "../../../util/handleException";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import componentStyle from "../../../css/components.module.css";
import QuickTicketHotelCostList from "./QuickTicketHotelCostList";

export default function QuickTicket() {
  /**
   * @type {QuickTicket}
   */
  const {
    customerName,
    customerEmail,
    customerPhoneNumber,
    managerName,
    managerEmail,
    managerPhoneNumber,
    tourDuration,
    guestCount,
    economyCost,
    delightCost,
    luxuryCost,
    classicCost,
    premiumCost,
    eliteCost,
  } = useSelector((state) => state.quickTicket);
  const [inputError, setInputError] = useState(false);
  const dispatch = useDispatch();

  const history = useHistory();
  async function handleTourDuration(duration) {
    dispatch(setQuickTicketTourDuration(duration));
    if (duration !== "") {
      const res = await getTourDetail(duration);
      if (res[0] !== null) {
        dispatch(setQuickTicketDaySchedule(res[0].daySchedules));
        dispatch(setQuickTicketHotelDetails(res[0].hotelDetails));
      } else {
        handleControllerResultException(res[1], history);
      }
    }
  }

  function inputValidate() {
    return (
      customerName !== "" &&
      customerEmail !== "" &&
      customerPhoneNumber !== "" &&
      managerName !== "" &&
      managerEmail !== "" &&
      managerPhoneNumber !== "" &&
      tourDuration !== "" &&
      guestCount !== "" &&
      economyCost !== "" &&
      delightCost !== "" &&
      premiumCost !== "" &&
      classicCost !== "" &&
      luxuryCost !== "" &&
      eliteCost !== ""
    );
  }

  function handlePreview() {
    if (inputValidate()) {
      history.push("/quick-ticket/preview");
    } else {
      setInputError(true);
    }
  }

  return (
    <div>
      <div>
        <div
          style={{ display: "flex", alignItems: "center", marginTop: "20px" }}
        >
          <InputField
            label="Customer name"
            showInputError={inputError}
            inputFieldAttributes={{
              value: customerName,
              onChange: (e) =>
                dispatch(setQuickTicketCustomerName(e.target.value)),
            }}
            stylesProps={{ width: "100%", marginRight: "10px" }}
          />
          <InputField
            label="Customer Email"
            showInputError={inputError}
            inputFieldAttributes={{
              type: "email",
              value: customerEmail,
              onChange: (e) =>
                dispatch(setQuickTicketCustomerEmail(e.target.value)),
            }}
            stylesProps={{ width: "100%", marginRight: "10px" }}
          />
          <InputField
            label="Customer phone no"
            showInputError={inputError}
            inputFieldAttributes={{
              type: "number",
              value: customerPhoneNumber,
              onChange: (e) =>
                dispatch(setQuickTicketCustomerPhoneNumber(e.target.value)),
            }}
            stylesProps={{ width: "100%", marginRight: "10px" }}
          />
        </div>
        <div
          style={{ display: "flex", alignItems: "center", marginTop: "20px" }}
        >
          <InputField
            label="Manager name"
            showInputError={inputError}
            inputFieldAttributes={{
              value: managerName,
              onChange: (e) =>
                dispatch(setQuickTickerManagerName(e.target.value)),
            }}
            stylesProps={{ width: "100%", marginRight: "10px" }}
          />
          <InputField
            label="Manager email"
            showInputError={inputError}
            inputFieldAttributes={{
              value: managerEmail,
              type: "email",
              onChange: (e) =>
                dispatch(setQuickTicketManagerEmail(e.target.value)),
            }}
            stylesProps={{ width: "100%", marginRight: "10px" }}
          />
          <InputField
            label="Manager Phone no"
            showInputError={inputError}
            inputFieldAttributes={{
              value: managerPhoneNumber,
              type: "number",
              onChange: (e) =>
                dispatch(setQuickTicketManagerPhoneNumber(e.target.value)),
            }}
            stylesProps={{ width: "100%", marginRight: "10px" }}
          />
        </div>
      </div>
      <InputField
        label="Guest count"
        inputFieldAttributes={{
          value: guestCount,
          onChange: (e) => dispatch(setQuickTicketGuestCount(e.target.value)),
        }}
        showInputError={inputError}
        stylesProps={{ marginTop: "20px" }}
      />
      <SelectField
        stylesProps={{ marginTop: "20px" }}
        label="Select Tour Duration"
        selectFieldAttributes={{
          value: tourDuration,
          onChange: (e) => handleTourDuration(e.target.value),
        }}
        options={["3N4D", "4N5D", "5N6D", "6N7D", "7N8D"]}
        showInputError={inputError}
      />
      <h2 style={{ marginTop: "2rem" }}>Hotel Cost Details</h2>
      <hr />
      <QuickTicketHotelCostList inputError={inputError} />
      <div
        style={{ marginTop: "1rem", display: "flex", justifyContent: "center" }}
      >
        <button className={componentStyle.btnPrimary} onClick={handlePreview}>
          Preview &rarr;
        </button>
      </div>
    </div>
  );
}
