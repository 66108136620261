export const DAY_NO = "day no";
export const INDEX = "index";
export const AC_AVAILABILITY = "ac availability";
export const CAR_NAME = "car name";
export const CAR_TYPE = "car type";
export const DESCRIPTION = "description";
export const SOURCE = "source";
export const DESTINATION = "destination";
export const HEADING = "heading";
export const NO_OF_SEATERS = "no of seaters";
export const RESET_CAR_FORM = "reset car form";
export const CAR_INFO_UPDATE = "car info update";

export function addDayNumber(day) {
  return {
    type: DAY_NO,
    payload: day,
  };
}

export function addIndex(index) {
  return {
    type: INDEX,
    payload: index,
  };
}

export function addAcAvailability(ac) {
  return {
    type: AC_AVAILABILITY,
    payload: ac,
  };
}

export function addCarName(carName) {
  return {
    type: CAR_NAME,
    payload: carName,
  };
}
export function addCarType(carType) {
  return {
    type: CAR_TYPE,
    payload: carType,
  };
}

export function addDescription(des) {
  return {
    type: DESCRIPTION,
    payload: des,
  };
}

export function addSource(source) {
  return {
    type: SOURCE,
    payload: source,
  };
}

export function addDestination(des) {
  return {
    type: DESTINATION,
    payload: des,
  };
}

export function addHeading(heading) {
  return {
    type: HEADING,
    payload: heading,
  };
}

export function addNoOfSeaters(seaters) {
  return {
    type: NO_OF_SEATERS,
    payload: seaters,
  };
}

export function resetCarForm() {
  return {
    type: RESET_CAR_FORM,
  };
}

export function addIsCarInfoUpdate(payload) {
  return {
    type: CAR_INFO_UPDATE,
    payload,
  };
}
