import {
  ADD_VOUCHER_DATA,
  REMOVE_VOUCHER_PNR,
  RESET_VOUCHER_STATE,
  VOUCHER_CHECK_IN_DATE,
  VOUCHER_CHECK_OUT_DATE,
  VOUCHER_EXTRA_MATRESS,
  VOUCHER_HOTEL_ISLAND_NAME,
  VOUCHER_HOTEL_LOCATION,
  VOUCHER_HOTEL_NAME,
  VOUCHER_INSERT_INCLUSION,
  VOUCHER_INSERT_INSTRICTION,
  VOUCHER_ISSUER_EMAIL,
  VOUCHER_ISSUER_NAME,
  VOUCHER_ISSUER_PHONE_NUMBER,
  VOUCHER_MEAL_PLAN,
  VOUCHER_NO_OF_ROOMS,
  VOUCHER_PERSON_CONTACT_NUMBER,
  VOUCHER_PERSON_EMAIL,
  VOUCHER_PERSON_NAME,
  VOUCHER_PNR,
  VOUCHER_REMOVE_INCLUSION,
  VOUCHER_REMOVE_INSTRUCTION,
  VOUCHER_RE_CHECK_IN_DATE,
  VOUCHER_RE_CHECK_OUT_DATE,
  VOUCHER_ROOM_TYPE,
  VOUCHER_TOTAL_NO_ADULTS,
  VOUCHER_TOTAL_NO_CHIDREN,
  VOUCHER_UPDATE_INCLUSION,
  VOUCHER_UPDATE_INSTRUCTION,
} from "../../actions/VoucherAction";
import "./VoucherStateType";
import { updateInclusion, updateInstruction } from "./voucherStateManageUtil";

/**
 * @type {VoucherState}
 */
const initialState = {
  isUpdate: false,
  id: 0,
  pnr: "",
  personName: "",
  personContactNumber: "",
  personEmail: "",
  issuerName: "",
  issuerEmail: "",
  issuerPhoneNumber: "",
  hotelName: "",
  hotelLocation: "",
  hotelIslandName: "",
  numOfRooms: "",
  roomType: "",
  totalNumOfAdults: "",
  totalNumOfChildren: "",
  extraMatress: "",
  mealPlan: "",
  checkInDate: "",
  checkOutDate: "",
  reCheckInDate: "",
  reCheckOutDate: "",
  inclusion: [],
  instruction: [
    "Infants and children below the age of 05 years without extra bed will be complimentary",
    "The tariff includes complimentary breakfast",
    "Check in time is 9.00 am and check out time is 9.30 am. Early check in and late check-out is subject to availability of room.",
  ],
};

/**
 * @returns {VoucherState}
 */
export function VoucherReducer(state = initialState, action) {
  switch (action.type) {
    case VOUCHER_PNR:
      return {
        ...state,
        pnr: action.payload,
      };
    case REMOVE_VOUCHER_PNR:
      return {
        ...state,
        pnr: "",
        personName: "",
        personEmail: "",
        personContactNumber: "",
      };
    case VOUCHER_PERSON_NAME:
      return {
        ...state,
        personName: action.payload,
      };
    case VOUCHER_PERSON_CONTACT_NUMBER:
      return {
        ...state,
        personContactNumber: action.payload,
      };
    case VOUCHER_PERSON_EMAIL:
      return {
        ...state,
        personEmail: action.payload,
      };
    case VOUCHER_ISSUER_NAME:
      return {
        ...state,
        issuerName: action.payload,
      };
    case VOUCHER_ISSUER_EMAIL:
      return {
        ...state,
        issuerEmail: action.payload,
      };
    case VOUCHER_ISSUER_PHONE_NUMBER:
      return {
        ...state,
        issuerPhoneNumber: action.payload,
      };
    case VOUCHER_HOTEL_NAME:
      return {
        ...state,
        hotelName: action.payload,
      };
    case VOUCHER_HOTEL_LOCATION:
      return {
        ...state,
        hotelLocation: action.payload,
      };
    case VOUCHER_HOTEL_ISLAND_NAME:
      return {
        ...state,
        hotelIslandName: action.payload,
      };
    case VOUCHER_NO_OF_ROOMS:
      return {
        ...state,
        numOfRooms: action.payload,
      };
    case VOUCHER_ROOM_TYPE:
      return {
        ...state,
        roomType: action.payload,
      };
    case VOUCHER_EXTRA_MATRESS:
      return {
        ...state,
        extraMatress: action.payload,
      };

    case VOUCHER_TOTAL_NO_ADULTS:
      return {
        ...state,
        totalNumOfAdults: action.payload,
      };
    case VOUCHER_TOTAL_NO_CHIDREN:
      return {
        ...state,
        totalNumOfChildren: action.payload,
      };
    case VOUCHER_MEAL_PLAN:
      return {
        ...state,
        mealPlan: action.payload,
      };
    case VOUCHER_CHECK_IN_DATE:
      return {
        ...state,
        checkInDate: action.payload,
      };
    case VOUCHER_CHECK_OUT_DATE:
      return {
        ...state,
        checkOutDate: action.payload,
      };
    case VOUCHER_RE_CHECK_IN_DATE:
      return {
        ...state,
        reCheckInDate: action.payload,
      };
    case VOUCHER_RE_CHECK_OUT_DATE:
      return {
        ...state,
        reCheckOutDate: action.payload,
      };
    case VOUCHER_INSERT_INCLUSION:
      return {
        ...state,
        inclusion: [...state.inclusion, action.payload],
      };
    case VOUCHER_UPDATE_INCLUSION:
      return {
        ...state,
        inclusion: updateInclusion(state, action.payload),
      };
    case VOUCHER_REMOVE_INCLUSION:
      return {
        ...state,
        inclusion: state.inclusion.filter(
          (data, index) => index !== action.payload
        ),
      };
    case VOUCHER_INSERT_INSTRICTION:
      return {
        ...state,
        instruction: [...state.instruction, action.payload],
      };
    case VOUCHER_UPDATE_INSTRUCTION:
      return {
        ...state,
        instruction: updateInstruction(state, action.payload),
      };
    case VOUCHER_REMOVE_INSTRUCTION:
      return {
        ...state,
        instruction: state.instruction.filter(
          (data, index) => index !== action.payload
        ),
      };
    case ADD_VOUCHER_DATA:
      return action.payload;
    case RESET_VOUCHER_STATE:
      return initialState;
    default:
      return state;
  }
}
