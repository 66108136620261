import React from "react";
import { DaySelector } from "./Components";
import { useState } from "react";
import Add from "./Add.svg";
import { useEffect } from "react";
import axios from "axios";
import { PackageCard } from "./PackageCard";
import { Link } from "react-router-dom";
function AddPackage() {
  const [day, setDay] = useState("3N4D");
  const [pk, setPk] = useState("");
  const [data, setData] = useState([]);

  const _day = ["3N4D", "4N5D", "5N6D", "6N7D", "7N8D"];

  const pks = ["Economy", "Luxury", "Delite", "Classic"];
  const pks2 = ["Premium", "Elite", "Add Folder"];

  useEffect(() => {
    day &&
      axios
        .get(`/wft_admin/itinerary-list?itinerary_type=${pk}&duration=${day}`)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
  }, [day, pk]);

  const handleDay = (d) => {
    setPk(d);
  };
  return (
    <div>
      <div style={{ display: "flex" }}>
        <div>
          <h2 className="my-4">Standard Packages</h2>
          <div className="d-flex gap-4">
            {_day.map((dat) => (
              <h3
                role="button"
                onClick={() => setDay(dat)}
                style={{
                  background: day === dat && "black",
                  color: day === dat && "white",
                  boxShadow: "0px 0px 1px gray",
                  paddingRight: 25,
                  paddingLeft: 25,
                  paddingTop: 5,
                  paddingBottom: 5,
                  borderRadius: 5,
                  cursor: "pointer",
                }}
              >
                {dat}
              </h3>
            ))}
          </div>
        </div>
      </div>

      <div className="d-flex gap-4 mt-4">
        {pks.map((dat) => (
          <DaySelector dn={dat} key={dat} handleClick={handleDay} />
        ))}
      </div>
      <div className="d-flex gap-4 mt-4">
        {pks2.map((dat) => (
          <DaySelector dn={dat} key={dat} handleClick={handleDay} />
        ))}
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "2rem",
          marginTop: 40,
        }}
      >
        {data.map((dat, i) => (
          <PackageCard
            img={dat.images}
            ac={dat.number_of_activities}
            duration={dat.duration}
            description={dat.description}
            heading={dat.heading}
            itinerary_sub_type={dat.itinerary_sub_type}
            itinerary_type={dat.itinerary_type}
            meal={dat.meal}
            hotel_type={dat.hotel_type}
            key={i}
            id={dat.id}
          />
        ))}
      </div>

      <div
        style={{
          marginRight: 100,
          padding: 20,
        }}
        className="d-flex mb-4"
      >
        {/* <div></div> */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            boxShadow: "0px 0px 1px gray",
            padding: "35px 25px",
            borderRadius: 15,
            cursor: "pointer",
          }}
        >
          <img src={Add} alt="add package" />
          <Link to="package/create">
            <h3>Add Package</h3>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default AddPackage;
