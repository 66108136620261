/**
 * @class
 * This class represents a team member
 */

export class TeamMemberModel {
  /**
   * @type {number}
   */
  id;

  /**
   * @type {string}
   */
  name;

  /**
   * @type {string}
   */
  email;

  /**
   * @type {string}
   */
  designation;

  /**
   * @type {string}
   */
  accessType;

  /**
   * @type {string}
   */
  profileImage;

  /**
   * @type {string}
   */
  joinDate;

  /**
   * @type {boolean}
   */
  isTeam;

  /**
   * @type {boolean}
   */
  isAdmin;

  /**
   * @type {number}
   */
  userId;

  /**
   * @type {boolean}
   */
  activeStatus;

  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.email = data.email;
    this.designation = data.designation;
    this.accessType = data.access_type;
    this.profileImage = data.image;
    this.joinDate = data.join_date;
    this.isAdmin = data.is_admin;
    this.isTeam = data.is_team;
    this.activeStatus = data.active_status;
    this.userId = data.user;
  }
}
