import "../store/reducers/voucher/VoucherStateType";

/**
 * @typedef {object} VoucherServerObj
 * @property {"voucherServerObj"} type
 * @property {number} id
 * @property {string | null} pnr
 * @property {string} person_name
 * @property {string} person_contact
 * @property {string} person_email
 * @property {string} issue_by_name
 * @property {string} issue_by_email
 * @property {string} issue_by_contact
 * @property {string} hotel_name
 * @property {string} hotel_location
 * @property {string} hotel_island_name
 * @property {string} no_of_room
 * @property {string} room_type
 * @property {string} no_of_guest
 * @property {string} meal_plan
 * @property {string} extra_matress
 * @property {string} check_in
 * @property {string} check_out
 * @property {string | null} re_check_in
 * @property {string | null} re_check_out
 * @property {string[]} inclusion
 * @property {string[]} instruction
 */

/**
 * @param {VoucherState} voucher
 */
export function voucherObjToServerVoucherObj(voucher) {
  /**
   * @type {VoucherServerObj}
   */
  const obj = {
    id: voucher.id,
    pnr: voucher.pnr !== "" ? voucher.pnr : null,
    person_name: voucher.personName,
    person_contact: voucher.personContactNumber,
    person_email: voucher.personEmail,
    issue_by_name: voucher.issuerName,
    issue_by_email: voucher.issuerEmail,
    issue_by_contact: voucher.issuerPhoneNumber,
    hotel_name: voucher.hotelName,
    hotel_location: voucher.hotelLocation,
    hotel_island_name: voucher.hotelIslandName,
    no_of_room: voucher.numOfRooms,
    no_of_guest: `${voucher.totalNumOfAdults} Adult, ${voucher.totalNumOfChildren} children`,
    room_type: voucher.roomType,
    extra_matress: voucher.extraMatress,
    meal_plan: voucher.mealPlan,
    check_in: voucher.checkInDate,
    check_out: voucher.checkOutDate,
    re_check_in: voucher.reCheckInDate !== "" ? voucher.reCheckInDate : null,
    re_check_out: voucher.reCheckOutDate !== "" ? voucher.reCheckOutDate : null,
    inclusion: voucher.inclusion,
    instruction: voucher.instruction,
  };
  return obj;
}

export function createVoucherServerObj(data) {
  /**
   * @type {VoucherServerObj}
   */
  const voucherObj = {
    id: data.id,
    pnr: data.pnr_unique_id,
    person_name: data.person_name,
    person_contact: data.person_contact,
    person_email: data.person_email,
    hotel_name: data.hotel_name,
    hotel_location: data.hotel_location,
    hotel_island_name: data.hotel_island_name,
    no_of_room: data.no_of_room,
    no_of_guest: data.no_of_guest,
    check_in: data.check_in,
    check_out: data.check_out,
    re_check_in: data.re_check_in,
    re_check_out: data.re_check_out !== null ? data.re_check_out : "",
    inclusion: data.inclusion_list,
    instruction: data.instruction_list,
  };
  return voucherObj;
}

export function createVoucherObj(data) {
  /**
   * @type {VoucherState}
   */
  const obj = {
    id: data.id,
    pnr: data.pnr_unique_id !== null ? data.pnr_unique_id : "",
    personName: data.person_name,
    personContactNumber: data.person_contact,
    personEmail: data.person_email,
    issuerEmail: data.issue_by_email,
    issuerName: data.issue_by_name,
    issuerPhoneNumber: data.issue_by_contact,
    hotelName: data.hotel_name,
    hotelLocation: data.hotel_location,
    hotelIslandName: data.hotel_island_name,
    numOfRooms: data.no_of_room,
    roomType: data.room_type,
    extraMatress: data.extra_matress,
    mealPlan: data.meal_plan,
    totalNumOfAdults: data.no_of_guest.split(" ")[0],
    totalNumOfChildren: data.no_of_guest.split(" ")[2],
    checkInDate: data.check_in,
    checkOutDate: data.check_out,
    reCheckInDate: data.re_check_in !== null ? data.re_check_in : "",
    reCheckOutDate: data.re_check_out !== null ? data.re_check_out : "",
    inclusion: data.inclusion_list !== null ? data.inclusion_list : [],
    instruction: data.instruction_list !== null ? data.instruction_list : [],
  };
  return obj;
}

/**
 * @param {VoucherServerObj} voucher
 */
export function serverVoucherObjToVoucherObj(voucher) {
  /**
   * @type {VoucherState}
   */
  const obj = {
    isUpdate: true,
    id: voucher.id,
    pnr: voucher.pnr !== null ? voucher.pnr : "",
    personName: voucher.person_name,
    personContactNumber: voucher.person_contact,
    personEmail: voucher.person_email,
    hotelName: voucher.hotel_name,
    hotelLocation: voucher.hotel_location,
    hotelIslandName: voucher.hotel_island_name,
    numOfRooms: voucher.no_of_room,
    totalNumOfAdults: voucher.no_of_guest.split(" ")[0],
    totalNumOfChildren: voucher.no_of_guest.split(" ")[2],
    checkInDate: voucher.check_in,
    checkOutDate: voucher.check_out,
    reCheckInDate: voucher.re_check_in !== null ? voucher.re_check_in : "",
    reCheckOutDate: voucher.re_check_out !== null ? voucher.re_check_out : "",
    inclusion: voucher.inclusion,
    instruction: voucher.inclusion,
  };
  return obj;
}
