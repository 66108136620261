import axios from "axios";
import { GuestModel } from "../model/collectionModel";
import { ReviewModel } from "../model/ReviewListItemModel";
import { HttpResponse } from "../model/HttpResponse";
import "../model/typeDefs";
import { ContactModel } from "../model/ContactModel";
import { handleException } from "../util/handleException";
import { getToken } from "../util/tokenHandler";
import { createUserObj } from "../model/UserModel";
import { createUserAssignedTicketObj } from "../model/UserTicketModal";

const LeadListAPI = "/wft_admin/guest-list";
const changeGuestActiveStatusAPI = "wft_admin/guest-active-status-change";
const updateGuestStatusAPI = "/wft_admin/update-gueststatus";
const reviewListAPI = "/wft_admin/review-listing";
const reviewUpdateAPI = "/wft_admin/review-staus-change";
const contactListAPI = "/wft_admin/contact-list";
const contactFilteredListAPI = "/wft_admin/contact-list-filter";
const userListAPI = "wft_admin/user-list";
const assingTicketToUserAPI = "wft_admin/user-ticket-assign";
const cancelRequestListAPI = "wft_admin/ticket-cancel-list";
const cancelRequestPaymentUpdateAPI = "/wft_admin/cancel-payment";

/**
 * @param {number} page
 * @param {string} searchText
 * @returns {Promise<[{result:GuestModel[], count:number}|null, HttpResponse|null]>}
 * This function will return the guest list or error
 */
export async function getGuestList(
  page = 1,
  searchText = "",
  activeStatus = true
) {
  /**
   *
   * @type {GuestListOrError}
   */
  const result = [null, null];
  const userDetails = localStorage.getItem("userDetails");

  if (userDetails) {
    try {
      const parsedUser = JSON.parse(userDetails);
      const token = parsedUser["user"]["token"];
      const res = await axios.get(`${LeadListAPI}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
        params: {
          keyword: searchText,
          page,
          active_status: activeStatus === true ? 1 : 0,
        },
      });
      /**
       *  @type {GuestModel[]}
       */
      const guestArr = [];
      for (let data of res.data.results) {
        const guest = GuestModel.createGuestObj(data);
        guestArr.push(guest);
      }
      result[0] = {
        result: guestArr,
        count: res.data.count,
      };
      return result;
    } catch (err) {
      result[1] = new HttpResponse(
        err.response.status,
        null,
        err.response.statusText
      );
    }
  } else {
    result[1] = new HttpResponse(401, null, "Unauthorized");
  }

  return result;
}

/**
 *
 * @param {string} guestId
 * @param {string} status
 * @returns {Promise<HttpResponse>}
 */

export async function updateGuestStatus(guestId, status) {
  const userDetails = localStorage.getItem("userDetails");
  const result = new HttpResponse(0, null, null);
  if (userDetails) {
    try {
      const parsedUser = JSON.parse(userDetails);
      const token = parsedUser["user"]["token"];
      const res = await axios.put(
        `${updateGuestStatusAPI}/${guestId}`,
        {
          followup_status: status,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      result.statusCode = res.status;
      result.success = res.data.message;
    } catch (err) {
      result.statusCode = err.response.status;
      result.error = err.response.statusText;
    }
  } else {
    return new HttpResponse(401, null, "Unauthorized");
  }

  return result;
}

export async function changeGuestActiveStatus(guestId) {
  try {
    const res = await axios.get(`${changeGuestActiveStatusAPI}/${guestId}`, {
      headers: {
        Authorization: `Token ${getToken()}`,
      },
    });
    return new HttpResponse(res.status, res.data.message, null);
  } catch (err) {
    return handleException(err);
  }
}

//___________________________________________________________________//

/**
 *
 * @param {number} page
 * @returns {Promise<[{result:ReviewModel[],count:number}|null, HttpResponse|null]>}
 */

export async function getReviewList(page = 1) {
  /**
   * @type {[{result:ReviewModel[],count:number}|null, HttpResponse|null]}
   */
  const result = [null, null];
  const userDetails = localStorage.getItem("userDetails");
  if (userDetails) {
    try {
      const parsedUser = JSON.parse(userDetails);
      const token = parsedUser["user"]["token"];
      const res = await axios.get(`${reviewListAPI}?page=${page}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      /**
       *  @type {ReviewModel[]}
       */
      const reviewArr = [];
      for (let data of res.data.results) {
        const review = new ReviewModel(data);
        reviewArr.push(review);
      }
      result[0] = {
        result: reviewArr,
        count: res.data.count,
      };
      return result;
    } catch (err) {
      result[1] = new HttpResponse(
        err.response.status,
        null,
        err.response.statusText
      );
    }
  } else {
    result[1] = new HttpResponse(401, null, "Unauthorized");
  }

  return result;
}

/**
 * @param {number} reviewId
 * @param {"Live" | "Draft"} status
 * @returns {Promise<HttpResponse>}
 * Changes review status
 */
export async function updateReviewStatus(reviewId, status) {
  const userDetails = localStorage.getItem("userDetails");
  if (userDetails) {
    try {
      const parsedUser = JSON.parse(userDetails);
      const token = parsedUser["user"]["token"];
      const res = await axios.post(
        `${reviewUpdateAPI}/${reviewId}`,
        {
          review_status: status,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      return new HttpResponse(res.status, res.data.message, null);
    } catch (err) {
      return new HttpResponse(
        err.response.status,
        null,
        err.response.statusText
      );
    }
  } else {
    return new HttpResponse(401, null, "Unauthorized");
  }
}

//___________________________________________________________________//

/**
 *
 * @param {number} page
 * @param {string} searchText
 * @returns {Promise<[{result:ContactModel[],count:0}|null,HttpResponse|null]>}
 */
export async function getContactList(page = 1, searchText = "") {
  /**
   * @type {ContactListOrError}
   */
  const result = [null, null];

  const userDetails = localStorage.getItem("userDetails");
  if (userDetails) {
    try {
      const parsedUser = JSON.parse(userDetails);
      const token = parsedUser["user"]["token"];
      const res = await axios.get(
        `${
          searchText === "" ? contactListAPI : contactFilteredListAPI
        }?page=${page}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
          params: {
            keyword: searchText,
          },
        }
      );
      /**
       * @type {ContactModel[]}
       */
      const contactList = [];
      for (let data of res.data.results) {
        const contact = new ContactModel(data);
        contactList.push(contact);
      }
      result[0] = {
        result: contactList,
        count: res.data.count,
      };
    } catch (err) {
      result[1] = new HttpResponse(
        err.response.status,
        null,
        err.response.statusText
      );
    }
  } else {
    result[1] = new HttpResponse(401, null, "Unauthorized");
  }

  return result;
}

/**- --------------------------------------- USER tab -----------------------------------------  */

/**
 *
 * @returns {Promise<[{result:import("../model/UserModel").User[],count:number}|null, HttpResponse|null]>}
 */
export async function getUserList(page) {
  const result = [null, null];
  try {
    const res = await axios.get(userListAPI, {
      headers: {
        Authorization: `Token ${getToken()}`,
      },
      params: {
        page,
      },
    });
    const dataArr = res.data.results;
    const userArr = [];
    for (let user of dataArr) {
      userArr.push(createUserObj(user));
    }
    result[0] = {
      result: userArr,
      count: res.data.count,
    };
    return result;
  } catch (err) {
    result[1] = handleException(err);
    return result;
  }
}

export async function assignTicketTouser(pnrTicketId, userId) {
  try {
    const res = await axios.post(
      assingTicketToUserAPI,
      { ticket: pnrTicketId, user: userId },
      {
        headers: {
          Authorization: `Token ${getToken()}`,
        },
      }
    );
    return new HttpResponse(res.status, res.data.message, null);
  } catch (err) {
    return handleException(err);
  }
}

// ------------------------------ Cancel Request ------------------- //
/**
 *@param {"canceled"|"request"} listType - canceled" for getting a list of already canceled tickets, "request" for getting a list of cancel requests
 * @returns {Promise<[import("../model/UserTicketModal").UserAssignedTicket[]|null, HttpResponse|null]>}
 */

export async function cancelRequest(listType) {
  const res = [null, null];
  try {
    const res = await axios.get(cancelRequestListAPI, {
      headers: {
        Authorization: `Token ${getToken()}`,
      },
      params: {
        cancel_status: listType,
      },
    });
    const tickerArr = [];
    for (let data of res.data) {
      tickerArr.push(createUserAssignedTicketObj(data));
    }
    res[0] = tickerArr;
    return res;
  } catch (err) {
    res[1] = handleException(err);
    return res;
  }
}

/**
 *
 * @param {number} ticketId
 * @param {boolean} cancelPayment
 * @param {string} cancelPaymentAmount
 * @param {string} cancelPaymentDate
 * @param {string} cancelPaymentTransction
 * @param {string} cancelPaymentRemark
 * @returns {Promise<HttpResponse>}
 */
export async function cancelRequestPaymentUpdate(
  ticketId,
  cancelPayment,
  cancelPaymentAmount,
  cancelPaymentDate,
  cancelPaymentTransction,
  cancelPaymentRemark
) {
  try {
    const res = await axios.post(
      `${cancelRequestPaymentUpdateAPI}/${ticketId}`,
      {
        cancel_payment: cancelPayment,
        cancel_payment_amount: cancelPaymentAmount,
        cancel_payment_date: cancelPaymentDate,
        cancel_payment_transaction: cancelPaymentTransction,
        cancel_payment_remark: cancelPaymentRemark,
      },
      {
        headers: {
          Authorization: `Token ${getToken()}`,
        },
      }
    );
    return new HttpResponse(res.status, res.data.message);
  } catch (err) {
    return handleException(err);
  }
}
