import React from "react";
import InputField from "../../components/global/InputField";

/**
 * @typedef {object} Props
 * @property {"Economy"|"Delight"|"Classic"|"Premium"|"Luxury"|"Elite"} hotelType
 * @property {string} hotelCost
 * @property {(price:string)=>void} setHotelCost
 * @property {string} childOneToFiveCost
 * @property {(price:string)=>void} setChildOneToFiveCost
 * @property {string} childFiveToTwelveCost
 * @property {(price:string)=>void} setChildFiveToTwelveCost
 * @property {boolean} inputError
 * @property {React.CSSProperties} styles
 *
 */

/**
 * @param {Props} props
 */

export default function TicketHotelPrice({
  hotelType,
  hotelCost,
  setHotelCost,
  childOneToFiveCost,
  setChildOneToFiveCost,
  childFiveToTwelveCost,
  setChildFiveToTwelveCost,
  inputError,
  styles,
}) {
  return (
    <div style={{ ...styles }}>
      <h3 style={{background:"#212121", color:"white", padding:"10px 10px"}}>{hotelType}</h3>
      <InputField
        label="Cost"
        inputFieldAttributes={{
          value: hotelCost,
          type: "number",
          onChange: (e) => setHotelCost(e.target.value),
        }}
        showInputError={inputError}
      />
      <InputField
        label="Cost per child (1 - 5 years) (optional)"
        inputFieldAttributes={{
          value: childOneToFiveCost,
          type: "number",
          onChange: (e) => setChildOneToFiveCost(e.target.value),
        }}
        stylesProps={{marginTop:"10px"}}
      />
      <InputField
        label="Cost per child (5 - 12 years) (optional)"
        inputFieldAttributes={{
          value: childFiveToTwelveCost,
          type: "number",
          onChange: (e) => setChildFiveToTwelveCost(e.target.value),
          
        }}
        stylesProps={{marginTop:"10px"}}

      />
    </div>
  );
}
