import React, { useState } from "react";
import Button from "../../../components/global/Button";
import AlertModal from "../../../components/global/AlertModal";
import CancelRequestPaymentModal from "./CancelRequestPaymentModal";
/**
 * @typedef {object} Props
 * @property {import('../../../../model/UserTicketModal').UserAssignedTicket} ticket
 * @property {(ticketId:number)=>void} handleTicketStatus
 * @property {string} currentTab
 */

/**
 * @param {Props} props
 */
export default function CancelRequestListItem({
  ticket,
  currentTab,
  handleTicketStatus,
}) {
  const [showConfirmCancelModal, setShowConfirmCancelModal] = useState(false);
  const [showCancelPaymentModal, setShowCancelPaymentModal] = useState(false);
  return (
    <tr key={ticket.id}>
      <td>{ticket.pnrNumber}</td>
      <td>{ticket.customerName}</td>
      <td>{ticket.customerEmail}</td>
      <td>{ticket.customerPhoneNumber}</td>
      <td>{ticket.manager}</td>
      <td>{ticket.packageCategory}</td>
      <td>{ticket.packageType}</td>
      {currentTab === "request" && (
        <td>
          <Button
            variant="Danger"
            onClick={() => {
              setShowConfirmCancelModal(true);
            }}
          >
            Cancel
          </Button>
          {showConfirmCancelModal && (
            <AlertModal
              alertMessage="reason for cancellation"
              subMessage={ticket.cancelReason}
              confirmButtonAction={() => handleTicketStatus(ticket.id)}
              setModalVisibility={setShowConfirmCancelModal}
            />
          )}
        </td>
      )}
      {currentTab === "canceled" && (
        <td>
          <Button onClick={()=>setShowCancelPaymentModal(true)}>Payment</Button>
          {showCancelPaymentModal && (
            <CancelRequestPaymentModal
              setShowModal={setShowCancelPaymentModal}
              ticket={ticket}
            />
          )}
        </td>
      )}
    </tr>
  );
}
