import React, { useState } from "react";
import { ActivityFilter, DateCard, HeaderComp1, Input } from "./Components";
import img1 from "./img1.png";
import { Input as Inputt, message } from "antd";
import img2 from "./img2.png";
import img3 from "./img3.png";
import img4 from "./img4.png";
import line from "./Line.png";
import { H4 } from "../Componets";
import axios from "axios";
import { useEffect } from "react";
import moment from "moment/moment";
import { Button, Modal } from "antd";
import RichText from "./richText";

function Form2({ payload, handleForm1 }) {
    let resp = payload?.itinerary_detail ? [payload?.itinerary_detail] : null;

    const [response, setRes] = useState(resp ? resp : []);
    const [data, setData] = useState({});
    const [hotel, setHotel] = useState(payload?.["hotels"] || []);
    const [ov, setOv] = useState({});
    const [ht, setHt] = useState({});
    const [length, setLength] = useState(1);
    const [details, setDetails] = useState(["Select one"]);
    const [date, setDate] = useState(payload["travelling_date"] || "");
    const [open, setOpen] = useState(false);

    useEffect(() => {
        let lth =
            parseInt(data.package_category?.split(/[ND]/)[0]) ||
            payload?.["hotels"]?.length ||
            1;
        setLength(lth);
        let obj = {};
        if (payload) {

            payload["hotels"]?.map((dat, i) => {
                obj[i] = dat;
            });

        }
        setHt(obj);
    }, [data.package_category]);

    useEffect(() => {

        if (payload) {
            let newDat = {}
            newDat['package_type'] = payload.package_type
            newDat['package_category'] = payload.package_category
            setData(newDat)
        }

    }, [])

    let inData = [
        {
            label: "Select Package Range ",
            placeholder: "Select Package Range",
            name: "package_type",
            type: "select",
            option: [
                "Select one",
                "Economy",
                "Delight",
                "Luxury",
                "Classic",
                "Premium",
                "Elite",
            ],
        },
        {
            label: "Select Package Category",
            placeholder: "Select Package Category",
            name: "package_category",
            type: "select",
            option: [
                "Select one",
                "3N4D",
                "4N5D",
                "5N6D",
                "6N7D",
                "7N8D",
                "8N9D",
                "9N10D",
                "10N11D",
                "11N12D",
                "12N13D",
                "13N14D",
                "14N15D",
            ],
        },
        {
            label: "Select Package Type",
            placeholder: "Select Package Type",
            type: "select",
            name: "itinerary_sub_type",
            type: "select",
            option: ["Select one", "Couple", "Couple Honeymoon", "Family"],
        },
    ];

    let p_flow = [
        {
            title: "Economy",
            img: img2,
        },
        {
            title: "3N4D",
            img: img3,
        },
        {
            title: "Couple",
            img: img3,
        },
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prevData) => ({
            ...prevData,
            [name]: value,
        }));


    };
    useEffect(() => {
        data &&
            axios
                .get(
                    "wft_admin/itinerary-ticket-list" +
                    "?itinerary_sub_type=" +
                    data?.itinerary_sub_type +
                    "&" +
                    "itinerary_type=" +
                    data?.package_type +
                    "&duration=" +
                    data?.package_category
                )
                .then((resp) => {
                    let l_data = resp.data;
                    if (l_data.length) {
                        setRes(l_data);
                    }
                })
                .catch((err) => {
                    message.error(err.response.statusText);
                });

        axios
            .get(
                "wft_admin/accommodation-list-type" +
                "?type_of_accomodation=" +
                data?.package_type || "Economy"
            )
            .then((resp) => {
                setHotel(resp.data);
            })
            .catch((err) => {
                message.error(err.response.statusText);
            });
    }, [data]);

    const overview = [];

    for (let i = 0; i < length; i++) {
        overview.push(i);
    }

    const handleHotel = (e, i) => {
        const { name, value } = e.target;
        setHt({
            ...ht,
            [name]: value,
        });
    };

    const handleOwerview = (e) => {
        const { name, value } = e.target;
        setOv({
            ...ov,
            [name]: value,
        });
    };

    var newArr = [];

    var val = [];

    const handleForm2 = () => {
        localStorage.setItem("hotels", JSON.stringify(ht));
        // let hotels = Object.keys(ht).map((da, i)=>da == i ? da : 0)
        let hotel = Object.values(ht);

        let event = { target: { name: "hotels", value: hotel } };
        handleForm1(event);
    };

    function print(val) {
        localStorage.setItem("htmlText", JSON.stringify(val));
        let event = { target: { name: "inclusion_exclution", value: val } };
        handleForm1(event);
    }

    return (
        <div className="mb-4">
            <div className="container">
                <HeaderComp1
                    title="Package Details"
                    discription="Select appropriate package according to the customer requirement"
                />
                <div className="">
                    <div className="row">
                        <div className="col-sm">
                            <Input
                                onChange={(e) => { setDate(e.target.value); handleForm1(e) }}
                                name="travelling_date"
                                type="date"
                                value={payload["travelling_date"]}
                                label="Traveling Date"
                                placeholder="Choose a Starting Date"
                            />
                            {inData.map((data) => (
                                <Input
                                    name={data.name}
                                    value={payload?.[data.name]}
                                    onChange={(e) => {
                                        handleChange(e);
                                        handleForm1(e);
                                    }}
                                    type={data.type}
                                    options={data.option}
                                    label={data.label}
                                    placeholder={data.placeholder}
                                />
                            ))}
                            <label htmlFor={"pnr"} style={{ color: "black" }}>
                                Select Package
                            </label>
                            <select
                                style={{ width: "100%", borderColor: "gray" }}
                                defaultValue={payload?.["itinerary_detail"]?.id}
                                className="form-control input-default"
                                id={"pnr"}
                                name={"itinerary"}
                                placeholder={"Select Package"}
                                onChange={(e) => {
                                    handleForm1(e);
                                    localStorage.setItem("pnr", e.target.value);
                                }}
                            >
                                <option value={null}>Select</option>
                                {response.length &&
                                    response.map((o, i) => (
                                        <option value={o.id}>{o.unique_id}</option>
                                    ))}
                            </select>
                            <label
                                htmlFor={"rt"}
                                style={{
                                    color: "black",
                                    marginTop: 8,
                                }}
                            >
                                Package Inclusions & Exclusions (Optional)
                            </label>
                        </div>
                        <div className="col-sm d-flex position-relative">
                            <div className="whitespace-nowrap mx-4">
                                <h4 className="text-nowrap text-center">
                                    Package Selection Flow
                                </h4>
                                <div className="d-flex justify-content-center mb-2">
                                    <img src={line} width={5} height={20} />
                                </div>
                                <div className="d-flex flex-column align-items-center gap-4">
                                    {p_flow.map((data) => (
                                        <div className="d-flex flex-column align-items-center">
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    height: 10,
                                                    gap: 4,
                                                    marginBottom: 8,
                                                }}
                                            >
                                                <img src={data.img} width={20} height={20} />
                                                <H4>{data.title}</H4>
                                            </div>
                                            <img src={line} width={5} height={20} />
                                        </div>
                                    ))}
                                </div>
                                <H4 className="text-nowrap p-2 border border-primary border-1">
                                    Honeymoon Special Package
                                </H4>
                            </div>
                            <div style={{ right: 50 }} className="position-absolute">
                                <img src={img1} style={{ width: 200 }} />
                            </div>
                        </div>
                    </div>
                </div>
                <RichText print={print} value={payload["inclusion_exclution"]} />
            </div>
            <div className="my-5 d-flex justify-content-end">
                <Button
                    onClick={() => setOpen(true)}
                    type="primary"
                    style={{ background: "#55b3e5", color: "#fff", fontWeight: 700 }}
                    className="bg-[#55b3e5]"
                >
                    + ADD HOTELS
                </Button>
            </div>

            <Modal
                onCancel={() => {
                    setOpen(false);
                    handleForm2(ov, ht);
                }}
                footer={false}
                open={open}
            >
                <div>
                    <h3 style={{ textDecorationLine: "underline", textAlign: "center" }}>
                        Select Hotel
                    </h3>

                    <h4>
                        Ticket ID: <span style={{ color: "#55b3e5" }}>#TicketID</span>
                    </h4>
                    {/* <h3>Overview</h3>
                {overview.map(dat=><div className='d-flex gap-4 my-2 align-items-center'>
                    <h4 style={{ whiteSpace:"nowrap"}}>Hotel {dat+1}</h4>
                    <Inputt name={dat} onChange={handleOwerview}  style={{ Width:"100%", borderRadius:5,padding:5}} placeholder={"enter hotel details"} />
                </div>)}
                <hr style={{height:1,background:"#000"}} /> */}

                    <h3>Day Wise</h3>

                    {overview.map((dat, i) => (
                        <div className="d-flex gap-4 my-2 align-items-center">
                            <h4 style={{ whiteSpace: "nowrap" }}>Hotel {dat + 1}</h4>
                            <select
                                onChange={(e) => handleHotel(e, i)}
                                defaultValue={payload?.['hotels']?.[i]}
                                name={dat.toString()}
                                style={{ width: "100%", borderRadius: 5, padding: 5 }}
                            >
                                <option value="">Select hotel</option>
                                {hotel.map((da) => (
                                    <option value={da.id}>
                                        {da.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    ))}

                    <div className="my-5 d-flex justify-content-center">
                        <div>
                            <Button
                                onClick={() => setOpen(false)}
                                type="text"
                                style={{ color: "#222", fontWeight: 500 }}
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={() => {
                                    handleForm2(ov, ht);
                                    setOpen(false);
                                }}
                                type="primary"
                                style={{ background: "#55b3e5", color: "#fff", fontWeight: 500 }}
                                className="bg-#55b3e5-500"
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
            {response.length &&
                response.filter(dat => dat.id == payload.itinerary).map((res, i) => {
                    return (
                        <>
                            <HeaderComp1 title="Preview of the Package:" />
                            <div
                                style={{ background: "#E5E7EB", borderRadius: 10 }}
                                className="p-4"
                            >
                                <HeaderComp1
                                    title={res?.heading}
                                    discription={res?.description}
                                />
                            </div>
                            <div className="p-6">
                                <h1>{res?.area}</h1>
                                <h1 style={{ color: "#41C5F2" }}>in {res?.duration}</h1>

                                <h3 style={{ fontWeight: 500 }}>Trip Started</h3>
                                <h3 style={{ fontWeight: 700 }}>Time : 10:00AM - 5:00PM</h3>
                                {res.tour_detail?.length &&
                                    res?.tour_detail.map((dat, index) => (
                                        <div
                                            style={{
                                                borderColor: "#a0a0a070",
                                                borderRadius: 10,
                                                borderWidth: 1,
                                                borderStyle: "solid",
                                                padding: 10,
                                            }}
                                            className="d-md-flex mt-4 mx-md-4 flex-md-row-reverse align-item-center justify-content-between"
                                        >
                                            <DateCard
                                                day={index + 1}
                                                date={moment(date)
                                                    .add(index, "day")
                                                    .format("ddd, Do MMMM")}
                                                i={index + 1}
                                            />
                                            <div className="my-auto">
                                                {dat?.day_details.map((dt) => (
                                                    <ActivityFilter data={dt} />
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </>
                    );
                })}
        </div>
    );
}

export default Form2;