import FontStyles from "./FontStyles";
import Heading from "./Heading";
import List from "./List";
import { BsCodeSlash } from "react-icons/bs";
import { FaRedoAlt, FaUndoAlt } from "react-icons/fa";
import { MdHorizontalRule } from "react-icons/md";
import MenuBtnContainer from "./MenuBtnContainer";
/**
 * @typedef {object} Props
 * @property {import("@tiptap/react").Editor | null} editor
 */

/**
 *  @param {Props} props
 *  @returns {JSX.Element}  Renders editor toolbar
 */
export default function MenuBar({ editor }) {
  if (editor === null) {
    return <p>Error</p>;
  }
  return (
    <div className=" d-flex flex-wrap align-items-center">
      {editor && <FontStyles editor={editor} />}
      {editor && <Heading editor={editor} />}
      {editor && <List editor={editor} />}

      <MenuBtnContainer
        buttonActionFunc={() => editor.chain().focus().toggleCodeBlock().run()}
        toolTipText="Code"
      >
        <BsCodeSlash size="1.2em" />
      </MenuBtnContainer>
      <MenuBtnContainer
        buttonActionFunc={() =>
          editor.chain().focus().setHorizontalRule().run()
        }
        toolTipText="Horizontal line"
      >
        <MdHorizontalRule size="1.2em" />
      </MenuBtnContainer>

      <MenuBtnContainer
        buttonActionFunc={() => editor.chain().focus().undo().run()}
        toolTipText="Undo"
      >
        <FaUndoAlt size="1em" />
      </MenuBtnContainer>

      <MenuBtnContainer
        buttonActionFunc={() => editor.chain().focus().redo().run()}
        toolTipText="Redo"
      >
        <FaRedoAlt size="1em" />
      </MenuBtnContainer>
    </div>
  );
}
