import React, { useCallback, useEffect, useState } from "react";
import ModalLayout from "../../components/global/ModalLayout";
import { useDispatch } from "react-redux";
import {
  addPackageImage,
  deletePackageImage,
  fetchPackageDetail,
} from "../../../controller/PackageController";

import { message } from "antd";
import componentStyles from "../../../css/components.module.css";
import "../../../store/reducers/packagereducer/reduerStateType";
import { HTTPStatus } from "../../../util/helperObj";

/**
 * @typedef {object} Props
 * @property {boolean} setModalVisibility
 * @property {number} packageId
 */

/**
 * @param {Props} props
 */
export default function PackageImagesModal({ setModalVisibility, packageId }) {
  /**
   * @type {PackageImage[]}
   */
  const packageImageListType = [];
  const [imageList, setImageList] = useState(packageImageListType);
  const [imageFile, setImageFile] = useState();
  const [uploading, setUploading] = useState(false);
  const [deletingImage, setDeletingImage] = useState(false);

  const fetchPackageDetailsHandler = useCallback(async (id) => {
    const res = await fetchPackageDetail(id);
    if (res[0] !== null) {
      setImageList(res[0].images);
    } else {
      message.error(res[1].error);
    }
  }, []);

  useEffect(() => {
    if (packageId) {
      fetchPackageDetailsHandler(packageId);
    }
  }, [packageId, fetchPackageDetailsHandler]);

  async function handleUploadPackageImage() {
    if (imageFile) {
      setUploading(true);
      const res = await addPackageImage(packageId, imageFile);
      setUploading(false);
      if (res[0] !== null) {
        setImageList([...imageList, res]);
        message.success("Image is uploaded");
      } else {
        message.error(res[1].error);
      }
    } else {
      message.error("Add an image");
    }
  }

  async function handleDeletePackageImage(imageId) {
    setDeletingImage(true);
    const res = await deletePackageImage(imageId);
    setDeletingImage(false);
    if (res.statusCode === HTTPStatus.SUCCESS) {
      message.success(res.success);
      setImageList((prevState) =>
        prevState.filter((data) => data.id !== imageId)
      );
    } else {
      message.error(res.error);
    }
  }

  return (
    <ModalLayout
      modalCloseAction={() => setModalVisibility(false)}
      modalStyles={{
        height: "70%",
        width: "50%",
        overflowY: "scroll",
      }}
    >
      <div>
        <input
          className={componentStyles.inputField}
          type="file"
          onChange={(e) => setImageFile(e.target.files[0])}
        />
        <button
          className={componentStyles.btnPrimary}
          style={{ marginLeft: "20px" }}
          onClick={handleUploadPackageImage}
          disabled={uploading}
        >
          {uploading ? "...Uploading" : "Upload"}
        </button>
      </div>
      <div className={componentStyles.grid3} style={{ marginTop: "2rem" }}>
        {imageList.length > 0 &&
          imageList.map((data) => (
            <div
              key={data.id}
              style={{
                border: "1px solid #b3b3b3",
                padding: "10px",
                borderRadius: "5px",
              }}
            >
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                  onClick={() => handleDeletePackageImage(data.id)}
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    color: "red",
                    transform: "scale(1.2)",
                  }}
                  disabled={deletingImage}
                >
                  &#215;
                </button>
              </div>
              <img
                src={data.package_images}
                alt="package_image"
                height="200"
                width="100"
                style={{ objectFit: "fill", width: "100%" }}
              />
            </div>
          ))}
      </div>
      {imageList.length === 0 && (
        <p style={{ textAlign: "center" }}>No images found</p>
      )}
    </ModalLayout>
  );
}
