import React from "react";
import carImg from "../../../../images/car.png";
import carLogo from "../../../../jsx/newPages/NewTicket/car.png";
import "../../../../store/reducers/packagereducer/reduerStateType";
import { addIsCarInfoUpdate } from "../../../../store/actions/CarTravelFormAction";
import { useDispatch } from "react-redux";
import ActivityWidgetLayout from "../ActivityWidgetLayout";

/**
 * @typedef {object}Props
 * @property {TravelByCar} widgetData
 * @property {React.CSSProperties} widgetCardContainerStyle
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setShowCarTravelFormModal
 */

/**
 * @param {Props} props
 */
export default function CarTravelWidget({
  widgetData,
  widgetCardContainerStyle,
  setShowCarTravelFormModal,
}) {
  const dispatch = useDispatch();

  function handleUpdate() {
    dispatch(addIsCarInfoUpdate(widgetData));
    setShowCarTravelFormModal(true);
  }

  return (
    <ActivityWidgetLayout
      widgetContainerStyle={widgetCardContainerStyle}
      editWidgetAction={() => handleUpdate()}
      wingetDayIndexValue={{
        day_no: parseInt(widgetData.day_no),
        index: parseInt(widgetData.index),
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <img src={carLogo} alt="car logo" height="20" width="20" />
        <span
          style={{
            marginLeft: "20px",
            fontWeight: "500",
            color: "#212121",
            fontSize: "20px",
          }}
        >
          {widgetData.source} to {widgetData.dest}
        </span>
      </div>
      <p style={{color:"#212121", wordBreak:"break-word"}}>{widgetData.heading}</p>
      <div style={{ display: "flex", marginTop: "20px" }}>
        <img src={carImg} alt="car" height="150" width="200" />
        <div style={{ marginLeft: "50px" }}>
          <h1 style={{ fontSize: "1.6rem" }}>
            {widgetData.car_type} - {widgetData.ac_non}
          </h1>
          <h4 style={{ color: "grey", fontWeight: "400", fontSize: "1rem" }}>
            {widgetData.car_name}
          </h4>
          <div style={{ marginTop: "15px" }}>
            <span
              style={{ fontSize: "15px", color: "#212121", fontWeight: "500" }}
            >
              {widgetData.no_of_searters} seaters | {widgetData.ac_non}{" "} | Luggage Bags
            </span>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "20px" }}>
        <h5 style={{ fontSize: "20px" }}>Inclusion</h5>
        <p
          style={{
            fontSize: "14px",
            color: "#212121",
            fontWeight: "500",
            wordBreak: "break-all",
          }}
        >
          {widgetData.description}
        </p>
      </div>
    </ActivityWidgetLayout>
  );
}
