class SightSeen {
  /**
   * @type {number}
   */
  id;
  /**
   * @type {string}
   */
  area;
  /**
   * @type {string}
   */
  location;
  /**
   * @type {string}
   */
  name;
  /**
   * @type {string}
   */
  image;
  /**
   * @type {string | null}
   */
  description;

  constructor(data) {
    this.id = data.id;
    this.area = data.area;
    this.location = data.location;
    this.name = data.name;
    this.image = data.image;
    this.description = data.sightseeing_description;
  }
}

export default SightSeen;
