import axios from "axios";
import { handleException } from "../util/handleException";
import "../model/typeDefs";
import SightSeen from "../model/sighseeingModel";
import { HttpResponse } from "../model/HttpResponse";
import CustomPackageListItem from "../model/CustomPackageListItem";
import { convertServerPackageObjToPackageObj } from "../util/handlePackageDataFormat";
import { getToken } from "../util/tokenHandler";
const itinerayTypesAPI = "/wft_admin/itinerary-dropdown-option";
const activityNameListAPI = "/wft_admin/itinerary-activity-option";
const sightseeingListAPI = "/wft_admin/sightseeing-list";
const savePackageAPI = "wft_admin/itinerary-create";
const customPackageListAPI = "wft_admin/itinerary-custom-list";
const packageDetailAPI = "wft_admin/itinerary-detail";
const updatePackageAPI = "wft_admin/itinerary-update";
const updatePackageStatusAPI = "wft_admin/itinerary-active-satus-change";
const inactiveCustomPackagesAPI = "wft_admin/itinerary-deactive-list";
const uploadPackageImageAPI = "wft_admin/package-images-create";
const deletePackageImageAPI = "wft_admin/package-images-delete";
/**
 *
 * @returns {Promise<[string[], string[]]| HttpResponse>}
 * If the data fetch is successful, it will return an array that includes two values:
 *    - The first value is an array of itinerary types.
 *    - The second value is an array of itinerary subtypes.
 *
 *
 *  If the data fetch fails, the function will return an HttpResponse error.
 */

export async function fetchIteneraryTypes() {
  try {
    const res = await axios.get(itinerayTypesAPI);
    /**
     * @type {[string[],string[]]}
     */
    const result = [[], []];
    result[0] = res.data.package_type;
    result[1] = res.data.package_sub_type;
    return result;
  } catch (err) {
    return handleException(err);
  }
}

/**
 *
 * @returns {Promise<string[]|HttpResponse>}
 */
export async function fetchActivityNameList() {
  try {
    const res = await axios.get(activityNameListAPI);
    return res.data.activity;
  } catch (err) {
    return handleException(err);
  }
}

/**
 * @returns {Promise<SightSeen[]|HttpResponse>}
 */
export async function fetchSightSeeingList() {
  try {
    const res = await axios.get(sightseeingListAPI);
    const sightSeenArr = [];
    for (let x = 0; x < res.data.length; x++) {
      const data = new SightSeen(res.data[x]);
      sightSeenArr.push(data);
    }
    return sightSeenArr;
  } catch (err) {
    return handleException(err);
  }
}

export async function savePackage(packageData) {
  const userDetails = localStorage.getItem("userDetails");
  if (userDetails) {
    try {
      const parsedUser = JSON.parse(userDetails);
      const token = parsedUser["user"]["token"];
      const res = await axios.post(savePackageAPI, packageData, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      return new HttpResponse(res.status, res.data.message, null);
    } catch (err) {
      return handleException(err);
    }
  } else {
    return new HttpResponse(401, null, "Unauthorized");
  }
}

/**
 * @param {"active"|"inactive"} packageStatus
 * @returns {Promise<CustomPackageListItem[]|HttpResponse>}
 */
export async function fetchCustomPackageList(packageStatus) {
  const userDetails = localStorage.getItem("userDetails");
  if (userDetails) {
    const parsedUser = JSON.parse(userDetails);
    const token = parsedUser["user"]["token"];
    const res = await axios.get(
      packageStatus === "active"
        ? customPackageListAPI
        : inactiveCustomPackagesAPI,
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
    /**
     * @type {CustomPackageListItem[]}
     */
    const result = [];
    for (let x = 0; x < res.data.length; x++) {
      const packageItem = new CustomPackageListItem(res.data[x]);
      result.push(packageItem);
    }
    return result;
  } else {
    return new HttpResponse(401, null, "Unauthorized");
  }
}

export async function fetchPackageDetail(packageId) {
  /**
   * @type {[PackageState|null, HttpResponse|null]}
   */
  const result = [null, null];
  try {
    const res = await axios.get(`${packageDetailAPI}/${packageId}`);
    const packObj = convertServerPackageObjToPackageObj(res.data);
    result[0] = packObj;
  } catch (err) {
    console.log(err);
    result[1] = handleException(err);
  }
  return result;
}

export async function updatePackage(dataObj, id) {
  const userDetails = localStorage.getItem("userDetails");
  if (userDetails) {
    try {
      const parsedUser = JSON.parse(userDetails);
      const token = parsedUser["user"]["token"];
      const res = await axios.put(`${updatePackageAPI}/${id}`, dataObj, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      return new HttpResponse(res.status, res.data.message);
    } catch (err) {
      return handleException(err);
    }
  } else {
    return new HttpResponse(401, null, "Unauthorized");
  }
}

/**
 *
 * @param {number} packageId
 * @param {boolean} status
 */
export async function changeCustomPackageStatus(packageId, status) {
  const userDetails = localStorage.getItem("userDetails");
  if (userDetails) {
    const parsedUser = JSON.parse(userDetails);
    const token = parsedUser["user"]["token"];
    try {
      const res = await axios.get(`${updatePackageStatusAPI}/${packageId}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      return new HttpResponse(res.status, res.data.message, null);
    } catch (err) {
      return handleException(err);
    }
  } else {
    return new HttpResponse(401, null, "Unauthorized");
  }
}

/**
 *
 * @param {number} packageId
 * @param {File} image
 * @returns {Promise<[PackageImage|null, HttpResponse|null]>}
 */
export async function addPackageImage(packageId, image) {
  const result = [null, null];
  try {
    const formData = new FormData();
    formData.append("itinerary", packageId);
    formData.append("package_images", image);
    const res = await axios.post(uploadPackageImageAPI, formData, {
      headers: {
        Authorization: `Token ${getToken()}`,
      },
    });
    /**
     * @type {PackageImage}
     */
    const result = {
      id: res.data.id,
      package_images: res.data.package_images,
    };
    result[0] = result;
    return result;
  } catch (err) {
    result[1] = handleException(err);
    return err;
  }
}

export async function deletePackageImage(imageId) {
  try {
    const res = await axios.delete(`${deletePackageImageAPI}/${imageId}`, {
      headers: {
        Authorization: `Token ${getToken()}`,
      },
    });
    return new HttpResponse(res.status, res.data.message, null);
  } catch (err) {
    return handleException(err);
  }
}
