/**
 * @typedef {object} JobModel
 * @property {number} id
 * @property {string} title
 * @property {string} description
 * @property {string} location
 * @property {string} jobType
 * @property {string} postType
 * @property {string} salary
 * @property {string} responsibility
 * @property {string} qualification
 * @property {boolean} isActive
 */

export function createJobObj(data) {
  /**
   * @type {JobModel}
   */
  const result = {
    id: data.id,
    title: data.title,
    description: data.description !== null ? data.description : "",
    location: data.location,
    jobType: data.job_type,
    postType: data.post_type,
    salary: data.salary,
    qualification: data.quialification !== null ? data.quialification : "",
    responsibility: data.responsibility !== null ? data.responsibility : "",
    isActive: data.is_active,
  };
  return result;
}
