import React from "react";
import { useSelector } from "react-redux";
import ShortDayScheduleCard from "../../components/package/ShortDayScheduleCard";
import componentStyle from "../../../css/components.module.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import waveImg from "../NewTicket/hi.png";
import QuickTicketHotelDetailTable from "./QuickTicketHotelDetailTable";
import companyNameImg from "../../../images/companyName.png";
export default function QuickTicketPreview() {
  const history = useHistory();
  /**
   * @type {QuickTicket}
   */
  const {
    managerName,
    managerEmail,
    managerPhoneNumber,
    customerEmail,
    customerName,
    customerPhoneNumber,
    daySchedule,
    guestCount
  } = useSelector((state) => state.quickTicket);

  function handlePrint() {
    const doc = document.querySelector("#print");
    const winPrint = window.open();
    winPrint.document.write(doc.innerHTML);
    winPrint.document.title = "TravelOps";
    winPrint.document.head.innerHTML += `<link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap" rel="stylesheet">`;

    winPrint.document.head.innerHTML += `<link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap" rel="stylesheet">`;

    winPrint.document.close();
    winPrint.print();
  }
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "3rem",
        }}
      >
        <button
          className={componentStyle.btnPrimary}
          onClick={() => history.goBack()}
          style={{}}
        >
          &larr; Preview
        </button>
        <button className={componentStyle.btnPrimary} onClick={handlePrint}>
          print
        </button>
      </div>
      <div style={{ color: "#212121", fontSize: "14px" }} id="print">
        <img
          src={companyNameImg}
          alt="company name"
          height="80"
          width="200"
          style={{ objectFit: "contain" }}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "1rem",
            fontFamily: "Poppins",
          }}
        >
          <img src={waveImg} alt="wavehand" height="35" width="35" />
          <p style={{ marginLeft: "1rem", textTransform:"capitalize", fontSize:"2rem" }}>
            Hi <span style={{ fontWeight: "bold" }}>{customerName}</span>
          </p>
        </div>
        <div
          style={{
            border: "1px solid #212121",
            borderRadius: "8px",
            padding: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            textTransform: "capitalize",
          }}
        >
          <div style={{ fontFamily: "Poppins" }}>
            <div>
              <span>Customer name: </span> <br />{" "}
              <strong>{customerName}</strong>
            </div>
            <div style={{ marginTop: "10px" }}>
              <span>Customer Email: </span> <br />{" "}
              <strong>{customerEmail}</strong>
            </div>
            <div style={{ marginTop: "10px" }}>
              <span>Customer phone no: </span> <br />{" "}
              <strong>{customerPhoneNumber}</strong>
            </div>
          </div>
          <div style={{ fontFamily: "Poppins", textAlign:"right" }}>
            <div>
              <span>Manager name: </span> <br /> <strong>{managerName}</strong>
            </div>
            <div style={{ marginTop: "10px" }}>
              <span>Manager email: </span> <br />{" "}
              <strong>{managerEmail}</strong>
            </div>
            <div style={{ marginTop: "10px" }}>
              <span>Manager phone no: </span> <br />{" "}
              <strong>{managerPhoneNumber}</strong>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "2rem" }}>
          {daySchedule.length > 0 &&
            daySchedule.map((data, index) => (
              <ShortDayScheduleCard daySchedules={data} key={index} />
            ))}
        </div>
        <p style={{fontSize:"1.6rem", fontFamily:"Poppins"}}>Cost is based on <span style={{fontWeight:"500"}}>{guestCount}</span></p>
        <QuickTicketHotelDetailTable />
      </div>
    </div>
  );
}
