import axios from "axios";
import { createPnrListItemObj } from "../model/PnrListItemModel";
import { handleException } from "../util/handleException";
import { getToken } from "../util/tokenHandler";
import { HttpResponse } from "../model/HttpResponse";
import { createUserAssignedTicketObj } from "../model/UserTicketModal";
import { createTicketPaymentObj } from "../model/TicketPaymentModel";

const ticketActiveListAPI = "/wft_admin/pnr-list";
const ticketInactiveListAPI = "/wft_admin/pnr-inactive-list";
const ticketStatusAPI = "wft_admin/pnr-status-change";
const userTicketListAPI = "wft_admin/user-ticket-assign-list";
const userTicketChangeStatusAPI = "wft_admin/user-ticket-cancel";
const ticketPaymentDetailsAPI = "wft_admin/payment-detail-by-ticket-unique";
const updateTicketPaymentInfoAPI = "/wft_admin/payment-receive";

/**
 *
 * @param {number} page
 * @param {boolean} activeList
 * @param {string} searchText
 * @returns {Promise<[{result:import("../model/PnrListItemModel").PnrListItem[], count:number}|null, import("../model/HttpResponse").HttpResponse|null]>}
 */
export async function getTicketList(page = 1, activeList, searchText = "") {
  const result = [null, null];
  try {
    const res = await axios.get(
      activeList ? ticketActiveListAPI : ticketInactiveListAPI,
      {
        params: {
          page,
          keyword: searchText,
        },
        headers: {
          Authorization: `Token ${getToken()}`,
        },
      }
    );
    const resultData = res.data.results;

    const ticketArr = [];
    for (let data of resultData) {
      ticketArr.push(createPnrListItemObj(data));
    }
    result[0] = { result: ticketArr, count: res.data.count };
    return result;
  } catch (err) {
    result[1] = handleException(err);
    return result;
  }
}

export async function changeTicketStatus(ticketId) {
  try {
    const res = await axios.get(`${ticketStatusAPI}/${ticketId}`, {
      headers: {
        Authorization: `Token ${getToken()}`,
      },
    });
    return new HttpResponse(res.status, res.data.message, null);
  } catch (err) {
    return handleException(err);
  }
}

/**
 *
 * @returns {Promise<[import("../model/UserTicketModal").UserAssignedTicket[]|null, HttpResponse|null]>}
 */
export async function userTicketList(userId) {
  const result = [null, null];
  try {
    const res = await axios.get(`${userTicketListAPI}/${userId}`, {
      headers: {
        Authorization: `Token ${getToken()}`,
      },
    });
    const dataArr = res.data;
    const ticketArr = [];
    for (let ticket of dataArr) {
      ticketArr.push(createUserAssignedTicketObj(ticket));
    }
    result[0] = ticketArr;
    return result;
  } catch (err) {
    console.log(err);
    result[1] = handleException(err);
    return result;
  }
}

export async function userTicketChanceCancelStatus(id) {
  try {
    const res = await axios.get(`${userTicketChangeStatusAPI}/${id}`, {
      headers: {
        Authorization: `Token ${getToken()}`,
      },
    });
    return new HttpResponse(res.status, res.data.message, null);
  } catch (err) {
    return handleException(err);
  }
}

/**
 * @returns {Promise<[import("../model/TicketPaymentModel").TicketPaymentModel[]|null, HttpResponse|null]>}
 */
export async function getTicketPaymentDetails(pnrNo) {
  const result = [null, null];
  try {
    const res = await axios.get(`${ticketPaymentDetailsAPI}/${pnrNo}`, {
      headers: {
        Authorization: `Token ${getToken()}`,
      },
    });
    const paymentArr = [];
    for (let data of res.data) {
      paymentArr.push(createTicketPaymentObj(data));
    }
    result[0] = paymentArr;
    return result;
  } catch (err) {
    result[1] = handleException(err);
    return result;
  }
}

export async function updateTicketPaymentInfo(
  paymentId,
  paymentStatus,
  paymentDate,
  paymentRemarks
) {
  try {
    const res = await axios.post(
      `${updateTicketPaymentInfoAPI}/${paymentId}`,
      {
        payment_done: paymentStatus,
        payment_done_remarks: paymentRemarks,
        payment_done_date: paymentDate,
      },
      {
        headers: {
          Authorization: `Token ${getToken()}`,
        },
      }
    );
    return new HttpResponse(res.status, res.data.message);
  } catch (err) {
    return handleException(err);
  }
}
