import React from "react";
import { Nav, Tab } from "react-bootstrap";
import Hotels from "./Hotels/Hotels";
import SightSeens from "./sighseens/SightSeens";

export default function Services() {
  return (
    <Tab.Container defaultActiveKey="Hotels">
      <div className="card-tabs mt-3 mt-sm-0 mb-xxl-0 mb-4">
        <Nav as="ul" className="nav nav-tabs">
          <Nav.Item as="li" className="nav-item">
            <Nav.Link
              className="nav-link d-flex flex-row align-items-center ml-1"
              eventKey="Hotels"
            >
              Hotels
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as="li" className="nav-item">
            <Nav.Link
              className="nav-link d-flex flex-row align-items-center ml-1"
              eventKey="SightSeens"
            >
              Sightseens
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
      <Tab.Content>
        <Hotels />
        <SightSeens/>
      </Tab.Content>
    </Tab.Container>
  );
}
