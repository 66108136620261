import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addPackageDay,
  addPackageDescription,
  addPackageHeading,
  addPackageHotelType,
  addPackageManager,
  addPackageMeal,
  addPackageNight,
  addPackagePrice,
  addPackageRating,
  insertCompletePackageData,
  resetPackageState,
  setIsPackageInitialDataFilled,
} from "../../../store/actions/PackageAction";
import componentStyles from "../../../css/components.module.css";
import layoutStyles from "../../../css/layout.module.css";
import ItineraryTypeSelector from "../../components/package/ItineraryTypeSelector";
import ActivitySelector from "../../components/package/ActivitySelector";
import InputField from "../../components/global/InputField";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import "../../../store/reducers/packagereducer/reduerStateType";
import SelectField from "../../components/global/SelectField";
import {
  fetchPackageDetail,
  updatePackage,
} from "../../../controller/PackageController";
import { message } from "antd";
import { convertPackageDataObjToServerObj } from "../../../util/handlePackageDataFormat";
import { HTTPStatus } from "../../../util/helperObj";
export default function CreatePackage() {
  /**
   * @type {PackageState}
   */
  const packageObj = useSelector((state) => state.package);
  const [inputError, setInputError] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { packageId } = useParams();
  const dispatch = useDispatch();

  const fetchPackageDetailHandler = useCallback(
    async (id) => {
      const res = await fetchPackageDetail(id);
      if (res[0] !== null) {
        dispatch(insertCompletePackageData(res[0]));
      } else {
        message.error(res[1].error);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (packageId) {
      fetchPackageDetailHandler(packageId);
    }
  }, [packageId, fetchPackageDetailHandler]);

  function handleSubmit() {
    setInputError(false);
    if (
      packageObj.heading !== "" &&
      packageObj.description !== "" &&
      packageObj.managed_by !== "" &&
      packageObj.price !== "" &&
      packageObj.meal !== "" &&
      packageObj.activity.length > 0 &&
      packageObj.itinerary_type !== "" &&
      packageObj.itinerary_sub_type !== "" &&
      packageObj.hotel_type !== "" &&
      packageObj.rating !== "" &&
      packageObj.day !== "" &&
      packageObj.night !== ""
    ) {
      dispatch(setIsPackageInitialDataFilled(true));
      history.push("/custom-package/schedule");
    } else {
      setInputError(true);
    }
  }

  async function handlePackageUpdate() {
    setLoading(true);
    const resultObj = convertPackageDataObjToServerObj(
      packageObj,
      packageObj.isCustomized
    );
    const res = await updatePackage(resultObj, resultObj.id);
    setLoading(false);
    if (res.statusCode === HTTPStatus.SUCCESS) {
      message.success(res.success);
      packageObj.isCustomized
        ? history.push("/custom-packages")
        : history.push("/packages");
    } else {
      message.error(res.error);
    }
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <button
          className={componentStyles.btnPrimary}
          disabled={loading}
          onClick={() => {
            dispatch(resetPackageState());
            history.goBack();
          }}
        >
          <span style={{ marginRight: "4px" }}>&#x2190;</span> Previous
        </button>
        {packageObj.isUpdate && (
          <button
            className={componentStyles.btnPrimary}
            onClick={handlePackageUpdate}
            disabled={loading}
          >
            {loading ? "...Upading" : <span> &#x2713; Update</span>}
          </button>
        )}
      </div>
      <div style={{ marginTop: "3rem" }}>
        <InputField
          label="Heading"
          inputFieldAttributes={{
            value: packageObj.heading,
            onChange: (e) => dispatch(addPackageHeading(e.target.value)),
            type: "text",
          }}
          showInputError={inputError}
        />
        <InputField
          label="Manager"
          inputFieldAttributes={{
            value: packageObj.managed_by,
            onChange: (e) => dispatch(addPackageManager(e.target.value)),
            type: "text",
          }}
          stylesProps={{ marginTop: "20px" }}
          showInputError={inputError}
        />
        <InputField
          label="Description"
          inputFieldAttributes={{
            value: packageObj.description,
            onChange: (e) => dispatch(addPackageDescription(e.target.value)),
            type: "text",
          }}
          stylesProps={{ marginTop: "20px" }}
          showInputError={inputError}
        />
        <InputField
          label="Price"
          inputFieldAttributes={{
            value: packageObj.price,
            onChange: (e) => dispatch(addPackagePrice(e.target.value)),
            type: "number",
          }}
          stylesProps={{ marginTop: "20px" }}
          showInputError={inputError}
        />
        <InputField
          label="Rating"
          inputFieldAttributes={{
            value: packageObj.rating,
            onChange: (e) => dispatch(addPackageRating(e.target.value)),
            type: "number",
          }}
          stylesProps={{ marginTop: "20px" }}
          showInputError={inputError}
        />
        <div
          style={{ display: "flex", alignItems: "center", marginTop: "20px" }}
        >
          <InputField
            label="Day"
            inputFieldAttributes={{
              value: packageObj.day,
              onChange: (e) => dispatch(addPackageDay(e.target.value)),
              type: "number",
            }}
            showInputError={inputError}
          />
          <InputField
            label="Night"
            inputFieldAttributes={{
              value: packageObj.night,
              onChange: (e) => dispatch(addPackageNight(e.target.value)),
              type: "number",
            }}
            showInputError={inputError}
            stylesProps={{ marginLeft: "20px" }}
          />
        </div>
        <ItineraryTypeSelector showInputError={inputError} />
        <ActivitySelector showInputError={inputError} />
        <InputField
          label="Meal"
          inputFieldAttributes={{
            value: packageObj.meal,
            onChange: (e) => dispatch(addPackageMeal(e.target.value)),
            type: "text",
          }}
          stylesProps={{ marginTop: "20px" }}
          showInputError={inputError}
        />
        <SelectField
          label="Hotel type"
          selectFieldAttributes={{
            value: packageObj.hotel_type,
            onChange: (e) => dispatch(addPackageHotelType(e.target.value)),
          }}
          showInputError={inputError}
          options={[
            "Economy",
            "Delight",
            "Luxury",
            "Classic",
            "Premium",
            "Elite",
          ]}
          stylesProps={{ marginTop: "20px" }}
        />
      </div>
      <div className={layoutStyles.flexRight} style={{ marginTop: "15px" }}>
        <button
          disabled={loading}
          className={componentStyles.btnPrimary}
          onClick={handleSubmit}
        >
          Next <span style={{ marginLeft: "3px" }}>&rarr;</span>
        </button>
      </div>
    </div>
  );
}
