/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useState } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";
import useScrollPosition from "use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import pluas from "./pluas.svg";
import { adminConfirmation } from "../../../services/util";

/// Image
//import profile from "../../../images/profile/pic1.jpg";
//import plus from "../../../images/plus.png";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () => {
  const { iconHover, sidebarposition, headerposition, sidebarLayout } =
    useContext(ThemeContext);

  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);

    //sidebar icon Heart blast
    var handleheartBlast = document.querySelector(".heart");
    function heartBlast() {
      return handleheartBlast.classList.toggle("heart-blast");
    }
    handleheartBlast.addEventListener("click", heartBlast);
  }, []);

  useEffect(() => {
    const res = adminConfirmation();
    if (res !== null) {
      setIsAdmin(res);
    }
  }, []);

  let scrollPosition = useScrollPosition();
  /// Path
  let path = window?.location?.pathname;
  const pathArr = window.location.href.split("/");
  path = pathArr[pathArr.length - 1];
  /// Active menu
  let routes = [
    "",
    "dashboard-dark",
    "guest-list",
    "guest-detail",
    "concierge",
    "room-list",
    "reviews",
    "task",
  ];
  return (
    <div className="hideee-print">
      <div
        className={`deznav ${iconHover} ${
          sidebarposition.value === "fixed" &&
          sidebarLayout.value === "horizontal" &&
          headerposition.value === "static"
            ? scrollPosition > 120
              ? "fixed"
              : ""
            : ""
        }`}
      >
        <PerfectScrollbar className="deznav-scroll">
          <MM className="metismenu" id="menu">
            {/* <li className={`${path === "new-ticket" ? "mm-active" : ""}`}>
              <Link to="/new-ticket">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill={path === "new-ticket" ? "#fff" : "gray"}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 0H17C17.5523 0 18 0.44772 18 1V17C18 17.5523 17.5523 18 17 18H1C0.44772 18 0 17.5523 0 17V1C0 0.44772 0.44772 0 1 0ZM2 2V16H16V2H2ZM8 8V4H10V8H14V10H10V14H8V10H4V8H8Z"
                    fill=""
                  />
                </svg>
                <i className="flaticon-025-plus"></i>

                <span className="nav-text ml-4"> New Ticket</span>
              </Link>
            </li> */}
            <li className={`${path === "quick-ticket" ? "mm-active" : ""}`}>
              <Link to="/quick-ticket">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill={path === "quick-ticket" ? "#fff" : "gray"}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 0H17C17.5523 0 18 0.44772 18 1V17C18 17.5523 17.5523 18 17 18H1C0.44772 18 0 17.5523 0 17V1C0 0.44772 0.44772 0 1 0ZM2 2V16H16V2H2ZM8 8V4H10V8H14V10H10V14H8V10H4V8H8Z"
                    fill=""
                  />
                </svg>
                <i className="flaticon-025-plus"></i>

                <span className="nav-text ml-4">Welcome Brochure</span>
              </Link>
            </li>

            {isAdmin && (
              <li className={`${path === "" ? "mm-active" : "dashboard"}`}>
                <Link to="/">
                  <i className="flaticon-025-dashboard"></i>
                  <span className="nav-text">Dashboard</span>
                </Link>
              </li>
            )}

            <li
              className={`${path === "Tickets" ? "mm-active" : ""}`}
              to="/Tickets"
            >
              <Link to="/Tickets">
                <span style={{ height: "20px", width: "20px" }}>
                  <svg
                    width="20"
                    height="14"
                    viewBox="0 0 20 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19 6C19.5498 6 20 5.5498 20 5V2C20 0.899902 19.1001 0 18 0H13V2C13 2.5498 12.5498 3 12 3C11.4502 3 11 2.5498 11 2V0H2C0.899902 0 0 0.899902 0 2V5C0 5.5498 0.450195 6 1 6C1.5498 6 2 6.4502 2 7C2 7.5498 1.5498 8 1 8C0.450195 8 0 8.4502 0 9V12C0 13.1001 0.899902 14 2 14H11V12C11 11.4502 11.4502 11 12 11C12.5498 11 13 11.4502 13 12V14H18C19.1001 14 20 13.1001 20 12V9C20 8.4502 19.5498 8 19 8C18.4502 8 18 7.5498 18 7C18 6.4502 18.4502 6 19 6ZM13 8C13 8.5498 12.5498 9 12 9C11.4502 9 11 8.5498 11 8V6C11 5.4502 11.4502 5 12 5C12.5498 5 13 5.4502 13 6V8Z"
                      fill={path === "Tickets" ? "#FFFFFF" : "#A5A5A5"}
                    />
                  </svg>
                </span>
                <span className="nav-text" style={{ marginLeft: "10px" }}>
                  Proposal
                </span>
              </Link>
            </li>
            <li
              className={`${path === "Voucher" ? "mm-active" : ""}`}
              to="/Voucher"
            >
              <Link to="/Voucher">
                <span style={{ height: "20px", width: "20px" }}>
                  <svg
                    width="20"
                    height="14"
                    viewBox="0 0 20 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19 6C19.5498 6 20 5.5498 20 5V2C20 0.899902 19.1001 0 18 0H13V2C13 2.5498 12.5498 3 12 3C11.4502 3 11 2.5498 11 2V0H2C0.899902 0 0 0.899902 0 2V5C0 5.5498 0.450195 6 1 6C1.5498 6 2 6.4502 2 7C2 7.5498 1.5498 8 1 8C0.450195 8 0 8.4502 0 9V12C0 13.1001 0.899902 14 2 14H11V12C11 11.4502 11.4502 11 12 11C12.5498 11 13 11.4502 13 12V14H18C19.1001 14 20 13.1001 20 12V9C20 8.4502 19.5498 8 19 8C18.4502 8 18 7.5498 18 7C18 6.4502 18.4502 6 19 6ZM13 8C13 8.5498 12.5498 9 12 9C11.4502 9 11 8.5498 11 8V6C11 5.4502 11.4502 5 12 5C12.5498 5 13 5.4502 13 6V8Z"
                      fill={path === "Voucher" ? "#FFFFFF" : "#A5A5A5"}
                    />
                  </svg>
                </span>
                <span className="nav-text" style={{ marginLeft: "10px" }}>
                  Hotel Bookings
                </span>
              </Link>
            </li>
            {isAdmin && (
              <li className={`${path === "Packages" ? "mm-active" : ""}`}>
                <Link to="/Packages" className="d-flex">
                  <span style={{ height: "20px", width: "20px" }}>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17 20H3C1.34315 20 0 18.6569 0 17V1C0 0.44772 0.44772 0 1 0H15C15.5523 0 16 0.44772 16 1V13H20V17C20 18.6569 18.6569 20 17 20ZM16 15V17C16 17.5523 16.4477 18 17 18C17.5523 18 18 17.5523 18 17V15H16ZM14 18V2H2V17C2 17.5523 2.44772 18 3 18H14ZM4 5H12V7H4V5ZM4 9H12V11H4V9ZM4 13H9V15H4V13Z"
                        fill={path === "Packages" ? "#FFFFFF" : "#A5A5A5"}
                      />
                    </svg>
                  </span>

                  <span style={{ marginLeft: "10px" }} className="nav-text">
                    Itinerary
                  </span>
                </Link>
              </li>
            )}

            <li className={`${path === "custom-packages" ? "mm-active" : ""}`}>
              <Link to="/custom-packages" className="d-flex">
                <span style={{ height: "20px", width: "20px" }}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17 20H3C1.34315 20 0 18.6569 0 17V1C0 0.44772 0.44772 0 1 0H15C15.5523 0 16 0.44772 16 1V13H20V17C20 18.6569 18.6569 20 17 20ZM16 15V17C16 17.5523 16.4477 18 17 18C17.5523 18 18 17.5523 18 17V15H16ZM14 18V2H2V17C2 17.5523 2.44772 18 3 18H14ZM4 5H12V7H4V5ZM4 9H12V11H4V9ZM4 13H9V15H4V13Z"
                      fill={path === "custom-packages" ? "#FFFFFF" : "#A5A5A5"}
                    />
                  </svg>
                </span>

                <span style={{ marginLeft: "10px" }} className="nav-text">
                  Custom Itinerary
                </span>
              </Link>
            </li>
            {isAdmin && (
              <li className={`${path === "Services" ? "mm-active" : ""}`}>
                <Link to="/Services">
                  <span style={{ height: "20px", width: "20px" }}>
                    <svg
                      width="22"
                      height="20"
                      viewBox="0 0 22 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.9 17.7778V6.66667H2.2V17.7778H9.9ZM9.9 4.44444V1.11111C9.9 0.497467 10.3925 0 11 0H20.9C21.5075 0 22 0.497467 22 1.11111V18.8889C22 19.5026 21.5075 20 20.9 20H1.1C0.492492 20 0 19.5026 0 18.8889V5.55556C0 4.94191 0.492492 4.44444 1.1 4.44444H9.9ZM12.1 2.22222V17.7778H19.8V2.22222H12.1ZM3.3 14.4444H8.8V16.6667H3.3V14.4444ZM13.2 14.4444H18.7V16.6667H13.2V14.4444ZM13.2 11.1111H18.7V13.3333H13.2V11.1111ZM13.2 7.77778H18.7V10H13.2V7.77778ZM3.3 11.1111H8.8V13.3333H3.3V11.1111Z"
                        fill={path === "Services" ? "#FFFFFF" : "#A5A5A5"}
                      />
                    </svg>
                  </span>

                  <span className="nav-text" style={{ marginLeft: "10px" }}>
                    Services
                  </span>
                </Link>
              </li>
            )}

            <li className={`${path === "Collections" ? "mm-active" : ""}`}>
              <Link to="/Collections">
                <span style={{ height: "20px", width: "20px" }}>
                  <svg
                    width="22"
                    height="23"
                    viewBox="0 0 22 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.44444 11.5C2.44444 11.8794 3.0079 12.5391 4.31502 13.1864C6.00606 14.0238 8.40494 14.5263 11 14.5263C13.595 14.5263 15.9939 14.0238 17.6849 13.1864C18.9921 12.5391 19.5556 11.8794 19.5556 11.5V8.87158C17.5389 10.1057 14.4556 10.8947 11 10.8947C7.54445 10.8947 4.46106 10.1057 2.44444 8.87158V11.5ZM19.5556 14.9242C17.5389 16.1583 14.4556 16.9474 11 16.9474C7.54445 16.9474 4.46106 16.1583 2.44444 14.9242V17.5526C2.44444 17.932 3.0079 18.5917 4.31502 19.239C6.00606 20.0765 8.40494 20.5789 11 20.5789C13.595 20.5789 15.9939 20.0765 17.6849 19.239C18.9921 18.5917 19.5556 17.932 19.5556 17.5526V14.9242ZM0 17.5526V5.44737C0 2.43887 4.92487 0 11 0C17.0752 0 22 2.43887 22 5.44737V17.5526C22 20.5612 17.0752 23 11 23C4.92487 23 0 20.5612 0 17.5526ZM11 8.47368C13.595 8.47368 15.9939 7.97122 17.6849 7.13379C18.9921 6.48648 19.5556 5.82677 19.5556 5.44737C19.5556 5.06797 18.9921 4.40825 17.6849 3.76095C15.9939 2.92352 13.595 2.42105 11 2.42105C8.40494 2.42105 6.00606 2.92352 4.31502 3.76095C3.0079 4.40825 2.44444 5.06797 2.44444 5.44737C2.44444 5.82677 3.0079 6.48648 4.31502 7.13379C6.00606 7.97122 8.40494 8.47368 11 8.47368Z"
                      fill={path === "Collections" ? "#FFFFFF" : "#A5A5A5"}
                    />
                  </svg>
                </span>
                <span className="nav-text" style={{ marginLeft: "10px" }}>
                  Guest Enquiry
                </span>
              </Link>
            </li>

            {isAdmin && (
              <li className={`${path === "Blogs" ? "mm-active" : ""}`}>
                <Link to="/Blogs">
                  <span style={{ height: "20px", width: "20px" }}>
                    <svg
                      width="18"
                      height="20"
                      viewBox="0 0 18 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17 20H1C0.44772 20 0 19.5523 0 19V1C0 0.44772 0.44772 0 1 0H17C17.5523 0 18 0.44772 18 1V19C18 19.5523 17.5523 20 17 20ZM16 18V2H2V18H16ZM4 4H8V8H4V4ZM4 10H14V12H4V10ZM4 14H14V16H4V14ZM10 5H14V7H10V5Z"
                        fill={path === "Blogs" ? "#FFFFFF" : "#A5A5A5"}
                      />
                    </svg>
                  </span>

                  <span className="nav-text" style={{ marginLeft: "10px" }}>
                    Blogs
                  </span>
                </Link>
              </li>
            )}
            {isAdmin && (
              <li className={`${path === "Team-Members" ? "mm-active" : ""}`}>
                <Link to="/Team-Members">
                  <span style={{ height: "20px", width: "20px" }}>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 9C12.7614 9 15 11.2386 15 14V20H13V14C13 12.4023 11.7511 11.0963 10.1763 11.0051L10 11C8.4023 11 7.09634 12.2489 7.00509 13.8237L7 14V20H5V14C5 11.2386 7.23858 9 10 9ZM3.5 12C3.77885 12 4.05009 12.0326 4.3101 12.0942C4.14202 12.594 4.03873 13.122 4.00896 13.6693L4 14L4.0007 14.0856C3.88757 14.0456 3.76821 14.0187 3.64446 14.0069L3.5 14C2.7203 14 2.07955 14.5949 2.00687 15.3555L2 15.5V20H0V15.5C0 13.567 1.567 12 3.5 12ZM16.5 12C18.433 12 20 13.567 20 15.5V20H18V15.5C18 14.7203 17.4051 14.0796 16.6445 14.0069L16.5 14C16.3248 14 16.1566 14.03 16.0003 14.0852L16 14C16 13.3343 15.8916 12.694 15.6915 12.0956C15.9499 12.0326 16.2211 12 16.5 12ZM3.5 6C4.88071 6 6 7.11929 6 8.5C6 9.8807 4.88071 11 3.5 11C2.11929 11 1 9.8807 1 8.5C1 7.11929 2.11929 6 3.5 6ZM16.5 6C17.8807 6 19 7.11929 19 8.5C19 9.8807 17.8807 11 16.5 11C15.1193 11 14 9.8807 14 8.5C14 7.11929 15.1193 6 16.5 6ZM3.5 8C3.22386 8 3 8.2239 3 8.5C3 8.7761 3.22386 9 3.5 9C3.77614 9 4 8.7761 4 8.5C4 8.2239 3.77614 8 3.5 8ZM16.5 8C16.2239 8 16 8.2239 16 8.5C16 8.7761 16.2239 9 16.5 9C16.7761 9 17 8.7761 17 8.5C17 8.2239 16.7761 8 16.5 8ZM10 0C12.2091 0 14 1.79086 14 4C14 6.20914 12.2091 8 10 8C7.79086 8 6 6.20914 6 4C6 1.79086 7.79086 0 10 0ZM10 2C8.8954 2 8 2.89543 8 4C8 5.10457 8.8954 6 10 6C11.1046 6 12 5.10457 12 4C12 2.89543 11.1046 2 10 2Z"
                        fill={path === "Team-Members" ? "#FFFFFF" : "#A5A5A5"}
                      />
                    </svg>
                  </span>

                  <span className="nav-text" style={{ marginLeft: "10px" }}>
                    Team Info
                  </span>
                </Link>
              </li>
            )}
            <li className={`${path === "jobs" ? "mm-active" : ""}`}>
              <Link to="/jobs">
                <span style={{ height: "20px", width: "20px" }}>
                  <svg
                    width="18"
                    height="20"
                    viewBox="0 0 18 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17 20H1C0.44772 20 0 19.5523 0 19V1C0 0.44772 0.44772 0 1 0H17C17.5523 0 18 0.44772 18 1V19C18 19.5523 17.5523 20 17 20ZM16 18V2H2V18H16ZM4 4H8V8H4V4ZM4 10H14V12H4V10ZM4 14H14V16H4V14ZM10 5H14V7H10V5Z"
                      fill={path === "jobs" ? "#FFFFFF" : "#A5A5A5"}
                    />
                  </svg>
                </span>

                <span className="nav-text" style={{ marginLeft: "10px" }}>
                  Join Us
                </span>
              </Link>
            </li>
            {isAdmin && (
              <li className={`${path === "company" ? "mm-active" : ""}`}>
                <Link to="/company">
                  <span style={{ height: "20px", width: "20px" }}>
                    <svg
                      width="20"
                      height="18"
                      viewBox="0 0 20 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19 18H1C0.44772 18 0 17.5523 0 17V9.4868C0 9.1978 0.12501 8.9229 0.34282 8.733L4 5.54435V1C4 0.44772 4.44772 0 5 0H19C19.5523 0 20 0.44772 20 1V17C20 17.5523 19.5523 18 19 18ZM7 16H10V9.9416L6 6.45402L2 9.9416V16H5V12H7V16ZM12 16H18V2H6V4.12729C6.23444 4.12729 6.46888 4.20938 6.65718 4.37355L11.6572 8.733C11.875 8.9229 12 9.1978 12 9.4868V16ZM14 8H16V10H14V8ZM14 12H16V14H14V12ZM14 4H16V6H14V4ZM10 4H12V6H10V4Z"
                        fill={path === "company" ? "#FFFFFF" : "#A5A5A5"}
                      />
                    </svg>
                  </span>
                  <span className="nav-text" style={{ marginLeft: "10px" }}>
                    Company
                  </span>
                </Link>
              </li>
            )}
          </MM>
          <div className="copyright">
            <p>
              <strong>TravelOps@MyBizmo</strong> © 2021 All Rights Reserved
            </p>
            <p className="fs-12">
              Made with <span className="heart"></span> Plausibility Solutions
            </p>
          </div>
        </PerfectScrollbar>
      </div>
    </div>
  );
};

export default SideBar;
