/**
 * @typedef {object} HotelModel
 * @property {number} id
 * @property {string} hotelName
 * @property {string} hotelLocation
 * @property {string} hotelDescription
 * @property {string} hotelImageLink
 * @property {string} hotelLowResImageLink
 */

/**
 * @param {any} data
 * @returns {HotelModel}
 */
export function createHotelObj(data) {
  /**
   * @type {HotelModel}
   */
  const result = {
    id: data.id,
    hotelName: data.name !== null ? data.name : "",
    hotelLocation: data.location !== null ? data.location : "",
    hotelDescription:
      data.accommodation_description !== null
        ? data.accommodation_description
        : "",
    hotelImageLink: data.image !== null ? data.image : "",
    hotelLowResImageLink: data.pdf_image !== null ? data.pdf_image : "",
  };
  return result;
}
