import React, { useRef } from 'react'
import Form1 from './Form1'
import { Button, Steps, message } from 'antd'
import { useState, useEffect } from 'react'
import Form2 from './Form2'
import Form3 from './Form3'
import Form4 from './Form4'
import axios from 'axios'

function NewTicket({ load }) {

    const [count, setCount] = useState(0)
    const [data, setData] = useState({
        candlelight: true,
        photography: true,
        flowerbed: true,
        payment_detail: []
    })

    function handleForm1(e) {
        let { name, value, } = e.target
        setData({
            ...data,
            [name]: value
        });
    }

    let inData = [
        {
            label: "Customer Name",
            placeholder: "Enter Customer Name",
            name: "customer_name"
        },
        {
            label: "Email ID",
            placeholder: "Enter Customer Email",
            name: "customer_email"
        },
        {
            label: "Phone Number",
            placeholder: "Enter Phone Number",
            name: "customer_contact"
        },
        {
            label: "Destination",
            placeholder: "Select Destination",
            name: "destination"
        },
        {
            label: "Places Visit",
            placeholder: "Enter Places Visit",
            name: "visit_place"
        },
        {
            label: "Manager Name",
            placeholder: "Enter Manager Name",
            name: "manager_name"
        },
        {
            label: "No.Years of Experience",
            placeholder: "Enter No.Years of Experience",
            type: "number",
            name: "experience_year"
        },
        {
            label: "Handling Trips / Months",
            placeholder: "Enter No.of Trips per month this manger handled ",
            name: "trip_per_month",
            type: "number",
        },
        {
            label: "Manager  Ph.Number",
            placeholder: "Enter Contact Manager’s Ph.Number",
            name: "manager_contact",

        },
        {
            label: "Manager Email ID",
            placeholder: "Enter Contact Manager’s Email ID",
            name: "manager_email"
        },
        {
            label: "Date of this Quotation Created",
            placeholder: "Select Date and Time",
            name: "creation_date",
            type: "date"
        },
        {
            label: "Date of Validity of Quotation ",
            placeholder: "Select Date and Time",
            name: "validity_date",
            type: "date"
        },
        {
            label: "No.Hotels Checking-In",
            placeholder: "Enter No.Hotels",
            name: "hotel_count",
            type: "number",
        },
        {
            label: "No.Activities Customer Participate ",
            placeholder: "Enter No.Activities",
            name: "activity_count",
            type: "number",
        },
        {
            label: "No.Sight Seeings Places",
            placeholder: "Enter No.Sight Seeings Places",
            name: "sightseeing_count",
            type: "number",
        },
        {
            label: "No. Transfers during journey",
            placeholder: "Enter No.Transfers",
            name: "transfer_count",
            type: "number",
        },

        {
            label: "Total Amount for the trip",
            placeholder: "Enter Total amount for the trip",
            name: "total_amount",
            type: "number",
        },
        {
            label: "No.Travelers Traveling in this trip",
            placeholder: "Enter No.Travelers (Eg: 3 Adults , 1 Child)",
            name: "person_count"
        },
        {
            label: "Date for Review and Pay",
            placeholder: "Select Date and Time",
            name: "review_date",
            type: "date"
        },
        // {
        //     label: "One Time Payment Link",
        //     placeholder: "Enter Link for Payment (Please do check the link twice)",
        //     name: "payment_link"
        // },

    ]

    const arr = [<Form1 payload={data} handleForm1={handleForm1} />,
    <Form2 payload={data} handleForm1={handleForm1} />,
    <Form3 payload={data} handleForm1={handleForm1} />,
    <Form4 />]

    useEffect(() => {
        if (load && typeof load === 'object') {
            setData(load)
        }
    }, [load])

    async function handleNext() {
        const userDetails = localStorage.getItem("userDetails");
        const parsedUser = JSON.parse(userDetails);
        const token = parsedUser?.user?.token
        try {


            if (count === 0) {
                localStorage.setItem("pnr_data", JSON.stringify(data));
                for (let dat of inData) {
                    if (!data[dat.name]) {
                        return message.info(`required ${dat.label}`)
                    }
                }
            }
            if (count === 1 && !data['method']) {

                if (!data['travelling_date']) {
                    return message.info('Travel date is required field')
                } else if (!data['package_category']) {
                    return message.info('Package Category is required field')
                } else if (!data['package_type']) {
                    return message.info('Package Range is required field')
                } else if (!data['itinerary_sub_type']) {
                    return message.info('Select Package Type')
                } else if (!data['itinerary']) {
                    return message.info('Select Package')
                } else if (!data['hotels'] && !data['hotels']?.length) {
                    let confirm = window.confirm('continue without adding hotels?')
                    if (!confirm) {
                        return window.scrollTo(0, 700)
                    } else {
                        data['hotels'] = ['2']
                    }

                }

                const res = await axios.post('wft_admin/pnr-create', data, {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                })

                if (res) {
                    localStorage.setItem("pnr_id", res.data.id)
                }
            }
            if (count === 2) {

                if (data['payment_detail'] && data['payment_detail'].length) {
                    // return message.info('Please add at least 1 payment')
                    for (let dat of data['payment_detail']) {
                        if (!dat['payment_cost']) {
                            return message.info(`required payment cost`)
                        } else if (!dat['payment_link']) {
                            return message.info(`required payment link`)
                        } else if (!dat['validity_date']) {
                            return message.info(`required validity date`)
                        }
                    }
                }


                if (data['method']) {
                    const res = await axios.put('wft_admin/pnr-update/' + data.edit, data, {
                        headers: {
                            Authorization: `Token ${token}`,
                        },
                    })

                    if (res) {
                        localStorage.setItem("pnr_id", res.data.id)
                    }
                } else {
                    let pnr_id = localStorage.getItem("pnr_id")
                    const res = await axios.post("wft_admin/payment-detail-create", { complete_payment: data['payment_detail'] || [], pnr_id }, {
                        headers: {
                            Authorization: `Token ${token}`,
                        },
                    })
                }
            }

            (count < 3 || count === 0) && setCount(count + 1)
        } catch (error) {
            message.info(error.response?.data.message || error.message)
        }
    }


    return (
        <div className=''>
            <Steps
                className='custom-steps'
                current={count}
                labelPlacement='vertical'
                responsive
                items={[
                    {
                        description: "Trip Information",
                    },
                    {
                        description: "Package Details",
                    },
                    {
                        description: "Payments",
                    },
                    {
                        description: "Send",
                    },
                ]}
            />

            {arr[count]}
            <div style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
                <Button onClick={() => { (count <= 3 && count > 0) && setCount(count - 1) }}  >Previous</Button>
               {count !== 3 && <Button onClick={() => { handleNext() }} style={{ marginLeft: 20 }}>{ count === 2 ?'Generate PDF':'Next'}</Button> }
            </div>
        </div>
    )
}

export default NewTicket
