import React, { useEffect, useState } from 'react'
import { Nav, Tab } from 'react-bootstrap'
import PP from "./icons8-documents-94-1.png"
import About from "./icons8-male-user-942.png"
import PortfolioImg from "./icons8-pictures-folder-94.png"
import Career from "./icons8-people-942.png"
import Faq from "./icons8-rules-962.png"
import { H4, ImgCard } from './Componets'
import Img from "./Rectangle302.png"
import Portfolio from './Portpolio'
import Careers from './Careers'
import axios from 'axios'


function Company() {

  const [ data, setData ] = useState("")

  useEffect(async()=>{
    axios.get("wft_admin/about-us-list")
    .then(res=>{
      setData(res.data[0])

    }).catch(err=>{
      console.log(err)
    })

  },[])

  const tabData = [
    {
      name: "About Us",
      icon: About,
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit."
    },
    {
      name: "Portfolio",
      icon: PortfolioImg,
      content:
        "Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache cliche tempor.      ",
    },
    {
      name: "Careers",
      icon: Career,
      content:
        "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove.",
    },

    {
      name: "FAQ’s",
      icon: Faq,
      content:
        "Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache cliche tempor.      ",
    },
    {
      name: "T&C",
      icon: PP,
      content:
        "Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache cliche tempor.      ",
    },
    {
      name: "Privacy Policy",
      icon: PP,
      content:
        "Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache cliche tempor.      ",
    },

  ];


  return (
    <div>
      <div className="custom-tab-1">
        <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
          <Nav as="ul" className="nav-tabs">
            {tabData.map((data, i) => (
              <Nav.Item as="li" key={i}>
                <Nav.Link eventKey={data.name.toLowerCase()}>
                  <img style={{ width: 20,marginRight:4, marginTop:-3 }} src={data.icon} />
                  {data.name}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
          <Tab.Content className="pt-4">
            <Tab.Pane eventKey="about us">
              <h4 className='font-bold my-4'>About us</h4>
              <p>{data.about_us}</p>

              <h4 className='font-bold my-4'>Our Story:</h4>
              <p>{data.our_story}</p>

              <h4 className='font-bold my-4'>Our Vision:</h4>
              <p>{data.our_vision}</p>

              <h4 className='font-bold my-4'>{data.column_one}: <span>{data.value_one}</span></h4>
              <h4 className='font-bold my-4'>{data.column_two}: <span>{data.value_two}</span></h4>
              <h4 className='font-bold my-4'>{data.column_three}:  <span>{data.value_three}</span></h4>
              <H4>Our Vision:</H4>
              <p>{data.our_vision}</p>
              <H4>Team of Passion:</H4>
                <p>{data.team_of_passion}</p>
              <div className='row'>
              
              {
                ["Team Member 1", "Team Member 2", "Team Member 3", "Team Member 4"].map((data, i) => {
                  return <ImgCard key={i}
                    title={data}
                  />
                })

              }
              </div>

              <div className='row'>
              
              {
                ["Team Member 1", "Team Member 2", "Team Member 3", "Team Member 4"].map((data, i) => {
                  return <ImgCard key={i}
                    title={data}
                  />
                })

              }
              </div>

              <H4>Quotation: </H4>
              <p>“{data.quotation}”</p>
              <H4>{data.name}</H4>
              <H4>CTO and Founder of HubSpot</H4>

              <H4>Partners Logos:</H4>
              <div style={{display: "flex",}} className='flex gap-4 rounded-xl'>
              <img src={Img} />
              <img src={Img} />
              </div>

            </Tab.Pane>
          </Tab.Content>

          <Tab.Content className="pt-4">
            <Tab.Pane eventKey="portfolio">
              <Portfolio/>
            </Tab.Pane>
            </Tab.Content>

            <Tab.Content className="pt-4">
            <Tab.Pane eventKey="careers">
              <Careers/>
            </Tab.Pane>
            </Tab.Content>
        </Tab.Container>
      </div>
    </div>
  )
}

export default Company
