import React, { useState } from 'react'
import { HeaderComp1, Input } from './Components'
import axios from 'axios';
import { useEffect } from 'react';
import { Radio } from 'antd';

function Form1({ handleForm1, payload }) {

    const [data, setData] = useState({})

   
    let inData = [[
        {
            label: "Customer Name",
            placeholder: "Enter Customer Name",
            name: "customer_name"
        },
        {
            label: "Email ID",
            placeholder: "Enter Customer Email",
            name: "customer_email"
        },
        {
            label: "Phone Number",
            placeholder: "Enter Phone Number",
            name: "customer_contact"
        },
        {
            label: "Lead ID",
            placeholder: "Enter Lead ID (If It’s there)",
            name: "lead_id"
        },
        {
            label: "Destination",
            placeholder: "Select Destination",
            name: "destination"
        },
    ], [
        {
            label: "No of Years of Experience",
            placeholder: "Enter No.Years of Experience",
            type: "number",
            name: "experience_year"
        },
        {
            label: "Handling Trips / Months",
            placeholder: "Enter No.of Trips per month this manger handled ",
            name: "trip_per_month",
            type: "number",
        },
        {
            label: "Manager  Ph.Number",
            placeholder: "Enter Contact Manager’s Ph.Number",
            name: "manager_contact",

        },
        {
            label: "Manager Email ID",
            placeholder: "Enter Contact Manager’s Email ID",
            name: "manager_email"
        },
        {
            label: "Date of this Quotation Created",
            placeholder: "Select Date and Time",
            name: "creation_date",
            type: "date"
        },
        {
            label: "Date of Validity of Quotation ",
            placeholder: "Select Date and Time",
            name: "validity_date",
            type: "date"
        },
    ], [
        {
            label: "No.Hotels Checking-In",
            placeholder: "Enter No.Hotels",
            name: "hotel_count",
            type: "number",
        },
        {
            label: "No of Activities Customer Participate ",
            placeholder: "Enter No.Activities",
            name: "activity_count",
            type: "number",
        },
        {
            label: "No of Sight Seeings Places",
            placeholder: "Enter No.Sight Seeings Places",
            name: "sightseeing_count",
            type: "number",
        },
        {
            label: "No of Transfers during journey",
            placeholder: "Enter No.Transfers",
            name: "transfer_count",
            type: "number",
        },
    ], [
        {
            label: "Total Amount for the trip",
            placeholder: "Enter Total amount for the trip",
            name: "total_amount",
            type: "number",
        },
        {
            label: "No of Travelers Traveling in this trip",
            placeholder: "Enter No.Travelers (Eg: 3 Adults , 1 Child)",
            name: "person_count"
        },
        {
            label: "Date for Review and Pay",
            placeholder: "Select Date and Time",
            name: "review_date",
            type: "date"
        },
        {
            label: "One Time Payment Link ( Optional )",
            placeholder: "Enter Link for Payment (Please do check the link twice)",
            name: "payment_link"
        },

    ]]



    function handleNext() {

        // axios.post("/wft_admin/pnr-create", data)
        // .then(res=>{
        //     console.log(res)
        // })
        // .catch(err=>{
        //     console.log(err);
        // })


    }

    let columns = [];

    for (let i = 0; i < inData.length; i += 1) {
        columns[i] = inData[i].map(({ option, label, placeholder, name, type, disabled }, index) => {
            return (
                <div key={index} className="col-sm">
                    <Input
                        type={type}
                        value={payload[name]}
                        name={name}
                        options={option}
                        onChange={(e) => handleForm1(e)}
                        label={label}
                        placeholder={placeholder}
                        disabled={disabled}
                    />
                </div>
            );
        });
    }

    let rows = [[], [], [], []]


    for (let ind = 0; ind < columns.length; ind += 1) {
        for (let i = 0; i < columns[ind].length; i += 2) {
            rows[ind].push(
                <div key={i + ind} className="row">
                    {columns[ind][i]}
                    {columns[ind][i + 1]}
                </div>
            );
        }

    }

    function handleChecked(e) {
        const { name, value } = e.target

        let val = { target: {}}
        val.target.name = name
        val.target.value = value == 'yes' ? true : false

        handleForm1(val)
    }


    return (
        <div className='m-4'>
            <div>
                <div className="">
                    {rows[0]}
                    <div className='row my-4'>
                        {['candlelight', 'photography', 'flowerbed', "honeymooncake"]
                            .map(dat =>
                                <div className='col-sm'>
                                    <h4>{dat.toLocaleUpperCase()}</h4>
                                    <Radio.Group
                                        name={dat}
                                        onChange={handleChecked}
                                        value={payload[dat]?'yes': 'no'}
                                        className='my-radio'
                                    >
                                        <Radio value='yes' >Yes</Radio>
                                        <Radio value='no' >No</Radio>
                                    </Radio.Group>
                                </div>
                            )}
                    </div>
                    <Input className="" name="visit_place" value={payload['visit_place']} label={"Places Visit"} onChange={(e) => handleForm1(e)} placeholder="Enter No.of places this trip covers" />
                    <hr />
                </div>
                <HeaderComp1 title="Contact Manager" discription="Fill the appropriate manager details who takes care of this Itinerary" />
                <div className='row'>
                    <Input className="col-sm" name="manager_name" value={payload['manager_name']} label={"Manager Name"} onChange={(e) => handleForm1(e)} placeholder="Enter Manager Name" />
                    <div className='col-sm'></div>
                </div>
                <div className="mb-3">
                    {rows[1]}
                </div>
                <hr />

                <HeaderComp1 title="Highlights of trip" discription="Fill the number of enjoyable moments spent during the trip" />

                <div className="mb-3">
                    {rows[2]}
                </div>
                <HeaderComp1 title="Total Cost Including Taxes" discription="Fill the total costing for the trip for how many travelers attach a payment link with review time" />
                <div className="mb-3">
                    {rows[3]}
                </div>
            </div>
        </div>
    )
}

export default Form1
