import React, { useCallback, useEffect, useState } from "react";
import useCreateEditor from "../../components/global/RichEditor/useCreateEditor";
import BlogElement from "./BlogElement";
import InputField from "../../components/global/InputField";
import SevableInputField from "../../components/global/SevableInputField";
import SelectField from "../../components/global/SelectField";
import Button from "../../components/global/Button";
import { generateLocalImageUrl } from "../../../util/util";
import {
  getBlogDetails,
  saveBlog,
  updateBlogDetails,
} from "../../../controller/blogController";
import { HTTPStatus } from "../../../util/helperObj";
import { message } from "antd";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { handleControllerResultException } from "../../../util/handleException";

export default function BlogForm() {
  const history = useHistory();
  const { blogId } = useParams();
  const [authorName, setAuthorName] = useState("");
  const [authorImage, setAuthorImage] = useState({
    image: undefined,
    authorImageUrl: "",
  });
  const [heading, setHeading] = useState("");
  const [firstContent, setFirstContent] = useState({
    image: undefined,
    contentText: "",
    imageUrl: "",
  });

  const [secondContent, setSecondContent] = useState({
    image: undefined,
    contentText: "",
    imageUrl: "",
  });

  const [thirdContent, setThirdContent] = useState({
    image: undefined,
    contentText: "",
    imageUrl: "",
  });
  const [blogStatus, setBlogStatus] = useState("");
  const [tags, setTags] = useState([]);
  const [readTime, setReadTime] = useState("");
  const firstEditor = useCreateEditor(firstContent.contentText);
  const secondEditor = useCreateEditor(secondContent.contentText);
  const thirdEditor = useCreateEditor(thirdContent.contentText);
  const [inputError, setInputError] = useState(false);

  function cheackError() {
    return (
      authorName !== "" &&
      blogStatus !== "" &&
      tags.length > 0 &&
      readTime !== "" &&
      firstContent.imageUrl !== ""
    );
  }

  async function handleSave() {
    if (cheackError()) {
      const res = await saveBlog({
        heading,
        authorName,
        authorImage: authorImage.image,
        readTime,
        tags,
        blogStatus,
        firstContentText: JSON.stringify(firstEditor.getHTML()),
        firstContentImage: firstContent.image,
        secondContentText: JSON.stringify(secondEditor.getHTML()),
        secondContentImage: secondContent.image,
        thirdContentText: JSON.stringify(thirdEditor.getHTML()),
        thirdContentImage: thirdContent.image,
      });
      if (res.statusCode === HTTPStatus.CREATED) {
        message.success(res.success);
        history.goBack();
      } else {
        handleControllerResultException(res, history);
      }
    } else {
      setInputError(true);
    }
  }

  async function handleUpdateBlogDetails() {
    if (cheackError()) {
      const res = await updateBlogDetails({
        id: blogId,
        heading,
        authorName,
        authorImage: authorImage.image,
        readTime,
        tags,
        blogStatus,
        firstContentText: JSON.stringify(firstEditor.getHTML()),
        firstContentImage: firstContent.image,
        secondContentText: JSON.stringify(secondEditor.getHTML()),
        secondContentImage: secondContent.image,
        thirdContentText: JSON.stringify(thirdEditor.getHTML()),
        thirdContentImage: thirdContent.image,
      });
      if (res.statusCode === HTTPStatus.SUCCESS) {
        message.success(res.success);
      } else {
        handleControllerResultException(res, history);
      }
    } else {
      setInputError(true);
    }
  }

  const handleBlogDetails = useCallback(
    async (blogId) => {
      const res = await getBlogDetails(blogId);
      if (res[0] !== null) {
        setHeading(res[0].heading);
        setAuthorName(res[0].authorName);
        setAuthorImage({ authorImageUrl: res[0].authorImageUrl });
        setReadTime(res[0].readTime);
        setBlogStatus(res[0].blogStatus);
        setTags(res[0].tags);
        setFirstContent({
          imageUrl: res[0].firstContentImageUrl,
          contentText: JSON.parse(res[0].firstContentText),
        });
        setSecondContent({
          imageUrl: res[0].secondContentImageUrl,
          contentText:
            res[0].secondContentText !== ""
              ? JSON.parse(res[0].secondContentText)
              : "",
        });
        setThirdContent({
          imageUrl: res[0].thirdContentImageUrl,
          contentText:
            res[0].thirdContentText !== ""
              ? JSON.parse(res[0].thirdContentText)
              : "",
        });
      } else {
        handleControllerResultException(res[1], history);
      }
    },
    [history]
  );

  useEffect(() => {
    if (blogId) {
      handleBlogDetails(blogId);
    }
  }, [blogId, handleBlogDetails]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "3rem",
        }}
      >
        <Button onClick={() => history.goBack()}>Return</Button>

        <Button onClick={blogId ? handleUpdateBlogDetails : handleSave}>
          {blogId ? "Update" : "Save"}
        </Button>
      </div>
      <InputField
        label="Heading"
        inputFieldAttributes={{
          value: heading,
          onChange: (e) => setHeading(e.target.value),
        }}
        stylesProps={{ marginBottom: "1rem" }}
        showInputError={inputError}
      />
      <InputField
        label="Author name"
        inputFieldAttributes={{
          value: authorName,
          onChange: (e) => setAuthorName(e.target.value),
        }}
        stylesProps={{ marginBottom: "1rem" }}
        showInputError={inputError}
      />
      <InputField
        label="Author image (optional)"
        inputFieldAttributes={{
          type: "file",
          onChange: (e) =>
            setAuthorImage({
              authorImageUrl: generateLocalImageUrl(e.target.files[0]),
              image: e.target.files[0],
            }),
        }}
        stylesProps={{ marginBottom: "1rem" }}
      />

      {authorImage.authorImageUrl !== "" && (
        <img
          src={authorImage.authorImageUrl}
          alt="author"
          height="100"
          width="100"
          style={{
            objectFit: "fill",
            borderRadius: "100%",
            marginBottom: "1rem",
          }}
        />
      )}

      <div
        style={{ marginBottom: "1rem", display: "flex", alignItems: "center" }}
      >
        <InputField
          label="Read time (in minute)"
          inputFieldAttributes={{
            type: "number",
            value: readTime,
            onChange: (e) => setReadTime(e.target.value),
          }}
          stylesProps={{ width: "100%" }}
          showInputError={inputError}
        />
        <SelectField
          label="Blog status"
          options={["Live", "Draft", "Archive"]}
          selectFieldAttributes={{
            value: blogStatus,
            onChange: (e) => setBlogStatus(e.target.value),
          }}
          stylesProps={{ width: "100%", marginLeft: "1rem" }}
          showInputError={inputError}
        />
      </div>
      <SevableInputField
        label="Tags"
        savedInputList={tags}
        saveInputFunc={(data) => setTags([...tags, data])}
        deleteInputFunc={(index, value) =>
          setTags(tags.filter((data) => data !== value))
        }
        editInputFunc={(data, index) => {
          let newArr = [...tags];
          newArr[index] = data;
          setTags(newArr);
        }}
        styleProps={{ marginBottom: "3rem" }}
        showInputError={inputError}
      />

      <BlogElement
        heading="First content"
        editor={firstEditor}
        setImage={(image, imageUrl) =>
          setFirstContent({ ...firstContent, image, imageUrl })
        }
        imageUrl={firstContent.imageUrl}
        showInputError={inputError}
      />
      <BlogElement
        heading="Second content (Optional)"
        styles={{ marginTop: "3rem" }}
        editor={secondEditor}
        setImage={(image, imageUrl) =>
          setSecondContent({ ...secondContent, image, imageUrl })
        }
        imageUrl={secondContent.imageUrl}
      />
      <BlogElement
        heading="Third content (Optional)"
        styles={{ marginTop: "3rem" }}
        editor={thirdEditor}
        setImage={(image, imageUrl) =>
          setThirdContent({ ...thirdContent, image, imageUrl })
        }
        imageUrl={thirdContent.imageUrl}
      />
    </div>
  );
}
