import "../reduerStateType";

/**
 * @typedef {object} dayIndexPayload
 * @property {number} day_no -- day_no key in the acitivity object
 * @property {number} index -- index key in the  acitivity object
 */

/**
 * @param {PackageState} state
 * @param {dayIndexPayload} payload
 */

export function moveUpActivityWidget(state, payload) {
  const newScheculeArr = [...state.complete_tour_data];
  const dayArr = newScheculeArr[payload.day_no - 1];
  const activityIndex = payload.index - 1;
  if (activityIndex > 0 && dayArr.length > 1) {
    // swap activity
    const tmp = dayArr[activityIndex];
    dayArr[activityIndex] = dayArr[activityIndex - 1];
    dayArr[activityIndex - 1] = tmp;

    // update index values
    dayArr[activityIndex - 1].index = `${
      parseInt(dayArr[activityIndex - 1].index) - 1
    }`;
    dayArr[activityIndex].index = `${
      parseInt(dayArr[activityIndex].index) + 1
    }`;
  }
  return newScheculeArr;
}

/**
 * @param {PackageState} state
 * @param {dayIndexPayload} payload
 */

export function moveDownAcitivityWidget(state, payload) {
  const newScheculeArr = [...state.complete_tour_data];
  const dayArr = newScheculeArr[payload.day_no - 1];
  const activityIndex = payload.index - 1;
  if (activityIndex < dayArr.length - 1 && dayArr.length > 1) {
    // swap activity
    const tmp = dayArr[activityIndex];
    dayArr[activityIndex] = dayArr[activityIndex + 1];
    dayArr[activityIndex + 1] = tmp;

    // update index values
    dayArr[activityIndex].index = `${
      parseInt(dayArr[activityIndex].index) - 1
    }`;
    dayArr[activityIndex + 1].index = `${
      parseInt(dayArr[activityIndex + 1].index) + 1
    }`;
  }
  return newScheculeArr;
}

/**
 * @param {PackageState} state
 * @param {dayIndexPayload} payload
 */
export function removeWidgetData(state, payload) {
  const newArr = [...state.complete_tour_data];
  const dayIndex = payload.day_no - 1;
  const activityIndex = payload.index - 1;
  newArr[dayIndex].splice(activityIndex, 1);
  // udpate index data in remaining activities
  if (newArr[dayIndex].length > 0) {
    for (let x = activityIndex; x < newArr[dayIndex].length; x++) {
      newArr[dayIndex][x].index = `${parseInt(newArr[dayIndex][x].index) - 1}`;
    }
  }
  return newArr;
}
