import "../reduerStateType";

/**
 * @param {PackageState} state
 * @param {number} index
 */

export function removePackageTourDay(state, index) {
  const newArr = [...state.complete_tour_data];
  newArr.splice(index, 1);
  // change day_no value in all activity inside other schedule
  if (newArr.length > 0) {
    for (let x = index; x < newArr.length; x++) {
      for (let y = 0; y < newArr[x].length; y++) {
        newArr[x][y].day_no = `${parseInt(newArr[x][y].day_no) - 1}`;
      }
    }
  }
  return newArr;
}
