import React, { useEffect } from 'react'
import { HeaderComp1, Input } from './Components'
import { useState } from 'react';
import img5 from "./img5.png"
import { Button, Collapse, Radio, message } from 'antd';
import { MdDelete } from 'react-icons/md';

function Form3({ handleForm1, payload }) {

  let payment = payload?.['payment_detail']?.length ? payload['payment_detail'][0].payment_name : 'partial_payment'

  const [p_mode, setP_Mode] = useState(payment)
  const [count, setCount] = useState(1)
  const [data, setData] = useState(payload?.payment_detail || [])

  const { Panel } = Collapse;

  let inData = [
    {
      label: "Total cost (including taxes)",
      placeholder: "Enter Amount in INR",
      name: "payment_cost"
    },
    {
      label: "Total Cost Payment Link",
      placeholder: "Paste Payment Link",
      name: "payment_link"
    },
    {
      label: "Link Valid Till",
      placeholder: "Select Date and Time",
      type: "date",
      name: "validity_date"
    },

  ]


  function handleChange(e, i) {
    let { name, value } = e.target
    let update = [...data];
    update[i] = { ...update[i], [name]: value, ['payment_name']: p_mode };
    setData(update)
    let val = { target: { name: 'payment_detail', value: update } }
    handleForm1(val)
  }

  function handleDelete(i) {

    if (data.length > 1) {
      let update = data.slice(0, i).concat(data.slice(i + 1));
      setData(update);
      let val = { target: { name: 'payment_detail', value: update } }
      handleForm1(val)
    } else {
      // message.info(`At least 1 payment is required`)
      setData([{
         payment_cost:'',
         payment_link:'',
         validity_date:''
      }
       
      ]);
      let val = { target: { name: 'payment_detail', value: [] } }
      handleForm1(val)
    }
  }

  let columns = [];

  for (let i = 0; i < count; i += 1) {
    columns[i] = inData.map(({ label, placeholder, name, type }, index) => {
      return (
        <div key={index} className={(index % 3 === 1) ? "col-sm-6" : "col-sm-3"}>
          <Input
            value={data[i]?.[name]}
            type={type}
            name={name}
            onChange={(e) => handleChange(e, i)}
            label={label}
            placeholder={placeholder}
          />
          <div className='d-flex justify-content-end m-2'>
            {(index === 2) && <MdDelete onClick={()=>handleDelete(i)} style={{ cursor:'pointer', fontSize:20, animationDuration:'300'}} />}
          </div>
        </div>
      );
    });
  }

  let rows = []


  for (let i = 0; i < columns.length; i += 3) {
    rows.push(
      <div key={i} className="row">
        {columns[i]}
        {columns[i + 1]}
        {columns[i + 2]}
      </div>
    );
  }

  useEffect(() => {
    const len = payload?.['payment_detail']?.length
    setCount(len ? len : 1)
  }, [data])



  // wft_admin_payment-detail-create_create


  return (
    <div className='container mb-4'>
      <HeaderComp1 title="Payment Details" discription="Fill Payment details according to the options" />
      <h2 style={{ display: "flex", justifyContent: "space-between" }}>Select Payment Mode : {p_mode === "partial_payment" && (count < 5) && <Button onClick={() => setCount(count + 1)} style={{ color: "white", background: "black", marginTop: 10 }}>+ Another Option</Button>}</h2>
      <Radio.Group name='payment_name' value={p_mode} onChange={(e) => { setP_Mode(e.target.value); }}>
        <Radio value="full_payment">Full Payment</Radio>
        <Radio value="partial_payment">Partial Payment</Radio>
      </Radio.Group>
      <hr />
      {rows}
      <div className='d-flex justify-content-center'>
        <img src={img5} />
      </div>
    </div>
  )
}

export default Form3
