import React from 'react'
import folder from "./folder.svg"

export function DaySelector({ dn,handleClick }) {
  return (
    <div role="button" style={{boxShadow:"0px 0px 1px gray",paddingRight:15,paddingLeft:15,cursor:"pointer", borderRadius:5, opacity:dn === "Add Folder" && .5 }} onClick={()=>handleClick && handleClick(dn)} className='d-flex gap-2 align-items-center'>
      <img src={folder} style={{widows:30,height:30, }} />
      <h3 style={{paddingTop:10}}>{dn}</h3>
    </div>
  )
}

 
