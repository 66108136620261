/**
 * @returns {boolean } Returns boolean or null
 * - boolean represents if the user is admin or not
 *  - the value will return null if the userDetails not exists in localstorage
 */
export function adminConfirmation() {
  const userDetails = localStorage.getItem("userDetails");
  if (userDetails) {
    const res = JSON.parse(userDetails);
    return res.user.profile["is_admin"];
  } else {
    return false;
  }
}
