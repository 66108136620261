import React, { useEffect, useState } from "react";
import { fetchActivityNameList } from "../../../controller/PackageController";
import { message } from "antd";
import componentStyle from "../../../css/components.module.css";
import layoutStyle from "../../../css/layout.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  addPackageActivity,
  removePackageActivity,
} from "../../../store/actions/PackageAction";

export default function ActivitySelector({ showInputError = false }) {
  const [activityList, setActivityList] = useState([]);
  const [selectActivity, setSelectActivity] = useState("");
  const { activity } = useSelector((state) => state.package);
  const dispatch = useDispatch();

  const fetchActivityHandler = async () => {
    const res = await fetchActivityNameList();
    if (Array.isArray(res)) {
      setActivityList(res);
    } else {
      message.error(res.error);
    }
  };

  useEffect(() => {
    fetchActivityHandler();
  }, []);

  const selectActivityHandler = (activity) => {
    if (activity !== "") {
      dispatch(addPackageActivity(activity));
    }
    setSelectActivity(activity);
  };

  return (
    <div className={layoutStyle.flexColStart} style={{ marginTop: "20px" }}>
      <label className={componentStyle.label}>Activity List</label>
      <div style={{ display: "flex", marginBottom: "10px", flexWrap: "wrap" }}>
        {activity.length > 0 &&
          activity.map((data, index) => (
            <div
              style={{
                margin: "0px 5px",
                padding: "2px 6px",
                borderRadius: "50px",
                color: "#212121",
                background: "#ecaa63",
              }}
              key={index}
            >
              {data}{" "}
              <span
                onClick={() => dispatch(removePackageActivity(data))}
                role="button"
                style={{ marginLeft: "8px" }}
              >
                &times;
              </span>
            </div>
          ))}
      </div>
      <select
        className={componentStyle.inputField}
        value={selectActivity}
        onChange={(e) => selectActivityHandler(e.target.value)}
      >
        <option>select activity list</option>
        {activityList.length > 0 &&
          activityList.map((data, index) => (
            <option key={index} value={data}>
              {data}
            </option>
          ))}
      </select>
      {showInputError && activity.length === 0 ? (
        <p style={{ color: "red" }}>Please select activities</p>
      ) : (
        <></>
      )}
    </div>
  );
}
