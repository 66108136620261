import React from "react";
import layoutStyles from "../../../css/layout.module.css";

/**
 * @typedef {object} Props
 * @property {React.CSSProperties} modalStyles
 * @property {()=>void} modalCloseAction
 * @property {JSX.Element} children
 */

/**
 * @param {Props} props
 */
export default function ModalLayout({
  modalStyles,
  modalCloseAction,
  children,
}) {
  return (
    <>
      <div
        className={layoutStyles.centerFixed}
        style={{
          padding: "2.5rem",
          borderRadius: "8px",
          zIndex: "50",
          background: "#FFFFFF",
          ...modalStyles,
        }}
      >
        {children}
      </div>
      <div
        style={{
          width: "100vw",
          height: "100vh",
          position: "fixed",
          top: "0",
          left: "0",
          zIndex: "40",
          background: "#212121",
          opacity: "0.3",
        }}
        onClick={modalCloseAction}
      ></div>
    </>
  );
}
