import "../model/typeDefs";
import { HttpResponse } from "../model/HttpResponse";
import axios from "axios";
import { TeamMemberModel } from "../model/TeamMemberModel";
import { handleException } from "../util/handleException";

const teamMemberAPI = "/wft_admin/team-list";
const teamMemberCreateAPI = "/wft_admin/team-invite-create";
const teamMemberStatusAPI = "/wft_admin/team-status-change";
const teamMemberUpdateAPI = "/wft_admin/team-update";
const assignTeamMemberAPI = "/wft_admin/assign-member";

/**
 *
 * @returns {Promise<TeamMemberListOrError>}
 */

export async function getTeamMembers() {
  const userDetails = localStorage.getItem("userDetails");
  /**
   * @type {TeamMemberListOrError}
   */
  const result = [];
  if (userDetails) {
    try {
      const parsedUser = JSON.parse(userDetails);
      const token = parsedUser["user"]["token"];
      /**
       * @type {TeamMemberModel[]}
       */
      const teamMemberArr = [];

      const res = await axios.get(teamMemberAPI, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      for (let data of res.data) {
        const teamMember = new TeamMemberModel(data);
        teamMemberArr.push(teamMember);
      }
      result[0] = teamMemberArr;
    } catch (err) {
      result[1] = new HttpResponse(
        err.response.status,
        null,
        err.response.statusText
      );
    }
  } else {
    result[1] = new HttpResponse(401, null, "Unauthorized");
  }

  return result;
}

/**
 * @typedef {Object} TeamMemberProps
 * @property {string} name
 * @property {string} email
 * @property {string} password
 * @property {string} designation
 * @property {string} accessType
 * @property {File | null} profileImage
 * @property {string} joinDate
 */

/**
 * @param {TeamMemberProps} props
 * @returns {Promise<HttpResponse>}
 */

export async function saveTeamMember({
  name,
  email,
  password,
  accessType,
  profileImage,
  joinDate,
  designation,
}) {
  const userDetails = localStorage.getItem("userDetails");
  if (userDetails) {
    try {
      const parsedUser = JSON.parse(userDetails);
      const token = parsedUser["user"]["token"];
      const formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("password", password);
      formData.append("access_type", accessType);
      (profileImage !== null) | undefined &&
        formData.append("profile_image", profileImage);
      formData.append("join_date", joinDate);
      formData.append("designation", designation);
      const res = await axios.post(teamMemberCreateAPI, formData, {
        headers: {
          Authorization: `Token ${token}`,
          "content-type": "multipart/form-data",
        },
      });
      return new HttpResponse(res.status, "saved successful", null);
    } catch (err) {
      return handleException(err);
    }
  } else {
    return new HttpResponse(401, null, "Unauthorized");
  }
}

/**
 *
 * @param {number} id
 * @returns {Promise<HttpResponse>}
 */

export async function changeTeamMemberStatus(id) {
  const userDetails = localStorage.getItem("userDetails");
  if (userDetails) {
    try {
      const parsedUser = JSON.parse(userDetails);
      const token = parsedUser["user"]["token"];
      const res = await axios.get(`${teamMemberStatusAPI}/${id}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      return new HttpResponse(res.status, res.data.message, null);
    } catch (err) {
      if (err.response.status === 400) {
        return new HttpResponse(
          err.response.status,
          null,
          "Please provide all information"
        );
      } else {
        return new HttpResponse(
          err.response.status,
          null,
          err.response.statusText
        );
      }
    }
  } else {
    return new HttpResponse(401, null, "Unauthorized");
  }
}

/**
 * @typedef {object} updateTeamProps
 * @property {number} id;
 * @property {string} name;
 * @property {string} designation;
 * @property {string} accessType;
 * @property {File} profileImage;
 * @property {joinDate} joinDate;
 */

/**
 * @param {updateTeamProps} props
 * @returns {Promise<HttpResponse>}
 */
export async function updateTeamMemberInfo({
  id,
  name,
  designation,
  accessType,
  profileImage,
  joinDate,
}) {
  const userDetails = localStorage.getItem("userDetails");
  if (userDetails) {
    try {
      const parsedUser = JSON.parse(userDetails);
      const token = parsedUser["user"]["token"];
      const formData = new FormData();
      formData.append("name", name);
      formData.append("access_type", accessType);
      formData.append("image", profileImage);
      formData.append("join_date", joinDate);
      formData.append("designation", designation);
      const res = await axios.put(`${teamMemberUpdateAPI}/${id}`, formData, {
        headers: {
          Authorization: `Token ${token}`,
          "content-type": "multipart/form-data",
        },
      });
      return new HttpResponse(res.status, "update successful", null);
    } catch (err) {
      if (err.response.status === 400) {
        return new HttpResponse(
          err.response.status,
          null,
          "Please provide all information"
        );
      } else {
        return new HttpResponse(
          err.response.status,
          null,
          err.response.statusText
        );
      }
    }
  } else {
    return new HttpResponse(401, null, "Unauthorized");
  }
}

/**
 * @param {string} userId
 * @param {string} guestId
 * @returns {Promise<HttpResponse>}
 */

export async function assignTeamMeber(userId, guestId) {
  const userDetails = localStorage.getItem("userDetails");
  if (userDetails) {
    try {
      const parsedUser = JSON.parse(userDetails);
      const token = parsedUser["user"]["token"];
      const res = await axios.post(
        `${assignTeamMemberAPI}/${guestId}`,
        {
          assign_to: parseInt(userId),
        },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      return new HttpResponse(res.status, res.data.message, null);
    } catch (err) {
      return new HttpResponse(
        err.response.status,
        null,
        err.response.statusText
      );
    }
  } else {
    return new HttpResponse(401, null, "Unauthorized");
  }
}
