import React, { useEffect, useState } from 'react'
import { H4 } from '../Componets'
import axios from 'axios'
import { Space, Table, Tag } from 'antd';
import moment from 'moment/moment';

function Portfolio() {

  const [ data, setData ] = useState("")
  const [ table, setTable ] = useState("")
  useEffect(async()=>{
    axios.get("wft_admin/portfolio-list")
    .then(res=>{
      setData(res.data[0])

    }).catch(err=>{
      console.log(err)
    })
    
    setTable((await axios.get("wft_admin/portfolioimage-list"))?.data || []) 

  },[])


  const columns = [
    {
      title: 'File Name',
      dataIndex: 'file_name',
      key: 'file_name',
      render: (img_link) => <div className=''><img  src={img_link} style={{width:30, height:30,borderRadius:5}} /></div>,
    },
    {
      title: 'Title of Image',
      dataIndex: 'title_of_image',
      key: 'title_of_image',
      render: (t)=><div style={{color: "#A5A5A5"}}>{t}</div>
    },
    {
      title: 'Date',
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: (d)=><div style={{color: "#A5A5A5"}}>{moment(d).format("DD/MM/YYYY")}</div>
    },
    {
      title: 'File SIze',
      key: 'image_size',
      dataIndex: 'image_size',
      render: (s)=><div >{(s/1024).toFixed(1)}{" "} MB</div>
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <div>
          <a style={{fontSize:20, fontWeight:"bolder",color:"#A5A5A5"}} >...</a>
        </div>
      ),
    },
  ];


  return (
    <>
      <H4>Portfolio:</H4>
      <p>{data.description}</p>
      <a href={data.link_video} target='_blank' style={{color:"blueviolet"}} className='text-blue-400'>{data.link_video}</a>
      <H4>{data.title}</H4>
      <p>Describe:  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
      <H4>{data.column_one}: <span>{data.value_one}</span></H4>
      <H4>{data.column_two}: <span>{data.value_two}</span></H4>
      <H4>{data.column_three}: <span>{data.value_three}</span></H4>

      <div className='p-2 d-grid gap-6'>
        <div className='d-flex justify-content-between'>
          <h1 className=''>Best Picture from clients</h1>
          <button style={{backgroundColor:"#41C5F2", color:"white", borderRadius:10, borderColor:"#41C5F2"}} className='py-2 px-4 rounded-xl' >+ New Image</button>

        </div>


        <Table columns={columns} dataSource={table} />


      </div>

    </>
  )
}

export default Portfolio




