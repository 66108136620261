import React, { useEffect, useState } from "react";
import componentStyles from "../../../css/components.module.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { addNewActivityDay } from "../../../store/actions/PackageAction";
import "../../../store/reducers/packagereducer/reduerStateType";
import DayScheduleCard from "../../components/package/DayScheduleCard";
import { convertPackageDataObjToServerObj } from "../../../util/handlePackageDataFormat";
import {
  savePackage,
  updatePackage,
} from "../../../controller/PackageController";
import { HTTPStatus } from "../../../util/helperObj";
import { message } from "antd";

/**
 * @param {number} days
 */

export default function CreateDayActivity() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  /**
   * @type {PackageState}
   */
  const packageObj = useSelector((state) => state.package);

  async function handleSave() {
    setLoading(true);
    const serverObj = convertPackageDataObjToServerObj(packageObj);
    setLoading(false);
    const res = await savePackage(serverObj);
    if (res.statusCode === HTTPStatus.CREATED) {
      message.success(res.success);
      history.push("/custom-packages");
    } else {
      message.error(res.error);
    }
  }

  async function handleUpdate() {
    setLoading(true);
    const resultObj = convertPackageDataObjToServerObj(
      packageObj,
      packageObj.isCustomized
    );
    setLoading(false);
    const res = await updatePackage(resultObj, resultObj.id);
    if (res.statusCode === HTTPStatus.SUCCESS) {
      message.success(res.success);
      packageObj.isCustomized
        ? history.push("/custom-packages")
        : history.push("/packages");
    } else {
      message.error(res.error);
    }
  }

  useEffect(() => {
    if (!packageObj.isBasicDataFilled) {
      history.goBack();
    }
  }, [packageObj.isBasicDataFilled, history]);

  function saveButtonName() {
    if (packageObj.isUpdate) {
      return loading ? "Updaing..." : "update";
    } else {
      return loading ? "Saving..." : "save";
    }
  }

  return (
    <div>
      <button
        className={componentStyles.btnPrimary}
        onClick={() => history.goBack()}
        disabled={loading}
      >
        <span style={{ marginRight: "4px" }}>&#x2190;</span> Previous
      </button>
      <div>
        <div
          style={{
            marginTop: "4rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <button
            className={componentStyles.btnPrimary}
            onClick={() => dispatch(addNewActivityDay())}
            disabled={loading}
          >
            <span style={{ marginRight: "4px" }}>&#43;</span> Add a day
          </button>
          <button
            className={componentStyles.btnPrimary}
            onClick={packageObj.isUpdate ? handleUpdate : handleSave}
            disabled={loading}
          >
            {loading ? (
              `...${saveButtonName()}`
            ) : (
              <span> &#x2713; {saveButtonName()}</span>
            )}
          </button>
        </div>
      </div>
      <div style={{ marginTop: "2rem" }}>
        {packageObj.complete_tour_data.length > 0 &&
          packageObj.complete_tour_data.map((data, index) => (
            <DayScheduleCard key={index} day={index + 1} />
          ))}
      </div>
    </div>
  );
}
