import React from "react";
import "../../../store/reducers/packagereducer/reduerStateType";
import { useSelector } from "react-redux";
import CarTravelWidget from "./cartravel/CarTravelWidget";
import FerryTravelWidget from "./ferryTravel/FerryTravelWidget";
import SightSeenWidget from "./sightseen/SightSeenWidget";
import FlightTravelWidget from "./flighTravel/FlightTravelWidget";

/**
 * @typedef {object} Props
 * @property {number} dayNo
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setShowCarTravelFormModal
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setShowFerryTravelFormModal
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setShowSightSeenFormModal
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setShowFlightTravelFormModal
 */

/**
 * @param {Props} props
 */
export default function ScheduleWidgetList({
  dayNo,
  setShowCarTravelFormModal,
  setShowFerryTravelFormModal,
  setShowSightSeenFormModal,
  setShowFlightTravelFormModal
}) {
  /**
   * @type {PackageState}
   */
  const { complete_tour_data } = useSelector((state) => state.package);

  /**
   * @param {DayScheduleWidget} widget
   * @param {number} key
   */
  function renderWidget(widget, key) {
    if (widget.activity_type === "travel_car") {
      return (
        <CarTravelWidget
          key={key}
          widgetData={widget}
          widgetCardContainerStyle={{ margin: "20px 0px" }}
          setShowCarTravelFormModal={setShowCarTravelFormModal}
        />
      );
    }
    if (widget.activity_type === "travel_ferry") {
      return (
        <FerryTravelWidget
          key={key}
          widgetData={widget}
          widgetCardContainerStyle={{ margin: "20px 0px" }}
          setShowFerryTravelFormModal={setShowFerryTravelFormModal}
        />
      );
    }
    if (widget.activity_type === "sightseeing") {
      return (
        <SightSeenWidget
          key={key}
          widgetData={widget}
          widgetCardContainerStyle={{ margin: "20px 0px" }}
          setShowSightSeenFormModal={setShowSightSeenFormModal}
        />
      );
    }
    if (widget.activity_type === "travel_flight") {
      return (
        <FlightTravelWidget
          key={key}
          widgetData={widget}
          widgetCardContainerStyle={{ margin: "20px 0px" }}
          setShowFlightTravelModal={setShowFlightTravelFormModal}
        />
      );
    }
  }

  return (
    <div>
      {complete_tour_data[dayNo - 1].length > 0 &&
        complete_tour_data[dayNo - 1].map((widget, index) =>
          renderWidget(widget, index)
        )}
    </div>
  );
}
