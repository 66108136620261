/**
 * @typedef {object} SightseenModel
 * @property {number} id
 * @property {string} sightseenName
 * @property {string} sightseenLocation
 * @property {string} sightseenDescription
 *  @property {string} sightseenDescriptionPdf
 * @property {string} sightseenImageLink
 * @property {string} sightseenLowResImageLink
 */

/**
 * @param {any} data
 * @returns {SightseenModel}
 */
export function createSightseenObj(data) {
  /**
   * @type {SightseenModel}
   */
  const result = {
    id: data.id,
    sightseenName: data.name !== null ? data.name : "",
    sightseenLocation: data.location !== null ? data.location : "",
    sightseenDescription:
      data.sightseeing_description !== null ? data.sightseeing_description : "",
    sightseenDescriptionPdf:
      data.pdf_description !== null ? data.pdf_description : "",
    sightseenImageLink: data.image !== null ? data.image : "",
    sightseenLowResImageLink: data.pdf_image !== null ? data.pdf_image : "",
  };
  return result;
}
