import React, { forwardRef, useEffect, useState } from "react";
import {
  ActivityFilter,
  ActivityFilter2,
  DateCard,
  HeaderComp1,
} from "./Components";
import axios from "axios";
import car from "./car.png";
import resort from "./resort.png";
import logo from "./logo.png";
import logo2 from "./logo2.png";
import hi from "./hi.png";
import h1 from "./h1.png";
import h2 from "./h2.png";
import h3 from "./h3.png";
import moment from "moment/moment";
import { message } from "antd";
import { convertPriceToIndFormat } from "../../../util/util";

const Print = forwardRef((props, ref) => {
  const [data, setData] = useState(null);
  const [data2, setData2] = useState([]);
  useEffect(() => {
    axios
      .get(
        "/wft_admin/pnr-detail/" +
          localStorage.getItem("pnr_id")
      )
      .then((resp) => {
        let obj = resp.data;
        setData(obj);

        let label = {
          candlelight: "Candlelight Dinner",
          photography: "Photography",
          flowerbed: "Flower Bed",
          honeymooncake:"Honeymoon cake"
        };
        let arr = ["candlelight", "flowerbed", "photography", "honeymooncake"]
          .filter((dat) => obj[dat])
          .map((dat, i) => (dat ? label[dat] : null));
        setData2(arr);

        // setRes(resp.data?.itinerary_detail?.tour_detail ||[])
      })
      .catch((err) => {
        message.info(err.response?.data.message || err.message);
        console.log(err);
      });
  }, []);

  let contests = [
      "your Itinerary",
      "Day Wise Details",
      "Payment Details",
      "Instructions and policies",
    ],
    hts = [h1, h2, h3, car];

  const container = {
    borderWidth: 2,
    borderColor: "#DEDEDE",
    borderRadius: 10,
    borderStyle: "solid",
    padding: 10,
    marginTop: 25,
  };

  const date = data?.travelling_date;

  const bold = { fontWeight: 500 };

  const MyComp = ({ heading, desp }) => {
    return (
      <div className="margin-top-2">
        <h3 style={{ fontWeight: 600 }}>{heading}</h3>
        {desp.map((dat, i) => (
          <p style={{ pageBreakInside: i && "avoid" }} className="p margin-top">
            {dat}
          </p>
        ))}
      </div>
    );
  };

  const cancelPolicy = [
    {
      heading: "CONTRACT",
      desp: [
        `All our bookings are tailored to your requirements and so bookings (hotel/flights etc) are subject to availability. Before a booking is confirmed, you will receive a confirmation email where you will be inviting you to view full details of your proposed trip.`,

        `Once you have confirmed to us that you happy with the proposal, we will proceed to take payment and issue you with a booking confirmation. Please check that all names are the same as the relevant identity cards, that dates and timings and all other elements of your trip are correct. Do let us know for any apparent discrepancies in the confirmed arrangements. Hereby you will be responsible for any additional charges that may arise as a result of alterations that have to be made to the booking.`,

        `A binding contract between us and you (the lead name on the booking) is only formed when we issue you with our booking confirmation after having received advance payment from you. By making the payment you accept that you have the authority to bind all members of your party to these terms and conditions of booking and you take responsibility as the lead name on the booking to make payment and to receive documentation on behalf of your party.`,
      ],
    },

    {
      heading: "PRICE AND PAYMENT",
      desp: [
        `We reserve the right to alter the prices of any trips shown on our website or set out in our written proposal to you at any time before your booking is confirmed. You will be advised of the current price before your booking is confirmed.`,
        `The price of your experience is inclusive of taxes and where applicable, all additional fees and charges and other costs. Where any costs cannot be reasonably calculated by us in advance of the conclusion of your contract, we will give you an indication at the time of booking of the type of additional costs which you may still have to bear. You will be responsible for any bank charges that may be incurred due to your payment to us.`,
        `Where bookings include flights with airlines that require full payment in advance of booking, the full price of the flight(s) will be included in the booking deposit and will be chargeable in the event of cancellation as a cancellation fee.`,

        `In all cases, a booking will not be confirmed until we are in receipt of cleared funds as mentioned to you at the time of booking.`,
      ],
    },
    {
      heading: "HEALTH FORMALITIES",
      desp: [
        `Not all trips and activities are appropriate for persons with a disability, reduced mobility or medical condition or allergies of any sort. If you or any member of your party have any disability, reduced mobility or medical condition or allergies of any sort, it is extremely important that you tell us before you book in order that we can ensure that the travel experience you are interested in is appropriate to and safe for your needs and make the necessary arrangements to make your experience go safely and smoothly.`,

        `All health and mobility information will only be used in order to advise you in relation to your booking and for passing to suppliers of your travel experience in accordance with our Privacy Policy. Occasionally some of our trips or certain activities may have minimum or maximum age restrictions. This is usually for safety and insurance purposes. Where they do, we may need to ask the ages of your party to confirm whether the trip/activity is appropriate.`,
      ],
    },
    {
      heading: "INFORMATION",
      desp: [
        `Whilst we make every effort to ensure that the information on our website is accurate and not misleading, it may have been published many months before your travel experience takes place and may be subject to change. We reserve the right to make changes to the website and any information it contains at any time.`,
      ],
    },
    {
      heading: "ASSISTANCE",
      desp: [
        `Whether or not we are liable for compensation, we will always provide you with appropriate assistance without undue delay if you are in difficulty. This will include providing appropriate information on health services, local authorities and consular assistance, assisting you to make distance communications and helping you to find alternative travel arrangements. We may charge you a reasonable fee for such assistance if the difficulty is caused intentionally by you or through your negligence.`,
      ],
    },
    {
      heading: "JURISDICTION",
      desp: [
        `This contract is governed and construed in accordance with the laws of Mumbai, Maharashtra (state) applicable to contracts made and to be wholly performed within such state, without giving effect to any conflict of law’s provisions thereof. The Federal and state courts located in Mumbai, Maharashtra (state) shall have sole and exclusive jurisdiction over any disputes arising under, or in any way connected with or related to, the terms of this Agreement.`,
      ],
    },
    {
      heading: "AMENDMENT, TRANSFERS AND CANCELLATION BY YOU",
      desp: [
        `Any cancellation or amendment request must be sent to us in writing to our postal address or by email to hello@wayfindtrips.com and will not take effect until received by us.`,
      ],
    },
    {
      heading: "Amendment",
      desp: [
        `If after our booking confirmation has been issued, you wish to change your travel arrangements in any way, we will do our best to assist you in amending your arrangements after booking, but, we cannot guarantee that this will always be possible. Any request for changes must be in writing from the person who made the booking. You may be asked to pay an administration charge of INR 1500 + GST in addition to the costs we incur in making those amendments. If we are unable to make the amendments and you decide to cancel, we may require you to pay us cancellation charges as mentioned below.`,
      ],
    },
    {
      heading: "Transfers",
      desp: [
        `You may transfer your booking to another person who satisfies all the conditions applicable to your travel contract.  We must receive at least 10 days’ written notice from you in order to make such a transfer. Any additional fees, charges or other costs incurred as a result of transferring your booking will be passed on to you with these costs being advised to you before we effect any transfer. We will also charge an amendment fee of INR 1000 + GST for any transfer. You and the person to whom you transfer your booking shall be jointly liable to us for the payment of the balance due and for any such additional fees, charges or other costs.`,
      ],
    },
    {
      heading: "Cancellation",
      desp: [
        `You, or any member of your party, may cancel your travel arrangements at any time before the start of the package. The cancellation will take effect from the date that we receive written notification of cancellation from you. Cancellations may incur cancellation charges depending on how much notice you give us prior to the departure date.`,
      ],
    },
  ];

  const terms = [
    {
      heading: "1. Hotel Check-in & Check-out:",
      desp: [
        `The Check in and check out time of the hotel is 1200 hrs and 0900 hrs at Port Blair/Havelock/ Neil Island; stay beyond the check-out time is purely at the discretion of the hotels. (In Havelock, there may be a time gap between check out and ferry timings, guests are advised to leave their luggage in the hotel and venture out for some optional activity till the ferry timing). For early check in and late check out the payment is to be settled directly by the guest.`,
      ],
    },
    {
      heading: "2. Breakfast & Meals:",
      desp: [
        `The standard time for breakfast in restaurants across Andaman is from 7:15am onwards to 10:00am. Packed breakfast is given to the guest from the hotel in case they leave the hotel in early morning. Numbers of meals are always corresponding to the number of nights booked. Breakfast is not provided on the day of arrival. The breakfast on the first day is given on the next day morning.`,
      ],
    },
    {
      heading: "3. Booking Confirmation:",
      desp: [
        `Hotels once confirmed cannot be changed. Guests are requested to check the reviews, pictures and hotel websites before confirming the hotel and advise any change at the time of booking confirmation. The itinerary is fixed and cannot be modified.Suggested hotels are subject to availability at the time of booking. In case the same is not available, then a similar category hotel will be provided.`,
      ],
    },
    {
      heading: "4. Hotel Policies",
      desp: [
        `A maximum of 3 adults are allowed in one room. The third occupant shall be provided a mattress/rollaway bed. Children between (0 – 5yrs) are generally considered complimentary. Children between (5-12 yrs) are charged on basis of child with/without extra mattress basis. Children above 12yrs are considered adults.`,
      ],
    },
    {
      heading: "5. Transportation & Services:",
      desp: [
        `Transportation shall be provided as per the itinerary and will not be at disposal. The vehicle used (A/C or Non A/C) is available for point to point services only and is not at disposal. Guests are requested to follow the timings in the program. Dinner/Shopping trips will be chargedINR 350 extra (depending on distance).Smoking and drinking is not allowed in the vehicle. Guest are suggested to carry towel from hotel if they wish to go for swim. Siting with drenched cloths are not allowed inside car.`,
      ],
    },
    {
      heading: "6. Change in Itinerary:",
      desp: [
        `Wayfind Trips Pvt. Ltd reserves the rights to re-arrange itinerary to suit hotel availability without changing the total number of nights in each destination and without compromising any services.due to reasons including but not limited to: Force Majeure events, strikes, fairs, festivals, weather conditions, traffic problems, overbooking of hotels / flights, cancellation / re- routing of flights, closure of /entry restrictions at a place of visit, etc. While we will do our best to make suitable alternate arrangements, we would not be held liable for any refunds/compensation claims arising out of this.`,
      ],
    },
    {
      heading: "7. Ferry Changes & Cancellations:",
      desp: [
        `Ferry timings are subject to change at the government's discretion, and we assume no liability for disruption in the tour owing to climatic conditions or any such events beyond our control. Rest assured, we will do our best to implement your itinerary to the fullest and provide you with commensurate back-up options. The cost of the same will need to be borne by the guest. Ferry services & timings are subjected to weather condition. Ferry Operation is purely subjected to Govt. clearance.In case the Private ferries could not be booked, the tickets will be transferred to Govt ferries. In case of any reschedule or change of destination, the travelling guest have to bear the cost or chose another itinerary that suffices their need. The refund for cancellation depends on the advance paid during the time of booking. Passengers furnishing incorrect age details may incur penalty at the time of travelling NOTE: All packages that include ferry tickets are of the base category seats of the ferry/vessel.`,
      ],
    },
    {
      heading: "8. Speedboat & Services:",
      desp: [
        `The boats to places of visit are managed by a boat association of respective places who need individual guest to fill a consent form after which boats are allotted based on rotation and sharing basis. Due to limited boats guest may have to wait until their boat is allotted and we have no control over the wait time.`,
      ],
    },
    {
      heading: "9. Travel Documents:",
      desp: [
        `Travel documents used during the time of booking needs to carried in original during the time of visit. Id with photo and address are only considered as valid id proof. It is needed to be carried in hard copy during boarding ferries and places of visit such as cellular jail. It is mandatory to carry the age proof of children and infant (below 02yrs) along with other travel documents. Child without address proof shall be considered for an adult cost.`,
      ],
    },
    {
      heading: "10. Timing & Opening:",
      desp: [
        `Entry to Cellular jail and Museum closes by 1600hrs and light n sound show is open every day 5:20hrs.
        Light n sound is subjected to weather conditions and tickets issued are non-refundable by Andaman & Nicobar administration.
        For English show are only on Mondays, Wednesdays & Fridays.
        Baratang trips are done in early morning 3:30am onwards. Guest are advised to be prepared and be ready as per scenario.`,
      ],
    },
    {
      heading: "11. Activities & Refund:",
      desp: [
        `For any paid activity booked through us and which is non-operational due to any unforeseen reason, we will process refund & same should reach the guest within 7 days of processing the refund. Cancellation of any place of visit 4 hours prior to the time of visit will not be refunded as the vehicle was booked for the day for the same trip. For any activity which is complimentary and not charged to Wayfind trips & guest, no refund will be processed.`,
      ],
    },
    {
      heading: "12. Extra Expenses:",
      desp: [
        `The package price doesn’t include special dinner or mandatory charges at time levied by the hotels especially during New Year and Christmas or any special occasions. The same will be intimated to the traveling guest by Wayfind travel experts during their date of travel. The booking price does not include: Expenses of personal nature, such as laundry, telephone calls, room service, alcoholic beverages, mini bar charges, tips, portage, camera fees etc.`,
      ],
    },
    {
      heading: "13. Challenges of Andaman:",
      desp: [
        `Due to restriction on new vehicles on the islands by State Transport Department Of Andaman, you may be provided relatively older vehicles for transfers/sightseeing.`,
      ],
    },
  ];

  return (
    <div>
      {data && (
        <div ref={ref} className="print">
          <div style={{ marginBottom: 20 }}>
            <div
              style={{
                marginBottom: 30,
                position: "relative",
                display: "flex",
                justifyContent: "space-between",
                marginRight: 80,
              }}
            >
              <img src={logo} style={{ width: 150, height: 50 }} />
              {/* <img src={logo2} style={{ width: 300, height: 60 }} /> */}
            </div>
            <div>
              <h4>Itinerary Preview:</h4>
              <h4>
                Ticket ID:{" "}
                <span style={{ color: "#55b3e5" }}>{data.pnr_no}</span>
              </h4>
              <p>
                To see this Itinerary visit “Tickets” section and search with
                the ticket ID{" "}
              </p>

              <div>
                <p
                  style={{
                    fontSize: 20,
                    display: "flex",
                    gap: 10,
                    alignItems: "center",
                  }}
                >
                  <img src={hi} style={{ width: 40 }} />
                  Hi{" "}
                  <span
                    style={{ fontSize: 20, fontWeight: 700, color: "black" }}
                  >
                    {data.customer_name}
                  </span>
                </p>
                <p>
                  Your itinerary is ready for{" "}
                  <span style={{ fontWeight: 500 }}>
                    {data.destination} trip
                  </span>{" "}
                  on <strong>{data.creation_date}</strong>
                  <br /> you booked{" "}
                  <span style={{ fontWeight: 500, marginRight: 4 }}>
                    {data.package_type} - {data.package_category}
                  </span>{" "}
                  to travel around
                  <br />
                  <span style={{ fontWeight: 500, marginLeft: 4 }}>
                    {data.visit_place}
                  </span>
                </p>
              </div>

              <div style={{ marginTop: 25 }}>
                <h3>
                  <strong>Contests</strong>
                </h3>
                <div
                  style={{
                    display: "flex",
                    gap: 5,
                    marginTop: "15px",
                    justifyContent: "space-between",
                  }}
                >
                  {contests.map((con, i) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: 10,
                      }}
                    >
                      <h3
                        style={{
                          color: "white",
                          borderRadius: 9999,
                          width: 30,
                          padding: 2,
                          height: 30,
                          background: "#55b3e5",
                          textAlign: "center",
                        }}
                      >
                        {i + 1}
                      </h3>
                      <h3 style={{ whiteSpace: "nowrap" }} className="print-p">
                        {con}
                      </h3>
                    </div>
                  ))}
                </div>
              </div>
              <div
                style={{
                  marginTop: 15,
                  borderWidth: 2,
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  ...container,
                }}
              >
                <div style={{ paddingLeft: 25 }}>
                  Contact Manager
                  <h2 style={{ fontSize: 25, fontWeight: 700 }}>
                    {data.manager_name}
                  </h2>
                  <p>
                    {data.experience_year}+ years Experience.{" "}
                    {data.trip_per_month} Trips/month
                  </p>
                  <div>
                    Quotation Created on{" "}
                    <span style={{ fontWeight: 700, marginRight: 4 }}>
                      {moment(data.updated_at).format("MMM DD YYYY")}
                    </span>
                  </div>
                  <div>
                    Valid till{" "}
                    <span style={{ fontWeight: 700, marginRight: 4 }}>
                      {moment(data.validity_date).format("MMM DD YYYY")}
                    </span>
                  </div>
                </div>
                <div>
                  <p>Call: {data.manager_contact}</p>
                  <p>Email: {data.manager_email}</p>
                </div>
              </div>
              <h3 style={{ margin: "20px 0 10px 0", fontWeight: 600 }}>
                Highlights of your trip
              </h3>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 25,
                }}
                className="print-h3"
              >
                <div
                  style={{
                    borderWidth: 1,
                    borderColor: "Gray",
                    borderStyle: "solid",
                    borderRadius: 10,
                    paddingRight: 20,
                    paddingLeft: 20,
                    gap: 4,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h3
                    style={{
                      display: "flex",
                      gap: 4,
                      alignItems: "center",
                      paddingTop: 5,
                    }}
                    className="print-h3"
                  >
                    <img src={hts[2]} style={{ width: 23, paddingBottom: 5 }} />
                    {data.hotel_count + " "} Hotels
                  </h3>
                </div>
                <div
                  style={{
                    borderWidth: 1,
                    borderColor: "Gray",
                    borderStyle: "solid",
                    borderRadius: 10,
                    paddingRight: 20,
                    paddingLeft: 20,
                    gap: 4,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h3
                    style={{
                      display: "flex",
                      gap: 4,
                      alignItems: "center",
                      paddingTop: 5,
                    }}
                    className="print-h3"
                  >
                    <img src={hts[0]} style={{ width: 23 }} />
                    {data.activity_count + " "} Activities
                  </h3>
                </div>
                <div
                  style={{
                    borderWidth: 1,
                    borderColor: "Gray",
                    borderStyle: "solid",
                    borderRadius: 10,
                    paddingRight: 20,
                    paddingLeft: 20,
                    gap: 4,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h3
                    style={{
                      display: "flex",
                      gap: 4,
                      alignItems: "center",
                      paddingTop: 5,
                    }}
                    className="print-h3"
                  >
                    <img src={hts[1]} style={{ width: 23 }} />
                    {data.sightseeing_count + " "} Sight Seeing’s
                  </h3>
                </div>
                <div
                  style={{
                    borderWidth: 1,
                    borderColor: "Gray",
                    borderStyle: "solid",
                    borderRadius: 10,
                    paddingRight: 20,
                    paddingLeft: 20,
                    gap: 4,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h3
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 4,
                      paddingTop: 5,
                    }}
                    className="print-h3"
                  >
                    <img src={car} style={{ width: 23, paddingBottom: 5 }} />
                    {data.transfer_count + " "} Transfers
                  </h3>
                </div>
              </div>
              <h3 style={{ marginTop: 24, textAlign: "center" }}>
                "Make this trip a beautiful memory in your life"
              </h3>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginTop: 25,
                }}
                className="print-h3"
              >
                {data2.map((dat) => (
                  <div
                    style={{
                      borderWidth: 1,
                      borderColor: "Gray",
                      borderStyle: "solid",
                      borderRadius: 10,
                      paddingRight: 20,
                      paddingLeft: 20,
                      gap: 4,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h3
                      style={{
                        display: "flex",
                        gap: 4,
                        alignItems: "center",
                        paddingTop: 5,
                      }}
                      className="print-h3"
                    >
                      {/* <img src={hts[2]} style={{ width: 23, paddingBottom: 5 }} /> */}
                      {dat}
                    </h3>
                  </div>
                ))}
              </div>
              <div
                style={{
                  marginTop: 25,
                  padding: 24,
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                  borderWidth: 2,
                  borderColor: "Gray",
                  borderRadius: 10,
                  borderStyle: "solid",
                }}
              >
                <div style={{ marginRight: 20 }}>
                  <h2 className="print-h3">Total cost Including Taxes</h2>
                  <span style={{ whiteSpace: "pre-wrap" }}>
                    Note: All above Prices are subject to change without prior
                    notice as per availability, the final date of travel and any
                    changes in texes
                  </span>
                  <h3 className="print-p">
                    Review and Pay by {moment(data.review_date).format("DD/MM/YYYY")} 
                  </h3>
                </div>
                <div
                  style={{
                    width: "55%",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <h2>
                      {" "}
                      {"₹" + convertPriceToIndFormat(data.total_amount)}{" "}
                    </h2>{" "}
                    <span style={{ whiteSpace: "nowrap" }}>
                      / {data.person_count}
                    </span>
                  </span>

                  {/* <a
                    href={data.payment_link || "#"}
                    target="_blank"
                    style={{
                      paddingLeft: 70,
                      marginTop: 10,
                      paddingRight: 70,
                      whiteSpace: "nowrap",
                      fontWeight: 700,
                      paddingBottom: 10,
                      paddingTop: 10,
                      textAlign: "center",
                      color: "white",
                      background: "black",
                      borderRadius: 100,
                    }}
                  >
                    Pay Now
                  </a> */}
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              textAlign: "center",
              fontSize: 15,
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
              background: "#00000009",
              padding: 15,
              marginTop: 20,
              zIndex: 0,
              color: "#55b3e5",
            }}
          >
            Special Note
          </div>

          <div
            style={{
              border: "2px solid #E5E7EB",
              padding: 10,
              borderBottomLeftRadius: 10,
              borderBottomRightRadius: 10,
            }}
            dangerouslySetInnerHTML={{ __html: data.inclusion_exclution }}
          />

          <div style={{ marginTop: 20 }}>
            <HeaderComp1 title="Preview of the Package:" />
          </div>
          <div
            style={{ background: "#00000009", borderRadius: 10 }}
            className="p-4"
          >
            <HeaderComp1
              title={data.itinerary_detail?.heading}
              discription={data.itinerary_detail?.description}
            />
          </div>
          <div
            className="page-break"
            style={{
              position: "relative",
              marginTop: 20,
              marginBottom: 20,
              zIndex: 0,
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                height: 2,
                background: "#E5E7EB",
                width: "50vw",
                zIndex: 10,
              }}
            ></div>

            <div
              className=""
              style={{
                background: "#E5E7EB",
                paddingLeft: 25,
                zIndex: 20,
                paddingRight: 25,
                paddingTop: 5,
                paddingBottom: 5,
                borderRadius: 20,
                fontWeight: 600,
                color: "#55b3e5",
                right: "50%",
              }}
            >
              Overview
            </div>
            <div
              style={{
                height: 2,
                background: "#E5E7EB",
                width: "50vw",
                zIndex: 10,
              }}
            ></div>
          </div>
          <h1 style={{ fontWeight: 700 }}>
            {data.destination} in {data.itinerary_detail?.duration}
          </h1>
          {/* <h1 style={{ color: "#41C5F2" }}></h1> */}

          <h4 style={{ fontWeight: 500, margin: "20px 0 10px 25px" }}>
            Trip Started
          </h4>
          {data.itinerary_detail.tour_detail?.map((dat, index) => (
            <div
              style={{
                pageBreakInside: "avoid",
                borderColor: "#E5E7EB",
                borderRadius: 10,
                borderWidth: 2,
                marginTop: index === 0 ? 0 : 30,
                borderStyle: "solid",
                overflow: "hidden",
                position: "relative",
              }}
              className={`d-md-flex mx-md-4 flex-md-row-reverse align-item-center justify-content-between`}
            >
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "50px",
                  background: "#00000009",
                  zIndex: "-10",
                }}
              ></div>
              <div style={{ marginTop: "8px", marginRight: "8px" }}>
                <DateCard
                  day={index + 1}
                  date={
                    moment(date).add(index, "days").format("MMM DD , YYYY") ||
                    "00-00-23"
                  }
                  i={index + 1}
                />
              </div>
              <div>
                <div
                  style={{
                    height: "50px",
                    padding: "5px 20px 5px 40px",
                    margin: "auto 0",
                    color: "#777",
                    fontSize: 25,
                    fontWeight: 600,
                  }}
                >
                  Day {index + 1}
                </div>
                <div style={{ padding: "10px 30px" }}>
                  {dat?.day_details.map((dt) => (
                    <ActivityFilter data={dt} />
                  ))}
                </div>
              </div>
            </div>
          ))}
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            <div
              style={{
                position: "relative",
                marginTop: 20,
                marginBottom: 20,
                zIndex: 0,
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  height: 2,
                  background: "#E5E7EB",
                  width: "50vw",
                  zIndex: 10,
                }}
              ></div>

              <div
                style={{
                  background: "#E5E7EB",
                  paddingLeft: 25,
                  zIndex: 20,
                  whiteSpace: "nowrap",
                  paddingRight: 25,
                  paddingTop: 5,
                  paddingBottom: 5,
                  borderRadius: 20,
                  fontWeight: 600,
                  color: "#55b3e5",
                  right: "50%",
                }}
              >
                Daywise details
              </div>
              <div
                style={{
                  height: 2,
                  background: "#E5E7EB",
                  width: "50vw",
                  zIndex: 10,
                }}
              ></div>
            </div>
            {data.itinerary_detail.tour_detail?.map((dat, index) => (
              <div className="">
                <div
                  style={{
                    height: 2,
                    marginTop: 30,
                    background: "#E5E7EB",
                    width: "100vw",
                  }}
                />
                <h2
                  style={{
                    fontWeight: 700,
                    fontSize: 35,
                    marginTop: "10px",
                    marginBottom: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>DAY {index + 1}</span>
                  <span style={{ fontSize: "24px", fontWeight: 500 }}>
                    {moment(date).add(index, "days").format("MMM DD YYYY") ||
                      "00-00-2023"}
                  </span>
                </h2>
                <div
                  style={{ height: 2, background: "#E5E7EB", width: "100vw" }}
                />
                <div style={{ padding: 25 }}>
                  {dat?.day_details.map((dt) => (
                    <div
                      style={{
                        ...container,
                        padding: "25px",
                        pageBreakInside: "avoid",
                      }}
                    >
                      <ActivityFilter2 data={dt} />
                    </div>
                  ))}
                </div>
              </div>
            ))}
            <div
              style={{ marginTop: 30 }}
              className="mt-6 flex flex-col margin-top"
            >
              <p>{data.description}</p>
            </div>
          </div>
          <h1
            style={{ fontSize: 30, fontWeight: 900, textAlign: "start" }}
            className="margin-top page-break"
          >
            Pay Online
          </h1>
          <hr />
          <div style={container}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: 10,
                alignItems: "center",
              }}
            >
              <h1 style={{ fontSize: 25, fontWeight: 500 }}>
                Payment Options & Schedule
                <br></br>
                <span style={{ fontSize: 15 }}>Total cost including taxes</span>
              </h1>
              <span
                style={{
                  fontSize: 25,
                  fontWeight: 500,
                  color: "blue",
                  marginRight: 20,
                }}
              >
                ₹ {convertPriceToIndFormat(data.total_amount)}
              </span>
            </div>
          </div>
          {data.payment_detail.length ? (
            <div style={container} className="margin-top">
              <div>
                <tr
                  style={{
                    fontSize: 20,
                    color: "#000",
                    marginLeft: 10,
                    marginRight: 10,
                    width: "100%",
                    placeItems: "center",
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr 1fr",
                  }}
                >
                  <td>
                    <p>Payment Options</p>
                  </td>
                  <td>
                    {" "}
                    <p>Date</p>
                  </td>
                  <td>
                    <p>Amount</p>
                  </td>
                  <td>Payment</td>
                </tr>
                <hr style={{ height: 2, background: "black", width: "100%" }} />
                {data.payment_detail.map((data) => (
                  <>
                    <tr
                      style={{
                        placeItems: "center",
                        color: "#000",
                        marginLeft: 10,
                        marginRight: 10,
                        fontSize: 20,
                        width: "100%",
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 1fr 1fr",
                      }}
                    >
                      <td style={{ marginRight: 50, whiteSpace: "nowrap" }}>
                        <p>
                          {data.payment_name === "full_payment"
                            ? "Full Payment"
                            : "Partial Payment"}
                        </p>
                      </td>
                      <td style={{ marginRight: 50 }}>
                        {" "}
                        <p>{moment(data.validity_date).format("DD MMM YY")}</p>
                      </td>
                      <td style={{ marginRight: 50 }}>
                        <p> {convertPriceToIndFormat(data.payment_cost)}</p>
                      </td>
                      <td>
                        <a
                          href={data.payment_link || "#"}
                          target="_blank"
                          style={{ color: "blue", textDecoration: "underline" }}
                        >
                          PayNow
                        </a>
                      </td>
                    </tr>
                    <hr
                      style={{ height: 2, background: "black", width: "100%" }}
                    />
                  </>
                ))}
              </div>
            </div>
          ) : (
            <div style={container} className="margin-top">
              <div>
                <tr
                  style={{
                    fontSize: 20,
                    color: "#000",
                    marginLeft: 10,
                    marginRight: 10,
                    width: "100%",
                    placeItems: "center",
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr 1fr",
                  }}
                >
                  <td>
                    <p>Payment Options</p>
                  </td>
                  <td>
                    {" "}
                    <p>Date</p>
                  </td>
                  <td>
                    <p>Amount</p>
                  </td>
                  <td>{data.payment_link ? "Payment" : "Review Date"}</td>
                </tr>
                <hr style={{ height: 2, background: "black", width: "100%" }} />
                <tr
                  style={{
                    placeItems: "center",
                    color: "#000",
                    marginLeft: 10,
                    marginRight: 10,
                    fontSize: 20,
                    width: "100%",
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr 1fr",
                  }}
                >
                  <td style={{ marginRight: 50, whiteSpace: "nowrap" }}>
                    <p>Full payment</p>
                  </td>
                  <td style={{ marginRight: 0 }}>
                    {" "}
                    <p>{moment(data.creation_date).format("DD MMM YY")}</p>
                  </td>
                  <td style={{ marginRight: 0 }}>
                    <p> {convertPriceToIndFormat(data.total_amount)}</p>
                  </td>
                  <td>
                    {data.payment_link ? (
                      <a
                        href={data.payment_link || "#"}
                        target="_blank"
                        style={{ color: "blue", textDecoration: "underline" }}
                      >
                        PayNow
                      </a>
                    ) : (
                      moment(data.review_date).format("DD MMM YY")
                    )}
                  </td>
                </tr>
                <hr style={{ height: 2, background: "black", width: "100%" }} />
              </div>
            </div>
          )}

          <div style={{ pageBreakInside: "avoid" }} className="margin-top-2">
            <h1
              style={{
                fontSize: "2.25rem",
                lineHeight: "2.5rem",
                fontWeight: 700,
                textAlign: "center",
                color: "#303A42",
              }}
            >
              BOOKING CONDITIONS & CANCELLATION POLICIES:
            </h1>
            <div className="margin-top">
              <p className="p">
                Thank you for visiting www.wayfindtrips.com. This site is owned
                and operated by WayFind Trips Private Limited group which has
                its registered office address at B-128, 1st Floor, Eastern
                Business DistrictNeptune Magnet Mall, LBS Marg, Bhandup (W)
                Mumbai:<span style={bold}>400078</span> CIN:{" "}
                <span style={bold}>U63040MH2023PTC396754</span> / GST:{" "}
                <span style={bold}>27AADCW4503C1ZP</span> / PAN:{" "}
                <span style={bold}>AADCW4503C</span>
              </p>

              <p className="p mt-4">
                When you make a booking for a Holiday/Vacations, your contract
                is with WayFind Trips Private Limited. In these terms and
                conditions “we” “us” or “our” mean WayFind Trips Private Limited
                and they form the basis of your contract with us. Please read
                them carefully as they are legally binding.
              </p>
            </div>

            {cancelPolicy.map(({ heading, desp }) => (
              <MyComp heading={heading} desp={desp} />
            ))}
          </div>

          <div
            style={{ margin: "25px", pageBreakInside: "avoid" }}
            className=""
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <table
                style={{
                  border: "1px solid #0004",
                  borderCollapse: "collapse",
                  borderSpacing: 10,
                  borderRadius: 10,
                  width: 650,
                }}
              >
                <tr
                  style={{
                    fontWeight: 700,
                    margin: 10,
                  }}
                >
                  <td
                    style={{
                      padding: "10px 10px",
                      borderRight: "1px solid #0004",
                    }}
                  >
                    <h4 style={{ textAlign: "center" }}>
                      Tenure (If cancelled from)
                    </h4>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <h4>Amount to be charged</h4>
                  </td>
                </tr>
                <tr style={{ border: "1px solid #0004" }}>
                  <td
                    style={{
                      padding: "10px 10px",
                      borderRight: "1px solid #0004",
                    }}
                  >
                    <p className="p">
                      {Object.keys(data.amount_refund_policy)[0]}
                    </p>
                  </td>
                  <td style={{ padding: "10px 10px" }}>
                    <p className="p">
                      {Object.values(data.amount_refund_policy)[0]}
                    </p>
                  </td>
                </tr>
                <tr style={{ border: "1px solid #0004" }}>
                  <td
                    style={{
                      padding: "10px 10px",
                      borderRight: "1px solid #0004",
                    }}
                  >
                    <p className="p">
                      {Object.keys(data.amount_refund_policy)[1]}
                    </p>
                  </td>
                  <td style={{ padding: "10px 10px" }}>
                    <p className="p">
                      {Object.values(data.amount_refund_policy)[1]}
                    </p>
                  </td>
                </tr>
                <tr style={{ border: "1px solid #0004" }}>
                  <td
                    style={{
                      padding: "10px 10px",
                      borderRight: "1px solid #0004",
                    }}
                  >
                    <p className="p">
                      {Object.keys(data.amount_refund_policy)[2]}
                    </p>
                  </td>
                  <td style={{ padding: "10px 10px" }}>
                    <p className="p">
                      {Object.values(data.amount_refund_policy)[2]}
                    </p>
                  </td>
                </tr>
              </table>
              <div></div>
            </div>
          </div>

          {/* <div dangerouslySetInnerHTML={{ __html: html}} /> */}

          <div className="margin-top-2">
            <h1
              style={{
                fontSize: "2.25rem",
                lineHeight: "2.5rem",
                fontWeight: 700,
                textAlign: "center",
                color: "#303A42",
              }}
            >
              Terms and Conditions
            </h1>

            {terms.map(({ heading, desp }) => (
              <MyComp heading={heading} desp={desp} />
            ))}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <span>
              For our latest terms & codition and privacy policy click this link
              👉{" "}
            </span>
            <a
              href="https://destinations.wayfindtrips.com/termscondition"
              style={{
                fontWeight: 700,
                fontSize: 10,
                marginLeft: "10px",
                color: "blue",
                textDecoration: "underline",
              }}
              target="_blank"
              rel="noreferrer"
            >
              Terms and Conditions
            </a>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <span>For our latest cancellation policy click this link 👉 </span>
            <a
              href="https://destinations.wayfindtrips.com/cancellationpolicy"
              style={{
                fontWeight: 700,
                fontSize: 10,
                marginLeft: "10px",
                color: "blue",
                textDecoration: "underline",
              }}
              target="_blank"
              rel="noreferrer"
            >
              Cancellation Policy
            </a>
          </div>

          {/* <div style={container}>

          {
            ["one", "two", "three", "four", "five", "six", "seven", "eight"].map(da => 
            <div className={da === "three" && "page-break margin-buttom"}><TermComponent
              content={data.terms[`content_${da}`]}
              heading={data.terms[`heading_${da}`]}
            /></div>
            )
          }

        </div> */}
        </div>
      )}
    </div>
  );
});

export default Print;

export function TermComponent({ content, heading }) {
  return (
    <div style={{ marginTop: 10, color: "#000" }}>
      <h1 style={{ fontSize: 25, fontWeight: 500 }}>{heading}</h1>
      <p style={{ color: "#000" }}>{content}</p>
    </div>
  );
}
