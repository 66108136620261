import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import Table from "../../components/global/Table/Table";
import PaginationPageSelectors from "../../components/global/PaginationPageSelectors";
import {
  changeTicketStatus,
  getTicketList,
} from "../../../controller/ticketController";
import { handleControllerResultException } from "../../../util/handleException";
import { message } from "antd";
import { adminConfirmation } from "../../../services/util";
import InputField from "../../components/global/InputField";
import Button from "../../components/global/Button";
import TicketListItem from "./TicketListItem";
import componentStyle from "../../../css/components.module.css";
/**
 * @type {import("../../../model/PnrListItemModel").PnrListItem[]}
 */
const tickerListType = [];

export default function Tickets() {
  const [list, setList] = useState(tickerListType);
  const [loading, setLoading] = useState(false);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [activeList, setActiveList] = useState(true);
  const [isSearched, setIsSearched] = useState(false);
  const isAdmin = adminConfirmation();
  const history = useHistory();

  const handleTicketList = useCallback(
    async (page = 1, activeList = true, searchText = "") => {
      setLoading(true);
      const res = await getTicketList(page, activeList, searchText);
      setLoading(false);
      if (res[0] !== null) {
        setList(res[0].result);
        setNumberOfPages(Math.ceil(res[0].count / 10));
      } else {
        handleControllerResultException(res[1], history);
      }
    },
    [history]
  );

  useEffect(() => {
    handleTicketList();
  }, [handleTicketList]);

  async function handlePackageStatus(id) {
    const res = await changeTicketStatus(id);
    if (res.statusCode === 200) {
      message.success(res.success);
      setList((prevState) => prevState.filter((data) => data.id !== id));
    } else {
      handleControllerResultException(res, history);
    }
  }

  function handleSearch() {
    handleTicketList(1, true, searchText);
    setCurrentPage(1);
    searchText === "" ? setIsSearched(false) : setIsSearched(true);
  }

  return (
    <div>
      {isAdmin && (
        <div
          style={{
            width: "70%",
            margin: "3rem auto",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "1.5rem",
          }}
        >
          <span
            role="button"
            onClick={() => {
              setActiveList(true);
              setCurrentPage(1);
              handleTicketList(1, true);
            }}
            style={{
              backgroundColor: `${activeList ? "#55b3e5" : "#ffffff"}`,
              padding: "2px 30px",
              borderRadius: "8px",
              color: `${activeList ? "#212121" : "#55b3e5"}`,
            }}
          >
            Active Tickets
          </span>
          <span
            role="button"
            onClick={() => {
              setActiveList(false);
              setCurrentPage(1);
              handleTicketList(1, false);
            }}
            style={{
              backgroundColor: `${!activeList ? "#55b3e5" : "#ffffff"}`,
              padding: "2px 30px",
              borderRadius: "8px",
              color: `${!activeList ? "#212121" : "#55b3e5"}`,
            }}
          >
            Inactive Tickets
          </span>
        </div>
      )}
      {activeList && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <InputField
            placeHolder="Search based on ticked ID"
            inputFieldAttributes={{
              value: searchText,
              onChange: (e) => setSearchText(e.target.value),
            }}
            stylesProps={{ width: "40%" }}
          />
          <Button styles={{ marginLeft: "1rem" }} onClick={handleSearch}>
            Search
          </Button>
          {isSearched && (
            <button
              style={{
                border: "none",
                background: "white",
                marginLeft: "1rem",
                transform: "scale(1.5)",
                color: "red",
              }}
              onClick={() => {
                handleTicketList();
                setCurrentPage(1);
                setSearchText("");
                setIsSearched(false);
              }}
            >
              &times;
            </button>
          )}
        </div>
      )}
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <button
          className={componentStyle.btnPrimary}
          onClick={() => history.push("/new-ticket")}
        >
          <span style={{ marginRight: "5px" }}>&#43;</span> Add Proposal
        </button>
      </div>
      <div style={{ overflowX: "auto", marginTop: "2rem" }}>
        <Table>
          <thead>
            <tr>
              <th>Ticket ID</th>
              <th>Created Date</th>
              <th>Creator</th>
              <th>Customer</th>
              <th>Package</th>
              <th>Quote </th>
              <th>Status</th>
              {isAdmin && <th>Active</th>}
              <th>Payments</th>
              <th className="sorting"></th>
            </tr>
          </thead>
          {!loading ? (
            <tbody>
              {list.length &&
                list.map((data, i) => (
                  <TicketListItem
                    key={i}
                    ticket={data}
                    handlePackageStatus={(ticketId) =>
                      handlePackageStatus(ticketId)
                    }
                  />
                ))}
            </tbody>
          ) : (
            <p style={{ fontSize: "1.5rem", textAlign: "center" }}>
              Loading...
            </p>
          )}
        </Table>

        {numberOfPages > 1 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "2rem",
            }}
          >
            <PaginationPageSelectors
              totalPages={numberOfPages}
              currentPage={currentPage}
              pageClickFunc={(page) => {
                setCurrentPage(page);
                handleTicketList(page, activeList, searchText);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
