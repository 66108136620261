import React from "react";

/**
 * @typedef {object} Props
 * @property {boolean} status
 * @property {()=>void} onClick
 * @property {string} statusFalseText
 * @property {string} statusTrueText
 */

/**
 * @param {Props} props
 */
export default function StatusChangeButton({
  status,
  onClick,
  statusTrueText = "Active",
  statusFalseText = "Inactive",
}) {
  return (
    <button
      onClick={onClick}
      style={{
        border: `2px solid ${status ? "green" : "red"}`,
        borderRadius: "5px",
        fontSize: "15px",
        padding: "5px 10px",
        color: `${status ? "green" : "red"}`,
        backgroundColor: `${status ? "#dcfce7" : "#fee2e2"}`,
      }}
    >
      {status ? statusTrueText : statusFalseText}
    </button>
  );
}
