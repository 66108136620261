export const ADD_VOUCHER_LIST = "Add voucher list";

/**
 *
 * @param {import("../../util/handleVoucherDataFormat").VoucherServerObj[]} payload
 */
export function addVoucherList(payload) {
  return {
    type: ADD_VOUCHER_LIST,
    payload,
  };
}
