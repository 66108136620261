import React, { useCallback, useEffect, useState } from "react";
import { Tab } from "react-bootstrap";
import {
  assignTicketTouser,
  getUserList,
} from "../../../../controller/collectionController";
import { handleControllerResultException } from "../../../../util/handleException";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Table from "../../../components/global/Table/Table";
import componentStyle from "../../../../css/components.module.css";
import PaginationPageSelectors from "../../../components/global/PaginationPageSelectors";
import PnrSelectorModal from "../../../components/voucher/PnrSelectorModal";
import { HTTPStatus } from "../../../../util/helperObj";
import { message } from "antd";
import AssignedTicketModal from "./AssignedTicketModal";

/**
 * @type {import("../../../../model/UserModel").User[]}
 */
const userListType = [];

export default function Users() {
  const [userList, setUserList] = useState(userListType);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [showPnrTicketModal, setShowPnrTicketModal] = useState(false);
  const [showAssignTicketListModal, setShowAssignTicketListModal] =
    useState(false);
  const [selectedUserId, setSelectedUserId] = useState(0);
  const history = useHistory();

  const handleUserList = useCallback(async () => {
    const res = await getUserList(1);
    if (res[0] !== null) {
      setUserList(res[0].result);
      setNumberOfPages(Math.ceil(res[0].count / 20));
      return true;
    } else {
      handleControllerResultException(res[1], history);
    }
  }, [history]);
  useEffect(() => {
    handleUserList();
  }, [handleUserList]);

  async function handlePageChange(page) {
    const previousPage = currentPage;
    setCurrentPage(page);
    const res = await handleUserList(page);
    if (!res) {
      setCurrentPage(previousPage);
    }
  }

  async function assignTicketHandler(pnrTicketId) {
    const res = await assignTicketTouser(pnrTicketId, selectedUserId);
    if (res.statusCode === HTTPStatus.SUCCESS) {
      message.success(res.success);
    } else {
      handleControllerResultException(res, history);
    }
  }

  return (
    <Tab.Pane eventKey="Users">
      <div style={{ overflowX: "auto", marginTop: "2rem" }}>
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone number</th>
              <th>Created at</th>
              <th>Assign ticket</th>
              <th>Ticket list</th>
            </tr>
          </thead>
          <tbody>
            {userList.length > 0 &&
              userList.map((data) => (
                <tr key={data.id}>
                  <td>{data.name}</td>
                  <td>{data.email}</td>
                  <td>{data.phoneNumber}</td>
                  <td>{data.createdAt}</td>
                  <td>
                    <button
                      onClick={() => {
                        setShowPnrTicketModal(true);
                        setSelectedUserId(data.id);
                      }}
                      className={componentStyle.btnPrimary}
                    >
                      Assign
                    </button>
                  </td>
                  <td>
                    <button
                      onClick={() => {
                        setShowAssignTicketListModal(true);
                        setSelectedUserId(data.id);
                      }}
                      className={componentStyle.btnPrimary}
                    >
                      Ticket list
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
      {numberOfPages > 1 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "2rem",
          }}
        >
          <PaginationPageSelectors
            totalPages={numberOfPages}
            currentPage={currentPage}
            pageClickFunc={(page) => handlePageChange(page)}
          />
        </div>
      )}
      {showPnrTicketModal && (
        <PnrSelectorModal
          setShowPnrSelectorModal={setShowPnrTicketModal}
          selectFunc={(pnr) => {
            assignTicketHandler(pnr.pnrNumber);
          }}
        />
      )}
      {showAssignTicketListModal && (
        <AssignedTicketModal
          setShowAssignTicketModal={setShowAssignTicketListModal}
          userId={selectedUserId}
        />
      )}
    </Tab.Pane>
  );
}
