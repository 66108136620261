import React from 'react'

function Popular() {
  return (
    <div>
        <p>*This Popular packages are appear in landing page, Star the package from the list of packages  </p>
    </div>
  )
}

export default Popular
