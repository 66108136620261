import React, { useState } from "react";
import Button from "../../components/global/Button";
import moment from "moment";
import { convertPriceToIndFormat } from "../../../util/util";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { adminConfirmation } from "../../../services/util";
import TicketPaymentModal from "./TicketPaymentModal";
/**
 * @typedef {object} Props
 * @property {import('../../../model/PnrListItemModel').PnrListItem} ticket
 * @property {(ticketId:number)=>void} handlePackageStatus
 *
 */

/**
 * @param {Props} props
 */
export default function TicketListItem({ ticket, handlePackageStatus }) {
  const history = useHistory();
  const isAdmin = adminConfirmation();
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  return (
    <tr role="row">
      <td>
        <h5>
          <span style={{ whiteSpace: "nowrap" }} className="text-warning">
            {ticket.pnrNumber}
          </span>{" "}
        </h5>
      </td>

      <td>
        <h5 style={{ whiteSpace: "nowrap" }} className="fs-14 text-start ">
          {moment(ticket.createdAt).format("dddd, MMM Do, YYYY")}
        </h5>
        <h5 className="fs-12 text-start" style={{ color: "#969696" }}>
          {moment(ticket.createdAt).format("hh:mm -A")}
        </h5>
      </td>
      <td>
        <h5 className="fs-14 text-center">{ticket.manager}</h5>
      </td>
      <td>
        <h5 className="fs-14 text-center">{ticket.customerName}</h5>
      </td>
      <td>
        <h5 className="fs-14">{ticket.packageType.toUpperCase()}</h5>
      </td>
      <td>
        <h5 className="fs-14">{convertPriceToIndFormat(ticket.totalAmount)}</h5>
      </td>
      <td>
        <select className="rounded text-warning">
          <option className="text-warning">Sent itinerary</option>
        </select>
      </td>
      {isAdmin && (
        <td>
          <button
            onClick={() => handlePackageStatus(ticket.id)}
            style={{
              border: `2px solid ${ticket.isActive ? "green" : "red"}`,
              borderRadius: "5px",
              fontSize: "15px",
              padding: "5px 10px",
              color: `${ticket.isActive ? "green" : "red"}`,
              backgroundColor: `${ticket.isActive ? "#dcfce7" : "#fee2e2"}`,
            }}
          >
            {ticket.isActive ? "Active" : "Inactive"}
          </button>
        </td>
      )}
      <td>
        <Button onClick={()=>setShowPaymentModal(true)}>Payments</Button>
        {showPaymentModal && (
          <TicketPaymentModal
            modalCloseAction={() => setShowPaymentModal(false)}
            ticket={ticket}
          />
        )}
      </td>
      <td>
        <button
          onClick={() => history.push("/Tickets/" + ticket.id)}
          style={{
            whiteSpace: "nowrap",
            border: "1px solid gray",
            padding: "2px 20px",
            color: "#0E1B43",
          }}
          className="btn"
        >
          Edit Details
        </button>
      </td>
    </tr>
  );
}
