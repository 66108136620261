import React, { useState, useCallback, useEffect } from "react";
import { Tab } from "react-bootstrap";
import "../../../../model/typeDefs.js";
import {
  changeGuestActiveStatus,
  getGuestList,
} from "../../../../controller/collectionController";
import LeadStatus from "./LeadStatus";
import { useDispatch } from "react-redux";
import { logout } from "../../../../store/actions/AuthActions.js";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min.js";
import { getTeamMembers } from "../../../../controller/teamController.js";
import { message } from "antd";
import TeamMemberAssing from "./TeamMemberAssing.jsx";
import viewMoreBtn from "../../../../images/optiondot.svg";
import GuestDetailModal from "./GuestDetailModal.jsx";
import { GuestModel } from "../../../../model/collectionModel.js";
import { adminConfirmation } from "../../../../services/util.js";
import Table from "../../../components/global/Table/Table.jsx";
import componentStyle from "../../../../css/components.module.css";
import InputField from "../../../components/global/InputField.jsx";
import PaginationPageSelectors from "../../../components/global/PaginationPageSelectors.jsx";
import StatusChangeButton from "../../../components/global/StatusChangeButton.jsx";
import { HTTPStatus } from "../../../../util/helperObj.js";
import { handleControllerResultException } from "../../../../util/handleException.js";
import HorizontalTabs from "../../../components/global/HorizontalTabs.jsx";

/**
 * @type {GuestModel[]}
 */
const GuestListType = [];

/**'
 * @type {TeamMemberModel[]}
 */
const TeamMemeberListType = [];

/**
 * @type {GuestModel}
 */
const initialSelectedGuest = GuestModel.initialGuestObj();

function LeadList({ history }) {
  const [guestList, setGuestList] = useState(GuestListType);
  const [guestTab, setGuestTab] = useState("Active");
  const [teamMemberList, setTeamMemberList] = useState(TeamMemeberListType);
  const [selectedGuest, setSelectedGuest] = useState(initialSelectedGuest);
  const [guestModalOpen, setGuestModalOpen] = useState(false);
  const [fetching, setFetching] = useState(false);
  const dispatch = useDispatch();
  const [isAdmin, setIsAdmin] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isSearched, setIsSearched] = useState(false);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  /**
   * @param {number} page
   */

  useEffect(() => {
    const res = adminConfirmation();
    if (res !== null) {
      setIsAdmin(res);
    }
  }, []);

  const fetchList = useCallback(
    async (page = 1, search = "", activeStatus = true) => {
      setFetching(true);
      const res = await getGuestList(page, search, activeStatus);
      setFetching(false);
      if (res[0] !== null) {
        setGuestList(res[0].result);
        setNumberOfPages(Math.ceil(res[0].count / 20));
        return true;
      } else if (res[1].statusCode === 401) {
        dispatch(logout(history));
      } else {
        message.error(res[1].error);
      }
    },
    [dispatch, history]
  );
  useEffect(() => {
    fetchList();
  }, [fetchList]);

  async function handleSearch() {
    if (searchText !== "") {
      const res = await fetchList(
        1,
        searchText,
        guestTab === "Active" ? true : false
      );
      if (res) {
        setCurrentPage(1);
        setIsSearched(true);
      }
    }
  }

  async function handleCancelSearch() {
    const res = await fetchList(1, "", guestTab === "Active" ? true : false);
    if (res) {
      setSearchText("");
      setCurrentPage(1);
      setIsSearched(false);
    }
  }

  async function handlePageChange(pageNumber) {
    const res = await fetchList(
      pageNumber,
      searchText,
      guestTab === "Active" ? true : false
    );
    if (res) {
      setCurrentPage(pageNumber);
    }
  }

  async function handleTabs(tab) {
    const previousTab = guestTab;
    setGuestTab(tab);
    const res = await fetchList(1, "", tab === "Active" ? true : false);
    if (res) {
      setCurrentPage(1);
      setIsSearched(false);
      setSearchText("");
    } else {
      setGuestTab(previousTab);
    }
  }

  // Fetch members
  const fetchMembers = useCallback(async () => {
    const res = await getTeamMembers();
    if (res[0] !== null) {
      const data = res[0];
      setTeamMemberList(data);
    } else if (res[1].statusCode === 401) {
      dispatch(logout(history));
    } else {
      if (res[1].error !== null) {
        message.error(res[1].error);
      }
    }
  }, [dispatch, history]);

  useEffect(() => {
    fetchMembers();
  }, [fetchMembers]);

  async function handleGuestActiveStatus(id) {
    const res = await changeGuestActiveStatus(id);
    if (res.statusCode === HTTPStatus.SUCCESS) {
      setGuestList((prevState) =>
        prevState.filter((data) => data.guestId !== id)
      );
      message.success(res.success);
    } else {
      handleControllerResultException(res, history);
    }
  }

  /**
   *
   * @param {GuestModel} data
   */
  const handleModalVisible = (data) => {
    setSelectedGuest(data);
    setGuestModalOpen(true);
  };

  return (
    <Tab.Pane eventKey="Leads">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <InputField
          stylesProps={{ width: "30%" }}
          placeHolder="Search based on name or email"
          inputFieldAttributes={{
            value: searchText,
            onChange: (e) => setSearchText(e.target.value),
          }}
        />
        <button
          className={componentStyle.btnPrimary}
          style={{ marginLeft: "20px" }}
          onClick={handleSearch}
          disabled={fetching}
        >
          Search
        </button>
        {isSearched && (
          <button
            onClick={handleCancelSearch}
            style={{
              border: "none",
              transform: "scale(1.5)",
              color: "red",
              background: "white",
              marginLeft: "10px",
            }}
          >
            &times;
          </button>
        )}
      </div>
      {isAdmin && (
        <div>
          <HorizontalTabs
            tabList={["Active", "Inactive"]}
            currentActiveTab={guestTab}
            onClick={(tab) => handleTabs(tab)}
          />
        </div>
      )}
      <div style={{ overflowX: "auto", marginTop: "2rem" }}>
        <Table>
          <thead>
            <tr>
              <th>Full Name</th>
              <th>Email</th>
              <th>Guests</th>
              <th>Package type</th>
              <th>Budget</th>
              <th>Date</th>
              <th>Time</th>
              <th>Source</th>
              <th>Status</th>
              {isAdmin && <th>Assign to</th>}
              {isAdmin && <th>Status</th>}
              <th className="sorting"></th>
            </tr>
          </thead>
          <tbody>
            {guestList.length > 0 &&
              guestList.map((data) => (
                <tr key={data.guestId}>
                  <td>
                    <h5 style={{ width: "200px" }}>
                      <span className="text-warning">{data.guestId}</span>{" "}
                      {data.fullName}
                    </h5>
                  </td>

                  <td>
                    <h5>{data.emailId}</h5>
                  </td>
                  <td>
                    <h5 style={{ width: "100px" }}>{data.guestDetail}</h5>
                  </td>
                  <td>
                    <h5>{data.duration}</h5>
                  </td>
                  <td>
                    <h5 style={{ width: "100px" }}>{data.budget}</h5>
                  </td>
                  <td>
                    <h5 style={{ width: "100px" }}>{data.date}</h5>
                  </td>
                  <td>
                    <h5 style={{ width: "100px" }}>{data.time}</h5>
                  </td>
                  <td>
                    <h5>{data.source}</h5>
                  </td>
                  <td>
                    <LeadStatus
                      statusValue={data.followUpStatus}
                      guestId={data.guestId}
                    />
                  </td>
                  {isAdmin && (
                    <td>
                      <TeamMemberAssing
                        teamMemberList={teamMemberList}
                        defaultAssingId={data.assingTo}
                        guestId={data.guestId}
                      />
                    </td>
                  )}
                  {isAdmin && (
                    <td>
                      <StatusChangeButton
                        status={data.isActive}
                        onClick={() => handleGuestActiveStatus(data.guestId)}
                      />
                    </td>
                  )}
                  <td>
                    <img
                      onClick={() => handleModalVisible(data)}
                      src={viewMoreBtn}
                      height="20"
                      width="20"
                      alt="view more"
                      role="button"
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        {isSearched && guestList.length === 0 ? (
          <p
            style={{
              textAlign: "center",
              marginTop: "20px",
              color: "#212121",
              fontSize: "15px",
            }}
          >
            No result found🥲
          </p>
        ) : (
          <></>
        )}
      </div>

      {numberOfPages > 1 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "2rem",
          }}
        >
          <PaginationPageSelectors
            totalPages={numberOfPages}
            currentPage={currentPage}
            pageClickFunc={(page) => handlePageChange(page)}
          />
        </div>
      )}
      {guestModalOpen && (
        <GuestDetailModal
          guest={selectedGuest}
          setModalVisible={setGuestModalOpen}
        />
      )}
    </Tab.Pane>
  );
}

export default withRouter(LeadList);
