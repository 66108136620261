import axios from "axios";
import { handleException } from "../util/handleException";
import { getToken } from "../util/tokenHandler";
import { HttpResponse } from "../model/HttpResponse";
import { createJobObj } from "../model/JobModal";
import { createApplicantObj } from "../model/ApplicantModel";

const createJobAPI = "/wft_admin/join-us-create";
const jobListAPI = "/wft_admin/join-us-list";
const jobDetailAPI = "/wft_admin/join-us-detail";
const updateJobDetailsAPI = "/wft_admin/join-us-update";
const changeJobStatusAPI = "/wft_admin/join-us-status-change";
const applicantListAPI = "wft_admin/join-application-list";

/**
 * @param {import("../model/JobModal").JobModel} props
 */
export async function createJob({
  title,
  description,
  location,
  jobType,
  postType,
  salary,
  responsibility,
  qualification,
  isActive,
}) {
  try {
    const res = await axios.post(
      createJobAPI,
      {
        title,
        description,
        location,
        job_type: jobType,
        post_type: postType,
        salary,
        responsibility,
        quialification: qualification,
        is_active: isActive,
      },
      {
        headers: {
          Authorization: `Token ${getToken()}`,
        },
      }
    );
    return new HttpResponse(res.status, "Jon created successfuly");
  } catch (err) {
    return handleException(err);
  }
}

/**
 * @returns {Promise<[{results:import("../model/JobModal").JobModel[], count:number}|null, HttpResponse|null]>}
 */
export async function getJobList(page = 1, status = "Active") {
  const result = [null, null];
  try {
    const res = await axios.get(jobListAPI, {
      headers: {
        Authorization: `Token ${getToken()}`,
      },
      params: {
        page,
        active_status: status,
      },
    });
    const resultArr = [];
    for (let data of res.data.results) {
      resultArr.push(createJobObj(data));
    }
    result[0] = {
      results: resultArr,
      count: res.data.count,
    };
    return result;
  } catch (err) {
    result[1] = handleException(err);
    return result;
  }
}

/**
 *
 * @param {number} jobId
 * @returns {Promise<[import("../model/JobModal").JobModel|null, HttpResponse|null]>}
 */
export async function getJobDetails(jobId) {
  const result = [null, null];

  try {
    const res = await axios.get(`${jobDetailAPI}/${jobId}`, {
      headers: {
        Authorization: `Token ${getToken()}`,
      },
    });

    result[0] = createJobObj(res.data);
    return result;
  } catch (err) {
    result[1] = handleException(err);
    return result;
  }
}

/**
 * @param {import("../model/JobModal").JobModel} param
 */
export async function updateJobDetails({
  id,
  title,
  description,
  location,
  jobType,
  postType,
  salary,
  responsibility,
  qualification,
  isActive,
}) {
  try {
    const res = await axios.put(
      `${updateJobDetailsAPI}/${id}`,
      {
        title,
        description,
        location,
        job_type: jobType,
        post_type: postType,
        salary,
        responsibility,
        quialification: qualification,
        is_active: isActive,
      },
      {
        headers: {
          Authorization: `Token ${getToken()}`,
        },
      }
    );
    return new HttpResponse(res.status, "Update successful");
  } catch (err) {
    return handleException(err);
  }
}

export async function changeJobStatus(jobId) {
  try {
    const res = await axios.get(`${changeJobStatusAPI}/${jobId}`, {
      headers: {
        Authorization: `Token ${getToken()}`,
      },
    });
    return new HttpResponse(res.status, "Job status changed");
  } catch (err) {
    return handleException(err);
  }
}

/**
 *
 * @param {string} jobId
 * @returns {Promise<[import("../model/ApplicantModel").ApplicantModel[]|null, HttpResponse|null]>}
 */

export async function getApplicantList(jobId) {
  const result = [null, null];
  try {
    const res = await axios.get(`${applicantListAPI}/${jobId}`, {
      headers: {
        Authorization: `Token ${getToken()}`,
      },
    });

    const arr = [];
    for (let x of res.data) {
      arr.push(createApplicantObj(x));
    }
    result[0] = arr;
    return result;
  } catch (err) {
    result[1] = handleException(err);
    return result;
  }
}
