import React, { useCallback, useEffect, useState } from "react";
import ModalLayout from "../global/ModalLayout";
import { getPnrList } from "../../../controller/pnrController";
import { message } from "antd";
import PaginationPageSelectors from "../global/PaginationPageSelectors";
import componentStyle from "../../../css/components.module.css";
import InputField from "../global/InputField";

/**
 * @typedef {object} Props
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setShowPnrSelectorModal
 * @property {(pnr:import("../../../model/PnrListItemModel").PnrListItem)=>void} selectFunc
 */
/**
 * @param {Props} props
 */
export default function PnrSelectorModal({
  setShowPnrSelectorModal,
  selectFunc,
}) {
  /**
   * @type {import("../../../model/PnrListItemModel").PnrListItem[]}
   */
  const pnrListStateType = [];
  const [pnrItemList, setPnrItemList] = useState(pnrListStateType);
  const [searchText, setSearchText] = useState("");
  const [numOfPages, setNumOfPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [fetchingPnrList, setFetchingPnrList] = useState(false);

  const [isSearched, setIsSearched] = useState(false);

  const handlePnrList = async (page = 1, searchText = "") => {
    setFetchingPnrList(true);
    const res = await getPnrList(page, searchText);
    setFetchingPnrList(false);
    if (res[0] !== null) {
      setPnrItemList(res[0].result);
      setNumOfPages(Math.ceil(res[0].count / 10));
      return true;
    } else {
      message.error(res[1].error);
    }
  };

  useEffect(() => {
    handlePnrList();
  }, []);

  async function handlePageChange(page) {
    const previousPage = currentPage;
    setCurrentPage(page);
    const res = await handlePnrList(page, searchText);
    if (!res) {
      setCurrentPage(previousPage);
    }
  }

  async function handleSearch() {
    if (searchText !== "") {
      const res = await handlePnrList(1, searchText);
      if (res) {
        setCurrentPage(1);
        setIsSearched(true);
      }
    }
  }

  async function handleCancelSearch() {
    const res = await handlePnrList();
    if (res) {
      setSearchText("");
      setCurrentPage(1);
      setIsSearched(false);
    }
  }

  return (
    <ModalLayout
      modalCloseAction={() => setShowPnrSelectorModal(false)}
      modalStyles={{
        width: "70%",
        height: "80%",
        overflow: "hidden",
        overflowY: "auto",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "2rem",
        }}
      >
        <InputField
          stylesProps={{ width: "30%" }}
          placeHolder="Search based on name or email"
          inputFieldAttributes={{
            value: searchText,
            onChange: (e) => setSearchText(e.target.value),
          }}
        />
        <button
          className={componentStyle.btnPrimary}
          style={{ marginLeft: "20px" }}
          onClick={handleSearch}
        >
          Search
        </button>
        {isSearched && (
          <button
            onClick={handleCancelSearch}
            style={{
              border: "none",
              transform: "scale(1.5)",
              color: "red",
              background: "white",
              marginLeft: "10px",
            }}
          >
            &times;
          </button>
        )}
      </div>
      <table
        style={{
          width: "100%",
          color: "#212121",
          fontSize: "15px",
          textAlign: "center",
        }}
      >
        <thead>
          <tr style={{ backgroundColor: "#fed7aa" }}>
            <th style={{ border: "1px solid #212121", padding: "10px 20px" }}>
              Customer name
            </th>
            <th style={{ border: "1px solid #212121", padding: "10px 20px" }}>
              Contact details
            </th>
            <th style={{ border: "1px solid #212121", padding: "10px 20px" }}>
              manager
            </th>
            <th style={{ border: "1px solid #212121", padding: "10px 20px" }}>
              Package
            </th>
            <th style={{ border: "1px solid #212121", padding: "10px 20px" }}>
              Pnr
            </th>
            <th style={{ border: "1px solid #212121", padding: "10px 20px" }}>
              Select
            </th>
          </tr>
        </thead>
        {!fetchingPnrList ? (
          <tbody>
            {pnrItemList.length > 0 &&
              pnrItemList.map((data, index) => (
                <tr key={index}>
                  <td
                    style={{
                      border: "1px solid #212121",
                      padding: "10px 20px",
                    }}
                  >
                    {data.customerName}
                  </td>
                  <td
                    style={{
                      border: "1px solid #212121",
                      padding: "10px 20px",
                    }}
                  >
                    Email: {data.customerEmail} <br /> Ph:{" "}
                    {data.customerPhoneNumber}
                  </td>
                  <td
                    style={{
                      border: "1px solid #212121",
                      padding: "10px 20px",
                    }}
                  >
                    {data.manager}
                  </td>
                  <td
                    style={{
                      border: "1px solid #212121",
                      padding: "10px 20px",
                    }}
                  >
                    {data.packageCategory}
                  </td>
                  <td
                    style={{
                      border: "1px solid #212121",
                      padding: "10px 20px",
                    }}
                  >
                    {data.pnrNumber}
                  </td>
                  <td
                    style={{
                      border: "1px solid #212121",
                      padding: "10px 20px",
                    }}
                  >
                    <button
                      className={componentStyle.btnPrimary}
                      onClick={() => {
                        selectFunc && selectFunc(data);
                      }}
                    >
                      Select
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        ) : (
          <p style={{ textAlign: "center", fontSize: "20px", color: "black" }}>
            Loading...🚀
          </p>
        )}
      </table>

      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "3rem" }}
      >
        {numOfPages > 1 && (
          <PaginationPageSelectors
            totalPages={numOfPages}
            currentPage={currentPage}
            pageClickFunc={(page) => handlePageChange(page)}
          />
        )}
      </div>
    </ModalLayout>
  );
}
