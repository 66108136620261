import { createAtToDate } from "../util/util";

export class ContactModel {
  /**
   * @type {number}
   */
  id;
  /**
   *  @type {string}
   */
  pageName;
  /**
   *  @type {string|null}
   */
  name;
  /**
   *  @type {string}
   */
  email;
  /**
   *  @type {string|null}
   */
  message;
  /**
   * @type {string}
   */
  date;

  /**
   *
   * @type {string} data
   */
  contactNumber;
  constructor(data) {
    this.id = data.id;
    this.pageName = data.page_name;
    this.name = data.name;
    this.email = data.email;
    this.message = data.message;
    this.date = createAtToDate(data.created_at);
    this.contactNumber = data.contact_no !== null ? data.contact_no : "";
  }
}
