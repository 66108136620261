import {
  FERRY_DAY_NO,
  FERRY_DESCRIPTION,
  FERRY_DESTINATION,
  FERRY_HEADING,
  FERRY_INDEX,
  FERRY_SOURCE,
  FERRY_TYPE,
  IS_FERRY_TRAVEL_INFO_UPDATE,
  RESET_FERRY_FORM,
} from "../../actions/FerryTravelFormAction";

/**
 * @type {import("./ferryFormStatetypes").FerryTravelFormState}
 */
const initialState = {
  id: null,
  isUpdate: false,
  day_no: "",
  index: "",
  activity_type: "travel_ferry",
  description: "",
  dest: "",
  heading: "",
  source: "",
  type: "",
};

/**
 * @returns {import("./ferryFormStatetypes").FerryTravelFormState}
 */

export function ferryTravelFormReducer(state = initialState, action) {
  switch (action.type) {
    case FERRY_DAY_NO:
      return {
        ...state,
        day_no: action.payload,
      };
    case FERRY_INDEX:
      return {
        ...state,
        index: action.payload,
      };
    case FERRY_HEADING:
      return {
        ...state,
        heading: action.payload,
      };
    case FERRY_DESCRIPTION:
      return {
        ...state,
        description: action.payload,
      };
    case FERRY_SOURCE:
      return {
        ...state,
        source: action.payload,
      };
    case FERRY_DESTINATION:
      return {
        ...state,
        dest: action.payload,
      };
    case FERRY_TYPE:
      return {
        ...state,
        type: action.payload,
      };
    case IS_FERRY_TRAVEL_INFO_UPDATE:
      return {
        ...action.payload,
        isUpdate: true,
      };
    case RESET_FERRY_FORM:
      return initialState;
    default:
      return state;
  }
}
