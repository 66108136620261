import React from 'react'
import dayframe from "./Dayframe.png"
import couple from "./couple.png"
import img2 from "./img23.png"
import car from "./car.png"
import resort from "./resort.png"
import ferry from "./ferry icon.svg"
import pin from "./pin.png"
import company from "./company.png"
import Asset from "./Asset31.png"
import Demo3 from "./Demo3.png"
import CarImg from "../../../images/car.png";
import ferryImg from "../../../images/ferry.png";

export function Input({ label, value, options, onChange, placeholder, type, className, name, disabled }) {
  return (
    <div className={className}>
      <label htmlFor={name} style={{ color: disabled ? "#FD9525" : "black" }} className=' whitespace-nowrap'>{label}</label>
      {type === "select" ?
        <>
          <br />
          <select defaultValue={value} style={{ width: "100%", borderColor: "gray", }} className='form-control input-default' id={name} name={name} placeholder={placeholder} onChange={onChange}>
            {options.map((o, i) => (
              <option value={o}>{o}</option>
            ))}
          </select>
        </>

        : <input id={name} disabled={disabled} value={value} name={name} type={type || "text"} style={{ borderColor: "gray", borderWidth: 1, }} className={"form-control input-default"} placeholder={placeholder} onChange={onChange} />
      }

    </div>
  )
}


export function HeaderComp1({ title, discription }) {
  return (
    <>
      <h3>{title}</h3>
      <p>{discription}</p>
    </>
  )
}

export function DateCard({ day, date, i }) {
  return (
    <div style={{ display: "grid", placeContent: "center" }} >
      <div className='d-flex justify-content-between align-items-center'>
        <div className='position-relative'>
          <img src={dayframe} style={{ width: 30, height: 30 }} />
          <h4 style={{ position: "absolute", top: 11, left: 10, fontWeight: 700 }}>{day}</h4>
        </div>
        <h3 style={{ color: "#777", fontWeight: 700 }} >{date}</h3>
      </div>

      <img src={i%3 === 1 ? Asset   : i%2 === 0 ? img2  : couple } style={{ width: 200, height: 150, }} className='mx-auto mt-md-4' />
    </div>
  )
}

export function ActivityFilter({ data }) {
  return (
    <div style={{ marginTop: 15, }}>
      {
        data.activity_type === "travel_car" ?
          <div className='d-flex gap-1'>
            <img src={car} style={{ width: 25, height: 25,marginTop: -5, marginRight: 10 }} />
            <h5>{data.car_detail_json.source+ " "}
           to {data.car_detail_json.dest + " "}
           {/* in {data.car_detail_json.car_type} */}
           </h5>
          </div> :
          data.activity_type === "sightseeing" ?
            <div className='d-flex'>
              <img src={pin} style={{ width: 15, height: 25,marginTop: -5, marginRight: 20 }} />
              <h5>{data.sightseeing_detail[0].name}</h5>
              <h5 style={{ marginLeft: 5 }}>in {data.sightseeing_detail[0].location}</h5>
            </div> :
            data.activity_type === "accommodation" ?
              <div className='d-flex'>
                <img src={company} style={{ width: 25, height: 25,marginTop: -5, marginRight: 10 }} />
                <h5>Check in</h5>
                <h5>to {data.accommodation_detail[0].name}</h5>
                <h5>, {data.accommodation_detail[0].location}</h5>
              </div> :
              data.activity_type === "travel_ferry" ?
                <div className='d-flex align-items-center gap-1'>
                  <img src={ferry} style={{ width: 25, height: 25, marginRight: 10, marginTop: -10 }} />
                  <h5> Ferry from {data.ferry_detail_json.source}{' '}
                 to {data.ferry_detail_json.dest}{" "} 
                  by {data.ferry_detail_json.type}</h5>
                </div> :
                data.activity_type === "meal_breakfast" ?
                  <div className='d-flex'>
                    <img src={ferry} style={{ width: 25, height: 25, marginRight: 10 }} />
                    <h5 style={{ marginLeft: 5 }}>Ferry {data.description}</h5>

                  </div>
                  : null
      }
    </div>
  )
}


export function ActivityFilter2({ data }) {
  return (
    <div >
      {
        data.activity_type === "travel_car" ?
          <div>
            <div style={{textOverflow:"ellipsis",overflowY: "hidden", whiteSpace: "nowrap"}} className='d-flex gap-2 flex-wrap'>
              <img src={car} style={{ width: 25, height: 25,margin: "0 0 10px 0"}} />
              <h4 style={{textOverflow:"ellipsis",overflowY: "hidden"}}>{data.car_detail_json.source}</h4>
              <h4 style={{textOverflow:"ellipsis",}}>to {data.car_detail_json.dest}</h4>
              <h4 style={{textOverflow:"ellipsis",}}>in {data.car_detail_json.car_type}</h4>
            </div>
            <p>{data.heading}</p>
            <div className='d-flex gap-10'>
              <img src={CarImg} style={{ width: 150, height: 85, }} />
              <div style={{ marginLeft: 20 }}>
                <h4>Private Transfer / {data.car_detail_json.car_type} - {data.car_detail_json.ac_non}</h4>
                <p >{data.car_detail_json.car_name}</p>
                <h5>
                  {data.car_detail_json.no_of_searters} seater | {" "}
                  {data.car_detail_json.ac_non} |
                  {data.car_detail_json.luggage_space} Luggage space |
                  {" First Aid"}

                </h5>
              </div>
            </div>
            Inclusion :  {data.description}
          </div> :
          data.activity_type === "sightseeing" ?
            <div>
              <div className='d-flex align-items-center'>
                <img src={pin} style={{ width: 15, height: 25, margin: "0 10px 10px 0"}} />
                <h4>Sightseeing</h4>
                <h4 style={{ marginLeft: 5 }}>in {data.sightseeing_detail[0].location}</h4>
              </div>
              <p>{data.heading}</p>
              <div className='d-flex gap-10'>
                <img src={data.sightseeing_detail[0].image || Demo3}  style={{ width: 150, height: 85, }} />
                <div style={{ marginLeft: 20 }}>
                  <h3>{data.sightseeing_detail[0].name}</h3>

                </div>
              </div>
              <p style={{ letterSpacing: 1, marginTop: 20, color: "#555" }}>
                {data.sightseeing_detail[0].sightseeing_description}
              </p>
            </div> :
            data.activity_type === "accommodation" ?
              <div className=''>
                <div className='d-flex align-items-center'>
                <img src={company} style={{ width: 25, height: 25, margin: "0 10px 10px 0" }} />
                <h4>Hotel at {data.accommodation_detail[0].location}</h4>
                {/* <h4>to {data.accommodation_detail[0].name}</h4> */}
                </div>
                <p>{data.heading}</p>
                <div className='d-flex gap-10'>
                  <img src={data.accommodation_detail[0].image}  style={{ width: 150, height: 85, }} />
                  <div style={{ marginLeft: 20 }}>
                    <h3>{data.accommodation_detail[0].name}</h3>
                    <h5><img src={pin} style={{ height:18,}} /> {data.accommodation_detail[0].location}</h5>
                  
                    {/* <div style={{ display: "flex", justifyContent: "space-between",gap:10 }}>
                      <p>
                        Check In<br/>
                        {data.check_in}
                      </p>
                      <p>
                        Check out <br/>
                        {data.check_out}
                      </p>
                    </div> */}
                    <p style={{ letterSpacing: 1 }}>
                      {/* {data.accommodation_detail[0].no_of_searters} |
                      {data.accommodation_detail[0].ac_non} | */}
                        {data.accommodation_detail[0].accommodation_description}

                    </p>
                  </div>

                </div>
              </div>

              :
              data.activity_type === "travel_ferry" ?
                <div>
                  <div className='d-flex align-items-center'>
                    <img src={ferry} style={{ width: 25, height: 25,margin: "0 10px 10px 0" }} />
                    <h4> Ferry from {data.ferry_detail_json.source} to {data.ferry_detail_json.dest}</h4>
                  </div>
                  <p>{data.heading}</p>
                  <div className='d-flex gap-10'>
                    <img src={ferryImg}  style={{ width: 150, height: 85, }} />
                      <h3 style={{marginLeft:10}}>Ferry from {data.ferry_detail_json.source} to {data.ferry_detail_json.dest} {" " +data.ferry_detail_json.type}</h3>
                  </div>
                </div>
                : null
      }
    </div >
  )
}