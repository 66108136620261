import React from 'react'
import Print from './Print'
import { useState } from 'react'
import { Modal } from 'antd'
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { useRef } from 'react';
import img6 from "./opened envelope-png 2.png"
import img7 from "./icons8-download-94.png"
import img8 from "./icons8-server-94.png"
import img9 from "./icons8-whatsapp-750.png"
import { HeaderComp1 } from './Components';

function Form4() {

  const [print, setPrint] = useState(false)

  
  const componentRef = useRef();

  const handle = useReactToPrint({
    content: () => componentRef.current,
  })


  let inData = [
    // {
    //   title: "Send in Email",
    //   img: img6
    // },
    // {
    //   title: "Send in WhatsApp",
    //   img: img9
    // },
    {
      title: "View in Data Base",
      img: img8
    },
    {
      title: "Download as PDF",
      img: img7,
      handle: handle
    },
  ]
  function handlePrint() {
    setPrint(true)
  }

  
  return (
    <>
      <HeaderComp1 title="Sharing Options"
        discription="Itinerary was saved in system successfully use the below options to share with customers"
      />
      <div className='d-flex gap-4 mb-4'>
        {inData.map((data, i) => (
          <div role='button' onClick={() => data.handle()} className=' p-2 border border-primary border-1 d-flex gap-2 align-items-center'>
            <img src={data.img} height={30} />
            <h4>{data.title}</h4>
          </div>))}
      </div>
      <Print ref={componentRef} />
    </>
  )
}

export default Form4
