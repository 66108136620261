import "./VoucherStateType";

/**
 * @typedef {object} UpdatePayload
 * @property {number} index
 * @property {string} value
 */

/**
 * @param {VoucherState} state
 * @param {UpdatePayload} payload
 * @returns {string[]}
 */
export function updateInclusion(state, payload) {
  const newArr = [...state.inclusion];
  newArr[payload.index] = payload.value;
  return newArr;
}

/**
 * @param {VoucherState} state
 *  @param {UpdatePayload} payload
 * @returns {string[]}
 */

export function updateInstruction(state, payload) {
  const newArr = [...state.instruction];
  newArr[payload.index] = payload.value;
  return newArr;
}
