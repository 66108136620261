/**
 * @typedef {object} User
 * @property {number} id
 * @property {string} name
 * @property {string} email
 * @property {string} phoneNumber
 * @property {string} createdAt
 */

import { createAtToDate } from "../util/util";

/**
 *
 * @returns {User}
 */
export function createUserObj(data) {
  /**
   * @type {User}
   */
  const result = {
    id: data.id,
    name: data.name,
    email: data.email,
    phoneNumber: data.phone_no,
    createdAt: createAtToDate(data.created_at),
  };
  return result;
}
