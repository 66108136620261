import React, { useEffect, useRef, useState } from "react";
import ModalLayout from "../../global/ModalLayout";
import { useDispatch, useSelector } from "react-redux";
import {
  addFlightTravelDayNo,
  addFlightTravelFightDate,
  addFlightTravelFlightDestination,
  addFlightTravelFlightDuration,
  addFlightTravelFlightName,
  addFlightTravelFlightNumber,
  addFlightTravelFlightSource,
  addFlightTravelIndex,
  addFlightTravelLandingTime,
  addFlightTravelTakeoffTime,
  resetFlightTravelForm,
} from "../../../../store/actions/FlightTravelFormAction";
import InputField from "../../global/InputField";
import componentStyles from "../../../../css/components.module.css";
import {
  addWidgetInfo,
  updateFlightTravelInfo,
} from "../../../../store/actions/PackageAction";
/**
 * @typedef {object} Props
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setShowFlightTravelFormModal
 * @property {number} day
 */

/**
 * @param {Props} props
 */
export default function FlightTravelFormModal({
  setShowFlightTravelFormModal,
  day,
}) {
  /**
   * @type {import("../../../../store/reducers/flighttravel/flightTravelTypes").FlightTravelState}
   */
  const {
    day_no,
    index,
    flight_source,
    flight_dest,
    flight_duration,
    take_off,
    landing,
    flight_name,
    flight_no,
    flight_date,
    isUpdate,
    activity_type,
  } = useSelector((state) => state.flightTravel);
  const { complete_tour_data } = useSelector((state) => state.package);
  const dispatch = useDispatch();
  const widgetIndex = useRef(`${complete_tour_data[day - 1].length + 1}`);
  const [inputError, setInputError] = useState(false);

  useEffect(() => {
    if (index === "" && day_no === "") {
      dispatch(addFlightTravelDayNo(`${day}`));
      dispatch(addFlightTravelIndex(widgetIndex.current));
    }
  }, [day, day_no, dispatch, index]);

  function inputCheck() {
    return (
      flight_source !== "" &&
      flight_dest !== "" &&
      flight_duration !== "" &&
      take_off !== "" &&
      landing !== "" &&
      flight_name !== "" &&
      flight_no !== "" &&
      flight_date !== ""
    );
  }

  function handleSave() {
    if (inputCheck()) {
      const dataObj = {
        activity_type,
        day_no,
        index,
        flight_source,
        flight_dest,
        flight_duration,
        take_off,
        landing,
        flight_name,
        flight_no,
        flight_date,
      };
      if (!isUpdate) {
        dispatch(addWidgetInfo(dataObj));
      } else {
        dispatch(updateFlightTravelInfo(dataObj));
      }
      dispatch(resetFlightTravelForm());
      setShowFlightTravelFormModal(false);
    } else {
      setInputError(true);
    }
  }

  return (
    <ModalLayout
      modalCloseAction={() => {
        dispatch(resetFlightTravelForm());
        setShowFlightTravelFormModal(false);
      }}
      modalStyles={{
        width: "70%",
        height: "70%",
        overflowY: "scroll",
      }}
    >
      <InputField
        label="Flight name"
        inputFieldAttributes={{
          value: flight_name,
          onChange: (e) => dispatch(addFlightTravelFlightName(e.target.value)),
        }}
        showInputError={inputError}
      />
      <InputField
        label="Flight number"
        inputFieldAttributes={{
          value: flight_no,
          onChange: (e) =>
            dispatch(addFlightTravelFlightNumber(e.target.value)),
        }}
        showInputError={inputError}
        stylesProps={{ marginTop: "1.5rem" }}
      />
      <InputField
        label="Flight date"
        inputFieldAttributes={{
          value: flight_date,
          type: "date",
          onChange: (e) => dispatch(addFlightTravelFightDate(e.target.value)),
        }}
        showInputError={inputError}
        stylesProps={{ marginTop: "1.5rem" }}
      />

      <InputField
        label="Flight duration (hours)"
        inputFieldAttributes={{
          value: flight_duration,
          type: "number",
          onChange: (e) =>
            dispatch(addFlightTravelFlightDuration(e.target.value)),
        }}
        showInputError={inputError}
        stylesProps={{ marginTop: "1.5rem" }}
      />
      <InputField
        label="Source"
        inputFieldAttributes={{
          value: flight_source,
          onChange: (e) =>
            dispatch(addFlightTravelFlightSource(e.target.value)),
        }}
        showInputError={inputError}
        stylesProps={{ marginTop: "1.5rem" }}
      />
      <InputField
        label="Destination"
        inputFieldAttributes={{
          value: flight_dest,
          onChange: (e) =>
            dispatch(addFlightTravelFlightDestination(e.target.value)),
        }}
        showInputError={inputError}
        stylesProps={{ marginTop: "1.5rem" }}
      />
      <InputField
        label="Take off time"
        inputFieldAttributes={{
          value: take_off,
          type: "time",
          onChange: (e) => dispatch(addFlightTravelTakeoffTime(e.target.value)),
        }}
        showInputError={inputError}
        stylesProps={{ marginTop: "1.5rem" }}
      />
      <InputField
        label="Landing time"
        inputFieldAttributes={{
          value: landing,
          type: "time",
          onChange: (e) => dispatch(addFlightTravelLandingTime(e.target.value)),
        }}
        showInputError={inputError}
        stylesProps={{ marginTop: "1.5rem" }}
      />
      <div
        style={{
          marginTop: "25px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <button
          style={{ border: "none" }}
          onClick={() => {
            dispatch(resetFlightTravelForm());
            setShowFlightTravelFormModal(false);
          }}
        >
          Close
        </button>
        <button className={componentStyles.btnPrimary} onClick={handleSave}>
          &#x2713; {!isUpdate ? "Save" : "Update"}
        </button>
      </div>
    </ModalLayout>
  );
}
