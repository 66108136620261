import "../reducers/packagereducer/reduerStateType";

export const PACKAGE_HEADING = "package heading";
export const IS_PACKAGE_INITIAL_DATA_FILLED = "is package initial data filled";
export const RESET_PACKAGE_STATE = "reset package state";
export const PACKAGE_DESCRIPTION = "package description";
export const PACKAGE_MANAGED_BY = "package managed by";
export const PACKAGE_ITINERARY_TYPE = "package itinerary type";
export const PACKAGE_ITINERARY_SUB_TYPE = "package itinerary sub type";
export const PACKAGE_HOTEL_TYPE = "package hotel type";
export const PACKAGE_DAY = "package day";
export const PACKAGE_NIGHT = "package night";
export const ADD_PACKAGE_ACTIVITY = "add package activity";
export const REMOVE_PACKAGE_ACTIVITY = "remove package activity";
export const ADD_PACKAGE_RATING = "add package rating";
export const PACKAGE_PRICE = "package price";
export const PACKAGE_MEAL = "package meal";
export const ADD_PACKAGE_TOUR_DAY = "package tour day";
export const REMOVE_PACKAGE_TOUR_DAY = "remove package tour day";
export const MOVE_UP_PACKAGE_TOUR_DAY = "move up package tour day";
export const MOVE_DOWN_PACKAGE_TOUR_DAY = "move down package tour day";
export const ADD_WIDGET_INFO = "add widget info";
export const UPDATE_CAR_TRAVEL_INFO = "update car travel info";
export const REMOVE_WIDGET_DATA = "remove widget data";
export const MOVE_UP_ACTIVITY = "move up activity";
export const MOVE_DOWN_ACITIVITY = "move down activity";
export const UPDATE_FERRY_TRAVEL_INFO = "update ferry travel info";
export const UPDATE_SIGHT_SEEN_INFO = "update sight seen info";
export const UPDATE_FLIGHT_TRAVEL_INFO = "update flight travel info";
export const INSERT_COMPLETE_PACKAGE_DATA = "insert complete package data";

export function resetPackageState() {
  return {
    type: RESET_PACKAGE_STATE,
  };
}

export function setIsPackageInitialDataFilled(payload) {
  return {
    type: IS_PACKAGE_INITIAL_DATA_FILLED,
    payload,
  };
}

/**
 *
 * @param {string} heading
 */
export function addPackageHeading(heading) {
  return {
    type: PACKAGE_HEADING,
    payload: heading,
  };
}

/**
 *
 * @param {string} description
 */

export function addPackageDescription(description) {
  return {
    type: PACKAGE_DESCRIPTION,
    payload: description,
  };
}

/**
 *
 * @param {string} manager
 */
export function addPackageManager(manager) {
  return {
    type: PACKAGE_MANAGED_BY,
    payload: manager,
  };
}

/**
 *
 * @param {string} itinerary
 */
export function addPackageItineraryType(itinerary) {
  return {
    type: PACKAGE_ITINERARY_TYPE,
    payload: itinerary,
  };
}

/**
 *
 * @param {string} itinerarySubType
 */

export function addPackageItinerarySubType(itinerarySubType) {
  return {
    type: PACKAGE_ITINERARY_SUB_TYPE,
    payload: itinerarySubType,
  };
}
/**
 *
 * @param {string} rating
 */

export function addPackageRating(rating) {
  return {
    type: ADD_PACKAGE_RATING,
    payload: rating,
  };
}

/**
 *
 * @param {string} day
 */
export function addPackageDay(day) {
  return {
    type: PACKAGE_DAY,
    payload: day,
  };
}

/**
 * @param {string} night
 */

export function addPackageNight(night) {
  return {
    type: PACKAGE_NIGHT,
    payload: night,
  };
}

/**
 *
 * @param {string} hotel
 */
export function addPackageHotelType(hotel) {
  return {
    type: PACKAGE_HOTEL_TYPE,
    payload: hotel,
  };
}

/**
 *
 * @param {string} meal
 */
export function addPackageMeal(meal) {
  return {
    type: PACKAGE_MEAL,
    payload: meal,
  };
}

/**
 * @param {string} price
 */

export function addPackagePrice(price) {
  return {
    type: PACKAGE_PRICE,
    payload: price,
  };
}

/**
 *
 * @param {string} activity
 */
export function addPackageActivity(activity) {
  return {
    type: ADD_PACKAGE_ACTIVITY,
    payload: activity,
  };
}

/**
 *
 * @param {string} activity
 */
export function removePackageActivity(activity) {
  return {
    type: REMOVE_PACKAGE_ACTIVITY,
    payload: activity,
  };
}

export function addNewActivityDay() {
  return {
    type: ADD_PACKAGE_TOUR_DAY,
  };
}

/**
 *
 * @param {number} index
 */

export function removeActivityDay(index) {
  return {
    type: REMOVE_PACKAGE_TOUR_DAY,
    payload: index,
  };
}

/**
 *
 * @param {number} index
 */

export function moveUpActivityDay(index) {
  return {
    type: MOVE_UP_PACKAGE_TOUR_DAY,
    payload: index,
  };
}

/**
 *
 * @param {number} index
 */

export function moveDownActivityDay(index) {
  return {
    type: MOVE_DOWN_PACKAGE_TOUR_DAY,
    payload: index,
  };
}

/**
 *
 * @param {DayScheduleWidget} widgetData
 */

export function addWidgetInfo(widgetData) {
  return {
    type: ADD_WIDGET_INFO,
    payload: widgetData,
  };
}

/**
 *
 * @param {TravelByCar} carInfo
 */

export function updateCarTravelInfo(carInfo) {
  return {
    type: UPDATE_CAR_TRAVEL_INFO,
    payload: carInfo,
  };
}

/**
 *
 * @param {TravelFlight} flightInfo
 */
export function updateFlightTravelInfo(flightInfo) {
  return {
    type: UPDATE_FLIGHT_TRAVEL_INFO,
    payload: flightInfo,
  };
}

/**
 * @typedef {object} dayIndexPayload
 * @property {number} day_no -- day_no key in the acitivity object
 * @property {number} index -- index key in the  acitivity object
 */

/**
 *
 * @param {dayIndexPayload} payload
 */

export function removeWidgetInfo(payload) {
  return {
    type: REMOVE_WIDGET_DATA,
    payload,
  };
}

/**
 * @param {dayIndexPayload} payload
 */
export function moveDownActivity(payload) {
  return {
    type: MOVE_DOWN_ACITIVITY,
    payload,
  };
}

/**
 * @param {dayIndexPayload} payload
 */
export function moveUpActivity(payload) {
  return {
    type: MOVE_UP_ACTIVITY,
    payload,
  };
}

/**
 *
 * @param {TravelFerry} payload
 * @returns
 */
export function updateFerryTravelInfo(payload) {
  return {
    type: UPDATE_FERRY_TRAVEL_INFO,
    payload,
  };
}

/**
 *
 * @param {SightSeeing} payload
 */
export function updateSightSeenInfo(payload) {
  return {
    type: UPDATE_SIGHT_SEEN_INFO,
    payload,
  };
}

/**
 *
 * @param {PackageState} payload
 */
export function insertCompletePackageData(payload) {
  return {
    type: INSERT_COMPLETE_PACKAGE_DATA,
    payload,
  };
}
