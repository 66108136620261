import React, { useCallback, useEffect, useState } from "react";
import InputField from "../../components/global/InputField";
import useCreateEditor from "../../components/global/RichEditor/useCreateEditor";
import RichEditor from "../../components/global/RichEditor/RichEditor";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import Button from "../../components/global/Button";
import SelectField from "../../components/global/SelectField";
import {
  createJob,
  getJobDetails,
  updateJobDetails,
} from "../../../controller/CareerController";
import { HTTPStatus } from "../../../util/helperObj";
import { message } from "antd";
import { handleControllerResultException } from "../../../util/handleException";
export default function JobForm() {
  const history = useHistory();
  const [inputError, setInputError] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("<p>Job description</p>");
  const [location, setLocation] = useState("");
  const [postType, setPostType] = useState("");
  const [jobType, setJobType] = useState("");
  const [salary, setSalary] = useState("");
  const [qualification, setQualication] = useState("<p>Qualification</p>");
  const [responsibility, setResponsibility] = useState("<p>Responsibility</p>");
  const [jobStatus, setJobStatus] = useState("Active");
  const [loading, setLoading] = useState(false);
  const descriptionEdtor = useCreateEditor(description);
  const qualicationEditor = useCreateEditor(qualification);
  const responsibilityEditor = useCreateEditor(responsibility);
  const { jobId } = useParams();

  function checkInputError() {
    return (
      title !== "" &&
      location !== "" &&
      postType !== "" &&
      jobType !== "" &&
      salary !== ""
    );
  }

  async function handleSave() {
    if (checkInputError()) {
      setLoading(true);
      const res = await createJob({
        title,
        description: JSON.stringify(descriptionEdtor.getHTML()),
        location,
        jobType,
        postType,
        salary,
        responsibility: JSON.stringify(qualicationEditor.getHTML()),
        qualification: JSON.stringify(responsibilityEditor.getHTML()),
        isActive: jobStatus === "Active" ? true : false,
      });
      setLoading(false);
      if (res.statusCode === HTTPStatus.SUCCESS) {
        message.success();
        history.goBack();
      } else {
        handleControllerResultException(res, history);
      }
    } else {
      setInputError(true);
    }
  }

  const handleJobDetails = useCallback(
    async (jobId) => {
      const res = await getJobDetails(jobId);
      if (res[0] !== null) {
        setTitle(res[0].title);
        setDescription(
          res[0].description !== ""
            ? JSON.parse(res[0].description)
            : "<p>Job description</p>"
        );
        setLocation(res[0].location);
        setJobType(res[0].jobType);
        setPostType(res[0].postType);
        setSalary(res[0].salary);
        setQualication(
          res[0].qualification !== ""
            ? JSON.parse(res[0].qualification)
            : "<p>Qualification</p>"
        );
        setResponsibility(
          res[0].responsibility !== ""
            ? JSON.parse(res[0].responsibility)
            : "<p>Responsibility</p>"
        );
        setJobStatus(res[0].isActive ? "Active" : "Inactive");
      } else {
        handleControllerResultException(res[1], history);
      }
    },
    [history]
  );
  useEffect(() => {
    if (jobId) {
      handleJobDetails(jobId);
    }
  }, [handleJobDetails, jobId]);

  async function handleUpdate() {
    setLoading(true);
    const res = await updateJobDetails({
      id: jobId,
      title,
      description: JSON.stringify(descriptionEdtor.getHTML()),
      location,
      jobType,
      postType,
      salary,
      responsibility: JSON.stringify(qualicationEditor.getHTML()),
      qualification: JSON.stringify(responsibilityEditor.getHTML()),
      isActive: jobStatus === "Active" ? true : false,
    });
    setLoading(false);
    if (res.statusCode === HTTPStatus.SUCCESS) {
      message.success(res.success);
    } else {
      handleControllerResultException(res, history);
    }
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "3rem",
        }}
      >
        <Button onClick={() => history.goBack()}>Return</Button>
        <Button
          onClick={jobId ? handleUpdate : handleSave}
          loading={loading}
          disabled={loading}
        >
          {jobId ? "Update" : "Save"}
        </Button>
      </div>
      <InputField
        label="Title"
        inputFieldAttributes={{
          value: title,
          onChange: (e) => setTitle(e.target.value),
        }}
        showInputError={inputError}
        stylesProps={{ marginBottom: "2rem" }}
      />
      <RichEditor
        editor={descriptionEdtor}
        heading="Job Description"
        styles={{ marginBottom: "2rem" }}
      />
      <InputField
        label="Location"
        inputFieldAttributes={{
          value: location,
          onChange: (e) => setLocation(e.target.value),
        }}
        showInputError={inputError}
        stylesProps={{ marginBottom: "2rem" }}
      />
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "2rem" }}
      >
        <InputField
          label="Job type"
          inputFieldAttributes={{
            value: jobType,
            onChange: (e) => setJobType(e.target.value),
          }}
          showInputError={inputError}
          stylesProps={{ width: "100%" }}
        />
        <InputField
          label="Post type"
          inputFieldAttributes={{
            value: postType,
            onChange: (e) => setPostType(e.target.value),
          }}
          showInputError={inputError}
          stylesProps={{ width: "100%", marginLeft: "1rem" }}
        />
        <InputField
          label="Salary"
          inputFieldAttributes={{
            value: salary,
            onChange: (e) => setSalary(e.target.value),
          }}
          showInputError={inputError}
          stylesProps={{ width: "100%", marginLeft: "1rem" }}
        />
      </div>
      <RichEditor
        heading="Qualification"
        editor={qualicationEditor}
        styles={{ marginBottom: "2rem" }}
      />
      <RichEditor
        heading="Responsibility"
        editor={responsibilityEditor}
        styles={{ marginBottom: "2rem" }}
      />
      <SelectField
        label="Job status"
        options={["Active", "Inactive"]}
        selectFieldAttributes={{
          value: jobStatus,
          onChange: (e) => setJobStatus(e.target.value),
        }}
      />
    </div>
  );
}
