import React, { useState } from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { updateReviewStatus } from "../../../../controller/collectionController";
import { message } from "antd";
import { useDispatch } from "react-redux";
import { logout } from "../../../../store/actions/AuthActions";

/**
 * @typedef {object} Props
 * @property {number} reviewId
 * @property {string | null} defaultStatus
 */

/**
 * @param {Props} props
 * @returns render review status
 */

function ReviewStatus({ reviewId, defaultStatus, history }) {
  const [status, setStatus] = useState(
    defaultStatus !== null ? defaultStatus : ""
  );
  const dispatch = useDispatch();

  /**
   * @param {string} status
   */
  async function handleStatus(status) {
    setStatus(status);
    if (status !== "") {
      const res = await updateReviewStatus(reviewId, status);
      if (res.statusCode === 200) {
        message.success(res.success !== null ? res.success : "Status updated");
      } else if (res.statusCode === 401) {
        dispatch(logout(history));
      } else {
        message.error(res.error !== null ? res.error : "Something went wrong");
      }
    }
  }
  return (
    <div>
      <select
        className="rounded"
        value={status}
        onChange={(e) => handleStatus(e.target.value)}
      >
        <option value="">Status</option>
        <option value="Live">Live</option>
        <option value="Draft">Draft</option>
      </select>
    </div>
  );
}

export default withRouter(ReviewStatus);
