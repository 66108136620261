import React, { useCallback, useEffect, useState } from "react";
import "../../../../model/typeDefs";
import { Tab } from "react-bootstrap";
import { getContactList } from "../../../../controller/collectionController";
import { useDispatch } from "react-redux";
import { logout } from "../../../../store/actions/AuthActions";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import Table from "../../../components/global/Table/Table";
import componentStyle from "../../../../css/components.module.css";
import InputField from "../../../components/global/InputField";
import PaginationPageSelectors from "../../../components/global/PaginationPageSelectors";
/**
 * @type {ContactModel[]}
 */
const contactListType = [];

function ContactList({ history }) {
  const [contactList, setContactList] = useState(contactListType);
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isSearched, setIsSearched] = useState(false);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchContacts = useCallback(
    async (page = 1, search = "") => {
      const res = await getContactList(page, search);
      setFetching(false);
      if (res[0] !== null) {
        setContactList(res[0].result);
        setNumberOfPages(Math.ceil(res[0].count / 20));
        return true;
      } else if (res[1].statusCode === 401) {
        dispatch(logout(history));
      } else {
        if (res[1].error !== null) {
          alert(res[1].error);
        }
      }
    },
    [dispatch, history]
  );

  async function handleSearch() {
    if (searchText !== "") {
      const res = await fetchContacts(1, searchText);
      if (res) {
        setCurrentPage(1);
        setIsSearched(true);
      }
    }
  }
  async function handleCancelSearch() {
    const res = await fetchContacts(1, "");
    if (res) {
      setSearchText("");
      setCurrentPage(1);
      setIsSearched(false);
    }
  }

  async function handlePageChange(pageNumber) {
    const res = await fetchContacts(pageNumber, searchText);
    if (res) {
      setCurrentPage(pageNumber);
    }
  }

  useEffect(() => {
    fetchContacts();
  }, [fetchContacts]);

  return (
    <Tab.Pane eventKey="Contact">
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "2rem" }}
      >
        <InputField
          stylesProps={{ width: "30%" }}
          placeHolder="Search based on name or email"
          inputFieldAttributes={{
            value: searchText,
            onChange: (e) => setSearchText(e.target.value),
          }}
        />
        <button
          className={componentStyle.btnPrimary}
          style={{ marginLeft: "20px" }}
          onClick={handleSearch}
          disabled={fetching}
        >
          Search
        </button>
        {isSearched && (
          <button
            onClick={handleCancelSearch}
            style={{
              border: "none",
              transform: "scale(1.5)",
              color: "red",
              background: "white",
              marginLeft: "10px",
            }}
          >
            &times;
          </button>
        )}
      </div>
      <Table>
        <thead>
          <tr>
            <th>Page</th>
            <th>User name</th>
            <th>Email</th>
            <th>Contact no</th>
            <th>Date</th>
            <th>Message</th>
          </tr>
        </thead>
        <tbody>
          {contactList.length > 0 &&
            contactList.map((data) => (
              <tr key={data.id}>
                <td>{data.pageName}</td>
                <td>{data.name !== null ? data.name : "-"}</td>
                <td>{data.email}</td>
                <td>{data.contactNumber === "" ? "N/A":data.contactNumber }</td>
                <td>{data.date}</td>
                <td>{data.message !== null ? data.message : "-"}</td>
              </tr>
            ))}
        </tbody>
      </Table>
      {isSearched && contactList.length === 0 ? (
        <p
          style={{
            textAlign: "center",
            marginTop: "20px",
            color: "#212121",
            fontSize: "15px",
          }}
        >
          No result found🥲
        </p>
      ) : (
        <></>
      )}
      {numberOfPages > 1 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "2rem",
          }}
        >
          <PaginationPageSelectors
            totalPages={numberOfPages}
            currentPage={currentPage}
            pageClickFunc={(page) => handlePageChange(page)}
          />
        </div>
      )}
    </Tab.Pane>
  );
}

export default withRouter(ContactList);
