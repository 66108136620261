import React, { useEffect, useState } from "react";
import { fetchIteneraryTypes } from "../../../controller/PackageController";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  addPackageItinerarySubType,
  addPackageItineraryType,
} from "../../../store/actions/PackageAction";
import SelectField from "../global/SelectField";
import "../../../store/reducers/packagereducer/reduerStateType";

/**
 * @type {[string[],string[]]}
 */
const itineraryInitialState = [[], []];

export default function ItineraryTypeSelector({ showInputError = false }) {
  const [itinerayTypeArr, setItineraryTypeArr] = useState(
    itineraryInitialState
  );

  /**
   * @type {PackageState}
   */
  const { itinerary_type, itinerary_sub_type } = useSelector(
    (state) => state.package
  );

  const dispatch = useDispatch();

  const fetchItenareryTypeHandler = async () => {
    const res = await fetchIteneraryTypes();
    if (Array.isArray(res)) {
      setItineraryTypeArr(res);
    } else {
      message.error(res.error);
    }
  };

  useEffect(() => {
    fetchItenareryTypeHandler();
  }, []);

  return (
    <>
      <SelectField
        label="Itinerary type"
        selectFieldAttributes={{
          value: itinerary_type,
          onChange: (e) => dispatch(addPackageItineraryType(e.target.value)),
        }}
        stylesProps={{ marginTop: "20px" }}
        options={itinerayTypeArr[0]}
        showInputError={showInputError}
      />

      <SelectField
        label="Itinerary sub type"
        selectFieldAttributes={{
          value: itinerary_sub_type,
          onChange: (e) => dispatch(addPackageItinerarySubType(e.target.value)),
        }}
        stylesProps={{ marginTop: "20px" }}
        options={itinerayTypeArr[1]}
        showInputError={showInputError}
      />
    </>
  );
}
