import {
  DAY_NO,
  INDEX,
  AC_AVAILABILITY,
  CAR_NAME,
  CAR_TYPE,
  DESCRIPTION,
  SOURCE,
  DESTINATION,
  HEADING,
  NO_OF_SEATERS,
  RESET_CAR_FORM,
  CAR_INFO_UPDATE,
} from "../../actions/CarTravelFormAction";
/**
 * @type {import("./carTraveltypes").CarTravelState}
 */

const initialState = {
  id: null,
  day_no: "",
  index: "",
  activity_type: "travel_car",
  ac_non: "",
  car_name: "",
  car_type: "",
  description: "",
  dest: "",
  source: "",
  heading: "",
  no_of_searters: "",
};

/**
 * @returns {import("./carTraveltypes").CarTravelState}
 */

export function CarTravelFormReducer(state = initialState, action) {
  switch (action.type) {
    case DAY_NO:
      return {
        ...state,
        day_no: action.payload,
      };
    case INDEX:
      return {
        ...state,
        index: action.payload,
      };
    case AC_AVAILABILITY:
      return {
        ...state,
        ac_non: action.payload,
      };
    case CAR_NAME:
      return {
        ...state,
        car_name: action.payload,
      };
    case CAR_TYPE:
      return {
        ...state,
        car_type: action.payload,
      };
    case DESCRIPTION:
      return {
        ...state,
        description: action.payload,
      };
    case SOURCE:
      return {
        ...state,
        source: action.payload,
      };
    case DESTINATION:
      return {
        ...state,
        dest: action.payload,
      };
    case HEADING:
      return {
        ...state,
        heading: action.payload,
      };
    case NO_OF_SEATERS:
      return {
        ...state,
        no_of_searters: action.payload,
      };
    case CAR_INFO_UPDATE:
      return {
        ...action.payload,
        isUpdate: true,
      };
    case RESET_CAR_FORM:
      return initialState;
    default:
      return state;
  }
}
