import React from "react";
import loadingSvg from "../../../images/loader.svg";
/**
 *  @typedef {React.DOMAttributes<HTMLButtonElement> & {
 *   variant: "Primary" | "Secondary" | "Danger" | "Success" | "outlined-success" | "outlined-danger", styles: React.CSSProperties, children: React.ReactNode , disabled:boolean, loading:boolean
 * }} Props
 */

/**
 * @param {Props} props
 */
export default function Button({
  variant = "Primary",
  children,
  styles,
  disabled = false,
  loading = false,
  ...props
}) {
  /**
   *
   * @returns  {React.CSSProperties}
   */
  function getBackgroundAndTextColor() {
    if (variant === "Primary") {
      return { backgroundColor: "#55b3e5", color: "#212121", border: "none" };
    } else if (variant === "Secondary") {
      return { backgroundColor: "#1f2937", color: "white", border: "none" };
    } else if (variant === "Success") {
      return { backgroundColor: "#16a34a", color: "white", border: "none" };
    } else if (variant === "outlined-success") {
      return {
        background: "#dcfce7",
        color: "green",
        border: "2px solid green",
      };
    } else if (variant === "outlined-danger") {
      return { background: "#fee2e2", color: "red", border: "2px solid red" };
    } else {
      return { backgroundColor: "#dc2626", color: "white", border: "none" };
    }
  }

  return (
    <button
      disabled={disabled}
      {...props}
      style={{
        ...getBackgroundAndTextColor(),
        padding: "10px 1.6rem",
        fontFamily: "Poppins",
        borderRadius: "8px",
        ...styles,
      }}
    >
      {loading && (
        <img
          src={loadingSvg}
          alt="loading"
          height="20"
          width="20"
          style={{ marginRight: "5px" }}
        />
      )}
      {children}
    </button>
  );
}
