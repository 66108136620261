import React, { useState } from "react";
import { assignTeamMeber } from "../../../../controller/teamController";
import "../../../../model/typeDefs";
import { message } from "antd";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { logout } from "../../../../store/actions/AuthActions";

/**
 * @typedef {object} Props
 * @property {TeamMemberModel[]} teamMemberList
 * @property {number | null} defaultAssingId
 * @property {string} guestId
 */

/**
 * @param {Props} props
 * @returns {JSX.Element} render team member selector
 */
function TeamMemberAssing({
  teamMemberList,
  defaultAssingId,
  guestId,
  history,
}) {
  const [memberId, setMemberId] = useState(
    defaultAssingId !== null ? `${defaultAssingId}` : ""
  );
  const dispatch = useDispatch();
  /**
   * @param {React.ChangeEvent<HTMLSelectElement>} event
   */
  async function handleAssign(event) {
    setMemberId(event.target.value);
    if (event.target.value !== "") {
      const res = await assignTeamMeber(event.target.value, guestId);
      if (res.statusCode === 200) {
        message.success(
          res.success !== null ? res.success : "Assing successful"
        );
      } else if (res.statusCode === 401) {
        dispatch(logout(history));
      } else {
        message.error(res.error !== null ? res.error : "Something went wrong");
      }
    }
  }

  return (
    <select
      className="rounded"
      value={memberId}
      onChange={(e) => handleAssign(e)}
    >
      <option value="">Select member</option>
      {teamMemberList.length > 0 &&
        teamMemberList.map((data) => (
          <option key={data.id} value={data.userId}>
            {data.name}
          </option>
        ))}
    </select>
  );
}

export default withRouter(TeamMemberAssing);
