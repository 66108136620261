import React from "react";
import componentStyle from "../../../css/components.module.css";
import ModalLayout from "./ModalLayout";
/**
 * @typedef {object} Props
 * @property {string} alertMessage
 * @property {string} subMessage
 * @property {(data)=>void} confirmButtonAction
 * @property {React.CSSProperties} confirmButtonStyle
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setModalVisibility
 */
/**
 * @param {Props} props
 */
export default function AlertModal({
  alertMessage,
  subMessage = "",
  confirmButtonAction,
  confirmButtonStyle,
  setModalVisibility,
}) {
  function handleConfirmButtonAction() {
    confirmButtonAction();
    setModalVisibility(false);
  }

  return (
    <ModalLayout modalCloseAction={() => setModalVisibility(false)}>
      <h1 style={{ fontSize: "1.5rem" }}>{alertMessage}</h1>
      {subMessage !== "" && (
        <h3 style={{ fontSize: "14px", color: "#9A9A9A", marginTop: "10px" }}>
          {subMessage}
        </h3>
      )}
      <div
        style={{
          marginTop: "2rem",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <div>
          <button
            style={{ marginRight: "20px", border: "none", background:"white" }}
            onClick={() => setModalVisibility(false)}
          >
            cancel
          </button>
          <button
            style={confirmButtonStyle}
            className={componentStyle.btnDanger}
            onClick={handleConfirmButtonAction}
          >
            confirm
          </button>
        </div>
      </div>
    </ModalLayout>
  );
}
