import React from "react";
import tableStyles from "./table.module.css";

/**
 * @typedef {object} Props
 * @property {React.CSSProperties} styles
 *
 */
/**
 * @param {Props} props
 */
export default function Table({ children, styles }) {
  return (
    <table className={tableStyles.table} style={{ ...styles }}>
      {children}
    </table>
  );
}
