import React from "react";
import componentStyles from "../../../css/components.module.css";
import layoutStyles from "../../../css/layout.module.css";

/**
 * @typedef {object} Props
 * @property {string} label
 * @property {string[]|number[]} options
 * @property {React.CSSProperties} stylesProps
 * @property {boolean} showInputError
 * @property {React.SelectHTMLAttributes<HTMLSelectElement>} selectFieldAttributes
 */

/**
 * @param {Props} props
 */
export default function SelectField({
  label,
  selectFieldAttributes,
  stylesProps,
  options,
  showInputError = false,
}) {
  return (
    <div className={layoutStyles.flexColStart} style={stylesProps}>
      <label className={componentStyles.label}>{label}</label>
      <select className={componentStyles.inputField} {...selectFieldAttributes}>
        <option value="">select {label}</option>
        {options.length > 0 &&
          options.map((data, index) => (
            <option key={index} value={data}>
              {data}
            </option>
          ))}
      </select>
      {showInputError && selectFieldAttributes.value === "" ? (
        <p style={{ color: "red" }}>Please select {label}</p>
      ) : (
        <></>
      )}
    </div>
  );
}
