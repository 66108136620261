import React, { useEffect, useRef, useState } from "react";
import ModalLayout from "../../global/ModalLayout";
import { fetchSightSeeingList } from "../../../../controller/PackageController";
import { useDispatch, useSelector } from "react-redux";
import InputField from "../../global/InputField";
import {
  addSeightSeenDay,
  addSightSeenDescription,
  addSightSeenDuration,
  addSightSeenHeading,
  addSightSeenId,
  addSightSeenIndex,
  addSightSeenName,
  addSightSeendImage,
  resetSightSeenState,
} from "../../../../store/actions/SightSeenAction";
import { message } from "antd";
import componentStyles from "../../../../css/components.module.css";
import layoutStyles from "../../../../css/layout.module.css";
import {
  addWidgetInfo,
  updateSightSeenInfo,
} from "../../../../store/actions/PackageAction";

/**
 * @typedef {object} Props
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setShowSightSeedingFormModal
 *@property {number} day
 */

/**
 * @param {Props} props
 */

export default function SightSeeningFormModal({
  setShowSightSeedingFormModal,
  day,
}) {
  const { complete_tour_data } = useSelector((state) => state.package);
  const widgetIndex = useRef(`${complete_tour_data[day - 1].length + 1}`);
  const [inputError, setInputError] = useState(false);
  const dispatch = useDispatch();
  /**
   * @type {import ("../../../../model/sighseeingModel").default[]}
   */
  const sigthSeenListType = [];
  const [sightSeenList, setSightSeenList] = useState(sigthSeenListType);
  /**
   *@type {import("../../../../store/reducers/sightseen/sightSeenStateType").SightSeenStateType}
   */
  const {
    id,
    heading,
    duration,
    day_no,
    index,
    name,
    isUpdate,
    activity_type,
    sightseeing,
    image,
    description,
  } = useSelector((state) => {
    return state.sightSeen;
  });

  async function sightSeenListFetchHandler() {
    const res = await fetchSightSeeingList();
    if (Array.isArray(res)) {
      setSightSeenList(res);
    } else {
      message.error(res.error);
    }
  }

  useEffect(() => {
    if (sightSeenList.length === 0) {
      sightSeenListFetchHandler();
    }
  }, [sightSeenList.length]);

  useEffect(() => {
    if (index === "" && day_no === "") {
      dispatch(addSeightSeenDay(`${day}`));
      dispatch(addSightSeenIndex(widgetIndex.current));
    }
  }, [day, day_no, dispatch, index]);

  /**
   * @param {React.ChangeEvent<HTMLSelectElement>} e
   */
  function handleSightSeenSelector(e) {
    dispatch(addSightSeenName(e.target.value));
    dispatch(
      addSightSeenDescription(
        e.target.options[e.target.selectedIndex].getAttribute(
          "data-description"
        )
      )
    );
    dispatch(
      addSightSeenId(
        e.target.options[e.target.selectedIndex].getAttribute("data-id")
      )
    );
    dispatch(
      addSightSeendImage(
        e.target.options[e.target.selectedIndex].getAttribute("data-image")
      )
    );
  }

  function handleSave() {
    setInputError(false);
    if ( duration !== "" && name !== "") {
      const dataObj = {
        id,
        day_no,
        index,
        activity_type,
        sightseeing,
        duration,
        heading,
        image,
        name,
        description,
      };
      if (!isUpdate) {
        dispatch(addWidgetInfo(dataObj));
      } else {
        dispatch(updateSightSeenInfo(dataObj));
      }
      dispatch(resetSightSeenState());
      setShowSightSeedingFormModal(false);
    } else {
      setInputError(true);
    }
  }

  return (
    <ModalLayout
      modalStyles={{
        width: "35rem",
        height: "26rem",
        overflow: "hidden",
        overflowY: "auto",
      }}
      modalCloseAction={() => {
        dispatch(resetSightSeenState());
        setShowSightSeedingFormModal(false);
      }}
    >
      <div className={layoutStyles.flexColStart}>
        <label className={componentStyles.label}>Sight seens</label>
        <select
          className={componentStyles.inputField}
          onChange={(e) => handleSightSeenSelector(e)}
          value={name}
        >
          <option value="">Select sight seen</option>
          {sightSeenList.length > 0 &&
            sightSeenList.map((data, index) => (
              <option
                key={index}
                data-id={data.id}
                value={data.name}
                data-image={data.image}
                data-description={data.description}
              >
                {data.name}
              </option>
            ))}
        </select>
        {inputError && name === "" ? (
          <p style={{ color: "red" }}>Select a sight seen</p>
        ) : (
          <></>
        )}
      </div>
      <InputField
        label="Heading (optional)"
        stylesProps={{ marginTop: "20px" }}
        inputFieldAttributes={{
          value: heading,
          onChange: (e) => dispatch(addSightSeenHeading(e.target.value)),
        }}
      
      />
      <InputField
        label="Duration (in hour)"
        stylesProps={{ marginTop: "20px" }}
        inputFieldAttributes={{
          type: "number",
          value: duration,
          onChange: (e) => dispatch(addSightSeenDuration(e.target.value)),
        }}
        showInputError={inputError}
      />
      <div
        style={{
          marginTop: "25px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <button
          style={{ border: "none" }}
          onClick={() => {
            dispatch(resetSightSeenState());
            setShowSightSeedingFormModal(false);
          }}
        >
          Close
        </button>
        <button className={componentStyles.btnPrimary} onClick={handleSave}>
          &#x2713; {!isUpdate ? "Save" : "Update"}
        </button>
      </div>
    </ModalLayout>
  );
}
