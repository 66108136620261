import { AiOutlineUnorderedList, AiOutlineOrderedList } from "react-icons/ai";
import MenuBtnContainer from "./MenuBtnContainer";

/**
 * @typedef {object} Props
 * @property {import("@tiptap/react").Editor} editor
 */

/**
 *  @param {Props} props
 *  @returns {JSX.Element}  Renders editor toolbar
 */

export default function List({ editor }) {
  return (
    <>
      <MenuBtnContainer
        buttonActionFunc={() => editor.chain().focus().toggleBulletList().run()}
        toolTipText="List"
      >
        <AiOutlineUnorderedList size="1.2em" />
      </MenuBtnContainer>
      <MenuBtnContainer
        buttonActionFunc={() => editor.chain().focus().toggleOrderedList().run()}
        toolTipText="Number list"
      >
        <AiOutlineOrderedList size="1.2em" />
      </MenuBtnContainer>
    </>
  );
}
