import React from "react";
import ModalLayout from "../../components/global/ModalLayout";
import InputField from "../../components/global/InputField";
import TextArea from "../../components/global/TextArea";
import Button from "../../components/global/Button";

/**
 * @typedef {object} Props
 * @property {import('../../../model/ApplicantModel').ApplicantModel} applicant
 * @property {()=>void} setShowModal
 */

/**
 * @param {Props} props
 */
export default function ApplicantDetailsModal({ applicant, setShowModal }) {
  return (
    <ModalLayout
      modalStyles={{
        width: "50%",
        height: "65%",
        textAlign: "left",
        overflowY: "auto",
      }}
      modalCloseAction={() => setShowModal(false)}
    >
      <InputField
        label="Applicant name"
        inputFieldAttributes={{
          value: applicant.applicantName,
          disabled: true,
        }}
        stylesProps={{ marginBottom: "1.5rem" }}
      />

      <InputField
        label="Applicant Contact number"
        inputFieldAttributes={{
          value: applicant.applicantContactNumber,
          disabled: true,
        }}
        stylesProps={{ marginBottom: "1.5rem" }}
      />
      <InputField
        label="Applicant Email"
        inputFieldAttributes={{
          value: applicant.applicantEmail,
          disabled: true,
        }}
        stylesProps={{ marginBottom: "1.5rem" }}
      />
      <TextArea
        label="Why join us"
        textAreaAttributes={{
          value: applicant.reasonToJoin,
          disabled: true,
        }}
      />
      <div style={{marginTop:"2rem", display:"flex", justifyContent:"flex-end"}}>
        <a href={applicant.resume} target="_blank" rel="noreferrer">
          {" "}
          <Button variant="Secondary">Resume</Button>
        </a>
      </div>
    </ModalLayout>
  );
}
