/**
 * @typedef {object} BlogModel
 * @property {number} id
 * @property {string} heading
 * @property {string} authorName
 * @property {string} authorImageUrl
 * @property {string} readTime
 * @property {string} blogStatus
 * @property {string[]} tags
 * @property {File} firstContentImageUrl
 * @property {string} firstContentText
 * @property {string} secondContentImageUrl
 * @property {string} secondContentText
 * @property {string} thirdContentImageUrl
 * @property {string} thirdContentText
 */

/**
 * @param {any} data
 * @returns {BlogModel}
 */
export function createBlogObject(data) {
  /**
   * @type {BlogModel}
   */
  const result = {
    id: data.id,
    heading: data.heading,
    authorName: data.author_name,
    authorImageUrl: data.author_image !== null ? data.author_image : "",
    readTime: data.read_time,
    blogStatus: data.blog_status,
    tags: data.tag_list,
    firstContentImageUrl: data.image_one,
    firstContentText: data.content_one,
    secondContentImageUrl: data.image_two !== null ? data.image_two : "",
    secondContentText: data.content_two !== null ? data.content_two : "",
    thirdContentImageUrl: data.image_three !== null ? data.image_three : "",
    thirdContentText: data.content_three !== null ? data.content_three : "",
  };
  return result;
}
