import axios from "axios";
import { handleException } from "../util/handleException";
import { getToken } from "../util/tokenHandler";
import { HttpResponse } from "../model/HttpResponse";
import { createBlogObject } from "../model/BlogModel";

const createBlogAPI = "/wft_admin/blog-create";
const blogListAPI = "/wft_admin/blog-list";
const blogDetailsAPI = "/wft_admin/blog-detail";
const updateBlogDetailAPI = "/wft_admin/blog-update";
const changeBlogStatusAPI = "/wft_admin/blog-status-change";

/**
 * @typedef {object} BlogControllerProps
 * @property {number} id
 ** @property {string} heading
 * @property {string} authorName
 * @property {File|undefined} authorImage
 * @property {string} readTime
 * @property {string} blogStatus
 * @property {string[]} tags
 * @property {File} firstContentImage
 * @property {string} firstContentText
 * @property {File|undefined} secondContentImage
 * @property {string} secondContentText
 * @property {File|undefined} thirdContentImage
 * @property {string} thirdContentText
 */

/**
 * @param {BlogControllerProps} props
 */
export async function saveBlog({
  heading,
  authorName,
  authorImage,
  readTime,
  tags,
  blogStatus,
  firstContentText,
  firstContentImage,
  secondContentText,
  secondContentImage,
  thirdContentText,
  thirdContentImage,
}) {
  try {
    const formData = new FormData();
    formData.append("heading", heading);
    formData.append("author_name", authorName);
    authorImage !== undefined && formData.append("author_image", authorImage);
    formData.append("read_time", readTime);
    formData.append("tag", tags.toString());
    formData.append("blog_status", blogStatus);
    formData.append("image_one", firstContentImage);
    formData.append("content_one", firstContentText);
    secondContentText !== "" &&
      formData.append("content_two", secondContentText);
    secondContentImage !== undefined &&
      formData.append("image_two", secondContentImage);
    thirdContentText !== "" &&
      formData.append("content_three", thirdContentText);
    thirdContentImage !== undefined &&
      formData.append("image_three", thirdContentImage);

    const res = await axios.post(createBlogAPI, formData, {
      headers: {
        Authorization: `Token ${getToken()}`,
      },
    });
    return new HttpResponse(res.status, "Blog successfuly created");
  } catch (err) {
    return handleException(err);
  }
}

/**
 * @param {"Live"|"Draft"|"Archive"} blogType
 * @param {number} page
 * @returns {Promise<[{results:import("../model/BlogModel").BlogModel[], count:number}|null, HttpResponse|null]>}
 */
export async function getBlogList(blogType = "Live", page = 1) {
  const result = [null, null];
  try {
    const res = await axios.get(blogListAPI, {
      headers: {
        Authorization: `Token ${getToken()}`,
      },
      params: {
        blog_type: blogType,
        page,
      },
    });

    const blogArr = [];
    for (let data of res.data.results) {
      blogArr.push(createBlogObject(data));
    }
    result[0] = { results: blogArr, count: res.data.count };
    return result;
  } catch (err) {
    result[1] = handleException(err);
    return result;
  }
}

/**
 *
 * @param {number} blogId
 * @returns {Promise<[import("../model/BlogModel").BlogModel|null, HttpResponse|null]>}
 */
export async function getBlogDetails(blogId) {
  const result = [null, null];
  try {
    const res = await axios.get(`${blogDetailsAPI}/${blogId}`, {
      headers: {
        Authorization: `Token ${getToken()}`,
      },
    });
    result[0] = createBlogObject(res.data);
    return result;
  } catch (err) {
    result[1] = handleException(err);
    return result;
  }
}

/**
 * @param {BlogControllerProps} props
 */

export async function updateBlogDetails({
  id,
  heading,
  authorName,
  authorImage,
  readTime,
  tags,
  blogStatus,
  firstContentImage,
  firstContentText,
  secondContentImage,
  secondContentText,
  thirdContentImage,
  thirdContentText,
}) {
  try {
    const formData = new FormData();
    formData.append("heading", heading);
    formData.append("author_name", authorName);
    authorImage !== undefined && formData.append("author_image", authorImage);
    formData.append("read_time", readTime);
    formData.append("tag", tags.toString());
    formData.append("blog_status", blogStatus);
    formData.append("image_one", firstContentImage);
    formData.append("content_one", firstContentText);
    secondContentText !== "" &&
      formData.append("content_two", secondContentText);
    secondContentImage !== undefined &&
      formData.append("image_two", secondContentImage);
    thirdContentText !== "" &&
      formData.append("content_three", thirdContentText);
    thirdContentImage !== undefined &&
      formData.append("image_three", thirdContentImage);

    const res = await axios.put(`${updateBlogDetailAPI}/${id}`, formData, {
      headers: {
        Authorization: `Token ${getToken()}`,
      },
    });
    return new HttpResponse(res.status, "Update successful");
  } catch (err) {
    return handleException(err);
  }
}

export async function changeBlogStatus(blogId, blogStatus) {
  try {
    const res = await axios.patch(
      `${changeBlogStatusAPI}/${blogId}`,
      { blog_status: blogStatus },
      {
        headers: {
          Authorization: `Token ${getToken()}`,
        },
      }
    );
    return new HttpResponse(res.status, res.data.message);
  } catch (err) {
    return handleException(err);
  }
}
