
/**
 * @typedef {object} Props
 * @property {import("@tiptap/react").Editor} editor
 */

/**
 *  @param {Props} props
 *  @returns {JSX.Element}  Renders editor toolbar
 */

export default function Heading({ editor }) {
  /**
   *
   * @param {React.ChangeEvent<HTMLSelectElement>} e
   */
  function handleHeading(e) {
    const value = e.target.value;
    if (value !== "") {
      editor
        .chain()
        .focus()
        .toggleHeading({ level: parseInt(value) })
        .run();
    }
  }

  return (
    <>
      <select onChange={(e) => handleHeading(e)} className="toolBar-btn cursor-pointer">
        <option value="">Heading</option>
        <option value="1" style={{fontSize:"2.5rem"}}>Heading</option>
        <option value="2" style={{fontSize:"2rem"}}>Heading</option>
        <option value="3" style={{fontSize:"1.5rem"}}>Heading</option>
        <option value="4" style={{fontSize:"1rem"}}>Heading</option>
      </select>
    </>
  );
}
