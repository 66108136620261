import {
  ADD_WIDGET_INFO,
  ADD_PACKAGE_ACTIVITY,
  ADD_PACKAGE_TOUR_DAY,
  MOVE_DOWN_ACITIVITY,
  MOVE_DOWN_PACKAGE_TOUR_DAY,
  MOVE_UP_ACTIVITY,
  MOVE_UP_PACKAGE_TOUR_DAY,
  PACKAGE_DESCRIPTION,
  PACKAGE_HEADING,
  PACKAGE_HOTEL_TYPE,
  PACKAGE_ITINERARY_SUB_TYPE,
  PACKAGE_ITINERARY_TYPE,
  PACKAGE_MANAGED_BY,
  PACKAGE_MEAL,
  PACKAGE_PRICE,
  REMOVE_WIDGET_DATA,
  REMOVE_PACKAGE_ACTIVITY,
  REMOVE_PACKAGE_TOUR_DAY,
  UPDATE_CAR_TRAVEL_INFO,
  UPDATE_FERRY_TRAVEL_INFO,
  UPDATE_SIGHT_SEEN_INFO,
  ADD_PACKAGE_RATING,
  PACKAGE_NIGHT,
  PACKAGE_DAY,
  INSERT_COMPLETE_PACKAGE_DATA,
  RESET_PACKAGE_STATE,
  IS_PACKAGE_INITIAL_DATA_FILLED,
  UPDATE_FLIGHT_TRAVEL_INFO,
} from "../../actions/PackageAction";
import {
  moveDownDay,
  moveUpDay,
} from "./packageStateChageUtil/DayUpDownStateHandler";
import {
  insertWidgetInfo,
  updateCarTravelInfo,
} from "./packageStateChageUtil/carTravelStateHandler";
import {
  moveDownAcitivityWidget,
  moveUpActivityWidget,
  removeWidgetData,
} from "./packageStateChageUtil/AcitivityWidgetUtil";
import { removePackageTourDay } from "./packageStateChageUtil/removeDayScheduleHandler";
import { updateFerryTravelInfo } from "./packageStateChageUtil/ferryTravelStateHandler";
import "./reduerStateType";
import { updateSightSeenInfo } from "./packageStateChageUtil/sightSeenStateHandler";
import { updateFlightTravelState } from "./packageStateChageUtil/flightTravelStateHandler";

/**
 * @type {PackageState}
 */
const initialState = {
  isUpdate: false,
  isBasicDataFilled: false,
  unique_id: "",
  id: null,
  images: [],
  heading: "",
  description: "",
  managed_by: "",
  rating: "",
  itinerary_type: "",
  itinerary_sub_type: "",
  hotel_type: "",
  meal: "",
  day: "",
  night: "",
  activity: [],
  price: "",
  isCustomized: true,
  complete_tour_data: [],
};
/**
 *
 * @returns {PackageState}
 */

export function PackageReducer(state = initialState, action) {
  switch (action.type) {
    case PACKAGE_HEADING:
      return {
        ...state,
        heading: action.payload,
      };
    case IS_PACKAGE_INITIAL_DATA_FILLED:
      return {
        ...state,
        isBasicDataFilled: action.payload,
      };
    case PACKAGE_DESCRIPTION:
      return {
        ...state,
        description: action.payload,
      };
    case PACKAGE_MANAGED_BY:
      return {
        ...state,
        managed_by: action.payload,
      };
    case PACKAGE_ITINERARY_TYPE:
      return {
        ...state,
        itinerary_type: action.payload,
      };
    case PACKAGE_ITINERARY_SUB_TYPE:
      return {
        ...state,
        itinerary_sub_type: action.payload,
      };
    case PACKAGE_NIGHT:
      return {
        ...state,
        night: action.payload,
      };
    case PACKAGE_DAY:
      return {
        ...state,
        day: action.payload,
      };
    case PACKAGE_HOTEL_TYPE:
      return {
        ...state,
        hotel_type: action.payload,
      };
    case PACKAGE_MEAL:
      return {
        ...state,
        meal: action.payload,
      };
    case ADD_PACKAGE_RATING:
      return {
        ...state,
        rating: action.payload,
      };
    case PACKAGE_PRICE:
      return {
        ...state,
        price: action.payload,
      };
    case ADD_PACKAGE_ACTIVITY:
      return {
        ...state,
        activity: [...state.activity, action.payload],
      };
    case REMOVE_PACKAGE_ACTIVITY:
      return {
        ...state,
        activity: state.activity.filter((data) => data !== action.payload),
      };
    case ADD_PACKAGE_TOUR_DAY:
      return {
        ...state,
        complete_tour_data: [...state.complete_tour_data, []],
      };
    case REMOVE_PACKAGE_TOUR_DAY:
      return {
        ...state,
        complete_tour_data: removePackageTourDay(state, action.payload),
      };
    case MOVE_UP_PACKAGE_TOUR_DAY:
      return {
        ...state,
        complete_tour_data: moveUpDay(state, action.payload),
      };
    case MOVE_DOWN_PACKAGE_TOUR_DAY:
      return {
        ...state,
        complete_tour_data: moveDownDay(state, action.payload),
      };
    case ADD_WIDGET_INFO:
      return {
        ...state,
        complete_tour_data: insertWidgetInfo(state, action.payload),
      };
    case UPDATE_CAR_TRAVEL_INFO:
      return {
        ...state,
        complete_tour_data: updateCarTravelInfo(state, action.payload),
      };
    case UPDATE_FLIGHT_TRAVEL_INFO:
      return {
        ...state,
        complete_tour_data: updateFlightTravelState(state, action.payload),
      };
    case REMOVE_WIDGET_DATA:
      return {
        ...state,
        complete_tour_data: removeWidgetData(state, action.payload),
      };
    case MOVE_UP_ACTIVITY:
      return {
        ...state,
        complete_tour_data: moveUpActivityWidget(state, action.payload),
      };
    case MOVE_DOWN_ACITIVITY:
      return {
        ...state,
        complete_tour_data: moveDownAcitivityWidget(state, action.payload),
      };
    case UPDATE_FERRY_TRAVEL_INFO:
      return {
        ...state,
        complete_tour_data: updateFerryTravelInfo(state, action.payload),
      };
    case UPDATE_SIGHT_SEEN_INFO:
      return {
        ...state,
        complete_tour_data: updateSightSeenInfo(state, action.payload),
      };
    case RESET_PACKAGE_STATE:
      return initialState;
    case INSERT_COMPLETE_PACKAGE_DATA:
      return action.payload;
    default:
      return state;
  }
}
