import React from "react";
import crossImg from "../../../../images/cross.svg";
import ModalLayout from "../../../components/global/ModalLayout";
/**
 * @typedef {object} ExtendedReviewProps
 * @property {import("../../../../model/ReviewListItemModel").ReviewModel} review
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setClose
 */

/**
 * @param {ExtendedReviewProps} props
 */

export default function ExtendedReview({ review, setClose }) {
  return (
    <ModalLayout  modalStyles={{
      width: "35rem",
      height: "30rem",
      overflow: "hidden",
      overflowY: "auto",
    }} modalCloseAction={()=>setClose(false)}>
      <div className="d-flex justify-content-end ">
        <button
          className="btn "
          style={{ color: "#212121" }}
          onClick={() => setClose(false)}
        >
          <img
            style={{ marginRight: "4px" }}
            src={crossImg}
            alt="cross"
            height="10"
            width="10"
          />
          <span>Close</span>
        </button>
      </div>
      <div className="mt-4" style={{ width: "300px" }}>
        <div className="d-flex mb-3 justify-content-between  align-items-center ">
          <span style={{ color: "#374151" }}>Name</span>
          <span style={{ fontWeight: "bold", color: "#374151" }}>
            {review.name}
          </span>
        </div>
        <div className="d-flex mb-3 justify-content-between  align-items-center ">
          <span style={{ color: "#374151" }}>Heading</span>
          <span style={{ fontWeight: "bold", color: "#374151" }}>
            {review.headline}
          </span>
        </div>
        <div className="d-flex mb-3 justify-content-between  align-items-center ">
          <span style={{ color: "#374151" }}>Overall Rating</span>
          <span style={{ fontWeight: "bold", color: "#374151" }}>
            {review.overallRating}
          </span>
        </div>
        <div className="d-flex mb-3 justify-content-between  align-items-center ">
          <span style={{ color: "#374151" }}>Next Trip probability</span>
          <span style={{ fontWeight: "bold", color: "#374151" }}>
            {review.nextTripProbability}
          </span>
        </div>
        <div className="d-flex mb-3 justify-content-between  align-items-center ">
          <span style={{ color: "#374151" }}>Review Topic</span>
          <span style={{ fontWeight: "bold", color: "#374151" }}>
            {review.reviewTopic}
          </span>
        </div>
        <div className="d-flex mb-3 justify-content-between  align-items-center ">
          <span style={{ color: "#374151" }}>Trip Exp Rating</span>
          <span style={{ fontWeight: "bold", color: "#374151" }}>
            {review.tripExpRating}
          </span>
        </div>
        <div className="d-flex mb-3 justify-content-between  align-items-center ">
          <span style={{ color: "#374151" }}>Accommodation Rating</span>
          <span style={{ fontWeight: "bold", color: "#374151" }}>
            {review.accommodationRating}
          </span>
        </div>

        <div className="d-flex mb-3 justify-content-between  align-items-center ">
          <span style={{ color: "#374151" }}>Food Quality Rating</span>
          <span style={{ fontWeight: "bold", color: "#374151" }}>
            {review.foodQualityRating}
          </span>
        </div>
        <div className="d-flex mb-3 justify-content-between  align-items-center ">
          <span style={{ color: "#374151" }}>Customer Service Rating</span>
          <span style={{ fontWeight: "bold", color: "#374151" }}>
            {review.customerServiceRating}
          </span>
        </div>
        <div className="d-flex mb-3 justify-content-between  align-items-center ">
          <span style={{ color: "#374151" }}>Recommendation Rating</span>
          <span style={{ fontWeight: "bold", color: "#374151" }}>
            {review.recommendationRating}
          </span>
        </div>
        <div className="d-flex mb-3 justify-content-between  align-items-center ">
          <span style={{ color: "#374151" }}>Date</span>
          <span style={{ fontWeight: "bold", color: "#374151" }}>
            {review.date}
          </span>
        </div>
      </div>
      <div className="d-flex flex-column mb-3 ">
        <span style={{ color: "#374151" }}>Review Description</span>
        <span
          className="text-break"
          style={{ fontWeight: "bold", color: "#374151", textAlign: "justify" }}
        >
          {review.reviewDescription}
        </span>
      </div>
    </ModalLayout>
  );
}
