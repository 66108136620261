import React, { useEffect, useState } from "react";
import componentStyles from "../../../css/components.module.css";
import rightAngle from "../../../images/right-angle.svg";
import doubleRightAngle from "../../../images/double-right-angle.svg";
import leftAngle from "../../../images/left-angle.svg";
import doubleLeftAngle from "../../../images/double-left-angle.svg";
/**
 * @typedef {object} Props
 * @property {number} totalPages
 * @property {number} currentPage
 * @property {(page:number)=>void} pageClickFunc
 */

/**
 * @param {Props} props
 */
export default function PaginationPageSelectors({
  totalPages,
  currentPage,
  pageClickFunc,
}) {
  const [startingPage, setStartingPage] = useState(1);
  const [endingPage, setEndingPage] = useState(1);

  useEffect(() => {
    if (totalPages) {
      if (totalPages > 5) {
        setEndingPage(5);
      } else {
        setEndingPage(totalPages);
      }
    }
  }, [totalPages]);

  function generateSelectors() {
    if (totalPages > 0) {
      const pages = [];
      for (let x = startingPage; x <= endingPage; x++) {
        pages.push(
          <button
            onClick={() => pageClickFunc(x)}
            style={{
              background: currentPage === x && "#55b3e5",
              overflow: "hidden",
            }}
            className={componentStyles.paginationPageSelector}
            key={x}
          >
            {x}
          </button>
        );
      }
      return pages;
    } else {
      return <></>;
    }
  }

  function nextBatch() {
    if (endingPage + 5 <= totalPages) {
      setStartingPage(endingPage + 1);
      setEndingPage(endingPage + 5);
    }
  }

  function previousBatch() {
    if (startingPage - 5 >= 1) {
      setEndingPage(startingPage - 1);
      setStartingPage(startingPage - 5);
    }
  }

  function goToLastBatch() {
    if (endingPage !== totalPages) {
      setEndingPage(totalPages);
      setStartingPage(totalPages - 4 > 0 ? totalPages - 4 : 1);
    }
  }

  function goToFirstBatch() {
    setStartingPage(1);
    setEndingPage(5 > totalPages ? totalPages : 5);
  }
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div role="button" onClick={goToFirstBatch}>
        <img src={doubleLeftAngle} alt="left-angle" width="15" height="15" />
      </div>
      <div role="button" onClick={previousBatch} style={{ margin: "0px 10px" }}>
        <img src={leftAngle} alt="left-angle" width="15" height="15" />
      </div>
      {generateSelectors()}
      <div role="button" onClick={nextBatch} style={{ margin: "0px 10px" }}>
        <img src={rightAngle} alt="right-angle" width="20" height="20" />
      </div>
      <div role="button" onClick={goToLastBatch}>
        <img src={doubleRightAngle} alt="right-angle" width="20" height="20" />
      </div>
    </div>
  );
}
