import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import PostsReducer from "./reducers/PostsReducer";
import thunk from "redux-thunk";
import { AuthReducer } from "./reducers/AuthReducer";
import todoReducers from "./reducers/Reducers";
import { reducer as reduxFormReducer } from "redux-form";
import { PackageReducer } from "./reducers/packagereducer/PackageReducer";
import { CarTravelFormReducer } from "./reducers/cartravel/CarTravelFormReducer";
import { ferryTravelFormReducer } from "./reducers/Ferrytravel/FerryTravelFormReducer";
import { SightSeenReducer } from "./reducers/sightseen/SightSeenReducer";
import { VoucherReducer } from "./reducers/voucher/VoucherReducer";
import { voucherListReducer } from "./reducers/voucher/VoucherListReducer";
import { quickTicketReducer } from "./reducers/QuickTicketReducer/QuickTicketReducer";
import { flightTravelReducer } from "./reducers/flighttravel/FlightTravelReducer";
const middleware = applyMiddleware(thunk);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
  posts: PostsReducer,
  auth: AuthReducer,
  todoReducers,
  form: reduxFormReducer,
  package: PackageReducer,
  carTravel: CarTravelFormReducer,
  ferryTravel: ferryTravelFormReducer,
  sightSeen: SightSeenReducer,
  flightTravel: flightTravelReducer,
  voucher: VoucherReducer,
  voucherList: voucherListReducer,
  quickTicket: quickTicketReducer,
});

//const store = createStore(rootReducers);

export const store = createStore(reducers, composeEnhancers(middleware));
