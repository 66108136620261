import "../store/reducers/packagereducer/reduerStateType";

/**
 * @typedef {object} ServerObj
 * @property {null|number} id
 *  @property {string} heading
 * @property {string} description
 * @property {string} managed_by
 * @property {string} rating
 * @property {string} itinerary_type
 * @property {string} itinerary_sub_type
 * @property {string} hotel_type
 * @property {string} meal
 * @property {string} activity
 * @property  {string} price
 * @property {string} duration
 * @property {null} accommodation_detail
 * @property {boolean} is_customized
 * @property {DayScheduleWidget[]} complete_tour_data
 */

/**
 *
 * @param {PackageState} packageObj
 */
export function convertPackageDataObjToServerObj(
  packageObj,
  isCustomized = true
) {
  /**
   * @type {ServerObj}
   */
  const serverObj = {};
  serverObj.id = packageObj.id;
  serverObj.heading = packageObj.heading;
  serverObj.description = packageObj.description;
  serverObj.rating = packageObj.rating;
  serverObj.managed_by = packageObj.managed_by;
  serverObj.itinerary_type = packageObj.itinerary_type;
  serverObj.itinerary_sub_type = packageObj.itinerary_sub_type;
  serverObj.hotel_type = packageObj.hotel_type;
  serverObj.meal = packageObj.meal;
  serverObj.activity = packageObj.activity.toString();
  serverObj.duration = `${packageObj.night}N${packageObj.day}D`;
  serverObj.price = packageObj.price;
  serverObj.complete_tour_data = [];
  for (let x = 0; x < packageObj.complete_tour_data.length; x++) {
    serverObj.complete_tour_data = serverObj.complete_tour_data.concat(
      packageObj.complete_tour_data[x]
    );
  }
  serverObj.is_customized = isCustomized;
  return serverObj;
}

export function convertServerPackageObjToPackageObj(data) {
  /**
   * @type {PackageState}
   */
  const packageObj = {};
  packageObj.isBasicDataFilled = true;
  packageObj.isUpdate = true;
  packageObj.unique_id = data.unique_id;
  packageObj.id = data.id;
  packageObj.heading = data.heading;
  packageObj.description = data.description;
  packageObj.managed_by = data.managed_by;
  packageObj.price = data.price;
  packageObj.images = data.images;
  packageObj.rating = data.rating;
  packageObj.day = data.duration.split(/[ND]/)[1];
  packageObj.night = data.duration.split(/[ND]/)[0];
  packageObj.activity = data.activity_list;
  packageObj.meal = data.meal;
  packageObj.itinerary_type = data.itinerary_type;
  packageObj.itinerary_sub_type = data.itinerary_sub_type;
  packageObj.hotel_type = data.hotel_type;
  packageObj.complete_tour_data = [];
  packageObj.isCustomized = data.is_customized;
  if (data.tour_detail) {
    for (let x = 0; x < data.tour_detail.length; x++) {
      /**
       * @type {object[]}
       */
      const dayDetail = data.tour_detail[x]["day_details"];
      packageObj.complete_tour_data.push([]);
      for (let i = 0; i < dayDetail.length; i++) {
        const scheduleObj = dayDetail[i];
        if (scheduleObj.activity_type === "sightseeing") {
          /**
           * @type {SightSeeing}
           */
          const sightSeeingObj = {};

          sightSeeingObj.day_no = scheduleObj.day_no;
          sightSeeingObj.index = `${scheduleObj.index}`;
          sightSeeingObj.activity_type = "sightseeing";
          sightSeeingObj.sightseeing = scheduleObj.sightseeing;
          sightSeeingObj.duration = scheduleObj.duration;
          sightSeeingObj.heading = scheduleObj.heading;
          sightSeeingObj.image = scheduleObj.sightseeing_detail[0].image;
          sightSeeingObj.name = scheduleObj.sightseeing_detail[0].name;
          sightSeeingObj.description = scheduleObj.description;
          sightSeeingObj.id = scheduleObj.id;
          packageObj.complete_tour_data[x].push(sightSeeingObj);
        }
        if (scheduleObj.activity_type === "travel_ferry") {
          /**
           * @type {TravelFerry}
           */
          const ferryTraveObj = {};
          ferryTraveObj.day_no = scheduleObj.day_no;
          ferryTraveObj.index = `${scheduleObj.index}`;
          ferryTraveObj.activity_type = "travel_ferry";
          ferryTraveObj.source = scheduleObj.ferry_detail_json.source;
          ferryTraveObj.dest = scheduleObj.ferry_detail_json.dest;
          ferryTraveObj.type = scheduleObj.ferry_detail_json.type;
          ferryTraveObj.description = scheduleObj.description;
          ferryTraveObj.heading = scheduleObj.heading;
          ferryTraveObj.id = scheduleObj.id;
          packageObj.complete_tour_data[x].push(ferryTraveObj);
        }
        if (scheduleObj.activity_type === "travel_car") {
          /**
           * @type {TravelByCar}
           */
          const carTravelObj = {};
          carTravelObj.id = scheduleObj.id;
          carTravelObj.day_no = scheduleObj.day_no;
          carTravelObj.index = `${scheduleObj.index}`;
          carTravelObj.activity_type = "travel_car";
          carTravelObj.car_type = scheduleObj.car_detail_json.car_type;
          carTravelObj.car_name = scheduleObj.car_detail_json.car_name;
          carTravelObj.no_of_searters =
            scheduleObj.car_detail_json.no_of_searters;
          carTravelObj.ac_non = scheduleObj.car_detail_json.ac_non;
          carTravelObj.source = scheduleObj.car_detail_json.source;
          carTravelObj.dest = scheduleObj.car_detail_json.dest;
          carTravelObj.description = scheduleObj.description;
          carTravelObj.heading = scheduleObj.heading;
          packageObj.complete_tour_data[x].push(carTravelObj);
        }

        if (scheduleObj.activity_type === "travel_flight") {
          /**
           * @type {TravelFlight}
           */
          const flightTravel = {};
          flightTravel.id = scheduleObj.id;
          flightTravel.day_no = scheduleObj.day_no;
          flightTravel.index = `${scheduleObj.index}`;
          flightTravel.activity_type = "travel_flight";
          flightTravel.landing = scheduleObj.flight_detail_json.landing;
          flightTravel.take_off = scheduleObj.flight_detail_json.take_off;
          flightTravel.flight_source =
            scheduleObj.flight_detail_json.flight_source;
          flightTravel.flight_dest = scheduleObj.flight_detail_json.flight_dest;
          flightTravel.flight_date = scheduleObj.flight_detail_json.flight_date;
          flightTravel.flight_name = scheduleObj.flight_detail_json.flight_name;
          flightTravel.flight_duration =
            scheduleObj.flight_detail_json.flight_duration;
          flightTravel.flight_no = scheduleObj.flight_detail_json.flight_no;
          packageObj.complete_tour_data[x].push(flightTravel);
        }
      }
    }
  }
  return packageObj;
}

/**
 * @returns {Array<Array<DayScheduleWidget>>}
 */
export function createDaySchedulesArr(data) {
  const resultArr = [];
  for (let x = 0; x < data.length; x++) {
    /**
     * @type {object[]}
     */
    const dayDetail = data[x]["day_details"];
    if (dayDetail) {
      resultArr.push([]);
      for (let i = 0; i < dayDetail.length; i++) {
        const scheduleObj = dayDetail[i];
        if (scheduleObj.activity_type === "sightseeing") {
          /**
           * @type {SightSeeing}
           */
          const sightSeeingObj = {};

          sightSeeingObj.day_no = scheduleObj.day_no;
          sightSeeingObj.index = `${scheduleObj.index}`;
          sightSeeingObj.activity_type = "sightseeing";
          sightSeeingObj.sightseeing = scheduleObj.sightseeing;
          sightSeeingObj.duration = scheduleObj.duration;
          sightSeeingObj.heading = scheduleObj.heading;
          sightSeeingObj.image = scheduleObj.sightseeing_detail[0].image;
          sightSeeingObj.name = scheduleObj.sightseeing_detail[0].name;
          sightSeeingObj.description = scheduleObj.description;
          sightSeeingObj.id = scheduleObj.id;
          resultArr[x].push(sightSeeingObj);
        }
        if (scheduleObj.activity_type === "travel_ferry") {
          /**
           * @type {TravelFerry}
           */
          const ferryTraveObj = {};
          ferryTraveObj.day_no = scheduleObj.day_no;
          ferryTraveObj.index = `${scheduleObj.index}`;
          ferryTraveObj.activity_type = "travel_ferry";
          ferryTraveObj.source = scheduleObj.ferry_detail_json.source;
          ferryTraveObj.dest = scheduleObj.ferry_detail_json.dest;
          ferryTraveObj.type = scheduleObj.ferry_detail_json.type;
          ferryTraveObj.description = scheduleObj.description;
          ferryTraveObj.heading = scheduleObj.heading;
          ferryTraveObj.id = scheduleObj.id;
          resultArr[x].push(ferryTraveObj);
        }
        if (scheduleObj.activity_type === "travel_car") {
          /**
           * @type {TravelByCar}
           */
          const carTravelObj = {};
          carTravelObj.id = scheduleObj.id;
          carTravelObj.day_no = scheduleObj.day_no;
          carTravelObj.index = `${scheduleObj.index}`;
          carTravelObj.activity_type = "travel_car";
          carTravelObj.car_type = scheduleObj.car_detail_json.car_type;
          carTravelObj.car_name = scheduleObj.car_detail_json.car_name;
          carTravelObj.no_of_searters =
            scheduleObj.car_detail_json.no_of_searters;
          carTravelObj.ac_non = scheduleObj.car_detail_json.ac_non;
          carTravelObj.source = scheduleObj.car_detail_json.source;
          carTravelObj.dest = scheduleObj.car_detail_json.dest;
          carTravelObj.description = scheduleObj.description;
          carTravelObj.heading = scheduleObj.heading;
          resultArr[x].push(carTravelObj);
        }
      }
    }
  }
  return resultArr;
}
