import "../reduerStateType";

/**
 * @param {PackageState} state
 * @param {number} index
 */
export function moveUpDay(state, index) {
  const newArr = [...state.complete_tour_data];
  if (index > 0 && newArr.length > 1) {
    // swap schedules
    const tmp = newArr[index];
    newArr[index] = newArr[index - 1];
    newArr[index - 1] = tmp;
    // changing the day_no value in activities after schedules are swapped
    newArr[index].length > 0 &&
      newArr[index].forEach((data) => {
        data.day_no = `${parseInt(data.day_no) + 1}`;
      });

    newArr[index - 1].length > 0 &&
      newArr[index - 1].forEach((data) => {
        data.day_no = `${parseInt(data.day_no) - 1}`;
      });
  }
  return newArr;
}

/**
 * @param {PackageState} state
 * @param {number} index
 */
export function moveDownDay(state, index) {
  const newArr = [...state.complete_tour_data];
  // swap schedules
  if (index < state.complete_tour_data.length - 1 && newArr.length > 1) {
    const tmp = newArr[index];
    newArr[index] = newArr[index + 1];
    newArr[index + 1] = tmp;

    // changing the day_no value in activities after schedules are swapped
    newArr[index].length > 0 &&
      newArr[index].forEach((data) => {
        data.day_no = `${parseInt(data.day_no) - 1}`;
      });

    newArr[index + 1].length > 0 &&
      newArr[index + 1].forEach((data) => {
        data.day_no = `${parseInt(data.day_no) + 1}`;
      });
  }

  return newArr;
}
