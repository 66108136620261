import React, { useContext } from "react";

/// React router dom
import { Switch, Route } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
/// Dashboard
import Home from "./components/Dashboard/Home";

/// Pages
import Error404 from "./pages/Error404";

import Setting from "./layouts/Setting";
import { ThemeContext } from "../context/ThemeContext";
import Company from "./newPages/Company";
import NewTicket from "./newPages/NewTicket";
import Collections from "./newPages/collection/collections";
import TeamMembers from "./newPages/TeamMember/TeamMembers";
import TeamMemberForm from "./newPages/TeamMember/TeamMemberForm";
import Packages from "./newPages/Packages";
import ViewPackages from "./newPages/Packages/ViewPackages";
import Tickets from "./newPages/Tickets/Tickets";
import Services from "./newPages/Services/Services";
import Blogs from "./newPages/blogs/Blogs";
import CreatePackage from "./newPages/Packages/CreatePackage";
import CreateDaySchedule from "./newPages/Packages/CreateDaySchedule";
import CustomPackages from "./newPages/Packages/CustomPackages";
import EditTicket from "./newPages/Tickets/EditTicket";
import Voucher from "./newPages/voucher/Voucher";
import VoucherForm from "./newPages/voucher/VoucherForm";
import VoucherPreview from "./newPages/voucher/VoucherPreview";
import QuickTicket from "./newPages/quickTickets/QuickTicket";
import QuickTicketPreview from "./newPages/quickTickets/QuickTicketPreview";
import HotelForm from "./newPages/Services/Hotels/HotelForm";
import SightSeenForm from "./newPages/Services/sighseens/SightSeenForm";
import BlogForm from "./newPages/blogs/BlogsForm";
import Jobs from "./newPages/career/Jobs";
import JobForm from "./newPages/career/JobForm";
import Applicants from "./newPages/career/Applicants";

const Markup = () => {
  const { menuToggle } = useContext(ThemeContext);
  const routes = [
    /// Dashboard
    { url: "", component: Home },
    { url: "blank", component: Home },
    { url: "company", component: Company },
    { url: "new-ticket", component: NewTicket },
    { url: "quick-ticket", component: QuickTicket },
    { url: "quick-ticket/preview", component: QuickTicketPreview },
    { url: "Collections", component: Collections },
    { url: "Team-Members", component: TeamMembers },
    { url: "Team-Members/:id", component: TeamMemberForm },
    { url: "packages", component: Packages },
    { url: "packages/create/:packageId", component: CreatePackage },
    { url: "custom-packages", component: CustomPackages },
    { url: "custom-package/create", component: CreatePackage },
    { url: "custom-package/create/:packageId", component: CreatePackage },
    { url: "custom-package/schedule", component: CreateDaySchedule },
    { url: "packages/:id", component: ViewPackages },
    { url: "Tickets", component: Tickets },
    { url: "Tickets/:id", component: EditTicket },
    { url: "Services", component: Services },
    { url: "hotel-form", component: HotelForm },
    { url: "hotel-form/:hotelId", component: HotelForm },
    { url: "sightseen-form", component: SightSeenForm },
    { url: "sightseen-form/:sightseenId", component: SightSeenForm },
    { url: "Blogs", component: Blogs },
    { url: "blog-form", component: BlogForm },
    { url: "blog-form/:blogId", component: BlogForm },
    { url: "voucher", component: Voucher },
    { url: "Voucher/form", component: VoucherForm },
    { url: "Voucher/form/:voucherId", component: VoucherForm },
    { url: "Voucher/preview", component: VoucherPreview },
    { url: "Voucher/preview/:voucherId", component: VoucherPreview },
    { url: "jobs", component: Jobs },
    { url: "job-form", component: JobForm },
    { url: "job-form/:jobId", component: JobForm },
     {url:"applicants/:jobId",component:Applicants}
,
    /// pages

    { url: "page-error-404", component: Error404 },
  ];
  let path = window?.location?.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");
  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}  ${
          menuToggle ? "menu-toggle" : ""
        }`}
      >
        {!pagePath && <Nav />}

        <div className={`${!pagePath ? "content-body" : ""}`}>
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <Setting />
    </>
  );
};

export default Markup;
