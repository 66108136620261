import axios from "axios";
import { HttpResponse } from "../model/HttpResponse";
import { handleException } from "../util/handleException";
import { createPnrListItemObj } from "../model/PnrListItemModel";
const fetchPnrListAPI = "wft_admin/pnr-dropdown-list";
const fetchPnrFilteredListAPI = "wft_admin/pnr-filter-list";

/**
 * @returns {Promise<[{ result:import("../model/PnrListItemModel").PnrListItem[], count:number }|null, HttpResponse|null]>}
 */
export async function getPnrList(pageNum = 1, searchText = "") {
  const result = [null, null];
  const userDetails = localStorage.getItem("userDetails");
  if (userDetails) {
    try {
      const parsedUser = JSON.parse(userDetails);
      const token = parsedUser["user"]["token"];
      const res = await axios.get(
        searchText === "" ? fetchPnrListAPI : fetchPnrFilteredListAPI,
        {
          params: {
            page: pageNum,
            keyword: searchText,
          },
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      const pnrList = [];
      for (let x = 0; x < res.data.results.length; x++) {
        pnrList.push(createPnrListItemObj(res.data.results[x]));
      }
      result[0] = {
        result: pnrList,
        count: res.data.count,
      };
      return result;
    } catch (err) {
      result[1] = handleException(err);
      return result;
    }
  } else {
    result[1] = new HttpResponse(401, null, "Unauthorized");
    return result;
  }
}
