/**
 * @typedef {object} TicketPaymentModel
 * @property {number} id
 * @property {boolean} fullPayment - Indicate that if the payment is a full payment or partial payment
 * @property {string} paymentCost
 * @property {boolean} paymentDone
 * @property {string} paymentDoneDate
 * @property {string} paymentDoneRemarks
 * @property {string} paymentLink
 * @property {string} paymentName
 * @property {number} pnrId
 */

/**
 * @param {any} data
 * @returns {TicketPaymentModel}
 */
export function createTicketPaymentObj(data) {
  /**
   * @type {TicketPaymentModel}
   */
  const result = {
    id: data.id,
    fullPayment: data.full_payment,
    paymentCost: data.payment_cost,
    paymentDone: data.payment_done,
    paymentDoneDate:
      data.payment_done_date !== null ? data.payment_done_date : "",
    paymentDoneRemarks:
      data.payment_done_remarks !== null ? data.payment_done_remarks : "",
    paymentLink: data.payment_link,
    paymentName: data.payment_name,
  };
  return result;
}
