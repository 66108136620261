import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../../store/reducers/QuickTicketReducer/QuickTicketStateType";
import {
  setQuickTicketClassicChildFiveToTwelveCost,
  setQuickTicketClassicChildOneToFiveCost,
  setQuickTicketClassicCost,
  setQuickTicketDelightChildFiveToTwelveCost,
  setQuickTicketDelightChildOneToFiveCost,
  setQuickTicketDelightCost,
  setQuickTicketEconomyChildFiveToTwelveCost,
  setQuickTicketEconomyChildOneToFiveCost,
  setQuickTicketEconomyCost,
  setQuickTicketEliteChildFiveToTwelveCost,
  setQuickTicketEliteChildOneToFiveCost,
  setQuickTicketEliteCost,
  setQuickTicketLuxuryChildFiveToTwelveCost,
  setQuickTicketLuxuryChildOneToFiveCost,
  setQuickTicketLuxuryCost,
  setQuickTicketPremiumChildFiveToTwelveCost,
  setQuickTicketPremiumChildOneToFiveCost,
  setQuickTicketPremiumCost,
} from "../../../store/actions/QuickTicketAction";
import TicketHotelPrice from "./TicketHotelPrice";

/**
 * @typedef {object} Props
 * @property {boolean} inputError
 */

/**
 * @param {Props} props
 */
export default function QuickTicketHotelCostList({ inputError }) {
  const dispatch = useDispatch();
  /**
   * @type {QuickTicket}
   */
  const {
    economyCost,
    economyChildOneToFiveCost,
    economyChildFiveToTwelveCost,
    delightCost,
    delightChildOneToFiveCost,
    delightChildFiveToTwelveCost,
    luxuryCost,
    luxuryChildOneToFiveCost,
    luxuryChildFiveToTwelveCost,
    classicCost,
    classicChildOneToFiveCost,
    classicChildFiveToTwelveCost,
    premiumCost,
    premiumChildOneToFiveCost,
    premiumChildFiveToTwelveCost,
    eliteCost,
    eliteChildOneToFiveCost,
    eliteChildFiveToTwelveCost,
  } = useSelector((state) => state.quickTicket);

  const hotelCostList = [
    [economyCost, economyChildOneToFiveCost, economyChildFiveToTwelveCost],
    [delightCost, delightChildOneToFiveCost, delightChildFiveToTwelveCost],
    [luxuryCost, luxuryChildOneToFiveCost, luxuryChildFiveToTwelveCost],
    [classicCost, classicChildOneToFiveCost, classicChildFiveToTwelveCost],
    [premiumCost, premiumChildOneToFiveCost, premiumChildFiveToTwelveCost],
    [eliteCost, eliteChildOneToFiveCost, eliteChildFiveToTwelveCost],
  ];

  const sethotelCostList = [
    [
      setQuickTicketEconomyCost,
      setQuickTicketEconomyChildOneToFiveCost,
      setQuickTicketEconomyChildFiveToTwelveCost,
    ],
    [
      setQuickTicketDelightCost,
      setQuickTicketDelightChildOneToFiveCost,
      setQuickTicketDelightChildFiveToTwelveCost,
    ],
    [
      setQuickTicketLuxuryCost,
      setQuickTicketLuxuryChildOneToFiveCost,
      setQuickTicketLuxuryChildFiveToTwelveCost,
    ],
    [
      setQuickTicketClassicCost,
      setQuickTicketClassicChildOneToFiveCost,
      setQuickTicketClassicChildFiveToTwelveCost,
    ],
    [
      setQuickTicketPremiumCost,
      setQuickTicketPremiumChildOneToFiveCost,
      setQuickTicketPremiumChildFiveToTwelveCost,
    ],
    [
      setQuickTicketEliteCost,
      setQuickTicketEliteChildOneToFiveCost,
      setQuickTicketEliteChildFiveToTwelveCost,
    ],
  ];
  const hotelTypeList = [
    "Economy",
    "Delight",
    "Luxury",
    "Classic",
    "Premium",
    "Elite",
  ];

  return (
    <div>
      {hotelTypeList.map((data, index) => (
        <TicketHotelPrice
          key={index}
          hotelType={data}
          styles={{ marginTop: "2rem" }}
          hotelCost={hotelCostList[index][0]}
          setHotelCost={(price) => dispatch(sethotelCostList[index][0](price))}
          childOneToFiveCost={hotelCostList[index][1]}
          setChildOneToFiveCost={(price) =>
            dispatch(sethotelCostList[index][1](price))
          }
          childFiveToTwelveCost={hotelCostList[index][2]}
          setChildFiveToTwelveCost={(price) =>
            dispatch(sethotelCostList[index][2](price))
          }
          inputError={inputError}
        />
      ))}
    </div>
  );
}
