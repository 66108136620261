import "../reduerStateType";

/**
 *
 * @param {PackageState} state
 * @param {SightSeeing} payload
 */
export function updateSightSeenInfo(state, payload) {
  const newArr = [...state.complete_tour_data];
  const sightSeeingData =
    newArr[parseInt(payload.day_no) - 1][parseInt(payload.index) - 1];
  if (sightSeeingData.activity_type === "sightseeing") {
    sightSeeingData.sightseeing = payload.sightseeing;
    sightSeeingData.duration = payload.duration;
    sightSeeingData.heading = payload.heading;
    sightSeeingData.image = payload.image;
    sightSeeingData.name = payload.name;
    sightSeeingData.description = payload.description;
  }
  return newArr;
}
