import React, { useCallback, useEffect, useState } from "react";
import { Tab } from "react-bootstrap";
import Button from "../../../components/global/Button";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getHotelList } from "../../../../controller/hotelController";
import { handleControllerResultException } from "../../../../util/handleException";
import Table from "../../../components/global/Table/Table";
import PaginationPageSelector from "../../../components/global/PaginationPageSelectors";
import InputField from "../../../components/global/InputField";

/**
 * @type {import("../../../../model/HotelModel").HotelModel[]}
 */
const hotelListType = [];

export default function Hotels() {
  const [hotelList, setHotelList] = useState(hotelListType);
  const history = useHistory();
  const [searchText, setSearchText] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [isSearched, setIsSearched] = useState(false);

  const handleHotelList = useCallback(
    async (page = 1, searchText = "") => {
      const res = await getHotelList(page, searchText);
      if (res[0] !== null) {
        setHotelList(res[0].results);
        setTotalPages(Math.ceil(res[0].count / 10));
      } else {
        handleControllerResultException(res[1], history);
      }
    },
    [history]
  );

  useEffect(() => {
    handleHotelList();
  }, [handleHotelList]);

  function handleSearch() {
    setCurrentPage(1);
    handleHotelList(1, searchText);
    searchText === "" ? setIsSearched(false):setIsSearched(true);
  }

  return (
    <Tab.Pane eventKey="Hotels">
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Link to="/hotel-form">
          <Button variant="Secondary">&#43; Add Hotel</Button>
        </Link>
      </div>
      <div style={{ display: "flex", alignItems: "center", marginTop: "2rem" }}>
        <InputField
          placeHolder="Search based on name and location"
          inputFieldAttributes={{
            value: searchText,
            onChange: (e) => setSearchText(e.target.value),
          }}
          stylesProps={{ marginRight: "1rem", width: "60%" }}
        />
        <Button
          onClick={handleSearch}
        >
          Search
        </Button>
        {isSearched && (
          <button
            onClick={() => {
              setSearchText("");
              setCurrentPage(1);
              handleHotelList();
              setIsSearched(false)
            }}
            style={{
              border: "none",
              color: "red",
              background: "white",
              transform: "scale(1.5)",
              marginLeft: "1rem",
            }}
          >
            &times;
          </button>
        )}
      </div>
      <Table styles={{ marginTop: "3rem" }}>
        <thead>
          <tr>
            <th>Image</th>
            <th>Hotel name</th>
            <th>Location</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          {hotelList.length > 0 &&
            hotelList.map((data) => (
              <tr key={data.id}>
                <td>
                  <img
                    src={data.hotelLowResImageLink}
                    alt={data.hotelName}
                    height="80"
                    width="80"
                    style={{ objectFit: "fill", borderRadius: "100%" }}
                  />
                </td>
                <td>{data.hotelName}</td>
                <td>{data.hotelLocation}</td>
                <td>
                  <Link to={`/hotel-form/${data.id}`}>
                    <Button variant="Primary">Edit</Button>
                  </Link>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      <div
        style={{ marginTop: "2rem", display: "flex", justifyContent: "center" }}
      >
        <PaginationPageSelector
          pageClickFunc={(page) => {
            handleHotelList(page, searchText);
            setCurrentPage(page);
          }}
          totalPages={totalPages}
          currentPage={currentPage}
        />
      </div>
    </Tab.Pane>
  );
}
