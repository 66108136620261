import React, { useState } from "react";
import InputField from "../../components/global/InputField";
import SelectField from "../../components/global/SelectField";
import Button from "../../components/global/Button";
import loaderSvg from "../../../images/loader.svg";
import { updateTicketPaymentInfo } from "../../../controller/ticketController";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { HTTPStatus } from "../../../util/helperObj";
import { message } from "antd";
import { handleControllerResultException } from "../../../util/handleException";
/**
 * @typedef {object} Props
 * @property {import('../../../model/TicketPaymentModel').TicketPaymentModel} payment
 */

/**
 * @param {Props} props
 */
export default function TicketPaymentCard({ payment }) {
  const [paymentDate, setPaymentDate] = useState(payment.paymentDoneDate);
  const [paymentRemarks, setPaymentRemarks] = useState(
    payment.paymentDoneRemarks
  );
  const [paymentStatus, setPaymentStatus] = useState(
    payment.paymentDone ? "Paid" : "Not paid"
  );
  const [updating, setUpdating] = useState(false);
  const [showInputError, setShowInputError] = useState(false);
  const history = useHistory();

  async function handleUpdate() {
    if (paymentDate !== "") {
      setUpdating(true);
      const res = await updateTicketPaymentInfo(
        payment.id,
        paymentStatus === "Paid" ? true : false,
        paymentDate,
        paymentRemarks
      );
      setUpdating(false);
      if (res.statusCode === HTTPStatus.SUCCESS) {
        message.success(res.success);
      } else {
        handleControllerResultException(res, history);
      }
    } else {
      setShowInputError(true);
    }
  }

  return (
    <div
      style={{
        padding: "10px",
        border: "1px solid #212121",
        borderRadius: "5px",
        margin: "10px 0px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <p style={{ margin: 0 }}>
          Type :{" "}
          <span style={{ fontWeight: "600" }}>
            {payment.fullPayment ? "Full payment" : "Partial payment"}
          </span>
        </p>
        <p style={{ margin: 0 }}>
          Cost:{" "}
          <span style={{ fontWeight: "bold" }}>{payment.paymentCost}</span>
        </p>
      </div>
      <InputField
        label="Payment date"
        stylesProps={{ textAlign: "left", marginTop: "1rem" }}
        inputFieldAttributes={{
          value: paymentDate,
          onChange: (e) => setPaymentDate(e.target.value),
          type: "date",
        }}
        showInputError={showInputError}
      />
      <InputField
        label="Payment remarks (optional)"
        stylesProps={{ marginTop: "1rem", textAlign: "left" }}
        inputFieldAttributes={{
          value: paymentRemarks,
          onChange: (e) => setPaymentRemarks(e.target.value),
        }}
      />
      <SelectField
        label="Payment status"
        stylesProps={{ marginTop: "1rem", textAlign: "left" }}
        options={["Paid", "Not paid"]}
        selectFieldAttributes={{
          value: paymentStatus,
          onChange: (e) => setPaymentStatus(e.target.value),
        }}
      />
      <div
        style={{
          marginTop: "1rem",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button loading={updating} onClick={handleUpdate} disabled={updating}>
          Update
        </Button>
      </div>
    </div>
  );
}
