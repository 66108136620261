import React, { useCallback, useEffect, useState } from "react";
import Button from "../../components/global/Button";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  changeBlogStatus,
  getBlogList,
} from "../../../controller/blogController";
import { handleControllerResultException } from "../../../util/handleException";
import Table from "../../components/global/Table/Table";
import PaginationPageSelector from "../../components/global/PaginationPageSelectors";
import HorizontalTabs from "../../components/global/HorizontalTabs";
import BlogStatus from "./BlogStatus";
import { HTTPStatus } from "../../../util/helperObj";
import { message } from "antd";

/**
 * @type {import("../../../model/BlogModel").BlogModel[]}
 */
const blogListType = [];

export default function Blogs() {
  const [blogList, setBlogList] = useState(blogListType);
  const [currentTab, setCurrentTab] = useState("Live");
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();

  const handleBlogList = useCallback(
    async (page = 1, tab = "Live") => {
      const res = await getBlogList(tab, page);
      if (res[0] !== null) {
        setBlogList(res[0].results);
        setTotalPages(Math.ceil(res[0].count / 20));
      } else {
        handleControllerResultException(res[1], history);
      }
    },
    [history]
  );

  useEffect(() => {
    handleBlogList();
  }, [handleBlogList]);

  async function handleBlogStatus(blogId, blogStatus) {
    const res = await changeBlogStatus(blogId, blogStatus);
    if (res.statusCode === HTTPStatus.SUCCESS) {
      message.success(res.success);
      setBlogList((prevState) =>
        prevState.filter((data) => data.id !== blogId)
      );
    } else {
      handleControllerResultException(res, history);
    }
  }
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Link to="/blog-form">
          <Button variant="Secondary"> &#43; Add Blog</Button>
        </Link>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ width: "40%" }}>
          <HorizontalTabs
            tabList={["Live", "Draft", "Archive"]}
            currentActiveTab={currentTab}
            onClick={(tab) => {
              setCurrentTab(tab);
              handleBlogList(1, tab);
              setCurrentPage(1);
            }}
          />
        </div>
      </div>
      <div style={{ marginTop: "3rem", overflowX: "auto" }}>
        {blogList.length > 0 ? (
          <Table>
            <thead>
              <tr>
                <th>Author</th>
                <th>Heading</th>
                <th>Status</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {blogList.map((data) => (
                <tr key={data.id}>
                  <td>{data.authorName}</td>
                  <td>{data.heading}</td>
                  <td>
                    <BlogStatus
                      currentStatus={data.blogStatus}
                      onChange={(status) => handleBlogStatus(data.id, status)}
                    />
                  </td>
                  <td>
                    <Link to={`/blog-form/${data.id}`}>
                      <Button>Edit</Button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <p
            style={{
              textAlign: "center",
              fontSize: "1.2rem",
              color: "#212121",
            }}
          >
            No blog found ☹️
          </p>
        )}
      </div>
      {blogList.length > 0 && (
        <div
          style={{
            marginTop: "1rem",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <PaginationPageSelector
            totalPages={totalPages}
            currentPage={currentPage}
            pageClickFunc={(page) => {
              handleBlogList(page);
              setCurrentPage(page);
            }}
          />
        </div>
      )}
    </div>
  );
}
