/**
 * @returns {string|null}
 */

export function getToken() {
  const userDetails = localStorage.getItem("userDetails");
  if (userDetails !== null) {
    const parsedUser = JSON.parse(userDetails);
    const token = parsedUser["user"]["token"];
    return token;
  } else {
    return null;
  }
}
