import React, { useCallback, useEffect, useState } from "react";
import {
  changeTeamMemberStatus,
  getTeamMembers,
} from "../../../controller/teamController";
import { Card } from "react-bootstrap";
import "../../../model/typeDefs";
import styles from "./team.module.css";
import TeamMemberForm from "./TeamMemberForm";
import { useDispatch } from "react-redux";
import { logout } from "../../../store/actions/AuthActions";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import placeHolderImg from "../../../images/placeholderimg.jpg";

/**
 *  @type {TeamMemberModel[]}
 */
const teamMemberListType = [];

/**
 * @type {TeamMemberModel}
 */
const initialTeamMember = {
  id: 0,
  accessType: "",
  activeStatus: false,
  designation: "",
  email: "",
  isAdmin: false,
  isTeam: false,
  joinDate: "",
  name: "",
  profileImage: "",
};

function TeamMembers(props) {
  const [teamMemberList, setTeamMemberList] = useState(teamMemberListType);
  const [formVisible, setFormVisible] = useState(false);
  const dispatch = useDispatch();
  const [selectedTeamMember, setSelectedTeamMember] =
    useState(initialTeamMember);

  const fetchMembers = useCallback(async () => {
    const res = await getTeamMembers();
    if (res[0] !== null) {
      const data = res[0];
      setTeamMemberList(data);
    } else if (res[1].statusCode === 401) {
      dispatch(logout(props.history));
    } else {
      if (res[1].error !== null) {
        alert(res[1].error);
      }
    }
  }, [dispatch, props]);

  useEffect(() => {
    if (teamMemberList.length === 0) {
      fetchMembers();
    }
  }, [teamMemberList.length, fetchMembers]);

  /**
   *
   * @param {number} id
   */
  async function changeStatus(id) {
    const res = await changeTeamMemberStatus(id);
    if (res.statusCode === 200) {
      const newArr = teamMemberList.map((data) => {
        if (data.id === id) {
          data.activeStatus = !data.activeStatus;
        }
        return data;
      });
      setTeamMemberList(newArr);
    } else if (res.statusCode === 401) {
      dispatch(logout(props.history));
    } else {
      alert(res.error);
    }
  }

  function createNewMemberHandler() {
    setSelectedTeamMember(initialTeamMember);
    setFormVisible(true);
  }

  /**
   * @param {TeamMemberModel} member
   */
  function edit(member) {
    setSelectedTeamMember(member);
    setFormVisible(true);
  }

  return (
    <div className={`${styles.posRelative}`}>
      <div className={`${styles.flex}`}>
        <button className={`${styles.grayBtn}`} onClick={fetchMembers}>
          Refresh members list
        </button>
        <button
          className={`${styles.grayBtn}`}
          onClick={createNewMemberHandler}
        >
          New Member
        </button>
      </div>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {teamMemberList.length > 0 &&
          teamMemberList.map((data) => (
            <Card key={data.id} style={{ width: "20rem", margin: "20px" }}>
              <Card.Img
                variant="top"
                src={
                  data.profileImage !== null ? data.profileImage : placeHolderImg
                }
                style={{ objectFit: "fill", height: "300px", width: "300px" }}
              />
              <Card.Body>
                <Card.Title>{data.name}</Card.Title>
                <Card.Text className={`${styles.flex} ${styles.mt12}`}>
                  <span>Joined</span>
                  <span className="font-weight-bold">{data.joinDate}</span>
                </Card.Text>
                <Card.Text className={`${styles.flex} ${styles.mt8}`}>
                  <span>Access type</span>
                  <span className="font-weight-bold">
                    {data.isAdmin ? "Admin" : "Member"}
                  </span>
                </Card.Text>
                <div className={`${styles.flex} ${styles.mt12}`}>
                  <button
                    className={`${styles.transparentBtn}`}
                    onClick={() => changeStatus(data.id)}
                  >
                    {data.activeStatus ? "Active" : "Inactive"}
                  </button>
                  <button
                    className={`${styles.grayBtn}`}
                    style={{ padding: "0px 20px" }}
                    onClick={() => edit(data)}
                  >
                    Edit
                  </button>
                </div>
              </Card.Body>
            </Card>
          ))}
      </div>
      {formVisible && (
        <div>
          <TeamMemberForm
            setFormVisible={setFormVisible}
            teamMember={selectedTeamMember}
          />
        </div>
      )}
    </div>
  );
}

export default withRouter(TeamMembers);
