/**
 * @typedef {object} UserAssignedTicket
 * @property {boolean} id
 * @property {string} customerName
 * @property {string} customerEmail
 * @property {string} customerPhoneNumber
 * @property {string} manager
 * @property {boolean} cancelPayment
 * @property {string} cancelPaymentAmount
 * @property {string} cancelPaymentDate
 * @property {string} cancelPaymentRemark
 * @property {string} cancelPaymentTransaction
 * @property {string} packageCategory
 * @property {string} pnrNumber
 * @property {string} packageType
 * @property {string} totalAmount
 * @property {string} createdAt
 * @property {boolean} cancelStatus
 * @property {string} cancelReason
 */

import { createAtToDate } from "../util/util";

export function createUserAssignedTicketObj(data) {
  /**
   * @type {UserAssignedTicket}
   */
  const result = {
    id: data.id,
    customerName: data.ticket_detail.customer_name,
    customerEmail: data.ticket_detail.customer_email,
    customerPhoneNumber: data.ticket_detail.customer_contact,
    cancelPayment: data.cancel_payment,
    cancelPaymentAmount:
      data.cancel_payment_amount !== null ? data.cancel_payment_amount : "",
    cancelPaymentDate:
      data.cancel_payment_date !== null ? data.cancel_payment_date : "",
    cancelPaymentRemark:
      data.cancel_payment_remark !== null ? data.cancel_payment_remark : "",
    cancelPaymentTransaction:
      data.cancel_payment_transaction !== null
        ? data.cancel_payment_transaction
        : "",
    manager: data.ticket_detail.manager_name,
    packageCategory: data.ticket_detail.package_category,
    pnrNumber: data.ticket_detail.pnr_no,
    packageType: data.ticket_detail.package_type,
    totalAmount: data.ticket_detail.total_amount,
    createdAt: createAtToDate(data.created_at),
    cancelStatus: data.cancel_status,
    cancelReason: data.cancel_reason,
  };
  return result;
}
