import "../reduerStateType";

/**
 * @param {PackageState} state
 * @param {TravelFlight} payload
 */
export function updateFlightTravelState(state, payload) {
  const schedulerArr = [...state.complete_tour_data];
  const flightTravel = schedulerArr[payload.day_no - 1][payload.index - 1];
  if (flightTravel.activity_type === "travel_flight") {
    flightTravel.flight_name = payload.flight_name;
    flightTravel.flight_no = payload.flight_no;
    flightTravel.flight_duration = payload.flight_duration;
    flightTravel.flight_date = payload.flight_date;
    flightTravel.landing = payload.landing;
    flightTravel.flight_dest = payload.flight_dest;
    flightTravel.flight_source = payload.flight_source;
    flightTravel.take_off = payload.take_off;
  }
  return schedulerArr;
}
