import React from "react";
import ActivityWidgetLayout from "../ActivityWidgetLayout";
import { useDispatch } from "react-redux";
import { isUpdateSightSeenInfo } from "../../../../store/actions/SightSeenAction";
import pinImg from "../../../newPages/NewTicket/pin.png";
import "../../../../store/reducers/packagereducer/reduerStateType";
/**
 * @typedef {object}Props
 * @property {SightSeeing} widgetData
 * @property {React.CSSProperties} widgetCardContainerStyle
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setShowSightSeenFormModal
 */

/**
 * @param {Props} props
 */
export default function SightSeenWidget({
  widgetCardContainerStyle,
  widgetData,
  setShowSightSeenFormModal,
}) {
  const dispatch = useDispatch();

  return (
    <ActivityWidgetLayout
      widgetContainerStyle={widgetCardContainerStyle}
      editWidgetAction={() => {
        dispatch(isUpdateSightSeenInfo(widgetData));
        setShowSightSeenFormModal(true);
      }}
      wingetDayIndexValue={{
        day_no: parseInt(widgetData.day_no),
        index: parseInt(widgetData.index),
      }}
    >
      <div>
        <img src={pinImg} alt="pin" height="30" width="20" />{" "}
        <span style={{ fontSize: "15px", color: "#212121" }}>
          {widgetData.name}
        </span>
        <span style={{ fontSize: "15px", color: "#212121", fontWeight:"bold" }}>
          {" "}
          - {widgetData.duration} hour
        </span>
      </div>
      <p style={{color:"#212121", fontSize:"15px", textAlign:"justify"}}>{widgetData.heading}</p>
      <img
        src={widgetData.image}
        alt={widgetData.name}
        height="200"
        width="400"
        style={{ margin: "20px 0px" }}
      />
      <div>
        <h3>About</h3>
        <p style={{ fontSize: "15px", textAlign: "justify", color: "#58616e" }}>
          {widgetData.description}
        </p>
      </div>
    </ActivityWidgetLayout>
  );
}
