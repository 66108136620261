import "../reduerStateType";

/**
 * @param {PackageState} state
 * @param {TravelByCar} payload
 */
export function insertWidgetInfo(state, payload) {
  const newArr = [...state.complete_tour_data];
  newArr[payload.day_no - 1].push(payload);
  return newArr;
}

/**
 * @param {PackageState} state
 * @param {TravelByCar} payload
 */

export function updateCarTravelInfo(state, payload) {
  const newCarScheduleArr = [...state.complete_tour_data];
  const carTravelData =
    newCarScheduleArr[payload.day_no - 1][payload.index - 1];

  if (carTravelData.activity_type === "travel_car") {
    carTravelData.car_type = payload.car_type;
    carTravelData.car_name = payload.car_name;
    carTravelData.no_of_searters = payload.no_of_searters;
    carTravelData.ac_non = payload.ac_non;
    carTravelData.source = payload.source;
    carTravelData.dest = payload.dest;
    carTravelData.heading = payload.heading;
    carTravelData.description = payload.description;
  }
  return newCarScheduleArr;
}

/**
 * @param {PackageState} state
 * @param {TravelByCar} payload
 */
export function removeCarTravelInfo(state, payload) {
  const newCarScheduleArr = [...state.complete_tour_data];
  newCarScheduleArr[payload.day_no - 1].splice(payload.index - 1, 1);
  return newCarScheduleArr;
}
