/**
 * @typedef {object} PnrListItem
 * @property {number}  id
 * @property {string} customerName
 * @property {string} customerEmail
 * @property {string} customerPhoneNumber
 * @property {string} manager
 * @property {string} packageCategory
 * @property {string} pnrNumber
 * @property {string} packageType
 * @property {string} totalAmount
 * @property {string} createdAt
 * @property {boolean} isActive
 */

export function createPnrListItemObj(data) {
  /**
   * @type {PnrListItem}
   */
  const obj = {
    id: data.id,
    customerName: data.customer_name,
    customerEmail: data.customer_email,
    customerPhoneNumber: data.customer_contact,
    manager: data.manager_name,
    packageCategory: data.package_category,
    pnrNumber: data.pnr_no,
    packageType: data.package_type,
    totalAmount: data.total_amount,
    createdAt: data.created_at,
    isActive: data.is_active,
  };
  return obj;
}
