
let token 

export const headers = () => {
    if (!token) {
        let { user } = JSON.parse(localStorage.getItem('userDetails'))
        token = user?.token
    }

    return {
        headers: {
            Authorization: 'token ' + token
        }
    }

}