class CustomPackageListItem {
  /**
   * @type {number}
   */
  id;

  /**
   * @type {string}
   */
  heading;

  /**
   * @type {string}
   */
  duration;

  /**
   * @type {string}
   */
  itineraryType;

  /**
   * @type {string}
   */
  itinerarySubType;

  /**
   * @type {string}
   */
  managedBy;

  /**
   * @type {string}
   */
  price;

  /**
   * @type {string}
   */
  rating;
  /**
   * @type {string}
   */
  uniqueId;

  /**
   *
   * @type {boolean} isActive
   */

  isActive;

  constructor(data) {
    this.id = data.id;
    this.heading = data.heading;
    this.duration = data.duration;
    this.price = data.price;
    this.managedBy = data.managed_by;
    this.rating = data.rating;
    this.itineraryType = data.itinerary_type;
    this.itinerarySubType = data.itinerary_sub_type;
    this.uniqueId = data.unique_id;
    this.isActive = data.is_active;
  }
}

export default CustomPackageListItem;
