import React from "react";
import componentStyles from "../../../css/components.module.css";
import layoutStyles from "../../../css/layout.module.css";

/**
 * @typedef {object} Props
 * @property {string} label
 * @property {React.CSSProperties} stylesProps
 * @property {boolean} showInputError
 * @property {React.TextareaHTMLAttributes<HTMLTextAreaElement>} textAreaAttributes
 */

/**
 * @param {Props} props
 */
export default function TextArea({
  label,
  stylesProps,
  showInputError = false,
  textAreaAttributes,
}) {
  return (
    <div className={layoutStyles.flexColStart} style={stylesProps}>
      <label className={componentStyles.label}>{label}</label>
      <textarea
        className={componentStyles.inputField}
        {...textAreaAttributes}
      />
      {showInputError && textAreaAttributes.value === "" ? (
        <p style={{ color: "red" }}>Please provide {label}</p>
      ) : (
        <></>
      )}
    </div>
  );
}
