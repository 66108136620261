import React from "react";
import { useSelector } from "react-redux";
import "../../../store/reducers/QuickTicketReducer/QuickTicketStateType";
import { convertPriceToIndFormat } from "../../../util/util";
export default function QuickTicketHotelDetailTable() {
  /**
   * @type {QuickTicket}
   */
  const {
    hotelDetails,
    economyCost,
    economyChildOneToFiveCost,
    economyChildFiveToTwelveCost,
    delightCost,
    delightChildOneToFiveCost,
    delightChildFiveToTwelveCost,
    luxuryCost,
    luxuryChildOneToFiveCost,
    luxuryChildFiveToTwelveCost,
    classicCost,
    classicChildOneToFiveCost,
    classicChildFiveToTwelveCost,
    premiumCost,
    premiumChildOneToFiveCost,
    premiumChildFiveToTwelveCost,
    eliteCost,
    eliteChildOneToFiveCost,
    eliteChildFiveToTwelveCost,
  } = useSelector((state) => state.quickTicket);

  /**
   *
   * @param {"Economy"|"Delight"|"Classic"|"Premium"|"Luxury"|"Elite"} hoteType
   */
  function getCosts(hoteType) {
    if (hoteType === "Economy") {
      return [
        economyCost,
        economyChildOneToFiveCost,
        economyChildFiveToTwelveCost,
      ];
    } else if (hoteType === "Delight") {
      return [
        delightCost,
        delightChildOneToFiveCost,
        delightChildFiveToTwelveCost,
      ];
    } else if (hoteType === "Luxury") {
      return [
        luxuryCost,
        luxuryChildOneToFiveCost,
        luxuryChildFiveToTwelveCost,
      ];
    } else if (hoteType === "Classic") {
      return [
        classicCost,
        classicChildOneToFiveCost,
        classicChildFiveToTwelveCost,
      ];
    } else if (hoteType === "Premium") {
      return [
        premiumCost,
        premiumChildOneToFiveCost,
        premiumChildFiveToTwelveCost,
      ];
    } else if (hoteType === "Elite") {
      return [eliteCost, eliteChildOneToFiveCost, eliteChildFiveToTwelveCost];
    }
  }

  return (
    <table style={{width:"100%", fontSize:"15px",textAlign:"center", fontFamily:"Poppins", marginTop:"2rem"}}>
      <thead>
        <tr style={{backgroundColor:"#55b3e5"}}>
          <th style={{border:"1px solid #212121", padding:"10px 20px"}}>Package</th>
          <th  style={{border:"1px solid #212121", padding:"10px 20px"}}>Package Cost</th>
          <th  style={{border:"1px solid #212121", padding:"10px 20px"}}>Traveling child (1 - 5 years / 5 - 12 years)</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(hotelDetails).map((data, index) => (
          <tr key={index}>
            <td style={{textAlign:"left",  border:"1px solid #212121", padding:"10px 20px" }}>
                <div >
                    <span style={{fontWeight:"500", textDecoration: "underline"}}>{data}</span>
                    <p>{hotelDetails[data]}</p>
                </div>
            </td>
            <td style={{ border:"1px solid #212121", padding:"10px 20px"}}>{`Rs: ${convertPriceToIndFormat(getCosts(data)[0])}`}</td>
            <td style={{ border:"1px solid #212121", padding:"10px 20px"}}>
              {" "}
              {getCosts(data)[1] !== ""
                ? `Rs: ${convertPriceToIndFormat(getCosts(data)[1])}`
                : "N/A"}{" "}
              /{" "}
              {getCosts(data)[2]
                ? `Rs: ${convertPriceToIndFormat(getCosts(data)[2])}`
                : "N/A"}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
