import React, { useCallback, useEffect, useState } from "react";
import ModalLayout from "../../../components/global/ModalLayout";
import { userTicketList } from "../../../../controller/ticketController";
import { handleControllerResultException } from "../../../../util/handleException";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Table from "../../../components/global/Table/Table";

/**
 * @typedef {object} Props
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setShowAssignTicketModal
 * @property {number} userId
 */

/**
 * @param {Props} props
 */

export default function AssignedTicketModal({
  setShowAssignTicketModal,
  userId,
}) {
  const history = useHistory();

  /**
   * @type {import("../../../../model/UserTicketModal").UserAssignedTicket[]}
   */
  const ticketListType = [];
  const [ticketList, setTicketList] = useState(ticketListType);
  const ticketListHandler = useCallback(async () => {
    const res = await userTicketList(userId);
    if (res[0] !== null) {
      setTicketList(res[0]);
    } else {
      handleControllerResultException(res[1], history);
    }
  }, [userId, history]);

  useEffect(() => {
    ticketListHandler();
  }, [ticketListHandler]);

  return (
    <ModalLayout
      modalCloseAction={() => setShowAssignTicketModal(false)}
      modalStyles={{
        width: "70%",
        height: "60%",
        overflow: "hidden",
        overflowY: "auto",
      }}
    >
      <div style={{ overflowX: "auto", marginTop: "2rem" }}>
        <Table>
          <thead>
            <tr>
              <th>Pnr no</th>
              <th>Customer name</th>
              <th>Customer email</th>
              <th>Customer phone no</th>
              <th>Manager</th>
              <th>Package Category</th>
              <th>Package type</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {ticketList.length > 0 &&
              ticketList.map((ticket) => (
                <tr key={ticket.id}>
                  <td>{ticket.pnrNumber}</td>
                  <td>{ticket.customerName}</td>
                  <td>{ticket.customerEmail}</td>
                  <td>{ticket.customerPhoneNumber}</td>
                  <td>{ticket.manager}</td>
                  <td>{ticket.packageCategory}</td>
                  <td>{ticket.packageType}</td>
                  <td>
                    <button
                      style={{
                        border: `2px solid ${
                          !ticket.cancelStatus ? "green" : "red"
                        }`,
                        borderRadius: "5px",
                        fontSize: "15px",
                        padding: "5px 10px",
                        color: `${!ticket.cancelStatus ? "green" : "red"}`,
                        backgroundColor: `${
                          !ticket.cancelStatus ? "#dcfce7" : "#fee2e2"
                        }`,
                      }}
                    >
                      {!ticket.cancelStatus ? "Active" : "Cancel"}
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    </ModalLayout>
  );
}
