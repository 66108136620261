import {
  ADD_SIGHT_SEEN_DAY_NO,
  ADD_SIGHT_SEEN_DESCRIPTION,
  ADD_SIGHT_SEEN_DURATION,
  ADD_SIGHT_SEEN_HEADING,
  ADD_SIGHT_SEEN_ID,
  ADD_SIGHT_SEEN_IMAGE,
  ADD_SIGHT_SEEN_INDEX,
  ADD_SIGHT_SEEN_NAME,
  IS_UPDATE_SIGHT_SEEN_INFO,
  RESET_SIGHT_SEEN_STATE,
} from "../../actions/SightSeenAction";

/**
 * @type {import("./sightSeenStateType").SightSeenStateType}
 */
const initialState = {
  isUpdate: false,
  id: null,
  day_no: "",
  index: "",
  activity_type: "sightseeing",
  name: "",
  heading: "",
  description: "",
  duration: "",
  image: "",
  sightseeing: "",
};

/**
 * @returns {import("./sightSeenStateType").SightSeenStateType}
 */
export function SightSeenReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_SIGHT_SEEN_DAY_NO:
      return {
        ...state,
        day_no: action.payload,
      };
    case ADD_SIGHT_SEEN_INDEX:
      return {
        ...state,
        index: action.payload,
      };

    case ADD_SIGHT_SEEN_HEADING:
      return {
        ...state,
        heading: action.payload,
      };
    case ADD_SIGHT_SEEN_NAME:
      return {
        ...state,
        name: action.payload,
      };
    case ADD_SIGHT_SEEN_DESCRIPTION:
      return {
        ...state,
        description: action.payload,
      };
    case ADD_SIGHT_SEEN_DURATION:
      return {
        ...state,
        duration: action.payload,
      };
    case ADD_SIGHT_SEEN_IMAGE:
      return {
        ...state,
        image: action.payload,
      };
    case ADD_SIGHT_SEEN_ID:
      return {
        ...state,
        sightseeing: action.payload,
      };
    case IS_UPDATE_SIGHT_SEEN_INFO:
      return {
        ...action.payload,
        isUpdate: true,
      };
    case RESET_SIGHT_SEEN_STATE:
      return initialState;
    default:
      return state;
  }
}
