import React from "react";
import ActivityWidgetLayout from "../ActivityWidgetLayout";
import { addIsFlightTravelInfoUpdate } from "../../../../store/actions/FlightTravelFormAction";
import { useDispatch } from "react-redux";
import arrowRight from "../../../../icons/feather/arrow-right.svg";
/**
 * @typedef {object}Props
 * @property {TravelFlight} widgetData
 * @property {React.CSSProperties} widgetCardContainerStyle
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setShowFlightTravelModal
 */

/**
 * @param {Props} props
 */

export default function FlightTravelWidget({
  widgetData,
  widgetCardContainerStyle,
  setShowFlightTravelModal,
}) {
  const dispatch = useDispatch();

  function handleUpdate() {
    dispatch(addIsFlightTravelInfoUpdate(widgetData));
    setShowFlightTravelModal(true);
  }
  return (
    <ActivityWidgetLayout
      widgetContainerStyle={widgetCardContainerStyle}
      editWidgetAction={() => handleUpdate()}
      wingetDayIndexValue={{
        day_no: parseInt(widgetData.day_no),
        index: parseInt(widgetData.index),
      }}
    >
      <div style={{display:"flex"}}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: "1.4rem",
            color: "#212121",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span style={{ fontWeight: "500" }}>
              {widgetData.flight_source}
            </span>
            <span style={{ fontWeight: "500", color: "gray" }}>
              {widgetData.take_off}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              margin: "0 2rem",
            }}
          >
            <span
              style={{ fontSize: "13px", fontWeight: "500", color: "gray" }}
            >
              {widgetData.flight_duration}h
            </span>
            <img src={arrowRight} alt="arrow_right" height="30" width="50" />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span style={{ fontWeight: "500" }}>{widgetData.flight_dest}</span>
            <span style={{ fontWeight: "500", color: "gray" }}>
              {widgetData.landing}
            </span>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderLeft: "1px solid gray",
            justifyContent:"center", marginLeft:"2rem", 
            padding:"0px 20px"
          }}
        >
          <span style={{fontWeight:"500", color:"#212121"}}>Flight</span>
          <span style={{fontWeight:"500", marginTop:"5px", color:"#212121"}}>{widgetData.flight_name}</span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderLeft: "1px solid gray",
            justifyContent:"center", marginLeft:"2rem", 
            padding:"0px 20px"
          }}
        >
          <span style={{fontWeight:"500", color:"#212121"}}>Number</span>
          <span style={{fontWeight:"500", marginTop:"5px", color:"#212121"}}>{widgetData.flight_no}</span>
        </div>
        
      </div>
    </ActivityWidgetLayout>
  );
}
