import React, { useState } from "react";
import InputField from "./InputField";
import componentStyle from "../../../css/components.module.css";
import deleteIconSvg from "../../../images/delete.svg";
import editIcon from "../../../images/edit.svg";
/**
 * @typedef {object} Props
 * @property {string} label
 * @property {boolean} showInputError
 * @property {string[]} savedInputList
 * @property {(data:string)=>void} saveInputFunc ---   insert the value into the savedInputList
 * @property {(index:number, data:string)=>void} deleteInputFunc --- takes the input string index as an argument and remove the string from the list
 * @property {(data:string, index:number)} editInputFunc --- takes input string and index value to update the data in savedInputList
 * @property {React.CSSProperties} styleProps
 */

/**
 * @param {Props} props
 */
export default function SevableInputField({
  label,
  showInputError,
  savedInputList = [],
  styleProps,
  saveInputFunc,
  deleteInputFunc,
  editInputFunc,
}) {
  const [value, setValue] = useState("");

  /**
   *  updateIndex value less than 0 means we are not upading any string, if it's greater than equal
   */
  const [updateIndex, setUpdateIndex] = useState(-1);

  function handleSave() {
    if (value !== "") {
      saveInputFunc(value);
      setValue("");
    }
  }

  function cancelUpdate() {
    setValue("");
    setUpdateIndex(-1);
  }

  function handleDelete(index, data) {
    deleteInputFunc(index, data);
    setValue("");
    setUpdateIndex(-1);
  }

  function handleUpdate(index) {
    editInputFunc(value, index);
    setValue("");
    setUpdateIndex(-1);
  }
  return (
    <div style={{ ...styleProps }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{height:"100px",width:"100%", overflowY:"hidden"}}>
          <InputField
            label={label}
            inputFieldAttributes={{
              value: value,
              onChange: (e) => setValue(e.target.value),
            }}
            showInputError={showInputError}
            stylesProps={{ width: "100%" }}
            showErrorCondition={savedInputList.length === 0}
          />
        </div>
        <button
          className={componentStyle.btnPrimary}
           style={{marginLeft:"10px"}}
          onClick={() => {
            if (updateIndex >= 0) {
              handleUpdate(updateIndex);
            } else {
              handleSave();
            }
          }}
        >
          {updateIndex >= 0 ? "Update" : "add"}
        </button>
      </div>
      <div>
        {savedInputList.length > 0 &&
          savedInputList.map((data, index) => (
            <div
              style={{
                backgroundColor: updateIndex !== index ? "#e5e7eb" : "#fed7aa",
                color: "#212121",
                padding: "10px 20px",
                marginTop: "10px",
                borderRadius: "8px",
                display: "flex",
                justifyContent: "space-between",
              }}
              key={index}
            >
              <p
                style={{
                  margin: 0,
                  fontSize: "14px",
                  wordBreak: "break-word",
                  flexGrow: "1",
                }}
              >
                {data}
              </p>
              <div
                style={{
                  marginLeft: "20px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {updateIndex !== index ? (
                  <img
                    onClick={() => {
                      setValue(data);
                      setUpdateIndex(index);
                    }}
                    role="button"
                    src={editIcon}
                    alt="edit"
                    height="15"
                    width="15"
                  />
                ) : (
                  <div
                    onClick={cancelUpdate}
                    role="button"
                    style={{ height: "15", width: "15", padding: "2px" }}
                  >
                    &#215;{" "}
                  </div>
                )}

                <img
                  onClick={() => handleDelete(index, data)}
                  src={deleteIconSvg}
                  style={{ padding: 0, marginLeft: "20px" }}
                  alt="delete"
                  role="button"
                  height="15"
                  width="15"
                />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
