import React, { useState } from "react";
import { updateGuestStatus } from "../../../../controller/collectionController";
import { useDispatch } from "react-redux";
import { logout } from "../../../../store/actions/AuthActions";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { Dropdown } from "react-bootstrap";

/**
 * @typedef {object} LeadStatusProps
 * @property {string} statusValue
 * @property {string} guestId
 */

/**
 * @param {LeadStatusProps} props
 * @returns {JSX.Element}
 */

 function LeadStatus({ statusValue = "Follow-up", guestId,history }) {
  const [selectedOption, setSelectedOption] = useState(statusValue);
  const dispatch = useDispatch();

  /**
   *
   * @param {string} status
   */
  async function update(status) {
    setSelectedOption(status);
    const res = await updateGuestStatus(guestId, status);
    if (res.statusCode === 200) {
      res.success !== null && alert(res.success);
    } else if (res.statusCode === 401) {
      dispatch(logout(history));
    } else {
      res.error !== null && alert(res.error);
    }
  }

  return (
    <div>
      <select className="rounded"  value={selectedOption} onChange={(e) => update(e.target.value)}>
        <option value="Follow-up">Follow-up</option>
        <option value="Converted">Converted</option>
        <option value="Interested">Interested</option>
        <option value="Not Interested">Not Interested</option>
        <option value="Special">Special</option>
      </select>
    </div>
  );
}


export default withRouter(LeadStatus);