// manager information
export const QUICK_TICKET_CUSTOMER_NAME = "quick ticket customer name";
export const QUICK_TICKET_CUSTOMER_EMAIL = "quick ticket customer email";
export const QUICK_TICKET_CUSTOMER_PHONE_NUMBER =
  "quick ticket customer phone number";
export const QUICK_TICKET_MANAGER_NAME = "quick ticket manager name";
export const QUICK_TICKET_MANAGER_EXPERIENCE =
  "quick ticket manager experience";
export const QUICK_TICKET_MANAGER_PHONE_NUMBER =
  "quick ticket manager phone number";
export const QUICK_TICKET_MANAGER_EMAIL = "quick ticket manager email";
export const QUICK_TICKET_TOUR_DURATION = "quick ticker tour duration";
export const QUICK_TICKET_DAY_SCHEDULE = "quick ticket day schede";
export const QUCIK_TICKET_ECONOMY_COST = "quick ticket economy cost";
export const QUICK_TICKET_ECONOMY_CHILD_ADE_1_5_COST =
  "quick ticket economy child age 2 to 5 cost";
export const QUICK_TICKET_ECONOMY_CHILD_ADE_5_12_COST =
  "quick ticket economy child age 5 to 12 cost";

export const QUCIK_TICKET_DELIGHT_COST = "quick ticket delight cost";
export const QUICK_TICKET_DELIGHT_CHILD_ADE_1_5_COST =
  "quick ticket delight child age 2 to 5 cost";
export const QUICK_TICKET_DELIGHT_CHILD_ADE_5_12_COST =
  "quick ticket delight child age 5 to 12 cost";

export const QUCIK_TICKET_LUXURY_COST = "quick ticket luxury cost";
export const QUICK_TICKET_LUXURY_CHILD_ADE_1_5_COST =
  "quick ticket luxury child age 2 to 5 cost";
export const QUICK_TICKET_LUXURY_CHILD_ADE_5_12_COST =
  "quick ticket luxury child age 5 to 12 cost";

export const QUCIK_TICKET_CLASSIC_COST = "quick ticket classic cost";
export const QUICK_TICKET_CLASSIC_CHILD_ADE_1_5_COST =
  "quick ticket classic child age 2 to 5 cost";
export const QUICK_TICKET_CLASSIC_CHILD_ADE_5_12_COST =
  "quick ticket classic child age 5 to 12 cost";

export const QUCIK_TICKET_PREMIUM_COST = "quick ticket premium cost";
export const QUICK_TICKET_PREMIUM_CHILD_ADE_1_5_COST =
  "quick ticket premium child age 2 to 5 cost";
export const QUICK_TICKET_PREMIUM_CHILD_ADE_5_12_COST =
  "quick ticket premium child age 5 to 12 cost";

export const QUCIK_TICKET_ELITE_COST = "quick ticket elite cost";
export const QUICK_TICKET_ELITE_CHILD_ADE_1_5_COST =
  "quick ticket elite child age 2 to 5 cost";
export const QUICK_TICKET_ELITE_CHILD_ADE_5_12_COST =
  "quick ticket elite child age 5 to 12 cost";

export const QUICK_TICKET_HOTEL_DETAILS = "quick ticket hotel details";

export const QUICK_TICKET_GUEST_COUNT = "quick ticket guest count";

export function setQuickTicketCustomerName(payload) {
  return {
    type: QUICK_TICKET_CUSTOMER_NAME,
    payload,
  };
}

export function setQuickTicketCustomerEmail(payload) {
  return {
    type: QUICK_TICKET_CUSTOMER_EMAIL,
    payload,
  };
}

export function setQuickTicketCustomerPhoneNumber(payload) {
  return {
    type: QUICK_TICKET_CUSTOMER_PHONE_NUMBER,
    payload,
  };
}

export function setQuickTickerManagerName(payload) {
  return {
    type: QUICK_TICKET_MANAGER_NAME,
    payload,
  };
}

export function setQuickTicketManagerEmail(payload) {
  return {
    type: QUICK_TICKET_MANAGER_EMAIL,
    payload,
  };
}

export function setQuickTicketManagerPhoneNumber(payload) {
  return {
    type: QUICK_TICKET_MANAGER_PHONE_NUMBER,
    payload,
  };
}

export function setQuickTIcketManagerExperience(payload) {
  return {
    type: QUICK_TICKET_MANAGER_EXPERIENCE,
    payload,
  };
}

export function setQuickTicketTourDuration(payload) {
  return {
    type: QUICK_TICKET_TOUR_DURATION,
    payload,
  };
}

export function setQuickTicketDaySchedule(payload) {
  return {
    type: QUICK_TICKET_DAY_SCHEDULE,
    payload,
  };
}

export function setQuickTicketEconomyCost(payload) {
  return {
    type: QUCIK_TICKET_ECONOMY_COST,
    payload,
  };
}

export function setQuickTicketEconomyChildOneToFiveCost(payload) {
  return {
    type: QUICK_TICKET_ECONOMY_CHILD_ADE_1_5_COST,
    payload,
  };
}

export function setQuickTicketEconomyChildFiveToTwelveCost(payload) {
  return {
    type: QUICK_TICKET_ECONOMY_CHILD_ADE_5_12_COST,
    payload,
  };
}

export function setQuickTicketDelightCost(payload) {
  return {
    type: QUCIK_TICKET_DELIGHT_COST,
    payload,
  };
}

export function setQuickTicketDelightChildOneToFiveCost(payload) {
  return {
    type: QUICK_TICKET_DELIGHT_CHILD_ADE_1_5_COST,
    payload,
  };
}

export function setQuickTicketDelightChildFiveToTwelveCost(payload) {
  return {
    type: QUICK_TICKET_DELIGHT_CHILD_ADE_5_12_COST,
    payload,
  };
}

export function setQuickTicketLuxuryCost(payload) {
  return {
    type: QUCIK_TICKET_LUXURY_COST,
    payload,
  };
}

export function setQuickTicketLuxuryChildOneToFiveCost(payload) {
  return {
    type: QUICK_TICKET_LUXURY_CHILD_ADE_1_5_COST,
    payload,
  };
}

export function setQuickTicketLuxuryChildFiveToTwelveCost(payload) {
  return {
    type: QUICK_TICKET_LUXURY_CHILD_ADE_5_12_COST,
    payload,
  };
}

export function setQuickTicketClassicCost(payload) {
  return {
    type: QUCIK_TICKET_CLASSIC_COST,
    payload,
  };
}

export function setQuickTicketClassicChildOneToFiveCost(payload) {
  return {
    type: QUICK_TICKET_CLASSIC_CHILD_ADE_1_5_COST,
    payload,
  };
}

export function setQuickTicketClassicChildFiveToTwelveCost(payload) {
  return {
    type: QUICK_TICKET_CLASSIC_CHILD_ADE_5_12_COST,
    payload,
  };
}

export function setQuickTicketPremiumCost(payload) {
  return {
    type: QUCIK_TICKET_PREMIUM_COST,
    payload,
  };
}

export function setQuickTicketPremiumChildOneToFiveCost(payload) {
  return {
    type: QUICK_TICKET_PREMIUM_CHILD_ADE_1_5_COST,
    payload,
  };
}

export function setQuickTicketPremiumChildFiveToTwelveCost(payload) {
  return {
    type: QUICK_TICKET_PREMIUM_CHILD_ADE_5_12_COST,
    payload,
  };
}

export function setQuickTicketEliteCost(payload) {
  return {
    type: QUCIK_TICKET_ELITE_COST,
    payload,
  };
}

export function setQuickTicketEliteChildOneToFiveCost(payload) {
  return {
    type: QUICK_TICKET_ELITE_CHILD_ADE_1_5_COST,
    payload,
  };
}

export function setQuickTicketEliteChildFiveToTwelveCost(payload) {
  return {
    type: QUICK_TICKET_ELITE_CHILD_ADE_5_12_COST,
    payload,
  };
}

/**
 *
 * @param {QuickTicketHotelDetails} payload
 */
export function setQuickTicketHotelDetails(payload) {
  return {
    type: QUICK_TICKET_HOTEL_DETAILS,
    payload,
  };
}

export function setQuickTicketGuestCount(payload) {
  return {
    type: QUICK_TICKET_GUEST_COUNT,
    payload,
  };
}
