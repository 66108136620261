import React from 'react'
import folder from "./folder.svg"
import All from "./All.svg"
import star from "./star.svg"
import { Nav, Tab } from 'react-bootstrap'
import AddPackage from './AddPackage'
import Popular from './Popular'

function Packages() {

    const tabData = [
        {
          name: "All Packages",
          icon: All,
          content : <AddPackage/>
        },
        {
          name: "Popular Packages",
          icon: star,
          content: <Popular/>
        },
    ]

   

  return (
    <>
     <div className="custom-tab-1 gap-2">
                <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                  <Nav as="ul" className="nav-tabs">
                    {tabData.map((data, i) => (
                      <Nav.Item as="li" key={i}>
                        <Nav.Link eventKey={data.name.toLowerCase()}>
                          <img src={data.icon} />
                          {data.name}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                  <Tab.Content className="pt-4">
                    {tabData.map((data, i) => (
                      <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                        {data.content}
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Tab.Container>
              </div>

    </>
  )
}

export default Packages
