import React, { useState } from "react";
import folder from "./folder.svg";
import { Button } from "antd";
import { Link } from "react-router-dom";
import componentStyle from "../../../css/components.module.css";
import PackageImagesModal from "./PackageImagesModal";

export function PackageCard({
  handleClick,
  id,
  img,
  duration,
  ac,
  itinerary_type,
  itinerary_sub_type,
  hotel_type,
  meal,
  heading,
  description,
}) {
  const [imageModalVisible, setImageModalVisible] = useState(false);
  return (
    <div
      style={{
        boxShadow: "0px 0px 4px #22222250",
        borderRadius: 20,
        padding: "10px",
      }}
    >
      <div
        style={{
          padding: 10,
        }}
        className="d-flex"
      >
        <img
          src={img[0]?.package_images || folder}
          style={{ width: 100, height: 100, margin: 8 }}
        />
        {/* <Carousel>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="holder.js/800x400?text=First slide&bg=373940"
          alt="First slide"
        />
        <Carousel.Caption>
          <h3>First slide label</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="holder.js/800x400?text=Second slide&bg=282c34"
          alt="Second slide"
        />

        <Carousel.Caption>
          <h3>Second slide label</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="holder.js/800x400?text=Third slide&bg=20232a"
          alt="Third slide"
        />

        <Carousel.Caption>
          <h3>Third slide label</h3>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel> */}
        <Link to={"/packages/" + id}>
          <div style={{ marginLeft: 28 }}>
            <h3>{heading}</h3>
            <p
              style={{
                textOverflow: "ellipsis",
                overflowX: "hidden",
                width: "20rem",
                whiteSpace: "nowrap",
              }}
              className=""
            >
              {description}
            </p>
            <div
              style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}
            >
              <p>{duration.slice(0, 1)} Days</p>
              <p>{duration.slice(2, 3)} Nights</p>

              <p className="turncate">{hotel_type} Hotels</p>
              <p className="turncate">{meal}</p>
              <p>{ac}+ Activities</p>
            </div>
            <div
              style={{ alignItems: "center", width: "100%", display: "flex" }}
            >
              <div style={{ flex: 1 }} className="d-flex gap-2 flex-1">
                {[itinerary_type, itinerary_sub_type].map((dat) => (
                  <h6
                    style={{
                      color: "#F19838",
                      padding: 5,
                      background: "#E6E6E6",
                    }}
                  >
                    {dat}
                  </h6>
                ))}
              </div>
            </div>
          </div>
        </Link>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <button
          className={componentStyle.btnPrimary}
          style={{ marginRight: "10px" }}
          onClick={() => setImageModalVisible(true)}
        >
          Images
        </button>
        <Link to={`/packages/create/${id}`}>
          <button className={componentStyle.btnPrimary}>Edit</button>
        </Link>
      </div>
      {imageModalVisible && (
        <PackageImagesModal
          packageId={id}
          setModalVisibility={setImageModalVisible}
        />
      )}
    </div>
  );
}
