import React, { useCallback, useEffect, useState } from "react";
import "../../../../model/typeDefs";
import { Tab } from "react-bootstrap";
import { getReviewList } from "../../../../controller/collectionController";
import ExtendedReview from "./ExtendedReview";
import componentStyles from "../../../../css/components.module.css";
import { useDispatch } from "react-redux";
import { logout } from "../../../../store/actions/AuthActions";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import ReviewStatus from "./ReviewStatus";
import Table from "../../../components/global/Table/Table";
import PaginationPageSelectors from "../../../components/global/PaginationPageSelectors";
/**
 * @type {ReviewModel[]}
 */
const reviewListType = [];

/**
 * @type {ReviewModel};
 */

const selectedReviewType = {
  id: 0,
  accommodationRating: "",
  customerServiceRating: "",
  foodQualityRating: "",
  headline: "",
  name: "",
  nextTripProbability: "",
  overallRating: "",
  recommendationRating: "",
  reviewDescription: "",
  reviewTopic: "",
  tripExpRating: "",
};

function ReviewList({ history }) {
  const [reviewList, setReviewList] = useState(reviewListType);
  const [selectedReview, setSelectedReview] = useState(selectedReviewType);
  const [visibleDetails, setVisibleDetails] = useState(false);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  /**
   * @param {number} page
   */
  const fetchList = useCallback(
    async (page = 1) => {
      const res = await getReviewList(page);
      if (res[0] !== null) {
        setReviewList(res[0].result);
        setNumberOfPages(Math.ceil(res[0].count / 20));
      } else if (res[1].statusCode === 401) {
        dispatch(logout(history));
      } else {
        if (res[1].error !== null) {
          alert(res[1].error);
        }
      }
    },
    [dispatch, history]
  );

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  async function handlePageChange(pageNumber) {
    await fetchList(pageNumber);
    setCurrentPage(pageNumber);
  }

  /**
   *
   * @param {ReviewModel} review
   */
  const handleReviewSelect = (review) => {
    setSelectedReview(review);
    setVisibleDetails(true);
  };

  return (
    <Tab.Pane eventKey="Reviews">
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Headline</th>
            <th>Trip Exp Rating</th>

            <th>Recommendation Rating</th>
            <th>Next Trip</th>
            <th>Overall Rating</th>
            <th>Date</th>
            <th>Extended view</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {reviewList.length > 0 &&
            reviewList.map((data) => (
              <tr key={data.id}>
                <td>
                  <h5>{data.name}</h5>
                </td>
                <td>
                  <h5>{data.headline}</h5>
                </td>
                <td>
                  <h5>{data.tripExpRating}</h5>
                </td>

                <td>
                  <h5>{data.recommendationRating}</h5>
                </td>
                <td>
                  <h5>{data.nextTripProbability}</h5>
                </td>
                <td>
                  <h5>{data.overallRating}</h5>
                </td>
                <td>
                  <h5>{data.date}</h5>
                </td>
                <td>
                  <button
                    className={componentStyles.btnPrimary}
                    onClick={() => handleReviewSelect(data)}
                  >
                    View
                  </button>
                </td>
                <td>
                  <ReviewStatus
                    reviewId={data.id}
                    defaultStatus={data.reviewStatus}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      {numberOfPages > 1 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "2rem",
          }}
        >
          <PaginationPageSelectors
            totalPages={numberOfPages}
            currentPage={currentPage}
            pageClickFunc={(page) => handlePageChange(page)}
          />
        </div>
      )}
      {visibleDetails && (
        <div>
          <ExtendedReview
            review={selectedReview}
            setClose={setVisibleDetails}
          />
        </div>
      )}
    </Tab.Pane>
  );
}

export default withRouter(ReviewList);
