import React from "react";
import "../../../../model/typeDefs";
import crossImg from "../../../../images/cross.svg";
import ModalLayout from "../../../components/global/ModalLayout";
/**
 * @typedef {object} Props
 * @property {GuestModel} guest
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setModalVisible
 */

/**
 * @param {Props} props
 * @returns {JSX.Element} render guest details modal
 */

export default function GuestDetailModal({ guest, setModalVisible }) {
  return (
    <ModalLayout
      modalCloseAction={() => setModalVisible(false)}
      modalStyles={{
        width: "35rem",
        height: "30rem",
        overflow: "hidden",
        overflowY: "auto",
      }}
    >
      <div className="d-flex justify-content-end ">
        <button
          className="btn "
          style={{ color: "#212121" }}
          onClick={() => setModalVisible(false)}
        >
          <img
            style={{ marginRight: "4px" }}
            src={crossImg}
            alt="cross"
            height="10"
            width="10"
          />
          <span>Close</span>
        </button>
      </div>
      <div className="mt-4" style={{ width: "300px" }}>
        <div className="d-flex mb-3 justify-content-between  align-items-center ">
          <span style={{ color: "#374151" }}>Full name</span>
          <span style={{ fontWeight: "bold", color: "#374151" }}>
            {guest.fullName}
          </span>
        </div>
        <div className="d-flex mb-3 justify-content-between  align-items-center ">
          <span style={{ color: "#374151" }}>Lead ID</span>
          <span
            style={{ fontWeight: "bold", color: "#374151" }}
            className="text-warning"
          >
            #{guest.guestId}
          </span>
        </div>
        <div className="d-flex mb-3 justify-content-between  align-items-center ">
          <span style={{ color: "#374151" }}>Email ID</span>
          <span style={{ fontWeight: "bold", color: "#374151" }}>
            {guest.emailId}
          </span>
        </div>
        <div className="d-flex mb-3 justify-content-between  align-items-center ">
          <span style={{ color: "#374151" }}>Guests</span>
          <span style={{ fontWeight: "bold", color: "#374151" }}>
            {guest.guestDetail}
          </span>
        </div>
        <div className="d-flex mb-3 justify-content-between  align-items-center ">
          <span style={{ color: "#374151" }}>Package Type</span>
          <span style={{ fontWeight: "bold", color: "#374151" }}>
            {guest.duration}
          </span>
        </div>
        <div className="d-flex mb-2 justify-content-between  align-items-center ">
          <span style={{ color: "#374151" }}>Budget</span>
          <span style={{ fontWeight: "bold", color: "#374151" }}>
            {guest.budget}
          </span>
        </div>
        <div className="d-flex mb-3 justify-content-between  align-items-center ">
          <span style={{ color: "#374151" }}>Phone number</span>
          <span style={{ fontWeight: "bold", color: "#374151" }}>
            {guest.phoneNo}
          </span>
        </div>
        <div className="d-flex mb-3 justify-content-between  align-items-center ">
          <span style={{ color: "#374151" }}>Date</span>
          <span style={{ fontWeight: "bold", color: "#374151" }}>
            {guest.date}
          </span>
        </div>
        <div className="d-flex mb-3 justify-content-between  align-items-center ">
          <span style={{ color: "#374151" }}>Time</span>
          <span style={{ fontWeight: "bold", color: "#374151" }}>
            {guest.time}
          </span>
        </div>
      </div>
      <div className=" d-flex flex-column mb-3 ">
        <span style={{ color: "#374151" }}>Notes</span>
        <span
          className="text-break"
          style={{ fontWeight: "bold", color: "#374151", textAlign: "justify" }}
        >
          {guest.quickNotes}
        </span>
      </div>
    </ModalLayout>
  );
}
